import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DocumentPackageApiService } from '@appShared/api/document-package/document-package.api.service';
import { DocumentPackageWithRedBlank } from '@appShared/api/document-package/models/document-package/document-package-with-red-blank.model';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-document-packages',
  templateUrl: './autocomplete-document-packages.component.html',
  styleUrls: [],
})
/** компонента поля с автозаполнением сотрудников */
export class AutocompleteDocumentPackagesComponent extends ControlBaseComponent implements OnInit {
  /** Показывать крестик сброса */
  @Input()
  isShowClearBtn = false;
  /** Текст ошибки поиска */
  @Input()
  searchErrorMsg: string;
  /** Подсказка при поиске */
  @Input()
  searchTooltip: string;
  /** Дополнительные параметры для поискового запроса, опционально */
  @Input()
  apiQueryParams: Object;
  /** отображать только ПД для изменяемых документов (другой url запроса)*/
  @Input()
  isCapableForAffect: boolean;
  /** отображать только ПД для связанных документов (другой url запроса)*/
  @Input()
  isCapableForOtherAffect: boolean;
  /** отображать только ПД для журнала регистрационных номеров (другой url запроса)*/
  @Input()
  isReservedStatus: boolean;
  /** Айди пакета документов для проставления в форму */
  @Input()
  public set startDocPackageId(packageId: number) {
    this.getDocumentPackageFullInfo(packageId);
  }

  public filterControl: UntypedFormControl = new UntypedFormControl();
  public optionsList$: Observable<DocumentPackageWithRedBlank[]>;
  public optionLabel: string;
  public isLoaderActive: boolean;

  constructor(private documentPackageApi: DocumentPackageApiService) {
    super();
  }
  ngOnInit() {
    this.setOptionLabel();
    this.subscribeToInputFilter();
  }

  private setOptionLabel(): void {
    this.optionLabel = 'name';
    if (this.isReservedStatus) {
      this.optionLabel = 'documentPackageNameAndNumber';
    }
  }

  /** подписка на изменение поля фильтрации */
  private subscribeToInputFilter() {
    const otherOptions = {
      isCapableForAffect: this.isCapableForAffect,
      isReservedStatus: this.isReservedStatus,
      isCapableForOtherAffect: this.isCapableForOtherAffect,
    };

    this.optionsList$ = this.filterControl.valueChanges.pipe(
      filter((query) => query?.length > 2),
      distinctUntilChanged(),
      tap(() => {
        this.isLoaderActive = true;
      }),
      debounceTime(300),
      switchMap((filterString) =>
        this.documentPackageApi
          .searchDocumentPackagesByNameOrNumber(filterString, this.apiQueryParams, otherOptions)
          .pipe(
            finalize(() => {
              this.isLoaderActive = false;
            }),
            catchError(() => of([]))
          )
      )
    );
  }

  /** Получаем данные Пакета Документов при наличии айдишника на входе в компонент */
  private getDocumentPackageFullInfo(startDocPackageId: number): void {
    if (!startDocPackageId) {
      return;
    }

    this.documentPackageApi
      .getDocumentPackage(startDocPackageId)
      .pipe(first())
      .subscribe((resp) => {
        this.patchFormControlValue(resp);
      });
  }

  /** Заполняем данные в форму */
  private patchFormControlValue(docInfo: DocumentPackageResponseModel): void {
    const formValue: DocumentPackageWithRedBlank = {
      id: docInfo.id,
      name: docInfo.main.name,
      documentPackageNameAndNumber: `${docInfo.main.name} ${docInfo.packageNumber}`,
      documentPackageNumber: docInfo.packageNumber,
      projectType: docInfo.main.projectType,
      redaction: null,
      author: null,
      registrationNumber: null,
      lastRedBlank: null,
      description: null,
    };
    this.control.patchValue(formValue);
  }
}
