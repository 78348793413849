import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from '@appCore/urls/urls';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { CoveringTextModel } from './models/covering-text.model';

@Injectable({
  providedIn: 'root',
})
export class CoveringTextApiService extends DefaultDataService<CoveringTextModel> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('covering-text', http, httpUrlGenerator);
  }

  public add(coveringTextPackage: CoveringTextModel): Observable<CoveringTextModel> {
    const url = Urls.getCoveringText();

    return this.http.post<CoveringTextModel>(url, coveringTextPackage);
  }

  public getAll(): Observable<CoveringTextModel[]> {
    const url = Urls.getCoveringText();

    return this.http.get<CoveringTextModel[]>(url);
  }

  public getWithQuery(typeId: string): Observable<CoveringTextModel[]> {
    const url = Urls.getCoveringText();
    const queryParams = new HttpParams().append('templateTypeId', typeId);

    return this.http.get<CoveringTextModel[]>(url, { params: queryParams });
  }

  public update(coveringTextPackage: Update<CoveringTextModel>): Observable<CoveringTextModel> {
    const url = Urls.getCoveringText();

    return this.http.put<CoveringTextModel>(url, coveringTextPackage.changes);
  }

  public delete(id: number): Observable<null> {
    const url = Urls.deleteCoveringText(id);

    return this.http.delete<null>(url);
  }
}
