// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-title {
  padding: 3px 5px;
  background: #a9b9c3;
  cursor: default;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyb3VwLWl0ZW0tc2VsZWN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQUE7RUFDQSxtQkFBQTtFQUNBLGVBQUE7QUFDRiIsImZpbGUiOiJncm91cC1pdGVtLXNlbGVjdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5ncm91cC10aXRsZSB7XG4gIHBhZGRpbmc6IDNweCA1cHg7XG4gIGJhY2tncm91bmQ6ICNhOWI5YzM7XG4gIGN1cnNvcjogZGVmYXVsdDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/controls/select/group-item-select/group-item-select.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACA,oaAAoa","sourcesContent":[".group-title {\n  padding: 3px 5px;\n  background: #a9b9c3;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
