import { DictionaryFullNameModel } from '@models/base/dictionary-full-name.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { ApprovalFormTypesAction, ApprovalFormTypesActionTypes } from './approval-form-types.actions';

export const initialState = new DictionaryStoreModel<DictionaryFullNameModel>([], null);

export function approvalFormTypesReducer(
  state: DictionaryStoreModel<DictionaryFullNameModel> = initialState,
  action: ApprovalFormTypesAction
): DictionaryStoreModel<DictionaryFullNameModel> {
  switch (action.type) {
    case ApprovalFormTypesActionTypes.Reset:
      return initialState;

    case ApprovalFormTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case ApprovalFormTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case ApprovalFormTypesActionTypes.GetError:
      return {
        ...initialState,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (projectTypes) => projectTypes.saveStatus;
/** Получение словаря */
export const getApprovalFormTypes = (projectTypes) => projectTypes.dictionary;
