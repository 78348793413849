import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PermissionType } from '@appPermissions/enums/permission-type.enum';

@Injectable()
export class PermissionsGuardService  {
  constructor(private permissionsService: PermissionsService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permission = next.data.permission as string;
    const permissionType = next.data.permissionType as string;
    const id = next.params[next.data.idName] || '';

    return this.hasPermission$(permission, permissionType, id);
  }

  // Если доступа нет, ленивый модуль не грузим
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const permission = route.data.permission as string;
    const permissionType = route.data.permissionType as string;

    return this.hasPermission$(permission, permissionType);
  }

  private hasPermission$(permission, permissionType, id?: string): Observable<boolean> {
    return this.permissionsService.getPermissions(permission, permissionType, id).pipe(
      tap((result) => {
        if (!result && permissionType === PermissionType.summaryAnalytics) {
          this.router.navigate(['main', 'forbidden'], { queryParams: { redirectingUrl: 'analytics' } });
          return;
        }
        // Проверяем доступ к странице и если нет, то перекидываем на дефолтную для данного пользователя
        if (!result) {
          this.permissionsService.navigateToDefaultUrlByRole();
        }
      })
    );
  }
}
