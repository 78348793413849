import { GroupedProjectTypeModel } from '@models/document-package/project-type.model';
import { Action } from '@ngrx/store';

export enum ProjectTypesActionTypes {
  Get = '[Project Types] Get',
  GetSuccess = '[Project Types] GetSuccess',
  GetError = '[Project Types] GetError',
  Reset = '[Project Types] Reset',
  SetGroupType = '[Project Types] SetGroupType',
}

/** Обновление типа проекта */
export class ProjectTypesGet implements Action {
  readonly type = ProjectTypesActionTypes.Get;
}

/** Обновление типа проекта */
export class ProjectTypesGetError implements Action {
  readonly type = ProjectTypesActionTypes.GetError;
}

/** Обновление типа проекта */
export class ProjectTypesGetSuccess implements Action {
  readonly type = ProjectTypesActionTypes.GetSuccess;
  constructor(public payload: GroupedProjectTypeModel[]) {}
}

/** Обновление типа проекта */
export class ProjectTypesReset implements Action {
  readonly type = ProjectTypesActionTypes.Reset;
}

export type ProjectTypesAction = ProjectTypesGet | ProjectTypesGetSuccess | ProjectTypesGetError | ProjectTypesReset;
