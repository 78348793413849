import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from '@appCore/urls/urls';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { AssignedExecutorSendingModel } from './models/assigned-executor-sending.model';

@Injectable({ providedIn: 'root' })
export class AssignedExecutorsApiService extends DefaultDataService<AssignedExecutorSendingModel> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('assigned-executors', http, httpUrlGenerator);
  }

  public add(executor: AssignedExecutorSendingModel): Observable<AssignedExecutorSendingModel> {
    const url = Urls.postAssignedExecutors();

    return this.http.post<AssignedExecutorSendingModel>(url, executor);
  }

  public getAll(): Observable<AssignedExecutorSendingModel[]> {
    const url = Urls.getAssignedExecutors();

    return this.http.get<AssignedExecutorSendingModel[]>(url);
  }

  public delete(id: number): Observable<any> {
    const url = Urls.deleteAssignedExecutors(id);

    return this.http.delete(url);
  }
}
