import { RegulationsDeadlineModel } from '@models/regulations-deadline/regulations-deadline.model';
import { Action } from '@ngrx/store';

export enum RegulationsDeadlineActionTypes {
  RegulationsDeadlineGet = '[Regulations Deadline] RegulationsDeadlineGet',
  RegulationsDeadlineGetSuccess = '[Regulations Deadline] RegulationsDeadlineGetSuccess',
  RegulationsDeadlineUpdate = '[Regulations Deadline] RegulationsDeadlineUpdate',
  RegulationsDeadlineUpdateSuccess = '[Regulations Deadline] RegulationsDeadlineUpdateSuccess',
  RegulationsDeadlineError = '[Regulations Deadline] RegulationsDeadlineError',
  RegulationsDeadlineReset = '[Regulations Deadline] RegulationsDeadlineReset',
}

export class RegulationsDeadlineGet implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineGet;

  constructor(public payload: number) {}
}

export class RegulationsDeadlineGetSuccess implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineGetSuccess;

  constructor(public payload: RegulationsDeadlineModel) {}
}

export class RegulationsDeadlineUpdate implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineUpdate;

  constructor(public payload: RegulationsDeadlineModel) {}
}

export class RegulationsDeadlineUpdateSuccess implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineUpdateSuccess;

  constructor(public payload: RegulationsDeadlineModel) {}
}

export class RegulationsDeadlineError implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineError;

  constructor(public payload: any) {}
}

export class RegulationsDeadlineReset implements Action {
  readonly type = RegulationsDeadlineActionTypes.RegulationsDeadlineReset;
}

export type RegulationsDeadlineActions =
  | RegulationsDeadlineGet
  | RegulationsDeadlineGetSuccess
  | RegulationsDeadlineUpdate
  | RegulationsDeadlineUpdateSuccess
  | RegulationsDeadlineError
  | RegulationsDeadlineReset;
