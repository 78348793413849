import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  DocumentTypesAvailableAction,
  DocumentTypesAvailableActionTypes,
  DocumentTypesAvailableGet,
  DocumentTypesAvailableGetError,
  DocumentTypesAvailableGetSuccess,
} from '../document-types-available/document-types-available.actions';

@Injectable()
export class DocumentTypesAvailableEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetDocumentTypesAvailable$: Observable<DocumentTypesAvailableAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypesAvailableActionTypes.Get),
      switchMap((action: DocumentTypesAvailableGet) =>
        this.dictionaryApi
          .getDocumentTypesAvailable(action.payload?.projectGroupType, action.payload?.documentPackageId)
          .pipe(
            map((response) => new DocumentTypesAvailableGetSuccess(response)),
            catchError((err) => of(new DocumentTypesAvailableGetError()))
          )
      )
    )
  );
}
