import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Абстракция над HttpClient */
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /** Метод get возвращает observable<T> */
  public getObservable<T>(url: string, options?: any): Observable<T> {
    return <Observable<T>>this.http.get<T>(url, options).pipe(map((i: any) => i as T));
  }

  /** Метод post возвращает observable<T> */
  public postObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.post<T>(url, data, options).pipe(map((i: any) => i as T));
  }

  /** Метод delete возвращает observable<T> */
  public deleteObservable<T>(url: string, options?: any): Observable<T> {
    return <Observable<T>>this.http.delete<T>(url, options).pipe(map((i: any) => i as T));
  }

  /** Метод patch возвращает observable<T> */
  public patchObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.patch(url, data, options).pipe(map((i: any) => i as T));
  }

  /** Метод pub возвращает observable<T> */
  public putObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.put<T>(url, data, options).pipe(map((i: any) => i as T));
  }



  /** конвертировать примитивные свойства объекта в параметры запроса */
  public getRequestParams(searchParams: Object, canEncode?: boolean): HttpParams {
    let params = new HttpParams();
    for (const option in searchParams) {
      if (searchParams.hasOwnProperty(option)) {
        let optionValue = searchParams[option];

        if (optionValue !== null && optionValue !== undefined) {
          if (canEncode) {
            optionValue = encodeURIComponent(optionValue);
          }
          params = params.append(option, optionValue);
        }
      }
    }

    return params;
  }
}
