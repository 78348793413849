import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { DomEventTypes } from '@appShared/enums/dom-event-types.enum';
import { TooltipPositions } from '@libs/projects/component-lib/src/public-api';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { TooltipContentComponent } from './tooltip-content.component';

@Directive({
  selector: '[appTooltip]',
})
/** директива для отображение над хост-элементом компоненты-подсказки */
export class TooltipDirective extends Unsubscriber implements OnChanges, OnInit, OnDestroy {
  /** инстанс компоненты подсказки */
  tooltipComponentRef: ComponentRef<TooltipContentComponent>;
  /** поток событий курсора */
  private mouseEventStream: Subject<MouseEvent> = new Subject();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private _ngZone: NgZone
  ) {
    super();
    this.onScroll = this.onScroll.bind(this);
  }

  /** строка подсказки или селектор на шаблон <app-tooltip-content>...</app-tooltip-content>   */
  @Input()
  appTooltip: string | HTMLElement;
  /** выключатель подсказки */
  @Input()
  isTooltipDisabled: boolean;
  /** задержка перед отображением подсказки, мс */
  @Input()
  tooltipDelay = 2000;
  /** задержка перед скрытием подсказки, мс */
  @Input()
  tooltipDelayHide = 500;
  /** позиция подсказки */
  @Input()
  tooltipPosition: TooltipPositions = TooltipPositions.bottom;
  /** ширина подсказки */
  @Input()
  tooltipWidth = 250;
  /** отступ подсказки от хост-элемента */
  @Input()
  tooltipOffset = 20;
  /** флаг, что дректива с хост-эементом уже уничтожена */
  isTooltipDestroyed: boolean;

  private scrollElement;
  private scrollEventStream$: Subject<Event> = new Subject();

  ngOnInit(): void {
    this.processingMouseEvents();
    this.mouseEventListenerOutsideNgZone();
  }

  ngOnDestroy(): void {
    this.isTooltipDestroyed = true;
    this.hide();
    this.mouseEventStream.complete();
    this.viewContainerRef.element.nativeElement.removeEventListener(DomEventTypes.mouseenter, Function);
    this.viewContainerRef.element.nativeElement.removeEventListener(DomEventTypes.mouseleave, Function);
  }

  /** следить за изменениями в тексте подсказки */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.appTooltip) {
      this.updateTooltip();
    }

    if (changes.isTooltipDisabled) {
      this.hide();
    }
  }

  /** прослушивание событий курсора вне зоны ангуляра, что бы не плодить лишние проверки изменений */
  private mouseEventListenerOutsideNgZone(): void {
    this._ngZone.runOutsideAngular(() => {
      const parentHost = this.viewContainerRef.element.nativeElement;
      parentHost.addEventListener(
        DomEventTypes.mouseenter,
        (mouseEvent: MouseEvent) => {
          this.mouseEventStream.next(mouseEvent);
        },
        false
      );

      parentHost.addEventListener(
        DomEventTypes.mouseleave,
        (mouseEvent: MouseEvent) => {
          this.mouseEventStream.next(mouseEvent);
        },
        false
      );
    });
  }

  /** обработка событий курсора */
  private processingMouseEvents(): void {
    this.mouseEventStream
      .pipe(
        filter(() => !this.isTooltipDisabled),
        map((mouseEvent) => this.mapEventToType(mouseEvent)),
        distinctUntilChanged(),
        switchMap((eventType) => this.delayEmit(eventType)),
        distinctUntilChanged(),
        filter(() => !this.isTooltipDisabled && !this.isTooltipDestroyed),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((eventType) => {
        if (eventType === DomEventTypes.mouseenter && this.appTooltip) {
          this.show();
          return;
        }

        this.hide();
      });
  }

  /** ковертировать событие в его тип */
  private mapEventToType(mouseEvent: MouseEvent): DomEventTypes {
    return DomEventTypes[mouseEvent.type];
  }

  /** наблюдатель с задержкой выхлопа под каждый тип события */
  private delayEmit(eventType: DomEventTypes): Observable<DomEventTypes> {
    const delayTime =
      eventType === DomEventTypes.mouseenter ? Number(this.tooltipDelay) : Number(this.tooltipDelayHide);
    return of(eventType).pipe(delay(delayTime));
  }

  /** показать компоненту подсказки */
  show(): void {
    this._ngZone.run(() => {
      /** создать инстанс копоненты-подсказки */
      const factory = this.resolver.resolveComponentFactory(TooltipContentComponent);
      this.tooltipComponentRef = factory.create(this.injector);
      /** внести инстанс в дерево компонентов Angular, что бы его проверяли на изменения */
      this.applicationRef.attachView(this.tooltipComponentRef.hostView);
      /** подключить DOM компоненты дочкой к BODY */
      document.body.appendChild(this.getHtmlElement());
      /** заполнить входящие параметры компонеты-подсказки */
      this.tooltipComponentRef.instance.hostElement = this.viewContainerRef.element.nativeElement;
      this.setContent(this.appTooltip);
      this.tooltipComponentRef.instance.position = this.tooltipPosition;
      this.tooltipComponentRef.instance.width = Number(this.tooltipWidth);
      this.tooltipComponentRef.instance.offset = Number(this.tooltipOffset);
    });
    this.getParentScrollElement(this.viewContainerRef.element.nativeElement);
  }

  private getHtmlElement(): HTMLElement {
    return (this.tooltipComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  /** Обновление тултипа */
  private updateTooltip(): void {
    this._ngZone.run(() => {
      if (!this.tooltipComponentRef || !this.tooltipComponentRef.instance) {
        return;
      }
      this.setContent(this.appTooltip);
    });
  }

  private setContent(content: string | HTMLElement): void {
    if (content instanceof HTMLElement) {
      this.getHtmlElement().appendChild(content);
    } else {
      this.tooltipComponentRef.instance.content = content;
    }
  }

  /** уничтожить компоненту-подсказку */
  hide(): void {
    this._ngZone.run(() => {
      if (!this.tooltipComponentRef) {
        return;
      }

      this.applicationRef.detachView(this.tooltipComponentRef.hostView);
      this.tooltipComponentRef.destroy();
      if (this.scrollElement) {
        this.scrollElement.removeEventListener(DomEventTypes.scroll, this.onScroll);
      }
    });
  }

  private subscribeToScroll(): void {
    this.scrollEventStream$.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe$)).subscribe(() => this.hide());
  }

  private getParentScrollElement(node: HTMLElement): HTMLElement | null {
    if (!node) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight && node.scrollTop) {
      this.scrollElement = node;
      this.scrollElement.addEventListener(DomEventTypes.scroll, this.onScroll);
      this.subscribeToScroll();
      return node;
    }
    return this.getParentScrollElement(node.parentNode as HTMLElement);
  }

  private onScroll(event: Event): void {
    this.scrollEventStream$.next(event);
  }
}
