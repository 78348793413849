import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  AvailableNomenclaturesAction,
  AvailableNomenclaturesActionTypes,
  AvailableNomenclaturesGetError,
  AvailableNomenclaturesGetSuccess,
} from './available-nomenclatures.actions';

@Injectable()
export class AvailableNomenclaturesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  getAvailableNomenclatures$: Observable<AvailableNomenclaturesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AvailableNomenclaturesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() => this.dictionaryApi.getAvailableNomenclatureIndexes()),
      map((response) => new AvailableNomenclaturesGetSuccess(response)),
      catchError((err) => of(new AvailableNomenclaturesGetError()))
    )
  );
}
