import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '../../../../core/store/cache-time.const';
import { DictionaryApiService } from '../../../../shared/api/dictionary/dictionary.api.service';
import {
  AgreementRoutePhasesAction,
  AgreementRoutePhasesActions,
  AgreementRoutePhasesGetError,
  AgreementRoutePhasesGetSuccess,
} from '@appShared/store/dictionaries/agreement-route-phases/agreement-route-phases.actions';

@Injectable()
export class AgreementRoutePhasesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetProjectTypes$: Observable<AgreementRoutePhasesAction> = createEffect(() => this.actions$.pipe(
    ofType(AgreementRoutePhasesActions.Get),
    throttleTime(CACHE_TIME),
    switchMap(() => this.dictionaryApi.getAgreementRoutePhases()),
    map((response) => new AgreementRoutePhasesGetSuccess(response)),
    catchError((err) => of(new AgreementRoutePhasesGetError()))
  ));
}
