import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { PhaseResponseModel } from '@appShared/api/documents-route/models/route-tree.response/phase.response.model';
import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { VertexResponseModel } from '@appShared/api/documents-route/models/route-tree.response/vertex.response.model';

export class RouteStaticHelpers {
  public static getPhaseByPoint(route: RouteTreeResponseModel, point: VertexResponseModel): PhaseResponseModel {
    return route.phases.find((p) => p.id === point.phaseId).parentPhase;
  }

  public static getPreviousPoints(route: RouteTreeResponseModel, point: VertexResponseModel): VertexResponseModel[] {
    const incommingVertexes = route.edges.filter((e) => e[1] === point.id).map((e) => e[0]);
    return route.vertexes.filter((v) => incommingVertexes.includes(v.id));
  }

  public static getNextPoints(route: RouteTreeResponseModel, point: VertexResponseModel): VertexResponseModel[] {
    const outcommingVertexes = route.edges.filter((e) => e[0] === point.id).map((e) => e[1]);
    return route.vertexes.filter((v) => outcommingVertexes.includes(v.id));
  }

  public static getNextNotSystemPoints(
    route: RouteTreeResponseModel,
    point: VertexResponseModel
  ): VertexResponseModel[] {
    const nextPoints = this.getNextPoints(route, point);
    const nextNotSystemPoints = nextPoints[0]?.isSystem ? this.getNextPoints(route, nextPoints[0]) : nextPoints;
    return nextNotSystemPoints.filter((p) => !p.isSystem);
  }

  public static getPreviousNotDeletedPoints(
    route: RouteTreeResponseModel,
    point: VertexResponseModel
  ): VertexResponseModel[] {
    const points = this.getPreviousPoints(route, point);
    if (points && points[0] && points[0].deleted) {
      return this.getPreviousNotDeletedPoints(route, point);
    }
    return points;
  }

  public static getNextNotDeletedPoints(
    route: RouteTreeResponseModel,
    point: VertexResponseModel
  ): VertexResponseModel[] {
    const points = this.getNextPoints(route, point);
    if (points && points[0] && points[0].deleted) {
      return this.getNextNotDeletedPoints(route, points[0]);
    }
    return points;
  }

  public static getSidePoints(route: RouteTreeResponseModel, point: VertexResponseModel): VertexResponseModel[] {
    const outcommingVertexes = route.edges.filter((e) => e[1] === point.id);
    const pointsPhase = route.vertexes.filter((p) => p.phaseId === point.phaseId && p.level > point.level);

    return pointsPhase.filter((p) => outcommingVertexes.find((v) => v.find((id) => id === p.id)));
  }

  /**
   * Метод для получения <b>родственных</b> точек
   * @param { RouteTreeResponseModel } route - маршрут, в котором требуется производить поиск точек
   * @param { VertexResponseModel } point - точка, для которой требуется производить поиск родственных точек
   * @returns { VertexResponseModel[] } - возвращает массив точек
   */
  public static getSiblingPoints(route: RouteTreeResponseModel, point: VertexResponseModel): VertexResponseModel[] {
    const parentPointId = route.edges.filter((e) => e[1] === point.id).map((e) => e[0])[0];
    const siblingPointsId = route.edges.filter((e) => e[0] === parentPointId).map((e) => e[1]);
    const siblingPoints = route.vertexes.filter((v) => siblingPointsId.includes(v.id));

    return siblingPoints.filter((p) => p.id !== point.id && p.level === point.level);
  }

  public static getLastAuthorPoint(route: RouteTreeResponseModel): VertexResponseModel {
    return route.vertexes.filter((p) => p.pointRoleId === EmployeeRouteTypes.author).reduce((a, c) => c, null);
  }
}
