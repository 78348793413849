import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss'],
})
export class TimeSliderComponent {
  @Input()
  minValue: number;
  @Input()
  maxValue: number;
  @Input()
  sliderForm: UntypedFormGroup;

  options: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    showTicks: true,
    translate: (value: number): string => value + ':00',
  };
}
