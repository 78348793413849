import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalWindowRef } from '../../modals-helper/modal-window-ref';
import { AlertPopupDataModel } from '../../models/alert-popup-data.model';
import { AlertPopupBtnsActionModel } from '@appShared/models/alert-popup-btns-action.model';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertPopupComponent implements OnInit {
  public alertPopupBtnsActionModel = AlertPopupBtnsActionModel;
  public pastedData: AlertPopupDataModel;
  constructor(private modalWindowRef: ModalWindowRef) {}

  ngOnInit(): void {
    this.pastedData = this.modalWindowRef.data;
  }
  public confirmAction(actionType: AlertPopupBtnsActionModel): void {
    this.modalWindowRef.close({ condition: this.pastedData.condition, actionType });
  }
  public cancelAction(actionType: AlertPopupBtnsActionModel): void {
    this.modalWindowRef.close({ actionType });
  }
}
