import { DocumentPackageRegistrationStoreModel } from './document-package-registration.store.model';
import {
  DocumentPackageRegistrationAction,
  DocumentPackageRegistrationActionTypes,
} from './document-package-registration.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState: DocumentPackageRegistrationStoreModel = {
  error: null,
  status: null,
  regNumberMaxDate: null,
  options: [],
  totalLength: 0,
};

export const documentPackageRegistrationReducer = (
  state: DocumentPackageRegistrationStoreModel = initialState,
  action: DocumentPackageRegistrationAction
): DocumentPackageRegistrationStoreModel => {
  switch (action.type) {
    case DocumentPackageRegistrationActionTypes.GetNumberOptions:
      return { ...state, error: null, status: StoreLoadStatus.inProgress };

    case DocumentPackageRegistrationActionTypes.GetNumberOptionsSuccess:
      const { maxDate, totalLength, numberOptions } = action.payload || {};
      return {
        ...state,
        error: null,
        status: StoreLoadStatus.loaded,
        options: numberOptions,
        regNumberMaxDate: maxDate,
        totalLength,
      };

    case DocumentPackageRegistrationActionTypes.GetNumberOptionsError:
      return {
        ...state,
        error: action.payload,
        status: StoreLoadStatus.error,
      };

    default:
      return state;
  }
};
