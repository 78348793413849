import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';

@Pipe({
  name: 'employeeFullName',
})
export class EmployeeFullNamePipe implements PipeTransform {
  transform(employee: EmployeeBaseModel): string {
    return employeeFullName(employee);
  }
}

export function employeeFullName(employee: EmployeeBaseModel): string {
  if (!employee) {
    return '';
  }

  const data = [employee.lastName, employee.firstName, employee.patronymic].filter((i) => i);
  return data.join(' ');
}
