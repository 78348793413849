import { DocumentEditorVersionModel } from '@models/document/document-editor-version.model';
import { Action } from '@ngrx/store';

export enum CompositionSharedActingVersionsActionTypes {
  Get = '[Composition Shared Acting Versions] Get',
  GetSuccess = '[Composition Shared Acting Versions] GetSuccess',
  GetError = '[Composition Shared Acting Versions] GetError',
  Reset = '[Composition Shared Acting Versions] Reset',
}

export class CompositionSharedActingVersionsGet implements Action {
  readonly type = CompositionSharedActingVersionsActionTypes.Get;
  constructor(public dpId: number) {}
}

export class CompositionSharedActingVersionsGetSuccess implements Action {
  readonly type = CompositionSharedActingVersionsActionTypes.GetSuccess;
  constructor(public payload: DocumentEditorVersionModel[]) {}
}

export class CompositionSharedActingVersionsGetError implements Action {
  readonly type = CompositionSharedActingVersionsActionTypes.GetError;
  constructor(public payload: any) {}
}

export class CompositionSharedActingVersionsReset implements Action {
  readonly type = CompositionSharedActingVersionsActionTypes.Reset;
}

export type CompositionSharedActingVersionsAction =
  | CompositionSharedActingVersionsGet
  | CompositionSharedActingVersionsGetSuccess
  | CompositionSharedActingVersionsGetError
  | CompositionSharedActingVersionsReset;
