import { Clipboard } from '@angular/cdk/clipboard';
import { Component, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-tooltip-clipboard',
  templateUrl: './tooltip-clipboard.component.html',
  styleUrls: ['./tooltip-clipboard.component.scss'],
})

/** компонента, содержащая контент подсказки */
export class TooltipClipboardComponent {
  public title: string;
  public data: string;
  public isCopied: boolean;
  public copiedEvent = new Subject<void>();
  @HostBinding('style.top')
  topPosition: string;
  @HostBinding('style.bottom')
  bottomPosition: string;
  @HostBinding('style.left')
  leftPosition: string;
  @HostBinding('style.right')
  rightPosition: string;
  constructor(private cb: Clipboard) {}

  public copy(e: Event): void {
    if (this.isCopied) {
      return;
    }
    this.cb.copy(this.data);
    this.isCopied = true;
    e.stopPropagation();
    this.copiedEvent.next();
  }
}
