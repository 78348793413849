import { TreeTapModel } from '@appDocuments/documents-route/models/tree/tree-tap.model';
import {
  DocumentRouteTapActions,
  DocumentRouteTapActionTypes
} from '@appDocuments/documents-route/store/document-route-tap/document-route-tap.actions';

export interface DocumentRouteTapState {
  routeId: number;
  tapsList: {
    [key: number]: TreeTapModel;
  };
  stepsChanged: boolean;
}

const stateFromStore: DocumentRouteTapState = JSON.parse(localStorage.getItem('documentRouteTap'));

const initialState: DocumentRouteTapState = stateFromStore
  ? stateFromStore
  : {
      routeId: null,
      tapsList: {},
      stepsChanged: false,
    };

export function documentRouteTapReducer(state = initialState, action: DocumentRouteTapActions): DocumentRouteTapState {
  switch (action.type) {
    case DocumentRouteTapActionTypes.Reset: {
      return {
        routeId: null,
        tapsList: {},
        stepsChanged: false,
      };
    }

    case DocumentRouteTapActionTypes.Update: {
      return {
        routeId: action.payload.routeId,
        tapsList: {
          ...state.tapsList,
          [action.payload.phaseId]: action.payload.tap
        },
        stepsChanged: false,
      };
    }

    case DocumentRouteTapActionTypes.StepChange: {
      return {
        ...state,
        stepsChanged: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
