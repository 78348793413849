import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EmployeeApiService } from '@appShared/api/employee/employee.api.service';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { EmployeeWithFullNameModel } from '@models/employee/employee-with-full-name.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-employee',
  templateUrl: './autocomplete-employee.component.html',
  styleUrls: [],
})
/** компонента поля с автозаполнением сотрудников */
export class AutocompleteEmployeeComponent extends ControlBaseComponent implements OnInit {
  /** Показывать крестик сброса */
  @Input()
  isShowClearBtn = false;
  /** Текст ошибки поиска */
  @Input()
  searchErrorMsg: string;
  /** Айди пользователя для проставления в форму при ините компоненты */
  @Input()
  startEmployeeId: number;

  private exclusionEmployeeListId: number[];
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public optionsList$: Observable<EmployeeWithFullNameModel[]>;
  public optionLabel: string;
  public isLoaderActive: boolean;

  /** сотрудники, требующие исключения из списка */
  @Input()
  set exclusionEmployeeId(employeeId: number | number[]) {
    this.exclusionEmployeeListId = [].concat(employeeId);
  }

  constructor(private employeeApi: EmployeeApiService) {
    super();
  }

  ngOnInit() {
    this.optionLabel = 'fullName';
    this.subscribeToInputFilter();
    this.getEmployeeInfo();
  }

  /** подписка на изменение поля фильрации */
  private subscribeToInputFilter() {
    this.optionsList$ = this.filterControl.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.isLoaderActive = true;
      }),
      filter((filterString) => !!filterString),
      debounceTime(300),
      switchMap((filterString) =>
        this.employeeApi.getEmployeeList('', '', filterString).pipe(
          finalize(() => {
            this.isLoaderActive = false;
          })
        )
      ),
      map((employeeList) => this.filtredEmployees(employeeList)),
      map((response) => this.mapToEmployeeWithFullInfo(response))
    );
  }

  /** Получаем данные Пользователя при наличии айдишника на входе в компонент */
  private getEmployeeInfo(): void {
    if (!this.startEmployeeId) {
      return;
    }

    this.employeeApi
      .getSingleEmployee(this.startEmployeeId)
      .pipe(first())
      .subscribe((resp) => {
        this.patchFormControlValue(resp);
      });
  }

  /** Заполняем данные в форму */
  private patchFormControlValue(employeeInfo: EmployeeBaseModel): void {
    const employeeWithFullName = this.mapToEmployeeWithFullInfo([employeeInfo])[0];

    this.control.patchValue(employeeWithFullName);
  }

  /** вычесть исключения из массива сотрудников */
  private filtredEmployees(employeeList: EmployeeBaseModel[]): EmployeeBaseModel[] {
    if (this.exclusionEmployeeListId && this.exclusionEmployeeListId.length) {
      return employeeList.filter((employee) =>
        this.exclusionEmployeeListId.find((exclusionEmployeeId) => exclusionEmployeeId !== employee.id)
      );
    }

    return employeeList;
  }

  /** Мапинг в строку представления: Фамилия Имя Отчество, Должность, Организация */
  private mapToEmployeeWithFullInfo(employee: EmployeeBaseModel[]): EmployeeWithFullNameModel[] {
    return employee.map((params) => {
      return {
        ...params,
        fullName: `${params.lastName} ${params.firstName} ${params.patronymic}, ${params.jobPosition.name}, ${
          params.organization.shortName ? params.organization.shortName : params.organization.name
        }`,
      };
    });
  }
}
