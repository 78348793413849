export enum SettingCodeEnum {
  sendButton = 'SEND_BUTTON',
  supportPhone = 'SUPPORT_PHONE',
  supportEmail = 'SUPPORT_EMAIL',
  onlineCourse = 'ONLINE_COURSE',
  isproResolutionsGettingActive = 'ISPRO_RESOLUTIONS_GETTING_ACTIVE',
  recommendedMeetingWeekday = 'RECOMMENDED_MEETING_WEEKDAY',
  notificationConfirmationActionMovingActingVersionToAcceptedDocuments = 'NOTIFICATION_CONFIRMATION_ACTION_MOVING_ACTING_VERSION_TO_ACCEPTED_DOCUMENTS',
  eaLink = 'EA_LINK',
  groupingVersions = 'GROUPING_VERSIONS',
  parallelSignature = 'PARALLEL_SIGNATURE',
  /** url ЭА в зависимости от контура, для формирования ссылки на карточку в ЭА */
  eArchiveDocumentUrl = 'E_ARCHIVE_DOCUMENT_URL',
}
