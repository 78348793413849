import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  ParticipantTypesAction,
  ParticipantTypesActionTypes,
  ParticipantTypesGetError,
  ParticipantTypesGetSuccess,
} from '@appShared/store/dictionaries/participant-types/participant-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class ParticipantTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetParticipantTypes$: Observable<ParticipantTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ParticipantTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getConsiderationParticipantTypes().pipe(
        map((response) => new ParticipantTypesGetSuccess(response)),
        catchError((err) => of(new ParticipantTypesGetError()))
      )
    )
  ));
}
