import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputTrim]',
})
export class InputRefTrimValueDirective {
  @Input() appInputTrim = false;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) handleKeyPress() {

    const value = this.el.nativeElement?.value;
    if (value) {
      this.el.nativeElement.value = value.trimStart();
    }
  }
}
