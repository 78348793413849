import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { RouteOpenSertificateList } from '@appDocuments/documents-route/store/route-send-to-approvement/route-send-to-approvement.actions';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';

@Component({
  template: '',
})
export class SelectBaseComponent extends ControlBaseComponent {
  /** Нужно ли скролить вниз при открытии списка */
  @Input()
  isScrollToBottom = false;
  /** Параметр для просмотра опции */
  @Input()
  optionLabel: string | ((value: any) => string);
  /** Нужно ли скрывать кнопку сброса */
  @Input()
  hideClearButton: boolean;
  /** Нужно ли вычислять ширину */
  @Input()
  canCalculateWidth = true;
  /** Ширина селект бокса */
  @Input()
  selectBoxWidth: number;
  /** Триггер очистки селекта */
  @Output()
  clear: EventEmitter<any> = new EventEmitter();
  /** Триггер изменения значения селекта */
  @Output()
  change: EventEmitter<any> = new EventEmitter();

  /** Отобразить опции или нет */
  public isShowOptions = false;

  constructor(private elementRef: ElementRef, private redux: ReduxService) {
    super();
  }

  public selectOption(value: any): void {
    this.control.setValue(value);
    this.change.emit(value);
    this.hideOptionsList();
  }

  /** Коллбек фокуса на селекте */
  public onFocusSelect(): void {
    this.showOptionsList();
  }

  /**
   * Метод для отображения списка опций
   */
  public showOptionsList(): void {
    if (this.control.disabled) {
      return;
    }
    this.calculatePosition();
    this.isShowOptions = true;
    if (this.isScrollToBottom) {
      this.redux.dispatchAction(new RouteOpenSertificateList(true));
    }
  }

  /**
   * Метод для скрытия списка опций
   */
  public hideOptionsList(): void {
    this.redux.dispatchAction(new RouteOpenSertificateList(false));
    this.isShowOptions = false;

    this.returnPosition();
  }

  /**
   * очистка значения контрола
   */
  public onClearControlValue(): void {
    this.control.setValue(null);
    this.control.markAsTouched();
    this.change.emit(null);
    this.clear.emit(null);
  }

  /* Вычисляет позиционирование */
  private calculatePosition(): void {
    const el = this.elementRef.nativeElement;

    const selectBox = el.querySelector('.select-box');
    const selectBoxLayout = selectBox.getClientRects()[0];
    selectBox.style.top = `${window.scrollY + selectBoxLayout.top}px`;
    selectBox.style.left = `${window.scrollX + selectBoxLayout.left}px`;
    selectBox.style.position = 'sticky';
    selectBox.style.zIndex = 11;

    if (this.canCalculateWidth) {
      if (this.selectBoxWidth && selectBoxLayout.width >= this.selectBoxWidth) {
        selectBox.style.width = `${this.selectBoxWidth}px`;
        return;
      }
      selectBox.style.width = `${selectBoxLayout.width}px`;
    }
  }

  /* Возвращает прежнее позиционирование */
  private returnPosition(): void {
    const el = this.elementRef.nativeElement;
    const selectBox = el.querySelector('.select-box');
    selectBox.style.top = 0;
    selectBox.style.left = 0;
    selectBox.style.width = 'auto';
    selectBox.style.position = 'inherit';
    selectBox.style.zIndex = 0;
  }
}
