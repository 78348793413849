import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** типы экшенов */
export enum TaskResolutionsActionTypes {
  Get = '[Task Resolutions] Get',
  GetSuccess = '[Task Resolutions] GetSuccess',
  GetError = '[Task Resolutions] GetError',
  Reset = '[Task Resolutions] Reset'
}

/** экшен запроса */
export class TaskResolutionsGet implements Action {
  readonly type = TaskResolutionsActionTypes.Get;
}

/** экшен выдачи */
export class TaskResolutionsGetSuccess implements Action {
  readonly type = TaskResolutionsActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

/** выдать исключение */
export class TaskResolutionsError implements Action {
  readonly type = TaskResolutionsActionTypes.GetError;
}

/** очистить */
export class TaskResolutionsReset implements Action {
  readonly type = TaskResolutionsActionTypes.Reset;
}

export type TaskResolutionsActions =
  | TaskResolutionsGet
  | TaskResolutionsGetSuccess
  | TaskResolutionsError
  | TaskResolutionsReset;
