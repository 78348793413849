import { Action } from '@ngrx/store';
export enum ToastsActionTypes {
  Add = '[Toasts] Add',
  Remove = '[Toasts] Remove',
  Reset = '[Toasts] Reset'
}

/** Добавление тоста */
export class AddToast implements Action {
  readonly type = ToastsActionTypes.Add;
  /**
   * @param payload информация о тосте
   */
  constructor(public payload: any) {}
}

/** Удаление тоста */
export class RemoveToast implements Action {
  readonly type = ToastsActionTypes.Remove;
  /**
   * @param payload id тоста для удаления
   */
  constructor(public payload: number) {}
}

/** Сброс тостов */
export class ResetToasts implements Action {
  readonly type = ToastsActionTypes.Reset;
}

export type ToastsActions = AddToast | RemoveToast | ResetToasts;
