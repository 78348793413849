import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ErrorAlertComponent } from '@appShared/components/controls/form-field/error-alert/error-alert.component';
import { ErrorMarkDirective } from '@appShared/components/controls/form-field/error-mark.directive';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormFieldComponent extends Unsubscriber implements AfterViewInit, OnDestroy {
  @ContentChild(ErrorMarkDirective) errorMark: ErrorMarkDirective;
  /** Алерты  */
  @ContentChildren(ErrorAlertComponent) outerAlerts: QueryList<ErrorAlertComponent>;
  @ViewChildren(ErrorAlertComponent) innerAlerts: QueryList<ErrorAlertComponent>;

  /** Контрол связанный с данным компонентом */
  @Input()
  linkedFormControl: UntypedFormControl;

  /** Дефолтная стратегия индикации подразумевает, что контрол будет подсвечен как ошибочный, когда он является touched && invalid
   * если требуется другая логика, то следует перевести его в значение false и записывать в верстку ошибки через ErrorAlertComponent */
  @Input()
  isDefaultDangerIndicatorStrategy = true;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.subscribeToAlerts();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  /** Метод следит за алертами в верстке */
  private subscribeToAlerts(): void {
    const hasDefaultStrategyAlerts$: Observable<boolean> = this.innerAlerts.changes.pipe(
      startWith(this.innerAlerts),
      map((alerts: QueryList<ErrorAlertComponent>) => Boolean(alerts.length))
    );

    const hasCustomAlerts$: Observable<boolean> = this.outerAlerts.changes.pipe(
      startWith(this.outerAlerts),
      map((alerts: QueryList<ErrorAlertComponent>) => Boolean(alerts.length))
    );

    combineLatest([hasDefaultStrategyAlerts$, hasCustomAlerts$])
      .pipe(
        map(([hasInnerAlerts, hasOuterAlerts]) => hasInnerAlerts || hasOuterAlerts),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((isNeedMarkAsDanger) => {
        if (isNeedMarkAsDanger) {
          this.errorMark.markAsDanger();
          return;
        }
        this.errorMark.unMarkAsDanger();
      });
  }
}
