import { Pipe, PipeTransform } from '@angular/core';
import { ToastPlaceholders } from '@appCore/enums/toast-placeholders.enum';
import { ToastPlaceholder } from '@appCore/models/toast-placeholder.model';
import { ToastModel } from '@appCore/models/toast.model';
import moment from 'moment';

/** Пайп для динамического формирования контента в нотификаторе */
@Pipe({
  name: 'toastPlaceholder',
})
export class ToastPlaceholderPipe implements PipeTransform {
  transform(toast: ToastModel, link: string): ToastPlaceholder[] {
    if (!link) {
      return;
    }

    let placeholdersOrder: ToastPlaceholder[] = [];

    for (const entries of toast.placeholdersOrder) {
      const placeholder = this.getPlaceholder(entries, toast, link);
      placeholdersOrder = placeholder ? [...placeholdersOrder, placeholder] : placeholdersOrder;
    }

    return placeholdersOrder.sort((a, b) => a.index - b.index);
  }

  private getPlaceholder(entries: number[], toast: ToastModel, link?: string): ToastPlaceholder {
    const toastPlaceholderId = Number(entries[0]);
    const toastPlaceholderOrderIndex = entries[1];

    const placeholdersDictionary = {
      [ToastPlaceholders.theme]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.author]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.status]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.date]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.accountNumber]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.text]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId),
        index: toastPlaceholderOrderIndex,
      },
      [ToastPlaceholders.link]: {
        value: this.getPlaceholderValue(toast, toastPlaceholderId, link),
        index: toastPlaceholderOrderIndex,
        isLink: true,
      },
    };

    if (toastPlaceholderId === ToastPlaceholders.date && !toast.date) {
      return;
    }

    return placeholdersDictionary[toastPlaceholderId];
  }

  private getPlaceholderValue(toast: ToastModel, placeholderType: ToastPlaceholders, link?: string): string {
    const placeholdersDictionary = {
      [ToastPlaceholders.theme]: `<span class="theme">${toast.theme ? toast.theme : '-'}</span>`,
      [ToastPlaceholders.author]: `<span class="label"> ${toast.author ? 'От кого: ' + toast.author : '-'} </span>`,
      [ToastPlaceholders.status]: toast.header ? toast.header : '-',
      [ToastPlaceholders.date]: toast.date ? moment(toast.date).format('DD MMM YYYY') : '-',
      [ToastPlaceholders.accountNumber]: `<span class="label"> ${
        toast.accountNumber ? 'Уч. номер:</span>' + toast.accountNumber : '</span>-'
      }  </div>`,
      [ToastPlaceholders.text]: `<span class="text">${toast.text}</span>`,
      [ToastPlaceholders.link]: `<a class="link" href="${link}">Перейти</a>`,
    };
    return placeholdersDictionary[placeholderType];
  }
}
