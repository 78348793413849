import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** Справочник "типы рассмотрения" */

export enum ConsiderationTypesActionTypes {
  Get = '[Consideration Types] Get',
  GetSuccess = '[Consideration Types] GetSuccess',
  GetError = '[Consideration Types] GetError',
  Reset = '[Consideration Types] Reset',
}

export class ConsiderationTypesGet implements Action {
  readonly type = ConsiderationTypesActionTypes.Get;
}

export class ConsiderationTypesGetError implements Action {
  readonly type = ConsiderationTypesActionTypes.GetError;
}

export class ConsiderationTypesGetSuccess implements Action {
  readonly type = ConsiderationTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class ConsiderationTypesReset implements Action {
  readonly type = ConsiderationTypesActionTypes.Reset;
}

export type ConsiderationTypesAction =
  | ConsiderationTypesGet
  | ConsiderationTypesGetSuccess
  | ConsiderationTypesGetError
  | ConsiderationTypesReset;
