import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { ExternalUrlsInterface } from '@appShared/components/menu/interfaces/external-urls.interface';

@Pipe({
  name: 'getExternalUrl',
})
export class GetExternalUrlPipe implements PipeTransform {
  transform(item: MenuItemModel, externalUrls: ExternalUrlsInterface): string {
    return externalUrls[item.name] || item.routerLink;
  }
}
