/** Типы виджетов на страницы нотификаций */
export enum NotificationCounterTypes {
  /** Все оповещения */
  all = 'ALL',
  /** Только новые */
  notViewed = 'NOT_VIEWED',
  /** Просроченные */
  overdue = 'OVERDUE',
  /** Архив */
  archived = 'ARCHIVED',
  /** Отправленные уведомления */
  sent = 'SENT',
  /** О назначении исполнителем задачи */
  executor = 'EXECUTOR',
  /** О внесённых изменениях в документ после согласования */
  amendments = 'AMENDMENTS',
  /** Об отзыве документа с согласования */
  recall = 'RECALL',
  /** О соавторстве */
  coauthorInvite = 'COAUTHOR_INVITE',
  /** О приглашении на рассмотрение */
  invite = 'INVITE',
  /** Об изменении сроков */
  changeDates = 'CHANGE_DATES',
  /** О назначении соавтором */
  coauthored = 'COAUTHORED',
}
