import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DictionaryModel } from '@models/base/dictionary.model';
import {
  FolderViewAccessTypes,
  FolderViewAccessTypesAction,
} from '@appShared/store/dictionaries/folder/folder-view-access-types/folder-view-access-types.actions';

export const initialState = new DictionaryStoreModel<DictionaryModel>([], null, null);

export function folderViewAccessTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = initialState,
  action: FolderViewAccessTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case FolderViewAccessTypes.Reset:
      return initialState;

    case FolderViewAccessTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case FolderViewAccessTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case FolderViewAccessTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}
