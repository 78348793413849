import { AfterViewInit, Component, ElementRef, forwardRef, Injector, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlBaseComponent } from '../control-base/control-base.component';

/**
 * Компонент текстовой области
 */
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['../control-base/control-base.component.scss', './textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends ControlBaseComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('inputElement') inputElement: ElementRef;

  /** Минимальная высота контейнера поля */
  @Input()
  minHeight: string;

  /** Может прокручивать контент */
  @Input()
  canOverFlowScroll = false;

  /** Максимальная высота контейнера поля */
  @Input()
  maxHeight: string;

  /** Автозамена кавычек на ёлочки */
  @Input()
  useFrenchQuotes = true;

  @Input()
  setDefaultText;

  @Input()
  isHighlightArea: boolean;

  @Input()
  labelWithoutPadding = false;

  /** флаг включения/отключения счетчиков введенных символов наработки по импруву в дальнейшем */
  @Input()
  withCounter = false;

  /** Значение поля */
  public model = '';

  /** текст из textarea с пробелом в конце */
  public textareaFormattedText: string;

  /** Доступность поля */
  public disabled: boolean;

  public control: UntypedFormControl = new UntypedFormControl();

  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (_: string) => {};
  public onTouched = () => {};

  constructor(private injector: Injector) {
    super();
  }

  /** Получаем доступ к контролу в случае использования NG_VALUE_ACCESSOR */
  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl) {
      setTimeout(() => (this.control = ngControl.control as UntypedFormControl));
    }
  }
  /** Событие изменения значения поля */
  public onInputChange(): void {
    if (this.useFrenchQuotes) {
      this.model = this.frenchQuotes(this.model, this.inputElement.nativeElement);
    }

    this.onChange(this.model);
    this.textareaFormattedText = `${this.model} `;
  }
  /** Событие установки текста по умолчанию, если текст передается из вызывающего компонента */
  public onFocus() {
    if (this.setDefaultText) {
      if (!this.model) {
        this.model = this.setDefaultText;
        this.onInputChange();
      }
    }
  }

  /** Снятие фокуса */
  public onBlur(): void {
    this.onTouched();
    this.spaceTrim(this.model);
  }

  /** поймать передаваемое значение через NG_VALUE_ACCESSOR */
  public writeValue(value: string): void {
    this.model = value;
    this.textareaFormattedText = this.model;
  }

  /** определить функцию onChange из NG_VALUE_ACCESSOR */
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  /** определить функцию onTouched из NG_VALUE_ACCESSOR */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** поймать отключение формы через NG_VALUE_ACCESSOR */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
