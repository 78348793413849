import { ConsiderationParticipantTypeModel } from '@models/calendar-consideration/consideration-participant-type.model';
import { Action } from '@ngrx/store';

/** Справочник "типы участников в пакете повестки" */

export enum ParticipantTypesActionTypes {
  Get = '[Participant Types] Get',
  GetSuccess = '[Participant Types] GetSuccess',
  GetError = '[Participant Types] GetError',
  Reset = '[Participant Types] Reset',
}

export class ParticipantTypesGet implements Action {
  readonly type = ParticipantTypesActionTypes.Get;
}

export class ParticipantTypesGetError implements Action {
  readonly type = ParticipantTypesActionTypes.GetError;
}

export class ParticipantTypesGetSuccess implements Action {
  readonly type = ParticipantTypesActionTypes.GetSuccess;
  constructor(public payload: ConsiderationParticipantTypeModel[]) {}
}

export class ParticipantTypesReset implements Action {
  readonly type = ParticipantTypesActionTypes.Reset;
}

export type ParticipantTypesAction =
  | ParticipantTypesGet
  | ParticipantTypesGetSuccess
  | ParticipantTypesGetError
  | ParticipantTypesReset;
