import { DocumentRouteActiveTasksActions, DocumentRouteActiveTasksActionTypes } from '@appDocuments/documents-route/store/document-route-active-tasks/document-route-active-tasks.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export interface DocumentRouteActiveTasksState {
  tasks: any[];
  status: StoreLoadStatus;
  error: any;
}

export const initialState: DocumentRouteActiveTasksState = {
  tasks: null,
  status: null,
  error: null
};

export function documentRouteActiveTasksReducer(
  state = initialState,
  action: DocumentRouteActiveTasksActions
): DocumentRouteActiveTasksState {
  switch (action.type) {
    case DocumentRouteActiveTasksActionTypes.GetActiveTasks:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
        error: null
      };

    case DocumentRouteActiveTasksActionTypes.GetActiveTasksSuccess: {
      return {
        ...state,
        tasks: action.payload,
        status: StoreLoadStatus.loaded,
        error: null
      };
    }

    case DocumentRouteActiveTasksActionTypes.GetActiveTasksError: {
      return {
        ...state,
        status: StoreLoadStatus.error,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
