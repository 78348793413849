import {
  DocumentPackageSendingsAction,
  DocumentPackageSendingsActionTypes,
} from '@appDocuments/core/store/document-package-sendings/document-package-sendings.actions';
import { SendingsPopupTypes } from '@appDocuments/document-sendings/enums/sendings-popup-types.enum';
import { Sending } from '@appDocuments/document-sendings/models/sending.model';
import { UnsentSendingsModel } from '@appDocuments/document-sendings/models/unsent-sendings.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface DocumentPackageSendingsState extends EntityState<Sending> {
  error: any;
  status: StoreLoadStatus;
  sendingsPopupType: SendingsPopupTypes;
  sendingsPopupPayload: any;
  page: number;
  pageSize: number;
  pageCount: number;
  searchText: string;
  unsentSendings: UnsentSendingsModel;
  totalResultsCount: number;
}

const sendingsAdapter = createEntityAdapter<Sending>({
  selectId: (e) => e.id,
});

export const initialState = sendingsAdapter.getInitialState({
  error: null,
  status: null,
  sendingsPopupType: null,
  sendingsPopupPayload: null,
  page: 0,
  pageSize: 25,
  pageCount: null,
  searchText: null,
  unsentSendings: null,
  totalResultsCount: 0,
});

export function documentPackageSendingsReducer(
  state: DocumentPackageSendingsState = initialState,
  action: DocumentPackageSendingsAction
): DocumentPackageSendingsState {
  switch (action.type) {
    case DocumentPackageSendingsActionTypes.Get:

      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.GetSuccess:
      return sendingsAdapter.addMany(action.sendings.content, {
        ...state,
        page: action.sendings.currentPageNumber + 1,
        pageCount: action.sendings.pagesCount,
        status: StoreLoadStatus.loaded,
        totalResultsCount: action.sendings.totalResultsCount,
      });

    case DocumentPackageSendingsActionTypes.GetError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.ResetSendings:
      return {
        ...initialState,
      };

    case DocumentPackageSendingsActionTypes.GetSearch:
      return {
        ...initialState,
        searchText: action.searchText,
        status: StoreLoadStatus.loaded,
      };

    case DocumentPackageSendingsActionTypes.Update:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.UpdateSuccess:
      return sendingsAdapter.updateOne(action.sending, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.UpdateError:
      return {
        ...state,
        error: action.error,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.Add:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.AddSuccess:
      return sendingsAdapter.addOne(action.sending, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.AddError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.Remove:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.RemoveSuccess:
      return sendingsAdapter.removeOne(action.sendingId, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.RemoveError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.SendEmail:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.SendEmailSuccess:
      return sendingsAdapter.updateOne(action.sending, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.SendEmailError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.SendPaper:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.SendPaperSuccess:
      return sendingsAdapter.updateOne(action.sending, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.SendPaperError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.MassSend:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.MassSendSuccess:
      return {
        ...initialState,
        status: StoreLoadStatus.loaded,
      };

    case DocumentPackageSendingsActionTypes.MassSendError:
      return {
        ...state,
        error: action.error,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageSendingsActionTypes.SendingsPopupType:
      return {
        ...state,
        sendingsPopupType: action.popupType,
        sendingsPopupPayload: action.popupPayload,
      };

    case DocumentPackageSendingsActionTypes.GetUnsentSendingsCount:
      return {
        ...state,
      };

    case DocumentPackageSendingsActionTypes.GetUnsentSendingsCountSuccess:
      return {
        ...state,
        unsentSendings: action.unsentSending,
      };

    case DocumentPackageSendingsActionTypes.GetUnsentSendingsCountError:
      return {
        ...state,
        error: action.error,
      };

    case DocumentPackageSendingsActionTypes.RepeatedlySend:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageSendingsActionTypes.RepeatedlySendSuccess:
      return sendingsAdapter.updateOne(action.sending, {
        ...state,
        status: StoreLoadStatus.loaded,
      });

    case DocumentPackageSendingsActionTypes.RepeatedlySendError:
      return {
        ...state,
        error: action.error,
        status: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

export const { selectAll: selectAll } = sendingsAdapter.getSelectors();
export const { selectEntities: selectEntities } = sendingsAdapter.getSelectors();
