import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameExtraction',
})
export class NameExtractionPipe implements PipeTransform {
  transform(val: any[], key: string = 'name'): string {
    if (!val || !val.length) {
      return '';
    }

    const names = val.filter((v) => v.hasOwnProperty(key)).map((x) => x[key]);
    return names.sort().join(', ');
  }
}
