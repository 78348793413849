import { DocumentPackageConsiderationInfoModel } from '@appShared/api/document-package/models/document-package/document-package-consideration-info.model';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { RelatedDocumentsModelItem } from '@models/document-package/related-documents/related-documents.model';
import { DocumentStatusModel } from '@models/document-statuses/document-status.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { ReviewDateChangeRequestResponseModel } from '@appDocuments/shared/models/review-date-change-request-response.model';

export class DocumentPackageStoreModel {
  constructor(
    public documentPackage: DocumentPackageResponseModel,
    public error: any,
    public status: StoreLoadStatus,
    public considerationInfo: DocumentPackageConsiderationInfoModel[],
    public considerationInfoError: any,
    public considerationInfoStatus: StoreLoadStatus,
    public considerationRequestChanges: ReviewDateChangeRequestResponseModel[],
    public considerationRequestChangesError: any,
    public considerationRequestChangesStatus: StoreLoadStatus,
    public revisionTypeInfo: DocumentStatusModel,
    public revisionTypeInfoStatus: StoreLoadStatus,
    public changableDocuments: RelatedDocumentsModelItem[],
    public affectingDocuments: RelatedDocumentsModelItem[],
    public changableDocumentsSnapshot: RelatedDocumentsModelItem[],
    public affectingDocumentsSnapshot: RelatedDocumentsModelItem[]
  ) {}
}
