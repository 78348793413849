export enum DocumentVersionState {
  /**
   * Действуюая редакция
   */
  ACTING = 'ACTING',
  /**
   * Действующая редакция, утратившая силу
   */
  REPEALED = 'REPEALED',
  /**
   * Отменённая действующая редакция
   */
  CANCELED = 'CANCELED',
  /**
   * Измененная версия
   */
  MODIFIED = 'MODIFIED',
  /**
   * Копия действующей редакции
   */
  ACTING_COPY = 'ACTING_COPY',
  /**
   * Создана вручную.
   */
  MANUAL_MODE = 'MANUAL_MODE',
  /**
   * Сгенерирована автоматически
   */
  AUTO_MODE = 'AUTO_MODE',
  /**
   * Обычное (пустое) состояние
   */
  DEFAULT = 'DEFAULT',
  /**
   * Совместная работа
   */
  COLLABORATION = 'COLLABORATION',
}
