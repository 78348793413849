import { DictionaryStoreModel } from './dictionary-store.model';
import { StoreLoadStatus } from './enums/store-load-status.enum';

export class DictionaryStoreMapModel<T> extends DictionaryStoreModel<T> {
  constructor(
    public dictionary: T[] = [],
    public saveStatus: StoreLoadStatus = null,
    public loadStatus: StoreLoadStatus = null,
    public dictionaryMap: Map<number, T> = null
  ) {
    super(dictionary, saveStatus, loadStatus);
  }
}
