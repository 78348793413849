export const environment = {
  showGitInfoInTitle: false,
  envName: 'envProd',
  reduxMaxStoreAge: 20,
  baseUrl: 'npa/api',
  production: true,
  pathToLdeScriptsNpa: 'https://aissd.mos.ru/lde-api',
  pathToLdeScriptsLpa: 'https://aissd.mos.ru/lde-api-local',
  enableMockUser: false,
  ravenDns: 'https://f1dee3b8c3134f5c8627874438dab920@aissd.mos.ru:9001/1',
  sentryEnabled: false,
  loadLde: true,
  enableRRWeb: true,
  websocketBaseUrl: `https://aissd.mos.ru`,
  enableAnalytics: true,
  analyticsUrl: 'https://matomo-digitalofficial.mos.ru/',
  analyticsSiteId: 3,
  hpsmBaseUrl: 'npa/hpsm/api',
  needJWT: false,
  authBaseUrl: 'https://aissd.mos.ru/auth',
  imageBaseUrl: 'aissd.mos.ru',
};
