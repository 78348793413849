import { Component, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { UnderscoreType } from '@libs/projects/component-lib/src/public-api';
import { SelectGroupItemModel } from '@models/select-group-item.model';
import { GroupItemSelectComponent } from '../group-item-select/group-item-select.component';

@Component({
  selector: 'app-group-item-search-select',
  templateUrl: './group-item-search-select.component.html',
  styleUrls: [
    '../../control-base/control-base.component.scss',
    '../group-item-select/group-item-select.component.scss',
    '../select-base-component/select.base.scss',
    './group-item-search-select.component.scss',
  ],
})
export class GroupItemSearchSelectComponent extends GroupItemSelectComponent implements OnChanges {
  public viewGroupsList: SelectGroupItemModel[];
  public filterText: string;
  public matchFilterText: string;
  public underscoreType = UnderscoreType;

  constructor(elementRef: ElementRef, redux: ReduxService) {
    super(elementRef, redux);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupsList) {
      this.viewGroupsList = this.groupsList;
    }
    this.filterText = this.control.value?.name;
  }

  public onFilter(filterText: string, isResetFilter?: boolean): void {
    this.matchFilterText = this.filterText;

    if (isResetFilter || filterText?.length < 2) {
      this.viewGroupsList = this.groupsList;
      return;
    }
    const groups = this.groupsList.reduce((acc, currVal) => {
      const groupResult = currVal.optionsList.filter((option) => {
        const lowerCaseOptionLabel = option[this.optionLabel as string].toLowerCase();
        if (!filterText) {
          return true;
        }
        return lowerCaseOptionLabel.includes(filterText.toLowerCase());
      });

      const newGroup = { ...currVal, optionsList: groupResult };

      return acc.concat(newGroup);
    }, []);
    this.viewGroupsList = groups.filter((group) => group.optionsList.length);
  }

  public selectOptionWithSearch(value: any): void {
    this.selectOption(value);
    this.filterText = value?.name;
    this.viewGroupsList = this.groupsList;
    this.matchFilterText = null;
  }

  public onClearWithSearch(): void {
    this.onClearControlValue();
    this.filterText = null;
    this.viewGroupsList = this.groupsList;
    this.matchFilterText = null;
  }
}
