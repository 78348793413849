import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

export enum DocumentStatusesActionTypes {
  Get = '[Document Statuses] Get',
  GetSuccess = '[Document Statuses] GetSuccess',
  GetError = '[Document Statuses] GetError',
  Reset = '[Document Statuses] Reset',
}

/** Обновление типа проекта */
export class DocumentStatusesGet implements Action {
  readonly type = DocumentStatusesActionTypes.Get;
}

/** Обновление типа проекта */
export class DocumentStatusesGetError implements Action {
  readonly type = DocumentStatusesActionTypes.GetError;
}

/** Обновление типа проекта */
export class DocumentStatusesGetSuccess implements Action {
  readonly type = DocumentStatusesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

/** Обновление типа проекта */
export class DocumentStatusesReset implements Action {
  readonly type = DocumentStatusesActionTypes.Reset;
}

export type DocumentStatusesAction =
  | DocumentStatusesGet
  | DocumentStatusesGetSuccess
  | DocumentStatusesGetError
  | DocumentStatusesReset;
