export enum SymbolIconListEnum {
  "FIRE" = "assets/icons/symbols/fire.svg",
  "SPY" = "assets/icons/symbols/spy.svg",
  "LIPS" = "assets/icons/symbols/lips.svg",
  "BUILDING" = "assets/icons/symbols/building.svg",
  "TRIANGLE" = "assets/icons/symbols/triangle.svg",
  "RECTANGLE" = "assets/icons/symbols/rectangle.svg",
  "OCTAGON" = "assets/icons/symbols/octagon.svg",
  "CIRCLE" = "assets/icons/symbols/circle.svg",
  "EMBLEM" = "assets/icons/symbols/emblem.svg",
};
