import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from '@appCore/urls/urls';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { FolderModel } from '@models/folder/folder.model';

@Injectable({ providedIn: 'root' })
export class PersonalFoldersApiService extends DefaultDataService<FolderModel> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('personal-folders', http, httpUrlGenerator);
  }

  public add(folder: FolderModel): Observable<FolderModel> {
    const url = Urls.postFolder();

    return this.http.post<FolderModel>(url, folder);
  }

  public getAll(): Observable<FolderModel[]> {
    const url = Urls.getFolders();

    return this.http.get<FolderModel[]>(url);
  }

  public update(folder: Update<FolderModel>): Observable<FolderModel> {
    const url = Urls.postFolder();

    return this.http.put<FolderModel>(url, folder.changes);
  }

  public delete(id: number): Observable<any> {
    const url = Urls.deleteFolder(id);

    return this.http.delete(url);
  }
}
