import { Injectable } from '@angular/core';
import moment from 'moment';

/** Сервис для работы с датами */
@Injectable({ providedIn: 'root' })
export class DatesService {
  private months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  public getMonthTitle(dateData: string): string {
    if (!dateData) {
      return '';
    }

    const date = new Date(dateData);
    const result = this.months[date.getMonth()];

    return result;
  }

  public addTimeToDate(date: string, time: string, format = 'YYYY-MM-DD'): string {
    return `${moment(date).format(format)}T${time}:00+03:00`;
  }

  public getDayTitle(count: number, working?: boolean): string {
    if (!count) {
      return null;
    }

    const cases = [2, 0, 1, 1, 1, 2];
    const days = ['день', 'дня', 'дней'];
    const workingDays = ['рабочий день', 'рабочих дня', 'рабочих дней'];
    const titles = working ? workingDays : days;

    return titles[count % 100 > 4 && count % 100 < 20 ? 2 : cases[count % 10 < 5 ? count % 10 : 5]];
  }

  public isCorrectDateOrder(start: string, end: string): boolean {
    return moment(start).isBefore(end);
  }

  public isEqualDates(dateTimeOne: string, dateTimeTwo: string): boolean {
    const dateOne = moment(dateTimeOne).format('YYYY-MM-DD');
    const dateTwo = moment(dateTimeTwo).format('YYYY-MM-DD');

    return moment(dateOne).isSame(dateTwo);
  }
}
