import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsEmptyValuePipe } from '@appMain/analytics/summary-analytics/pipes/is-empty-value.pipe';
import { ToFormControlPipe } from '@appMain/calendar-consideration/pipes/to-form-control.pipe';
import { CalendarCurrentMonthFilter } from '@appShared/pipes/calendar-current-month-filter.pipe';
import { CheckObjectForNotNullableKeyPipe } from '@appShared/pipes/check-object-for-not-nullable-key.pipe';
import { EmployeeDelegatingTypeName } from '@appShared/pipes/employee-delegating-type-name';
import { Nl2BrPipe } from '@appShared/pipes/nl2br.pipe';
import { NpaDatePipe } from '@appShared/pipes/npa-date.pipe';
import { NumberedDocumentPipe } from '@appShared/pipes/numbered-document.pipe';
import { ObjectsToStrPipe } from '@appShared/pipes/objects-to-str.pipe';
import { SelectFilterPipe } from '@appShared/pipes/select-filter.pipe';
import { SortArrayPipe } from '@appShared/pipes/sort-array.pipe';
import { UrlValueByPropPipe } from '@appShared/pipes/url-value-by-prop.pipe';
import { AssignedExecutorsRemapPipe } from './assigned-executor-remap.pipe';
import { AssignedExecutorSortPipe } from './assigned-executor-sort.pipe';
import { CalculateTotalPipe } from './calculate-total.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CheckShowErrorMsgPipe } from './check-show-error-msg.pipe';
import { DisplayByLimit } from './display-by-limit.pipe';
import { DocumentActualVersionPipe } from './document-actual-version.pipe';
import { DocumentHeadVersionPipe } from './document-head-version.pipe';
import { EmployeeChangesInfoPipe } from './employee-changes-info.pipe';
import { EmployeeFullNamePipe } from './employee-full-name.pipe';
import { EmployeePhotoUrlPipe } from './employee-photo-url.pipe';
import { EmployeeShortNamePipe } from './employee-short-name.pipe';
import { GetStatisticsCountOverduePipe } from './get-statistics-count-overdue.pipe';
import { GetStatisticsCountPipe } from './get-statistics-count.pipe';
import { MapperWithIndexPipe } from './mapper-with-index.pipe';
import { MapperPipe } from './mapper.pipe';
import { OptionLabelNamePipe } from './option-label-name.pipe';
import { ProjectGroupTypeClassnamePipe } from './project-group-type-classname.pipe';
import { ReplaceQuotesWithFrenchPipe } from './replace-quotes-with-french.pipe';
import { SortConsiderationTypesPipe } from './sort-consideration-types.pipe';
import { SortPipe } from './sort.pipe';
import { StringsArrayFilterPipe } from './strings-array-filter.pipe';
import { TrimPipe } from './trim.pipe';
import { AccessByPermissionPipe } from '@appMain/dashboard/pipes/access-by-permission.pipe';

const pipes = [
  NpaDatePipe,
  Nl2BrPipe,
  SortArrayPipe,
  CalendarCurrentMonthFilter,
  SelectFilterPipe,
  OptionLabelNamePipe,
  DocumentActualVersionPipe,
  StringsArrayFilterPipe,
  EmployeeShortNamePipe,
  EmployeeFullNamePipe,
  EmployeePhotoUrlPipe,
  CheckObjectForNotNullableKeyPipe,
  NumberedDocumentPipe,
  DocumentHeadVersionPipe,
  AssignedExecutorsRemapPipe,
  AssignedExecutorSortPipe,
  EmployeeDelegatingTypeName,
  SortPipe,
  GetStatisticsCountPipe,
  GetStatisticsCountOverduePipe,
  MapperPipe,
  MapperWithIndexPipe,
  EmployeeChangesInfoPipe,
  ObjectsToStrPipe,
  IsEmptyValuePipe,
  TrimPipe,
  ReplaceQuotesWithFrenchPipe,
  ProjectGroupTypeClassnamePipe,
  CheckShowErrorMsgPipe,
  ToFormControlPipe,
  UrlValueByPropPipe,
  CapitalizePipe,
  SortConsiderationTypesPipe,
  CalculateTotalPipe,
  DisplayByLimit,
  AccessByPermissionPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class SharedPipesModule {}
