import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BigButtonComponent } from '@appShared/components/buttons/big-button/big-button.component';
import { HeaderButtonComponent } from '@appShared/components/buttons/header-button/header-button.component';
import { IconButtonComponent } from '@appShared/components/buttons/icon-button/icon-button.component';
import { MiddleButtonComponent } from '@appShared/components/buttons/middle-button/middle-button.component';
import { PolyhedralButtonComponent } from '@appShared/components/buttons/polyhedral-button/polyhedral-button.component';
import { SmallButtonComponent } from '@appShared/components/buttons/small-button/small-button.component';
import { TooltipModule } from '@appShared/components/tooltip/tooltip.module';
import { LoadersModule } from '../loaders/loaders.component';
import { ActionButtonComponent } from './action-button/action-button.component';

@NgModule({
  imports: [CommonModule, TooltipModule, LoadersModule],
  declarations: [
    SmallButtonComponent,
    PolyhedralButtonComponent,
    MiddleButtonComponent,
    IconButtonComponent,
    HeaderButtonComponent,
    BigButtonComponent,
    ActionButtonComponent,
  ],
  exports: [
    SmallButtonComponent,
    PolyhedralButtonComponent,
    MiddleButtonComponent,
    IconButtonComponent,
    HeaderButtonComponent,
    BigButtonComponent,
    ActionButtonComponent,
  ],
})
export class ButtonsModule {}
