import { createAction, props } from '@ngrx/store';
import { SharedFilter } from './models/shared-filter.model';

export enum SharedFilterTypes {
  GetAllShared = '[Shared Filter] GetAllShared',
  GetAllSharedSuccess = '[Shared Filter] GetAllSharedSuccess',
  GetAllSharedError = '[Shared Filter] GetAllSharedError',
}

/** Список личных папок текущего пользователя */
export const getSharedFilters = createAction(SharedFilterTypes.GetAllShared);
export const getSharedFiltersSuccess = createAction(
  SharedFilterTypes.GetAllSharedSuccess,
  props<{ sharedFilters: SharedFilter[] }>()
);
export const getSharedFiltersError = createAction(SharedFilterTypes.GetAllSharedError);

export const SharedFiltersActions = {
  getSharedFilters,
  getSharedFiltersSuccess,
  getSharedFiltersError,
};
