import { Injectable } from '@angular/core';
import { PersonalFoldersApiService } from '@appShared/api/personal-cabinet/personal-folders-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PersonalFoldersActions } from './personal-folders.actions';

@Injectable()
export class PersonalFoldersEffects {
  constructor(private actions$: Actions, private personalFoldersApiService: PersonalFoldersApiService) {}

  getAllFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalFoldersActions.getPersonalFolders),
      switchMap(() =>
        this.personalFoldersApiService.getAll().pipe(
          map((response) => {
            return PersonalFoldersActions.getPersonalFoldersSuccess({ personalFoldersList: response });
          }),
          catchError(() => of(PersonalFoldersActions.getPersonalFoldersError()))
        )
      )
    )
  );
}
