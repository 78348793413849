import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective implements OnInit {
  constructor(private el: ElementRef) {}
  currentState: string;
  public sections: HTMLObjectElement[];
  @HostListener('scroll')
  scrolling() {
    this.checkElempost();
  }
  ngOnInit() {
    this.sections = Array.from(this.el.nativeElement.children);
  }
  private checkElempost() {
    this.sections.forEach(element => {
      this.targetPos(element);
    });
  }

  private targetPos(element) {
    const windowPosition = this.el.nativeElement.scrollTop + 80;
    const targetPosition = element.offsetTop;

    const pos = windowPosition - targetPosition;

    if (-100 < pos && pos < 20) {
      if (this.currentState === element.id || element.id === 'dispatch-form') {
        return;
      }

      this.currentState = element.id;
    }
  }
}
