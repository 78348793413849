import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'reportGridSort',
})
export class ReportGridSortPipe implements PipeTransform {
  transform(gridList: any[], key: string, isReverse?: boolean): any[] {
    const gridListCopy = [...gridList];
    if (!gridListCopy) {
      return [];
    }

    if (!key) {
      return gridListCopy;
    }

    const firstElementByKey = gridListCopy.map((i) => i[key]).find((k) => k);

    if (!firstElementByKey) {
      return gridListCopy;
    }

    const result = this.setGridListSorted(firstElementByKey, gridListCopy, key);

    if (isReverse) {
      return result.reverse();
    }

    return result;
  }

  /** TODO: т. к. предшественники не озаботились провести нормальную типизацию надо будет изучить пайп и поставить типы */
  private setGridListSorted(firstElementByKey: any, gridListCopy: any[], key: string): any {
    if (firstElementByKey.options && firstElementByKey.options.dateFormat) {
      return gridListCopy.sort((a, b) =>
        moment(a.registrationDate, firstElementByKey.options.dateFormat).isAfter(
          b.registrationDate,
          firstElementByKey.options.dateFormat
        )
          ? -1
          : 1
      );
    }
    if (firstElementByKey.value) {
      return gridListCopy.sort((a, b) => (a[key].value > b[key].value ? 1 : -1));
    }
    const isStringType = gridListCopy.some((i) => typeof i[key] === 'string');
    if (isStringType) {
      return gridListCopy.sort((a, b) => a[key]?.toLowerCase().localeCompare(b[key]?.toLowerCase()));
    }

    return gridListCopy.sort((a, b) => (a[key] > b[key] ? 1 : -1));
  }
}
