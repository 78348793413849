import { SendingsPopupTypes } from '@appDocuments/document-sendings/enums/sendings-popup-types.enum';
import { EmailSendingRequestModel } from '@appDocuments/document-sendings/models/email-sending-request.model';
import { MassSendingRequestModel } from '@appDocuments/document-sendings/models/mass-sending-request.model';
import { PaperSendingRequestModel } from '@appDocuments/document-sendings/models/paper-sending-request.model';
import { RepeatedSendingModel } from '@appDocuments/document-sendings/models/repeated-sending.model';
import { Sending } from '@appDocuments/document-sendings/models/sending.model';
import { UnsentSendingsModel } from '@appDocuments/document-sendings/models/unsent-sendings.model';
import { PaginationResponseModel } from '@models/pagination-response.model';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

export enum DocumentPackageSendingsActionTypes {
  Get = '[Document Package Sendings] Get',
  GetSuccess = '[Document Package Sendings] GetSuccess',
  GetError = '[Document Package Sendings] GetError',
  ResetSendings = '[Document Package Sendings] ResetSendings',
  GetSearch = '[Document Package Sendings] GetSearch',
  Update = 'Document Package Sendings Update',
  UpdateSuccess = '[Document Package Sendings] UpdateSuccess',
  UpdateError = '[Document Package Sendings] UpdateError',
  Add = '[Document Package Sendings] Add',
  AddSuccess = '[Document Package Sendings] AddSuccess',
  AddError = '[Document Package Sendings] AddError',
  Remove = '[Document Package Sendings] Remove',
  RemoveSuccess = '[Document Package Sendings] RemoveSuccess',
  RemoveError = '[Document Package Sendings] RemoveError',
  SendEmail = '[Document Package Sendings] SendEmail',
  SendEmailSuccess = '[Document Package Sendings] SendEmailSuccess',
  SendEmailError = '[Document Package Sendings] SendEmailError',
  SendPaper = '[Document Package Sendings] SendPaper',
  SendPaperSuccess = '[Document Package Sendings] SendPaperSuccess',
  SendPaperError = '[Document Package Sendings] SendPaperError',
  MassSend = '[Document Package Sendings] MassSend',
  MassSendSuccess = '[Document Package Sendings] MassSendSuccess',
  MassSendError = '[Document Package Sendings] MassSendError',
  SendingsPopupType = '[Document Package Sendings] SendingsPopupType',
  GetUnsentSendingsCount = '[Document Package Sendings] GetUnsentSendingsCount',
  GetUnsentSendingsCountSuccess = '[Document Package Sendings] GetUnsentSendingsCountSuccess',
  GetUnsentSendingsCountError = '[Document Package Sendings] GetUnsentSendingsCountError',
  RepeatedlySend = '[Document Package Sendings] RepeatedlySend',
  RepeatedlySendSuccess = '[Document Package Sendings] RepeatedlySendSuccess',
  RepeatedlySendError = '[Document Package Sendings] RepeatedlySendError',
}

export class DocumentPackageSendingsGet implements Action {
  readonly type = DocumentPackageSendingsActionTypes.Get;
}

export class DocumentPackageSendingsGetSearch implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetSearch;
  constructor(public searchText: string) {}
}

export class DocumentPackageSendingsGetSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetSuccess;
  constructor(public sendings: PaginationResponseModel<Sending>) {}
}

export class DocumentPackageSendingsGetError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingsResetSendings implements Action {
  readonly type = DocumentPackageSendingsActionTypes.ResetSendings;
}

export class DocumentPackageSendingsUpdate implements Action {
  readonly type = DocumentPackageSendingsActionTypes.Update;
  constructor(public sending: Sending) {}
}

export class DocumentPackageSendingsUpdateSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.UpdateSuccess;
  constructor(public sending: Update<Sending>) {}
}

export class DocumentPackageSendingsUpdateError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.UpdateError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingAdd implements Action {
  readonly type = DocumentPackageSendingsActionTypes.Add;
  constructor(public sending: Sending) {}
}

export class DocumentPackageSendingAddSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.AddSuccess;
  constructor(public sending: Sending) {}
}

export class DocumentPackageSendingAddError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.AddError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingRemove implements Action {
  readonly type = DocumentPackageSendingsActionTypes.Remove;
  constructor(public sendingId: number) {}
}

export class DocumentPackageSendingRemoveSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.RemoveSuccess;
  constructor(public sendingId: number) {}
}

export class DocumentPackageSendingRemoveError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.RemoveError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingSendEmail implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendEmail;
  constructor(public emailSending: EmailSendingRequestModel) {}
}

export class DocumentPackageSendingSendEmailSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendEmailSuccess;
  constructor(public sending: Update<Sending>) {}
}

export class DocumentPackageSendingSendEmailError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendEmailError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingSendPaper implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendPaper;
  constructor(public paperSending: PaperSendingRequestModel) {}
}

export class DocumentPackageSendingSendPaperSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendPaperSuccess;
  constructor(public sending: Update<Sending>) {}
}

export class DocumentPackageSendingSendPaperError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendPaperError;
  constructor(public error: any) {}
}

export class DocumentPackageSendingMassSend implements Action {
  readonly type = DocumentPackageSendingsActionTypes.MassSend;
  constructor(public massSending: MassSendingRequestModel) {}
}

export class DocumentPackageSendingMassSendSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.MassSendSuccess;
}

export class DocumentPackageSendingMassSendError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.MassSendError;
  constructor(public error: any) {}
}

export class SetSendingsPopupType implements Action {
  readonly type = DocumentPackageSendingsActionTypes.SendingsPopupType;
  constructor(public popupType: SendingsPopupTypes, public popupPayload: any = null) {}
}

export class GetUnsentSendingsCount implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetUnsentSendingsCount;
  constructor(public documentPackageId: number) {}
}

export class GetUnsentSendingsCountSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetUnsentSendingsCountSuccess;
  constructor(public unsentSending: UnsentSendingsModel) {}
}

export class GetUnsentSendingsCountError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.GetUnsentSendingsCountError;
  constructor(public error: any) {}
}

export class RepeatedlySend implements Action {
  readonly type = DocumentPackageSendingsActionTypes.RepeatedlySend;
  constructor(public payload: RepeatedSendingModel) {}
}

export class RepeatedlySendSuccess implements Action {
  readonly type = DocumentPackageSendingsActionTypes.RepeatedlySendSuccess;
  constructor(public sending: Update<Sending>) {}
}

export class RepeatedlySendError implements Action {
  readonly type = DocumentPackageSendingsActionTypes.RepeatedlySendError;
  constructor(public error: any) {}
}

export type DocumentPackageSendingsAction =
  | DocumentPackageSendingsGet
  | DocumentPackageSendingsGetSuccess
  | DocumentPackageSendingsGetError
  | DocumentPackageSendingsResetSendings
  | DocumentPackageSendingsGetSearch
  | DocumentPackageSendingsUpdate
  | DocumentPackageSendingsUpdateSuccess
  | DocumentPackageSendingsUpdateError
  | DocumentPackageSendingAdd
  | DocumentPackageSendingAddSuccess
  | DocumentPackageSendingAddError
  | DocumentPackageSendingRemove
  | DocumentPackageSendingRemoveSuccess
  | DocumentPackageSendingRemoveError
  | DocumentPackageSendingSendEmail
  | DocumentPackageSendingSendEmailSuccess
  | DocumentPackageSendingSendEmailError
  | DocumentPackageSendingSendPaper
  | DocumentPackageSendingSendPaperSuccess
  | DocumentPackageSendingSendPaperError
  | DocumentPackageSendingMassSend
  | DocumentPackageSendingMassSendSuccess
  | DocumentPackageSendingMassSendError
  | SetSendingsPopupType
  | GetUnsentSendingsCount
  | GetUnsentSendingsCountSuccess
  | GetUnsentSendingsCountError
  | RepeatedlySend
  | RepeatedlySendSuccess
  | RepeatedlySendError;
