import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  NomenclaturesAction,
  NomenclaturesActionTypes,
  NomenclaturesGetError,
  NomenclaturesGetSuccess,
} from './nomenclatures.actions';

@Injectable()
export class NomenclaturesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetNomenclatures$: Observable<NomenclaturesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(NomenclaturesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() => this.dictionaryApi.getNomenclatureIndexes()),
      map((response) => new NomenclaturesGetSuccess(response)),
      catchError((err) => of(new NomenclaturesGetError()))
    )
  );
}
