import { DocumentRouteModuleState, selectDocumentRouteModule } from '@appDocuments/documents-route/store';
import { createSelector } from '@ngrx/store';

export const getRouteSendToApprovementStore = createSelector(
  selectDocumentRouteModule,
  (store: DocumentRouteModuleState) => store.routeSendToApprovement
);

export const getRouteSendToApprovementPhases = createSelector(getRouteSendToApprovementStore, (state) => state.phases);

export const getRouteSendToApprovementPhasesLoadStatus = createSelector(
  getRouteSendToApprovementStore,
  (state) => state.status
);

export const getRouteSendToApprovementPhasesOpenSertificatteList = createSelector(
  getRouteSendToApprovementStore,
  (state) => state.isOpenedSerificateList
);
