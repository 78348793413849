import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserInfoState } from './user-info.reducer';

export const getUserInfoStore = createFeatureSelector<UserInfoState>('userInfo');

export const getUserInfo = createSelector(getUserInfoStore, (userInfoState: UserInfoState) => userInfoState.userInfo);
export const getUserInfoError = createSelector(getUserInfoStore, (userInfoState: UserInfoState) => userInfoState.error);
export const getUserInfoStatus = createSelector(
  getUserInfoStore,
  (userInfoState: UserInfoState) => userInfoState.status
);
export const getIsHaveUserInfo = createSelector(
  getUserInfoStore,
  (userInfoState: UserInfoState) => userInfoState.userInfo && !!userInfoState.userInfo.currentUser.employee
);

/** Селектор вернёт организацию для текущено пользователя */
export const getOrganizationForCurrentUser = createSelector(
  getUserInfo,
  (info) => info.currentUser?.employee.organization
);

export const getRolesForCurrentUser = createSelector(getUserInfo, (info) => {
  if (!info) {
    return [];
  }
  return info.currentUser.roles;
});

/** Селектор вернёт true, если у текущего пользователя есть хотя бы одна из передаваемых ролей */
export const currentUserHasOneOfTheFollowingRoles = (requiredRoles: string[]) =>
  createSelector(getRolesForCurrentUser, (roles) => roles.some((role) => requiredRoles.includes(role)));
