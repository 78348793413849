import { Action } from '@ngrx/store';

/** набор экшенов для левого сайдбара документов */
export enum DocumentsAsideActionTypes {
  ExpandAsideBar = '[Aside Bar] ExpandAsideBar',
  NarrowAsideBar = '[Aside Bar] NarrowAsideBar'
}

/** расширить панель сайдбара */
export class ExpandAsideBar implements Action {
  readonly type = DocumentsAsideActionTypes.ExpandAsideBar;
}

/** сузить панель сайдбара */
export class NarrowAsideBar implements Action {
  readonly type = DocumentsAsideActionTypes.NarrowAsideBar;
}

/** возможные варианты экшенов для левого сайдбара документов */
export type DocumentsAsideAction = ExpandAsideBar | NarrowAsideBar;
