import { Injectable } from '@angular/core';
import { FolderTypes } from '@appMain/dashboard/enums/folder-types.enum';
import { NotificationType } from '@appMain/notifications/enums/notification-type.enum';
import { CalendarConsiderationApiService } from '@appShared/api/calendar-consideration/calendar-consideration-api.service';
import { NotificationLink } from '@models/notifications/notifications.response/notification-link.model';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationNavigateService {
  constructor(private considerationApiService: CalendarConsiderationApiService) {}

  /** Получение навигационной ссылки для перехода из уведомления на нужный экран
   * ВАЖНО! Ссылка формируется от корня навигации */
  public getLink(item: NotificationLink): Observable<string> {
    if (item.notificationTypeId === NotificationType.reviewDateChangeRequest) {
      return of(`/documents/edit/${item.documentPackageId}`);
    }

    if (item.notificationTypeId === NotificationType.extensionRequest) {
      return of(`/documents/route/${item.documentPackageId}?extensionRequest=${item.deadlineExtensionRequestId}`);
    }

    if (item.notificationTypeId === NotificationType.invite) {
      return this.considerationApiService.getConsiderationGeneralInfo({ id: item.agendaPackageId }).pipe(
        map((considerationInfo) => considerationInfo.events.shift()),
        map((res) => `/main/calendar/consideration/general-info?id=${item.agendaPackageId}&date=${res.dateBegin}`)
      );
    }

    if (item.notificationTypeId === NotificationType.addAccessToFolder) {
      return of(`/main/dashboard?folderId=${item.folderId}&folderType=${FolderTypes.shared}`);
    }

    if (item.notificationTypeId === NotificationType.addAccessToSubselection) {
      return of(`/main/dashboard?sharedFilterId=${item.folderId}`);
    }

    return of(`/documents/route/${item.documentPackageId}?activeTask=true`);
  }

  /** Правило указывает на то возможна ли навигация на другой экран для данного типа уведомления по кнопке "Перейти" */
  public canNavigateByNotificationType(notificationTypeId): boolean {
    return ![
      NotificationType.delegationOfAuthority,
      NotificationType.delegationOfAuthorityActing,
      NotificationType.changeSigningStatus,
      NotificationType.removeAccessToFolder,
      NotificationType.deleteFromRoute,
      NotificationType.changeJobPosition,
      NotificationType.removeAccessToSubselection,
    ].includes(notificationTypeId);
  }
}
