export enum NotificationType {
  /** О назначении исполнителем задачи */
  executor = 1,
  /** По сроку исполнения поручения */
  period = 2,
  /** О внесённых изменениях в документ после согласования */
  amendments = 3,
  /** Об отзыве документа с согласования */
  recall = 4,
  /** О запросе на соавторство */
  coauthorInvite = 5,
  /** Приглашение на рассмотрение */
  invite = 6,
  /** Удаление документа */
  delete = 7,
  /** О согласии на соавторство */
  coauthorAccept = 8,
  /** Об отказе от соавторства */
  coauthorReject = 9,
  /** Об обновлении/изменении версии документа */
  changeDocumentVersion = 10,
  /** О запросе на продление срока */
  extensionRequest = 11,
  /** Продление сроков одобрено */
  extensionRequestAccepted = 12,
  /** Продление сроков отклонено */
  extensionRequestRejected = 13,
  /** О завершении задачи */
  complete = 14,
  /** О делегировании полномочий действовать от имени */
  delegationOfAuthority = 15,
  /** О запросе консультации **/
  introduce = 16,
  /** Об изменении редакции пакета **/
  changePackageRevision = 17,
  /** О создании эталонной версии документа **/
  etalonVersionCreated = 18,
  /** О делегировании полномочий "Исполняюищй обязанности" **/
  delegationOfAuthorityActing = 19,
  /** Об изменении планируемой даты рассмотрения **/
  reviewDateChangeRequest = 26,
  /** Об удалении с маршрута */
  deleteFromRoute = 27,
  /** О замене автора */
  authorChanged = 28,
  /** О назначении автором */
  authorAssigned = 29,
  /** О замене руководителя */
  leaderChanged = 30,
  /** О назначении руководителем */
  leaderAssigned = 31,
  /** Об изменении статуса проекта (подписан) */
  changeSigningStatus = 32,
  /** Предоставлен доступ к папке */
  addAccessToFolder = 33,
  /** Отозван доступ к папке */
  removeAccessToFolder = 34,
  /** Даны права доступа к разделу/подразделу */
  addAccessToSubselection = 35,
  /** Удалены права доступа на раздел/подраздел */
  removeAccessToSubselection = 36,
  /** Об изменении должности */
  changeJobPosition = 39,
}
