import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ToastModel } from '../../models/toast.model';
import { ToastsActions, ToastsActionTypes } from './toasts.actions';

export interface ToastsState extends EntityState<ToastModel> {}

const adapter = createEntityAdapter<ToastModel>({
  selectId: toast => toast.id,
  sortComparer: false
});

export const initialState = adapter.getInitialState();

export function toastsReducer(state: ToastsState = initialState, action: ToastsActions): ToastsState {
  switch (action.type) {
    case ToastsActionTypes.Add:
      return adapter.addOne(action.payload, state);
    case ToastsActionTypes.Remove:
      return adapter.removeOne(action.payload, state);
    case ToastsActionTypes.Reset:
      return state;
    default:
      return state;
  }
}

export const { selectAll: selectAll, selectTotal: selectTotal } = adapter.getSelectors();
