import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalWindowRef } from '@appShared/modals-helper/modal-window-ref';

@Component({
  selector: 'app-redirect-to-appeals-popup',
  templateUrl: './redirect-to-appeals-popup.component.html',
  styleUrls: ['./redirect-to-appeals-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectToAppealsPopupComponent {
  constructor(private modalWindowRef: ModalWindowRef, private router: Router) {}

  public navigateToAppeals(): void {
    const url = 'personal-cabinet/appeals';
    this.router.navigateByUrl(url);
    this.closePopup();
  }

  public closePopup(): void {
    this.modalWindowRef.close();
  }
}
