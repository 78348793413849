import { Component } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './icon-button.component.scss'],
})
export class IconButtonComponent extends ButtonBaseComponent {
  constructor() {
    super();
  }
}
