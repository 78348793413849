import { DictionaryModel } from '@models/base/dictionary.model';
import { Action } from '@ngrx/store';

export enum OrganizationsLeaderActionTypes {
  Get = '[Organizations Leader] Get',
  GetSuccess = '[Organizations Leader] GetSuccess',
  GetError = '[Organizations Leader] GetError',
}

export class OrganizationsLeaderGet implements Action {
  readonly type = OrganizationsLeaderActionTypes.Get;
}

export class OrganizationsLeaderGetError implements Action {
  readonly type = OrganizationsLeaderActionTypes.GetError;
}

export class OrganizationsLeaderGetSuccess implements Action {
  readonly type = OrganizationsLeaderActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export type OrganizationsLeaderAction =
  | OrganizationsLeaderGet
  | OrganizationsLeaderGetError
  | OrganizationsLeaderGetSuccess;
