import { Injectable } from '@angular/core';
import { CalendarApiService } from '@appShared/api/calendar/calendar-api.service';
import {
  MeetingDatesActionTypes,
  MeetingDatesGet,
  MeetingDatesGetError,
  MeetingDatesGetSuccess,
} from '@appShared/components/controls/input-calendar/store/meeting-dates/meeting-dates.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MeetingDatesEffects {
  constructor(private actions$: Actions, private calendarApi: CalendarApiService) {}

  
  getMeetingDates$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MeetingDatesActionTypes.get),
    switchMap((action: MeetingDatesGet) =>
      this.calendarApi.getMeetingDates(action.payload.loadInterval, action.payload.meetingTypeIds).pipe(
        map((response) => new MeetingDatesGetSuccess(response)),
        catchError((error) => of(new MeetingDatesGetError(error)))
      )
    )
  ));
}
