import {
  MeetingDatesActions,
  MeetingDatesActionTypes,
} from '@appShared/components/controls/input-calendar/store/meeting-dates/meeting-dates.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export interface MeetingDatesState {
  meetingDates: string[];
  loadStatus: StoreLoadStatus;
}

const initialState: MeetingDatesState = {
  meetingDates: null,
  loadStatus: null,
};

export function meetingDatesReducer(
  state: MeetingDatesState = initialState,
  action: MeetingDatesActions
): MeetingDatesState {
  switch (action.type) {
    case MeetingDatesActionTypes.get:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.inProgress,
      };

    case MeetingDatesActionTypes.getSuccess:
      return {
        ...state,
        meetingDates: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };

    case MeetingDatesActionTypes.getError:
      return {
        ...state,
        loadStatus: StoreLoadStatus.error,
      };

    case MeetingDatesActionTypes.reset:
      return initialState;

    default:
      return state;
  }
}
