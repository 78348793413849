import { UserInfoResponseModel } from '@models/user-info.model';
import { Action } from '@ngrx/store';

export enum UserInfoActionTypes {
  GetUser = '[User Info] GetUser',
  GetUserSuccess = '[User Info]  GetUserSuccess',
  ErrorUser = '[User Info]  ErrorUser'
}

export class UserInfoGet implements Action {
  readonly type = UserInfoActionTypes.GetUser;
}

export class UserInfoGetSuccess implements Action {
  readonly type = UserInfoActionTypes.GetUserSuccess;
  constructor(public payload: UserInfoResponseModel) {}
}

export class UserInfoError implements Action {
  readonly type = UserInfoActionTypes.ErrorUser;
  constructor(public payload: any) {}
}

export type UserInfoAction = UserInfoGet | UserInfoGetSuccess | UserInfoError;
