import { TemplateDocumentTypeModel } from '@models/document/template-document-type.model';
import { Action } from '@ngrx/store';

export enum DocumentTemplatesActionTypes {
  Get = '[Document Templates] Get',
  GetSuccess = '[Document Templates] GetSuccess',
  GetError = '[Document Templates] GetError',
  Reset = '[Document Templates] Reset',
}

export class DocumentTemplatesGet implements Action {
  readonly type = DocumentTemplatesActionTypes.Get;
}

export class DocumentTemplatesGetError implements Action {
  readonly type = DocumentTemplatesActionTypes.GetError;
}

export class DocumentTemplatesGetSuccess implements Action {
  readonly type = DocumentTemplatesActionTypes.GetSuccess;
  constructor(public payload: TemplateDocumentTypeModel[]) {}
}

export class DocumentTemplatesReset implements Action {
  readonly type = DocumentTemplatesActionTypes.Reset;
}

export type DocumentTemplatesTypesAction =
  | DocumentTemplatesGet
  | DocumentTemplatesGetError
  | DocumentTemplatesGetSuccess
  | DocumentTemplatesReset;
