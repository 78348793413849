export const environment = {
  showGitInfoInTitle: false,
  envName: 'test',
  reduxMaxStoreAge: 20,
  baseUrl: 'npa/api',
  production: false,
  pathToLdeScriptsNpa: 'https://isusdpm-internal.it2g.ru/lde-api',
  pathToLdeScriptsLpa: 'https://isusdpm-internal.it2g.ru/lde-api-local',
  enableMockUser: true,
  ravenDns: 'https://1f26caf5f931406caf90864715476ab0@isusdpm-internal.it2g.ru:9001/1',
  sentryEnabled: false,
  loadLde: true,
  enableRRWeb: true,
  websocketBaseUrl: `https://isusdpm-internal.it2g.ru`,
  enableAnalytics: true,
  analyticsUrl: 'http://172.17.40.105/',
  analyticsSiteId: 1,
  hpsmBaseUrl: 'npa/hpsm/api',
  needJWT: false,
  imageBaseUrl: 'isusdpm-internal.it2g.ru',
  authBaseUrl: 'https://npa-test-mo.it2g.ru/auth',
};
