import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { DocumentStatusItemModel } from '@models/document-statuses/document-status-item.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentStatusItemsActions, DocumentStatusItemsActionTypes } from './document-status-items.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DocumentStatusItemModel>();

export function documentStatusItemsReducer(
  state: DictionaryStoreModel<DocumentStatusItemModel> = DEFAULT_STATE,
  action: DocumentStatusItemsActions
): DictionaryStoreModel<DocumentStatusItemModel> {
  switch (action.type) {
    case DocumentStatusItemsActionTypes.Reset:
      return DEFAULT_STATE;

    case DocumentStatusItemsActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentStatusItemsActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case DocumentStatusItemsActionTypes.Error:
      return {
        ...DEFAULT_STATE,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса загрузк */
export const getLoadStatus = (store) => store.loadStatus;
/** Получение словаря */
export const getDocumentStatusItems = (store) => store.dictionary;
