import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  AlertPeriodTypesAction,
  AlertPeriodTypesActionTypes,
  AlertPeriodTypesGetError,
  AlertPeriodTypesGetSuccess,
} from '@appShared/store/dictionaries/alert-period-types/alert-period-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
@Injectable()
export class AlertPeriodTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetAlertPeriodTypes$: Observable<AlertPeriodTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(AlertPeriodTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getAlertPeriodTypes().pipe(
        map((response) => new AlertPeriodTypesGetSuccess(response)),
        catchError((err) => of(new AlertPeriodTypesGetError()))
      )
    )
  ));
}
