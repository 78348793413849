import { Pipe, PipeTransform } from '@angular/core';
import { AssignedExecutorTableModel } from 'app/personal-cabinet/personal-settings/models/assigned-executor-table.model';

@Pipe({
  name: 'assignedExecutorSort'
})
export class AssignedExecutorSortPipe implements PipeTransform {
  transform(
    value: AssignedExecutorTableModel[],
    isReverse?: boolean,
    filterField?: string
  ): AssignedExecutorTableModel[] {
    if (value.length === 0) {
      return;
    }

    if (filterField === undefined || null) {
      return value;
    }

    if (isReverse) {
      return value.sort(sortByFieldReverse(filterField));
    }

    return value.sort(sortByField(filterField));
  }
}

export function sortByField(field) {
  return (a, b) => (a[field] > b[field] ? 1 : -1);
}

export function sortByFieldReverse(field) {
  return (a, b) => (a[field] > b[field] ? -1 : 1);
}
