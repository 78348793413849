import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { DocumentTypeModel } from '@models/document/document-type.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import {
  ConsiderationDocumentTypesAction,
  ConsiderationDocumentTypesActionTypes,
} from './consideration-document-types.actions';

export const initialState = new DictionaryStoreModel<DocumentTypeModel>([], null, null);

export function ConsiderationDocumentTypesReducer(
  state = initialState,
  action: ConsiderationDocumentTypesAction
): DictionaryStoreModel<DocumentTypeModel> {
  switch (action.type) {
    case ConsiderationDocumentTypesActionTypes.Reset:
      return initialState;

    case ConsiderationDocumentTypesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case ConsiderationDocumentTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case ConsiderationDocumentTypesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (projectTypes) => projectTypes.loadStatus;
/** Получение словаря */
export const getConsiderationDocumentTypes = (projectTypes) => projectTypes.dictionary;
