import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  QuestionResolutionTypesAction,
  QuestionResolutionTypesActionTypes,
  QuestionResolutionTypesGet,
  QuestionResolutionTypesGetError,
  QuestionResolutionTypesGetSuccess,
} from '@appShared/store/dictionaries/question-resolution-types/question-resolution-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class QuestionResolutionTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetQuestionResolutionTypes$: Observable<QuestionResolutionTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(QuestionResolutionTypesActionTypes.Get),
    switchMap((action: QuestionResolutionTypesGet) =>
      this.dictionaryApi.getConsiderationQuestionResolutionTypes(action.payload).pipe(
        map((response) => new QuestionResolutionTypesGetSuccess(response)),
        catchError((err) => of(new QuestionResolutionTypesGetError()))
      )
    )
  ));
}
