import { Pipe, PipeTransform } from '@angular/core';
import { DashboardStatisticsNames } from '@appMain/dashboard/enums/dashboard-statistics-names.enum';
import { DashboardStatisticsItemModel } from '@appMain/dashboard/models/dashboard-statistics-item.model';

@Pipe({
  name: 'getStatisticsCountOverdue',
})
export class GetStatisticsCountOverduePipe implements PipeTransform {
  transform(statistics: DashboardStatisticsItemModel[], staticsName: DashboardStatisticsNames): number {
    const stat = statistics.find((item) => item.name === staticsName);

    return stat ? stat.countOverdue : 0;
  }
}
