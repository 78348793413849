import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { Action } from '@ngrx/store';

/** Справочник "типы категорий вопросов для вопросов заседания ПМ и ППМ" */

export enum QuestionCategoriesActionTypes {
  Get = '[Question Categories] Get',
  GetSuccess = '[Question Categories] GetSuccess',
  GetError = '[Question Categories] GetError',
  Reset = '[Question Categories] Reset',
}

export class QuestionCategoriesGet implements Action {
  readonly type = QuestionCategoriesActionTypes.Get;
}

export class QuestionCategoriesGetError implements Action {
  readonly type = QuestionCategoriesActionTypes.GetError;
}

export class QuestionCategoriesGetSuccess implements Action {
  readonly type = QuestionCategoriesActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithCodeModel[]) {}
}

export class QuestionCategoriesReset implements Action {
  readonly type = QuestionCategoriesActionTypes.Reset;
}

export type QuestionCategoriesAction =
  | QuestionCategoriesGet
  | QuestionCategoriesGetError
  | QuestionCategoriesGetSuccess
  | QuestionCategoriesReset;
