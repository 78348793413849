import { Injectable } from '@angular/core';
import { EsignApiService } from '@appShared/api/crypto-pro/esign-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  CryptoProAction,
  CryptoProActionTypes,
  CryptoProGetSigners,
  CryptoProGetSignersError,
  CryptoProGetSignersSuccess
} from './crypto-pro.actions';

@Injectable()
export class CryptoProEffects {
  constructor(private actions$: Actions, private cryptoProApi: EsignApiService) {}

  
  GetSignersCryptoProEffect$: Observable<CryptoProAction> = createEffect(() => this.actions$.pipe(
    ofType(CryptoProActionTypes.GetSigners),
    switchMap((action: CryptoProGetSigners) =>
      this.cryptoProApi.getInfoAboutSignersDocuments(action.payload).pipe(
        map(response => new CryptoProGetSignersSuccess(response)),
        catchError(err => of(new CryptoProGetSignersError(err)))
      )
    )
  ));
}
