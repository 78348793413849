import moment from 'moment';

/** модель отображаемой ячейки календаря */
export class CalendarDateCellModel {
  /** день месяца */
  day: number;
  /**месяц */
  month: number;
  /** выбранный пользователем день */
  isSelectedDay: boolean;
  /** это сегодняшний день */
  isToday: boolean;
  /** этот неактивный для выбора пользователем день */
  isInactiveDay: boolean;
  /** это нерабочий день */
  isHoliday: boolean;
  /** цвет подсветки дня, при необходимости */
  highlightedColor: string;
  /** шрифт выделенного дня, при необходимости */
  highlightedFontWeight: string;
  /** выделенный день недели */
  specialDay: boolean;
}

/** модель итерации потока пользовательского ввода */
export class UserInputStreamModel {
  constructor(
    public inputString: string,
    public datePosition: moment.Moment = moment(null),
    public currentDate: moment.Moment = moment(null),
    public minDate?: moment.Moment,
    public maxDate?: moment.Moment,
    public isValidInput?: boolean,
    public isValidPeriod = true
  ) {}
}

export class DisplayPositionModel {
  constructor(
    public currentDate: moment.Moment,
    public datePosition: moment.Moment,
    public minDate: moment.Moment,
    public maxDate: moment.Moment
  ) {}
}
