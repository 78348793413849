import { Pipe, PipeTransform } from '@angular/core';
import { IdDocumentModel } from '@models/document-composition/id-document.model';

/** Проверит, есть ли в переданном списке идентификаторов документов переданный документ */
@Pipe({
  name: 'isIncludedDocument',
})
export class IsIncludedDocumentPipe implements PipeTransform {
  transform(document: IdDocumentModel, documentIds: number[]): boolean {
    if (!document) {
      return false;
    }
    if (!documentIds) {
      return false;
    }
    return documentIds.includes(document.id);
  }
}
