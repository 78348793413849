import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputRestriction]',
})
export class InputRestrictionDirective {
  @Input('appInputRestriction') inputRestriction: string;

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent): boolean {
    const regex = new RegExp(this.inputRestriction);
    const str = !event.key ? event.code : event.key;
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
}
