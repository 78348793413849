import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';

@Component({
  selector: 'app-header-search-input',
  templateUrl: './header-search-input.component.html',
  styleUrls: ['./header-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HeaderSearchInputComponent),
      multi: true,
    },
  ],
})
export class HeaderSearchInputComponent extends ControlBaseComponent implements ControlValueAccessor {
  /** нажатие enter */
  @Output()
  onEnter: EventEmitter<void> = new EventEmitter();

  /** Значение поля */
  public model: string;

  /** Доступность поля */
  public disabled: boolean;

  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (value: any) => {};
  public onTouched = () => {};

  constructor() {
    super();
  }

  public onModelChange(): void {
    this.onChange(this.model);
  }

  public onClear(): void {
    this.model = '';
    this.onChange(this.model);
  }

  writeValue(value: any): void {
    this.model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
