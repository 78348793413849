/** общие названия стилевых классы*/
export enum CommonClassNames {
  /**
   * Применяется для темной подложки
   * Класс для родителя
   * */
  blackTheme = 'black-theme',

  /**
   * Применяется для темной подложки
   * При наведении светлый фоновый цвет
   * */
  blackThemeWhiteHover = 'black-theme-white-hover',

  /**
   * Класс для включения стиля посказки,
   * применяется непосредственно к элементу
   */
  placeholder = 'placeholder',

  /**
   * Класс для заголовка,
   * применяется, если заголовок нельзя указать в  <label></label>
   */
  label = 'label',
}
