import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '@appShared/components/shared-components.module';

@NgModule({
  imports: [CommonModule, SharedComponentsModule],
  declarations: [PageNotFoundComponent]
})
export class PageNotFoundModule {}
