import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** Справочник "типы решений по вопросам в пакете повестки" */

export enum QuestionResolutionTypesActionTypes {
  Get = '[QuestionResolution Types] Get',
  GetSuccess = '[QuestionResolution Types] GetSuccess',
  GetError = '[QuestionResolution Types] GetError',
  Reset = '[QuestionResolution Types] Reset',
}

export class QuestionResolutionTypesGet implements Action {
  readonly type = QuestionResolutionTypesActionTypes.Get;
  /** передаем айдишник типа пакета повестки */
  constructor(public payload: number) {}
}

export class QuestionResolutionTypesGetError implements Action {
  readonly type = QuestionResolutionTypesActionTypes.GetError;
}

export class QuestionResolutionTypesGetSuccess implements Action {
  readonly type = QuestionResolutionTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class QuestionResolutionTypesReset implements Action {
  readonly type = QuestionResolutionTypesActionTypes.Reset;
}

export type QuestionResolutionTypesAction =
  | QuestionResolutionTypesGet
  | QuestionResolutionTypesGetSuccess
  | QuestionResolutionTypesGetError
  | QuestionResolutionTypesReset;
