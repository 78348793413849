import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { ToastsDocumentService } from '@appCore/services/toasts/toasts-document.service';
import { getDocumentPackageId } from '@appDocuments/core/store/document-package';
import { CompositionSharedActingVersionsGet } from '@appDocuments/documents-composition/store/composition-shared-acting-versions/composition-shared-acting-versions.actions';
import { DocumentCompositionApiService } from '@appShared/api/document-composition/document-composition.api.service';
import { DocumentSaveStatuses } from '@appShared/enums/documents-save-statuses.enum';
import { ModalWindowRef } from '@appShared/modals-helper/modal-window-ref';
import { AlertPopupDataModel } from '@appShared/models/alert-popup-data.model';
import { first, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alert-confirmation-popup',
  templateUrl: './alert-confirmation-popup.component.html',
  styleUrls: ['./alert-confirmation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertConfirmationPopupComponent extends Unsubscriber implements OnInit, OnDestroy {
  public pastedData: AlertPopupDataModel;
  public successBtnLabel = 'Да';
  constructor(
    private modalWindowRef: ModalWindowRef,
    private documentCompositionApi: DocumentCompositionApiService,
    private toastsService: ToastsDocumentService,
    private redux: ReduxService
  ) {
    super();
  }

  ngOnInit(): void {
    this.pastedData = this.modalWindowRef.data;
  }

  public closePopup(): void {
    this.modalWindowRef.close();
  }

  public cancelAction(): void {
    this.closePopup();
  }

  public confirmAction(versionId: number): void {
    this.documentCompositionApi
      .updateVisibilityActingVersion(versionId, true)
      .pipe(
        mergeMap(() => this.redux.selectStore(getDocumentPackageId)),
        first(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(
        (dpId) => {
          this.closePopup();
          this.redux.dispatchAction(new CompositionSharedActingVersionsGet(dpId));
          this.toastsService.addToast({ title: DocumentSaveStatuses.sharedActingVersionAddedSuccess });
        },
        (error) => console.log(error)
      );
  }

  ngOnDestroy(): void {
    super.unsubscribe();
  }
}
