import { Injectable } from '@angular/core';
import { EventData } from '@models/event-bus/event-data.model';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EventBusService {
  private subject$ = new Subject();

  /**
   * Генерация события
   * @param event EventData с именем и опциональным значением генерируемого события
   */
  emit(event: EventData): void {
    this.subject$.next(event);
  }

  /**
   * Подписка на генерируемые события
   * @param eventName имя события
   * @param action функция-обработчик
   * @returns подписка на входящие события
   */
  on(eventName: string, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EventData) => e.name === eventName),
        map((e: EventData) => e.value)
      )
      .subscribe(action);
  }
}
