import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ToastDocumentModel } from '@appCore/models/toast-document.model';
import { ToastsDocumentService } from '@appCore/services/toasts/toasts-document.service';
import { ButtonStyles } from '@appShared/enums/button-styles.enum';
import { NotificationCounterTypes } from '@models/notifications/notification-counter-types.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toasts-document',
  templateUrl: './toasts-document.component.html',
  styleUrls: ['./toasts-document.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'in',
        style({
          opacity: 1,
        })
      ),
      transition('void => *', [style({ opacity: 0 }), animate(200)]),
      transition('* => void', [animate(500, style({ opacity: 0, transform: 'translateX(600px)' }))]),
    ]),
  ],
})
export class ToastsDocumentComponent implements OnInit {
  public readonly buttonStyles = ButtonStyles;
  public readonly counterTypes = NotificationCounterTypes;

  public toasts$: Observable<ToastDocumentModel[]>;

  constructor(private toasts: ToastsDocumentService) {}

  ngOnInit() {
    this.toasts$ = this.toasts.getToasts();
  }

  /** Удаление "тоста" */
  public removeToast(toast: ToastDocumentModel): void {
    this.toasts.remove(toast);
  }
}
