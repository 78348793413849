import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DelegatingState } from '@appCore/store/delegating/delegating.reducer';
import * as fromReducer from './delegating.reducer';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const getDelegatingStore = createFeatureSelector<DelegatingState>('delegating');

export const getDelegating = createSelector(
  getDelegatingStore,
  fromReducer.selectAll
);

/** Селектор для отслеживания статуса сохранения делегирования */
export const getDelegatingSaveInProgress = createSelector(
  getDelegatingStore,
  (state: DelegatingState) => state.saveStatus === StoreLoadStatus.inProgress
);

/** Селектор для отслеживания статуса удаления делегирования */
export const getDelegatingDeleteStatus = createSelector(
  getDelegatingStore,
  (state: DelegatingState) => state.deleteStatus
);

/** Селектор для получения айдишника активного делегирования (активной вкладки на попапе) */
export const getActiveDelegationId = createSelector(
  getDelegatingStore,
  (state: DelegatingState) => state.activeDelegationId
);
