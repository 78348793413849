import { Inject, Injectable } from '@angular/core';
import { OibRoles } from '@models/enums/oib-roles.enum';
import { CURRENT_USER_ROLES } from '@appRouting/guards/auth-guard.service';
import { Observable, Subject } from 'rxjs';
import { WINDOW } from '@appShared/tokens/window.token';

@Injectable({
  providedIn: 'root',
})
export class CommonLogicService {
  private dataCoordsEl$ = new Subject<DOMRect>();

  constructor(
    @Inject(CURRENT_USER_ROLES) private currentUserRoles: Map<string, string>,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {}

  /* Проверяет совпадение роли по текущему пользователю */
  public hasAllowedUserRoles(allowEditRoles: string[]): boolean {
    const currentUserRolesArr = Array.from(this.currentUserRoles.values());
    return currentUserRolesArr.some((role: OibRoles) => allowEditRoles.includes(role));
  }

  public setDataCoordsEl(data: DOMRect): void {
    this.dataCoordsEl$.next(data);
  }

  public getDataCoordsEl(): Observable<DOMRect> {
    return this.dataCoordsEl$.asObservable();
  }

  public getUserRoles(): string[] {
    return Array.from(this.currentUserRoles.values());
  }

  public previousPageForbiddenDashboard(): boolean {
    const previousUrl = this.windowRef.history.state?.prevUrl;
    const isPreviousPageDashboard = previousUrl?.indexOf('dashboard') >= 0;
    return isPreviousPageDashboard;
  }
}
