// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  box-sizing: border-box;
  background: #506a7b;
  padding: 15px 30px;
  z-index: 1000;
}

.text {
  color: white;
  margin-bottom: 15px;
}

.button {
  margin-right: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwZGF0ZS12ZXJzaW9uLWluZm8uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsVUFBQTtFQUNBLFlBQUE7RUFDQSxzQkFBQTtFQUNBLG1CQUFBO0VBQ0Esa0JBQUE7RUFDQSxhQUFBO0FBQ0Y7O0FBRUE7RUFDRSxZQUFBO0VBQ0EsbUJBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0FBQ0YiLCJmaWxlIjoidXBkYXRlLXZlcnNpb24taW5mby5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIGxlZnQ6IDIwcHg7XG4gIGJvdHRvbTogMjBweDtcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgYmFja2dyb3VuZDogIzUwNmE3YjtcbiAgcGFkZGluZzogMTVweCAzMHB4O1xuICB6LWluZGV4OiAxMDAwO1xufVxuXG4udGV4dCB7XG4gIGNvbG9yOiB3aGl0ZTtcbiAgbWFyZ2luLWJvdHRvbTogMTVweDtcbn1cblxuLmJ1dHRvbiB7XG4gIG1hcmdpbi1yaWdodDogMjBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/core/components/update-version-info/update-version-info.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACA,4wBAA4wB","sourcesContent":[".container {\n  position: fixed;\n  left: 20px;\n  bottom: 20px;\n  box-sizing: border-box;\n  background: #506a7b;\n  padding: 15px 30px;\n  z-index: 1000;\n}\n\n.text {\n  color: white;\n  margin-bottom: 15px;\n}\n\n.button {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
