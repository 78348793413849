import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getUserInfo } from '@appCore/store/user-info';
import { Urls } from '@appCore/urls/urls';
import { getDocumentPackageId } from '@appDocuments/core/store/document-package';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { UserInfoResponseModel } from '@models/user-info.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { allUserColors, currentUserColor } from './agreement-route-users.const';
import { AgreementEditUserModel } from './models/agreement-route-user.model';

@Injectable({ providedIn: 'root' })
export class AgreementRouteUsersService extends DefaultDataService<AgreementEditUserModel> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private redux: ReduxService) {
    super('agreement-route-users', http, httpUrlGenerator);
  }

  private hexToRgb(hexString: string): number[] {
    return hexString.match(/.{2}/g).map((x) => parseInt(x, 16));
  }

  public getAll(): Observable<AgreementEditUserModel[]> {
    return combineLatest([this.redux.selectStore(getDocumentPackageId), this.redux.selectStore(getUserInfo)]).pipe(
      filter(([id, info]) => !!id && !!info),
      mergeMap(([id, user]: [number, UserInfoResponseModel]) => {
        const url = Urls.getDocumentsPackageAgreementRouteUsers(id);
        return this.http.get<EmployeeBaseModel[]>(url).pipe(
          map((o) => {
            const result = o.map((e, i) => {
              return this.mapModel(e, this.getColor(e, user, i));
            });

            // Если текущего пользователя нет на маршруте, а доступ к документу есть, цвет ему все равно присваивается
            if (result.filter((i) => i.employee.id === user.currentUser.employee.id).length === 0) {
              return result.concat(this.mapModel(user.currentUser.employee, currentUserColor));
            }

            return result;
          })
        );
      }),
      first()
    );
  }

  private mapModel(employee: EmployeeBaseModel, color: string): AgreementEditUserModel {
    return {
      id: employee.id,
      employee,
      color: this.hexToRgb(color),
      colorHex: color,
    };
  }

  private getColor(e: EmployeeBaseModel, user: UserInfoResponseModel, i: number): string {
    if (e.id === user.currentUser.employee.id) {
      return currentUserColor;
    }
    return allUserColors[i % allUserColors.length];
  }
}
