import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  ConsiderationPMMeetingQuestionsTypesAction,
  ConsiderationPMMeetingQuestionsTypesActionTypes,
  ConsiderationPMMeetingQuestionsTypesGetSuccess,
  ConsiderationPMMeetingQuestionsTypesReset,
} from '@appShared/store/dictionaries/consideration-pm-meeting-questions-types/consideration-pm-meeting-questions-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class ConsiderationPMMeetingQuestionsTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetConsiderationQuestionTypes$: Observable<ConsiderationPMMeetingQuestionsTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ConsiderationPMMeetingQuestionsTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getAgendaPackagePMMeetingQuestionsTypes().pipe(
        map((response) => new ConsiderationPMMeetingQuestionsTypesGetSuccess(response)),
        catchError((err) => of(new ConsiderationPMMeetingQuestionsTypesReset()))
      )
    )
  ));
}
