import { DocumentStatusItemModel } from '@models/document-statuses/document-status-item.model';
import { Action } from '@ngrx/store';

export enum DocumentStatusItemsActionTypes {
  Get = '[Document Status Items] Get',
  GetSuccess = '[Document Status Items] GetSuccess',
  Error = '[Document Status Items] Error',
  Reset = '[Document Status Items] Reset',
}

/** запросить список всех возможных cтатусов ПД  */
export class DocumentStatusItemsGet implements Action {
  readonly type = DocumentStatusItemsActionTypes.Get;
}

/** получить исключения */
export class DocumentStatusItemsError implements Action {
  readonly type = DocumentStatusItemsActionTypes.Error;
}

/** получить список всех возможных статусов ПД  */
export class DocumentStatusItemsGetSuccess implements Action {
  readonly type = DocumentStatusItemsActionTypes.GetSuccess;
  constructor(public payload: DocumentStatusItemModel[]) {}
}

/** сбросить список всех возможных статусов ПД */
export class DocumentStatusItemsReset implements Action {
  readonly type = DocumentStatusItemsActionTypes.Reset;
}

export type DocumentStatusItemsActions =
  | DocumentStatusItemsGet
  | DocumentStatusItemsGetSuccess
  | DocumentStatusItemsError
  | DocumentStatusItemsReset;
