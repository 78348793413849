import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ConsiderationDocumentTypesAction,
  ConsiderationDocumentTypesActionTypes,
  ConsiderationDocumentTypesGet,
  ConsiderationDocumentTypesGetError,
  ConsiderationDocumentTypesGetSuccess,
} from './consideration-document-types.actions';

@Injectable()
export class ConsiderationDocumentTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetConsiderationDocumentTypes$: Observable<ConsiderationDocumentTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ConsiderationDocumentTypesActionTypes.Get),
    switchMap((action: ConsiderationDocumentTypesGet) =>
      this.dictionaryApi.getCalendarConsiderationDocumentTypes(action.payload).pipe(
        map((response) => new ConsiderationDocumentTypesGetSuccess(response)),
        catchError((err) => of(new ConsiderationDocumentTypesGetError()))
      )
    )
  ));
}
