import { Action } from '@ngrx/store';

export enum PopupActionTypes {
  Open = '[Popup Open] Open',
  Close = '[Popup Close] Close',
}

/** Показать открытость попапа */
export class PopupOpen implements Action {
  readonly type = PopupActionTypes.Open;
}

/** Показать закрытость попапа */
export class PopupClose implements Action {
  readonly type = PopupActionTypes.Close;
}

export type PopupAction = PopupOpen | PopupClose;
