import { RegulationDeadlineDateModel } from '@appDocuments/documents-route/models/regulation-deadline.model';
import { ReportOnDurationFilterModel } from '@appMain/analytics/form-models/report-on-duration-filter.model';
import { AnalyticsReportFilterFormModel } from '@appMain/analytics/models/analytics-report-filter-form.model';
import { AnalyticsSystematizationRequestModel } from '@appMain/analytics/models/analytics-systematization.request.model';
import { SummaryAnalyticsSearchParam } from '@appMain/analytics/summary-analytics/models/summary-analytics-search-param.model';
import { TaskSearchFiltersModel } from '@appShared/api/task/models/task-search-filters';
import { environment } from '@environments/environment';
import { HistoryConsiderationsFiltersModel } from '@models/analytics/history-considerations/history-considerations-filters.model';
import { GetReservedNumbersResponseModel } from '@models/analytics/reserved-numbers.response/get-reserved-numbers.response.model';
import { DocumentStatusRedBlankRequestModel } from '@models/document-organalytics/document-status-red-blank-request.model';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';

export const baseUrl = environment.baseUrl;
export const hpsmBaseUrl = environment.hpsmBaseUrl;

/** Урлы */
export class Urls {
  public static loginPage(redirectUrl: string) {
    const url = encodeURIComponent(redirectUrl);
    return `auth/login?return_uri=${url}`;
  }

  /** Добавление файла  */
  public static uploadFiles() {
    return `${baseUrl}/attachments`;
  }
  /** Удаление файла */
  public static deleteFile(id) {
    return `${baseUrl}/attachments/${id}`;
  }

  /** Тип пакета документов(НПА) */
  public static packageTypes() {
    return `${baseUrl}/dictionaries/package-types`;
  }

  /** Этапы маршрута" */
  public static agreementRoutePhases() {
    return `${baseUrl}/dictionaries/agreement-route-phases`;
  }

  /** Получение типов для закрытой БД */
  public static closedBase() {
    return `${baseUrl}/dictionaries/closed-base-types`;
  }

  /** Согласование пакета */
  public static packageApproval(id: number): string {
    return `${baseUrl}/document-packages/${id}/approve`;
  }

  /** Согласование пакета */
  public static introduce(id: number) {
    return `${baseUrl}/document-packages/${id}/introduce`;
  }

  /** Согласование пакета */
  public static sendComment(id: number) {
    return `${baseUrl}/document-packages/${id}/send-comment`;
  }

  /** Завершение пакета */
  public static finishRoute(id: number) {
    return `${baseUrl}/agreement-routes/${id}/finish-route`;
  }

  /** Согласование пакета с замечаниями */
  public static packageApprovalWithRemark(id: number) {
    return `${baseUrl}/document-packages/${id}/approve-with-remark`;
  }

  /** Проверка наличия документа с указанным типом */
  public static documentPackagesHasDocumentByTypeId(id: number) {
    return `${baseUrl}/document-packages/${id}/has-document-by-type-id`;
  }

  /** Получение данных для календаря */
  public static getCalendarEvents() {
    return `${baseUrl}/calendar/event/generate`;
  }

  /** Получение количества уведомлений для пользователя */
  public static notificationsCount() {
    return `${baseUrl}/inner-notifications/count-by-employee`;
  }

  /** Получение данных по виджетам уведомлений */
  public static getNotificationsCounterData() {
    return `${baseUrl}/inner-notifications/counters`;
  }

  /** Добавление пакета в избранное  */
  public static addDocumentToFavorite() {
    return `${baseUrl}/favourites`;
  }

  /** Удаление пакета из избранного  */
  public static deleteDocumentFromFavorite(id: number) {
    return `${baseUrl}/favourites?id=${id}`;
  }

  /** Получение информации о пакете  */
  public static getInformationAboutDocument(id: number) {
    return `${baseUrl}/document-packages/${id}`;
  }

  /** Пакет документов/Сохранение формы */
  public static packageFormSave() {
    return `${baseUrl}/document-packages`;
  }

  /** Пакет документов/Сохранение формы */
  public static documentPackageGet(id: number) {
    return `${baseUrl}/document-packages/${id}`;
  }

  /** Получение бизнес версии пакета документов */
  public static documentPackageGetBusinessVersion(id: number) {
    return `${baseUrl}/document-packages/${id}/business-version`;
  }

  /** Получение списка пакетов документов на Dashboard */
  public static getDashboardItems(searchType: string) {
    return `${baseUrl}/dashboard/search/${searchType}`;
  }

  /** Получение списка пакетов документов по id "расшаренного фильтра" */
  public static getSharedFilterItems(sharedFilterId: number) {
    return `${baseUrl}/available-subsection/${sharedFilterId}`;
  }

  /** Возвращает URL для проверки существования расписания заседаний на выбранную дату */
  public static getCheckIfScheduleExistsUrl() {
    return `${baseUrl}/calendar/event/check-exists`;
  }

  /** Получение информации о рассмотрениях ПД */
  public static getDocumentPаckageConsiderationInfo() {
    return `${baseUrl}/calendar/agenda-packages/search`;
  }

  public static getDocumentPаckageConsiderationRequestsChange(id: number) {
    return `${baseUrl}/review-dates/by-document-package-id/${id}`;
  }

  /** Получение информации о типе редакции ПД */
  public static getDocumentPаckageRevisionTypeInfo(statusId: number, packageId: number) {
    return `${baseUrl}/document-packages/${packageId}/statuses/${statusId}`;
  }

  /** Поменять порядковый номер вопроса */
  public static changeTypeIndexQuestion(questionId: number, param: string) {
    return `${baseUrl}/calendar/agenda-package/questions/change-type-category-index/${questionId}?${param}`;
  }

  /** Учетный номер пакета документов */
  public static getDocumentsPackageEditingParticipants(id: number) {
    return `${baseUrl}/document-packages/${id}/editing-participants`;
  }

  /** Получение списка пользователей с маршрута */
  public static getDocumentsPackageAgreementRouteUsers(id: number) {
    return `${baseUrl}/document-packages/${id}/agreement-routes-users`;
  }

  /** получение списка комментариев */
  public static getDocumentCommentsList(id: number) {
    return `${baseUrl}/document-packages/${id}/comments`;
  }

  /** Сохранение комментария */
  public static postDocumentComment() {
    return `${baseUrl}/comments`;
  }

  /** Удаление комментария */
  public static deleteDocumentComment(id: number) {
    return `${baseUrl}/comments/${id}`;
  }

  public static documentEditorVersion() {
    return `${baseUrl}/editor-version`;
  }

  public static getSharedActingVersions(dpId: number): string {
    return `${baseUrl}/editor-version/acting/${dpId}`;
  }

  /** получение списка связей документов (копий) */
  public static getCompositionDocumentsCopies(documentPackageId: number) {
    return `${baseUrl}/document-packages/${documentPackageId}/document-relation`;
  }

  public static getCompositionDocuments(documentPackageId: number) {
    return `${baseUrl}/document-packages/${documentPackageId}/documents`;
  }

  /** Возвращает URL для получения списка типов документов */
  public static getDocumentTypesUrl() {
    return `${baseUrl}/dictionaries/document-types`;
  }

  /** Возвращает URL для получения списка типов документов доступных по ролям */
  public static getDocumentTypesAvailableUrl() {
    return `${baseUrl}/dictionaries/document-types/available`;
  }

  /** Получение списка шаблонов документов */
  public static getTemplateDocumentTypes() {
    return `${baseUrl}/document-templates`;
  }

  /** Получение уведомлений от сервера */
  public static getNotifications(): string {
    return `${baseUrl}/inner-notifications/filter`;
  }

  /** Пометить уведомление как прочитанное */
  public static markNotificationViewed(id: number, viewed: boolean): string {
    return `${baseUrl}/inner-notifications/${id}/set-viewed?viewed=${viewed}`;
  }

  /** Формируем ссылку для скачивания файла */
  public static createDownloadLinkForFile(link: string): string {
    return `${baseUrl}/${link}`;
  }

  /** Пакет документов/Типы проектов */
  public static documentsPackageProjectTypes() {
    return `${baseUrl}/dictionaries/project-types`;
  }

  /** Получение всех типов проекта доступных пользователю по роли */
  public static getProjectTypesAvailable(): string {
    return `${baseUrl}/dictionaries/project-types/available`;
  }

  /** Пакет документов/Статусы */
  public static documentsPackageStatuses() {
    return `${baseUrl}/dictionaries/package-statuses`;
  }

  /** Пакет документов/Типы заседаний(Форма утверждения) */
  public static documentsPackageMeetingTypes() {
    return `${baseUrl}/dictionaries/approval-forms`;
  }

  /** Пакет документов/Типы повестки */
  public static documentsPackageConsiderationTypes() {
    return `${baseUrl}/dictionaries/type-of-subpoena`;
  }

  /** Получение списка организаций по фильтру*/
  public static getOrganizations(): string {
    return `${baseUrl}/employee/organizations/search`;
  }

  /** Получение списка всех организаций */
  public static getOrganizationsAll(): string {
    return `${baseUrl}/employee/organizations`;
  }

  /** Получение организации по идентификатору */
  public static getOrganizationById(id: number): string {
    return `${baseUrl}/employee/organizations/${id}`;
  }

  /** Получение нижестоящих активных организаций в виде древа по id организации текущего пользователя */
  public static getActiveOrganizationTree(organizationId: number): string {
    return `${baseUrl}/dictionaries/organizations/${organizationId}/tree`;
  }

  /** Получение организации для текущего пользователя */
  public static getOrganizationsForCurrentUser(): string {
    return `${baseUrl}/employee/organizations?forAction=SUMMARY_ANALYTICS_PANEL_VIEW`;
  }

  /** Получение списка всех организаций */
  public static getOrganizationsByDecisionFilterRoute(): string {
    return `${baseUrl}/oiv-developer/developers/by-statistics`;
  }

  /** Получение списка должностей */
  public static getEmployeePositions(filter = '', positionName = ''): string {
    return `${this.getEmployeePositionsBaseUrl()}?orgId=${encodeURI(filter)}&name=${positionName || ''}`;
  }

  public static getEmployeePositionsBaseUrl(): string {
    return `${baseUrl}/employee/positions/search`;
  }

  /** Получение сотрудников организации с указанной ролью ОИБ */
  public static getEmployeesByRoleAndOrganizationUsingGET(name: string, orgId: number): string {
    return `${baseUrl}/employee/role/search?name=${name}&orgId=${orgId}`;
  }

  /** Получение списка сотруднико */
  public static getEmployeeList(
    organizationId: string,
    positionId: string,
    filterInput: string,
    positionName?: string,
    historyDate?: string
  ): string {
    const position = positionId ?? '';
    const organization = organizationId ?? '';
    const splitedUserInformation = filterInput.split(' ');
    const lastName = splitedUserInformation[0] ? splitedUserInformation[0] : '';
    const firstName = splitedUserInformation[1] ? splitedUserInformation[1] : '';
    const patronymic = splitedUserInformation[2] ? splitedUserInformation[2] : '';
    const posName = positionName ?? '';
    const historyDateTime = historyDate ?? '';

    return `${this.getEmployeeListBaseUrl()}?first_name=${firstName}&last_name=${lastName}&patronymic=${patronymic}&organisation_id=${organization}&position_id=${position}&position_name=${posName}&history_date_time=${historyDateTime}`;
  }

  public static getEmployeeListBaseUrl(): string {
    return `${baseUrl}/employee/search`;
  }

  /** Получение сотрудника по айди */
  public static getSingleEmployee(employeeId: number): string {
    return `${baseUrl}/employee/${employeeId}`;
  }

  /** Получение всех сотрудников по id организации */
  public static getAllEmployeesByOrganizationId(organizationID: number): string {
    return `${baseUrl}/employee/${organizationID}/organization`;
  }

  public static checkEmployeeOibRole(employeeId: number, roleName: string) {
    return `${baseUrl}/employee/${employeeId}/has-role/${roleName}`;
  }

  /** Получить список прикрепленных файлов */
  public static getAttachmentsList(documentPackageId: number): string {
    return `${baseUrl}/document-packages/${documentPackageId}/attachments`;
  }

  /** получить/отредактировать список мест проведения заседания */
  public static considerationPlaces(): string {
    return `${baseUrl}/calendar/dictionaries/location/web`;
  }

  /** Поиск событий по месту и времени проведения */
  public static findEventByPlaceAndTime(): string {
    return `${baseUrl}/calendar/event/search`;
  }

  public static findMeetengBySelectedDay(): string {
    return `${baseUrl}/calendar/event/has-pm-ppm-meetings-on-date`;
  }

  /** Сформировать отчет */
  public static generateReport() {
    return `${baseUrl}/reports/check-list`;
  }

  /** Сформировать отчет "Реестр документов для размещения в базах и официального опубликования" */
  public static generateDocumentsRegistryReport(): string {
    return `${baseUrl}/reports/documents-registry`;
  }

  /** Скачать файл с отчетом */
  public static downloadFileAnalytics(dateBegin: string, dateEnd: string) {
    return `${baseUrl}/reports/check-list/file?dateBeg=${dateBegin}&dateEnd=${dateEnd}`;
  }

  public static getAgendaQuestionsAnalytics(params: SummaryAnalyticsSearchParam) {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/analytics/agenda-question-status?${queryString}`;
  }

  /** Получить маршрут согласования */
  public static getRoute(id: number): string {
    return `${baseUrl}/document-packages/${id}/agreement-routes`;
  }

  /** Получение замененных пользователей маршрута согласования */
  public static getAgreementRoutesChangedUsers(id: number): string {
    return `${baseUrl}/document-packages/${id}/agreement-routes-changed-users`;
  }

  /** Инициализация даты для первой точки маршрута */
  public static configureDeadline(id: number): string {
    return `${baseUrl}/agreement-routes/${id}/configure-deadline`;
  }

  /** Назначить/отменить назначение ответственным исполнителем */
  public static markResponsibleExecutor(routeId: number, pointId: number, isResponsibleExecutor: boolean): string {
    return `${baseUrl}/agreement-routes/${routeId}/responsible-executor/${pointId}?isResponsibleExecutor=${isResponsibleExecutor}`;
  }

  /** Добавить после */
  public static routeAddAfter(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-after/${id}`;
  }

  /** Добавить перед */
  public static routeAddBefore(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-before/${id}`;
  }

  /** Добавить между */
  public static routeAddBetween(routeId: number, idBefore: number, idAfter: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-between/${idAfter}/${idBefore}`;
  }

  /** Добавить вбок */
  public static routeAddIncome(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-income/${id}`;
  }

  /** Востановить */
  public static repair(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/recovery`;
  }

  /** Добавить ознакомителя */
  public static routeAddIntroducer(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-introducer/${id}`;
  }

  /** Удалить ознакомителя */
  public static routeRemoveIntroducer(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/remove-introducer/${id}`;
  }

  /** добавить на этапе инициации */
  public static routeInitiationAdd(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/initiation/add`;
  }

  /** Начать разработку */
  public static routeStartDevelopment(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/start-development`;
  }

  /** Начать согласование (Отправить на согласование) */
  public static routeStartApprovement(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/start-approvement`;
  }

  /** Возврат внутри этапа */
  public static routeInternalReturn(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/internal-return`;
  }

  /** Возврат между этапами */
  public static routeExternalReturn(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/external-return`;
  }

  /** Отзыв пакета документа */
  public static routeRecall(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/recall`;
  }

  /** Добавление этапа на маршрут */
  public static routeAddPhase(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-phase`;
  }

  /** Добавление этапов на маршрут */
  public static routeAddPhases(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-phases`;
  }

  /** Добавление этапов параллельно существуюним на маршрут */
  public static routeAddPhasesParallel(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-phases-parallel`;
  }

  /** Удаление этапа */
  public static routeRemovePhase(id: number, parentPhaseId: number): string {
    return `${baseUrl}/agreement-routes/${id}/remove-phase/${parentPhaseId}`;
  }

  /** Удалить человека из дерева */
  public static routeDelete(routeId: number, id: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/remove-point/${id}`;
  }

  /** Возвращает url для проверки прав на замену автора/руководителя/исполнителя */
  public static getCanChangeEmployeesUrl(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/can-change-employees`;
  }

  /** Возвращает url для смены сотрудника в дереве в точке pointId */
  public static getChangeEmployeeUrl(routeId: number, pointId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/points/${pointId}/change-employee`;
  }

  /** Пропустить этап/Согласовать по умолчанию */
  public static skipPhase(routeId: number, parentPhaseId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/skip-phase/${parentPhaseId}`;
  }

  /** Проставление признака принадлежности к ЛС дополнительного соглашения инвестиционного контракта */
  public static updateAccessoryToInvestmentProject(
    routeId: number,
    pointId: number,
    isInvestmentProject: boolean
  ): string {
    return `${baseUrl}/agreement-routes/${routeId}/investment-project?pointId=${pointId}&isInvestmentProject=${isInvestmentProject}`;
  }

  public static routeRemoveInspector(controllerId: number): string {
    return `${baseUrl}/agreement-route-controllers/${controllerId}`;
  }

  /** Добавить вбок */
  public static routeStart(documentPackageId: number): string {
    return `${baseUrl}/document-packages/${documentPackageId}/start-route`;
  }

  /** Отзыв с согласования */
  public static routeTotalReturn(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/total-return`;
  }

  /** Добавление соавторов */
  public static routeAddCoauthors(routeId: number, pointId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/add-coauthors/${pointId}`;
  }

  /** Добавление контроллера */
  public static routeAddController(): string {
    return `${baseUrl}/agreement-route-controllers`;
  }

  /** Получение списка запросов на соавторство */
  public static coauthorsGetAllRequests(): string {
    return `${baseUrl}/agreement-route-coauthors/requests`;
  }

  /** Получение списка запросов на соавторство */
  public static coauthorsAcceptRequest(requestId: number): string {
    return `${baseUrl}/agreement-route-coauthors/accept/${requestId}`;
  }

  /** Получение списка запросов на соавторство */
  public static coauthorsRejectRequest(requestId: number): string {
    return `${baseUrl}/agreement-route-coauthors/reject/${requestId}`;
  }

  /** Заявка на становление соавтором */
  public static becomeCoauthorRequest(routeId: number) {
    return `${baseUrl}/agreement-routes/${routeId}/request-in-coauthors`;
  }

  /** Отклонить заявку на становление соавтором */
  public static rejectUserAsCoauthor(requestId: number) {
    return `${baseUrl}/agreement-route-coauthors/in/reject/${requestId}`;
  }

  /** Принять заявку на становление соавтором */
  public static acceptUserAsCoauthor(requestId: number) {
    return `${baseUrl}/agreement-route-coauthors/in/accept/${requestId}`;
  }

  /** Удаление соавтора */
  public static routeRemoveCoauthor(routeId: number, coauthorId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/remove-coauthor/${coauthorId}`;
  }

  /** Получение шаблонов пользовательских этапов для ПД */
  public static userPhaseTemplates(dpId: number): string {
    return `${baseUrl}/agreement-route-user-phase-template/for-document-package/${dpId}`;
  }

  /** Согласования пакета документов */
  public static agreements(documentPackageId: number) {
    return `${baseUrl}/agreements/by-document-package/${documentPackageId}`;
  }

  static createQueryString(data: { [key: string]: any }): string {
    return Object.keys(data)
      .map((key) => ({ key, value: data[key] }))
      .filter((item) => item.value || item.value === 0)
      .map((item) => `${item.key}=${item.value}`)
      .reduce((a, c) => `${a}&${c}`, '')
      .substr(1);
  }

  public static getAnalyticsRegistryItems(page: number = 0): string {
    return `${baseUrl}/oau-registry/?page=${page}`;
  }

  /** Экспорт реестра в csv */
  public static exportOAURegistry(): string {
    return `${baseUrl}/oau-registry/export`;
  }

  /** Получить список задач по фильтрующим значениям */
  public static tasksJournalSearch(taskSearchFilters: TaskSearchFiltersModel): string {
    const queryString = Urls.createQueryString(taskSearchFilters);

    return `${baseUrl}/tasks/journal/search?${queryString}`;
  }

  /** Получения задач по пакету, связанных с пользователем */
  public static getEmployeeTasks(documentPackageId: number, employeeId: number) {
    const params = {
      documentPackageId,
      employeeId,
    };
    const queryString = Urls.createQueryString(params);

    return `${baseUrl}/tasks/?${queryString}`;
  }

  /** Эндпоинт для редактирования таски */
  public static getEditTaskEndpoint(): string {
    return `${baseUrl}/tasks`;
  }

  /** Получения всех типов резолюций задач в Журнале Задач */
  public static getTaskResolutions(): string {
    return `${baseUrl}/dictionaries/task-resolutions`;
  }

  /** Получение/Создание/редактирование пакета повестки */
  public static getOrPostOrPutConsideration(): string {
    return `${baseUrl}/calendar/agenda-packages/`;
  }

  /** Получение/Создание/редактирование материала к пакету повестки */
  public static getOrPostOrPutConsiderationMateraials(): string {
    return `${baseUrl}/calendar/agenda-package/attachments`;
  }

  /** Удаление материала пакета повестки */
  public static deleteConsiderationMaterial(id) {
    return `${baseUrl}/calendar/agenda-package/attachments/${id}`;
  }

  /** Получение спика участников пакета повестки */
  public static getConsiderationParticipants() {
    return `${baseUrl}/calendar/agenda-package/participants/search`;
  }

  /** Добавление/редактирование участника пакета повестки */
  public static postOrPutConsiderationParticipant() {
    return `${baseUrl}/calendar/agenda-package/participants`;
  }

  /** Удаление участников пакета повестки */
  public static deleteConsiderationParticipant(id: number) {
    return `${baseUrl}/calendar/agenda-package/participants/${id}`;
  }

  /** Добавление/редактирование вопроса пакета повестки */
  public static postOrPutConsiderationQuestion() {
    return `${baseUrl}/calendar/agenda-package/questions`;
  }

  /** Редактирование вопросов и их резолюций */
  public static considerationQuestionAndResolution(): string {
    return `${baseUrl}/calendar/agenda-package/questions/questions-and-resolutions`;
  }

  /** Добавление рещения к вопросу пакета повестки */
  public static considerationQuestionResolution() {
    return `${baseUrl}/calendar/agenda-package/resolutions`;
  }

  /** Получение списка вопросов к вопросу пакета повестки */
  public static getConsiderationQuestionsList() {
    return `${baseUrl}/calendar/agenda-package/questions/search`;
  }

  /** Изменение порядкового номера вопроса в пакете повестки */
  public static changeConsiderationQuestionIndexOrType(id: number) {
    return `${baseUrl}/calendar/agenda-package/questions/change-type-index/${id}`;
  }

  /** Изменение типа доступа для вопроса повестки */
  public static changeQuestionAccessType(questionId: number) {
    return `${baseUrl}/calendar/agenda-package/questions/access-type/${questionId}`;
  }

  /** Изменение типа доступа для конкретного документа вопроса повестки */
  public static changeDocumentAccessType(questionId: number, documentId: number) {
    return `${baseUrl}/calendar/documents/access-type/${documentId}/${questionId}`;
  }

  /** Операции с сопроводительными материалами для вопросов пакета повестки */
  public static addAccompanyingMaterial(questionId: number): string {
    return `${baseUrl}/calendar/documents/accompanying-material/${questionId}`;
  }

  public static deleteAccompanyingMaterial(): string {
    return `${baseUrl}/calendar/documents/accompanying-material`;
  }

  public static findAccompanyingMaterials(): string {
    return `${baseUrl}/calendar/documents/accompanying-materials`;
  }

  /** Поиск пакетов документа для раздела вопросов пакета повестки */
  public static searchDocumentPackegesforConsiderationQuestions() {
    return `${baseUrl}/document-packages/search/for-agenda-package`;
  }

  /** Получение вопросов пакета повестки */
  public static getConsiderationQuestion(id: number) {
    return `${baseUrl}/calendar/agenda-package/questions/${id}`;
  }

  /** Удаление вопросов пакета повестки */
  public static deleteConsiderationQuestion(id: number) {
    return `${baseUrl}/calendar/agenda-package/questions/${id}`;
  }

  /** Перенос пакета повестки на другую дату */
  public static postConsiderationQuestionMeetingDate(id: number): string {
    return `${baseUrl}/calendar/agenda-package/questions/${id}/move-on-date`;
  }

  /** Сформирование вопросов */
  public static formConsiderationQuestion(id: number) {
    return `${baseUrl}/calendar/agenda-package/questions/form?agendaPackageId=${id}`;
  }

  /** Пересоздать вопросы для пакета повестки */
  public static reCreateConsiderationQuestion(id: number) {
    return `${baseUrl}/calendar/agenda-package/questions/recreate?agendaPackageId=${id}`;
  }

  /** Справочник типов пакета повестки */
  public static getAllAgendaPackageTypes() {
    return `${baseUrl}/calendar/dictionaries/agenda-package-types/web`;
  }

  /** Справочник топ 10 мест проведения пакетов повестки */
  public static getTopTenAgendaPackagePlaces() {
    return `${baseUrl}/calendar/dictionaries/location/web/top10`;
  }

  /** Справочник типов вопросов пакета повестки */
  public static getAgendaPackageQuestionsTypes() {
    return `${baseUrl}/calendar/agenda-package/questions/question-types`;
  }

  /** Справочник типов вопросов пакета повестки */
  public static getAgendaPackagePMMeetingQuestionsTypes() {
    return `${baseUrl}/calendar/agenda-package/questions/question-types/search`;
  }

  /** Формируем ссылку для скачивания доп. материала пакета повестки */
  public static createDownloadLinkForConsiderationMaterial(link: string): string {
    return `${baseUrl}/calendar/agenda-package/${link}`;
  }

  /** Справочник типов повторений */
  public static getRepeatTypes() {
    return `${baseUrl}/calendar/dictionaries/repeat-types/web`;
  }
  /** Получение списка бумажных подписей */
  public static getPaperSignatures(documentPackageId: number): string {
    return `${baseUrl}/document-packages/${documentPackageId}/paper-documents`;
  }

  /** Добавить бумажную подпись */
  public static addPaperSignatures(): string {
    return `${baseUrl}/paper-documents`;
  }

  /** Удалить бумажную подпись */
  public static deletePaperSignatures(id: number): string {
    return `${baseUrl}/paper-documents/${id}`;
  }

  /** Получение словаря результатов согласования на бумаге */
  public static getPaperReviewResultTypes(): string {
    return `${baseUrl}/dictionaries/paper-review-result`;
  }

  /** Получить список типов участников пакет повестки */
  public static getConsiderationParticipantTypes(): string {
    return `${baseUrl}/calendar/agenda-package/participants/participant-types`;
  }

  /** Получить список типов решений по вопросам пакета повестки */
  public static getConsiderationQuestionResolutionTypes(): string {
    return `${baseUrl}/calendar/agenda-package/resolutions/resolution-types`;
  }

  /** Получить словарь категорий вопросов повестки ПМ и ППМ */
  public static getQuestionCategoriesTypes(): string {
    return `${baseUrl}/dictionaries/agenda-package/question-category`;
  }

  /** получить список рабочих / праздничных дней  */
  public static get productionDays(): string {
    return `${baseUrl}/calendar/production-calendar`;
  }

  /** получить список дат заседаний ПМ   */
  public static getMeetingDates(): string {
    return `${baseUrl}/calendar/final-agenda`;
  }

  public static chooseGeneralInfoRevision(): string {
    return `${baseUrl}/calendar/general-info-revisions`;
  }

  public static deleteGeneralInfoRevision(documentId: number, agendaPackageId: number): string {
    return `${baseUrl}/calendar/general-info-revisions?agendaPackageId=${agendaPackageId}&documentId=${documentId}`;
  }

  /** экспорт событий календаря в DOCX/PDF  */
  public static getCalendarRegistry(): string {
    return `${baseUrl}/reports/calendar-registry`;
  }

  /** добавить резервный номер  */
  public static addReservedNumbers(): string {
    return `${baseUrl}/journal-reserved-numbers/quantity`;
  }

  /** получить резервный номер для ПД */
  public static getReservedNumberForPackage(packageId: number): string {
    return `${baseUrl}/journal-reserved-numbers/document-package-id/${packageId}`;
  }

  /** получить даты действия для ПД */
  public static getActionDatesForPackage(packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/action-dates`;
  }

  /** получить резервные номера  */
  public static getReservedNumbers(queryParams: GetReservedNumbersResponseModel): string {
    const queryString = Urls.createQueryString(queryParams);
    return `${baseUrl}/journal-reserved-numbers?${queryString}`;
  }

  /** Экспорт резервные номера  */
  public static exportReservedNumbers(queryParams: GetReservedNumbersResponseModel, format: string): string {
    const queryString = Urls.createQueryString({
      ...queryParams,
      format,
    });
    return `${baseUrl}/reports/journal-reserved-numbers?${queryString}`;
  }

  /** обновить резервный номер*/
  public static updateReservedNumber(): string {
    return `${baseUrl}/journal-reserved-numbers`;
  }

  /** обновить резервный номер*/
  public static deleteReservedNumber(reservedNumberId: number): string {
    return `${baseUrl}/journal-reserved-numbers/${reservedNumberId}`;
  }

  /** обновить даты действия ПД*/
  public static updateActionDates(packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/action-dates`;
  }

  public static documentPackageSendingsAddOrUpdate(): string {
    return `${baseUrl}/sending`;
  }

  public static documentPackageSendingsGetOrRemove(id: number): string {
    return `${baseUrl}/sending/${id}`;
  }

  public static documentPackageSendingsGetAll(documentPackageId: number): string {
    return `${baseUrl}/sending/by-document-package-id/${documentPackageId}`;
  }

  public static documentPackageSendingsSendPaper(): string {
    return `${baseUrl}/sending/paper-sending`;
  }

  public static documentPackageSendingsSendEmail(): string {
    return `${baseUrl}/sending/email-sending`;
  }

  public static documentPackageSendingsMassSend(): string {
    return `${baseUrl}/sending/mass-sending`;
  }

  public static documentPackageSendingsUnsentEmail(): string {
    return `${baseUrl}/sending/email-sending/has-unsent`;
  }

  public static documentPackageSendingsUnsentCount(): string {
    return `${baseUrl}/sending/count-unsent`;
  }

  public static documentPackageSendingsUnsentPaper(): string {
    return `${baseUrl}/sending/paper-sending/has-unsent`;
  }

  public static documentPackageSendingsGetEmployeeSendings(): string {
    return `${baseUrl}/sending/by-document-package-id-and-employee-id`;
  }

  public static documentPackageSendingsGetOrganizationSendings(): string {
    return `${baseUrl}/sending/by-document-package-id-and-organization-id`;
  }

  public static documentPackageSendingsRepeatedSendings(): string {
    return `${baseUrl}/sending/repeated-sending`;
  }

  public static documentPackagePublicationsGetOrUpdate(documentPackageId: number): string {
    return `${baseUrl}/publication/${documentPackageId}`;
  }

  /** Получение информации по статистикам дашборда (бывшие виджеты) */
  public static getDashboardStatistics(): string {
    return `${baseUrl}/dashboard/statistics`;
  }

  /** Получить категорию подписантов*/
  public static getSignerCategories(): string {
    return `${baseUrl}/dictionaries/internal-signer-categories`;
  }

  /** задать статус ПД */
  public static putDocumentStatus(packageId: number, itemId: number, valueId: number): string {
    return `${baseUrl}/document-packages/${packageId}/statuses/${itemId}/${valueId}`;
  }

  /** Список истории изменений статусов ПД */
  public static getDocumentStatusHistory(packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/statuses/history`;
  }

  /** Список текущих статусов ПД */
  public static getDocumentStatusLatest(packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/statuses/latest`;
  }

  /** Получение информации о последнем красном бланке */
  public static getLatestRedBlankInfo(packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/red-blank/latest`;
  }

  /** добавление информации о новом красном бланке */
  public static addNewRedBlankInfo(blankInfo: DocumentStatusRedBlankRequestModel, packageId: number): string {
    return `${baseUrl}/document-packages/${packageId}/red-blank/${blankInfo.number}/${blankInfo.datePrint}`;
  }

  /** Словарь всех возможных значений статуса ПД */
  public static getDocumentStatusValues(): string {
    return `${baseUrl}/dictionaries/document-package-status-values`;
  }

  /** Словарь всех возможных статусов ПД */
  public static getDocumentStatusItems(): string {
    return `${baseUrl}/dictionaries/document-package-status-items`;
  }

  public static getApprovalCard(id: number): string {
    return `${baseUrl}/approve-cards/${id}`;
  }

  public static updateApprovalCard(): string {
    return `${baseUrl}/approve-cards/`;
  }

  public static routeExtensionDeadline(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/request-deadline-extension`;
  }

  public static routeExtensionDeadlineAccept(): string {
    return `${baseUrl}/deadline-extension-requests/accept`;
  }

  public static routeExtensionDeadlineDecline(): string {
    return `${baseUrl}/deadline-extension-requests/decline`;
  }

  public static routeGetActiveTasks(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/current-user-tasks`;
  }

  public static getRegulationDeadLine(params: RegulationDeadlineDateModel): string {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/template-regulations-deadline?${queryString}`;
  }

  public static toggleNotificationsArchiveState(type: boolean): string {
    const result = type
      ? `${baseUrl}/inner-notifications/remove-archived-state`
      : `${baseUrl}/inner-notifications/set-archived-state`;
    return result;
  }

  public static getStatusValues(): string {
    return `${baseUrl}/oau-registry/document-package-status-values`;
  }

  /** Сформировать карточку приема */
  public static createReceptionCard() {
    return `${baseUrl}/document-packages/create-reception-card`;
  }

  public static searchDocumentPackagesByNameOrNumber(
    isCapableForAffect: boolean,
    isReservedStatus: boolean,
    isCapableForOtherAffect: boolean
  ) {
    if (isCapableForAffect) {
      return `${baseUrl}/document-packages/search/capable-for-affect`;
    }
    if (isReservedStatus) {
      return `${baseUrl}/document-packages/search/by-reserved-status`;
    }
    if (isCapableForOtherAffect) {
      return `${baseUrl}/document-packages/search/capable-for-other-affect`;
    }
    return `${baseUrl}/document-packages/search/by-name-or-number`;
  }

  public static searchDocumentPackagesByReservedStatus(): string {
    return `${baseUrl}/document-packages/search/by-reserved-status`;
  }

  public static getDocumentPackageRelationTypes() {
    return `${baseUrl}/dictionaries/document-package-relation-types`;
  }

  public static getDocumentStages() {
    return `${baseUrl}/dictionaries/document-stages`;
  }

  /** Создание шаблона маршрута */
  public static postCreateRouteTemplate(routeId: number) {
    return `${baseUrl}/agreement-route-templates/${routeId}`;
  }

  /** Получение всех шаблонов маршрута */
  public static getAllRouteTemplate() {
    return `${baseUrl}/agreement-route-templates`;
  }

  /** Поиск по шаблонам маршрутов */
  public static searchRouteTemplate(organizationId: number, projectTypeId: number, name: string) {
    const queryString = Urls.createQueryString({
      organizationId,
      projectTypeId,
      name: encodeURIComponent(name),
    });
    return `${baseUrl}/agreement-route-templates/search?${queryString}`;
  }

  /** Применение шаблона к маршруту */
  public static applyRouteTemplate(routeId: number, templateId: number) {
    return `${baseUrl}/agreement-route-templates/${templateId}/apply/${routeId}`;
  }

  /** Создание шаблона поручения из маршрута */
  public static createRouteTemplateInitiation(routeId: number) {
    return `${baseUrl}/agreement-route-templates/initiation/${routeId}`;
  }

  /** Применение шаблона поручение к маршруту */
  public static applyRouteTemplateInitiation(routeId: number, templateId: number) {
    return `${baseUrl}/agreement-route-templates/initiation/${templateId}/apply/${routeId}`;
  }

  /** Поиск по шаблонам поручения */
  public static searchRouteTemplateInitiation(
    organizationId: number,
    projectTypeId: number,
    name: string,
    phaseTypeId: number
  ) {
    const queryString = Urls.createQueryString({
      projectTypeId,
      organizationId,
      name,
      phaseTypeId,
    });
    return `${baseUrl}/agreement-route-templates/initiation/search?${queryString}`;
  }

  /** Сделать этап из шаблона активным */
  public static activePoinstRouteTemplateInitiation(routeId: number) {
    return `${baseUrl}/agreement-route-templates/initiation/activate/${routeId}`;
  }

  /** Чтение основной информации архивной карточки по ID пакета документа */
  public static getInfoArchiveCard(packageId: number) {
    return `${baseUrl}/archive-cards/info/${packageId}`;
  }

  /** Чтение раздела "Экспертиза ценности" архивной карточки по ID пакета документа */
  public static getExpertiseArchiveCard(packageId: number) {
    return `${baseUrl}/archive-cards/expertise/${packageId}`;
  }

  /** Обновление основной информации архивной карточки */
  public static putInfoArchiveCard() {
    return `${baseUrl}/archive-cards/info`;
  }

  /** Обновление раздела "Экспертиза ценности" архивной карточки */
  public static putExpertiseArchiveCard() {
    return `${baseUrl}/archive-cards/expertise`;
  }
  /** Аналитика - статистика */
  public static getSummaryAnalytics(params: SummaryAnalyticsSearchParam) {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/analytics/projects-in-work?${queryString}`;
  }

  /** Полученить типы документов в Пакете повестки */
  public static getCalendarConsiderationDocumentTypes() {
    return `${baseUrl}/dictionaries/document-types/calendar`;
  }

  public static getAndSaveNotificationsConfig(): string {
    return `${baseUrl}/notifications/settings/`;
  }

  /** Создать эталонную версию */
  public static postDocumentEtalon(): string {
    return `${baseUrl}/etalon-versions/`;
  }

  /** Отправить в электронный архив */
  public static sendToElectronicArchiveRoute(dpId: number): string {
    return `${baseUrl}/electronic-archive/send/${dpId}`;
  }

  /** Удалить из электронного архива */
  public static removeFromElectronicArchiveRoute(dpId: number): string {
    return `${baseUrl}/electronic-archive/delete/${dpId}`;
  }

  /** Создать действующую редакцию */
  public static postCurrentVersion(): string {
    return `${baseUrl}/document-acting-versions`;
  }

  /** Удалить действующую редакцию */
  public static deleteCurrentVersion(): string {
    return `${baseUrl}/document-acting-versions/by-document-id`;
  }

  /** Сменить признак видимости действующей редакции для обычных пользователей */
  public static updateVisibilityActingVersion(): string {
    return `${baseUrl}/document-acting-versions/shared-property/by-document-acting-version-id`;
  }
  /** Сменить признак видимости всех действующих редакций для обычных пользователей */
  public static updateVisibilityActingVersionsAll(): string {
    return `${baseUrl}/document-acting-versions/shared-property/by-acting-version-ids`;
  }

  /** Снятие признака эталонности с версии документа */
  public static unselectEtalonMark(): string {
    return `${baseUrl}/etalon-versions/remove`;
  }

  public static documentPackageHasMainDocument(id: number): string {
    return `${baseUrl}/document-packages/${id}/has-main-document`;
  }

  public static getArchiveInventoryCard(packageId: number): string {
    return `${baseUrl}/archive-cards/inventory/${packageId}`;
  }

  public static getPhasesSendToApprovement(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/outer-approvements-info`;
  }

  public static updateSignersCategories(routeId: number, pointId: number) {
    return `${baseUrl}/agreement-routes/${routeId}/signer-categories?pointId=${pointId}`;
  }

  public static postSendToApprovement(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/send-to-outer-approvement`;
  }

  public static getSystematizationCases(params: AnalyticsSystematizationRequestModel): string {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/archive-cards/?${queryString}`;
  }

  public static getCaseStatuses(): string {
    return `${baseUrl}/dictionaries/case-statuses`;
  }

  public static getAnalyticsReport(queryParams: AnalyticsReportFilterFormModel): string {
    const queryString = Urls.createQueryString(queryParams);
    return `${baseUrl}/legal-acts-report/?${queryString}`;
  }

  public static exportAnalyticsReport(queryParams: AnalyticsReportFilterFormModel, format: string): string {
    const queryString = Urls.createQueryString({
      ...queryParams,
      format,
    });
    return `${baseUrl}/reports/legal-acts?${queryString}`;
  }

  public static generateLegalActsReferenceReport(): string {
    return `${baseUrl}/legal-acts-publishing-report/`;
  }

  public static getDocumentControlAlternatives(): string {
    return `${baseUrl}/document-version-attributes/receive`;
  }

  public static postMergeRevisions(documentId: string) {
    return `${baseUrl}/documents/${documentId}/mergeRemarks`;
  }

  public static getOrganizationsLeader(): string {
    return `${baseUrl}/employee/organization/leaders`;
  }

  public static getOrganizationsLeadersByPhaseType(phaseId: number): string {
    return `${baseUrl}/employee/organization/leaders/phase/${phaseId}`;
  }

  public static generateDocuments(documentPackageId: number): string {
    return `${baseUrl}/archive-cards/documents/${documentPackageId}`;
  }

  public static getRelatedDocumentPackages(documentPackageId: number): string {
    return `${baseUrl}/document-packages/${documentPackageId}/amendments/third-party/`;
  }

  public static getAlertPeriodTypes(): string {
    return `${baseUrl}/dictionaries/notifications/alert-period-types`;
  }

  public static postSendToOauLeader(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/send-to-oau-leader`;
  }

  public static sortConsiderationQuestions(): string {
    return `${baseUrl}/calendar/agenda-package/questions/sorting`;
  }

  public static getDocumentPackageRelationConflicts(params: SummaryAnalyticsSearchParam): string {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/relations/conflicts?${queryString}`;
  }

  public static getProjectInWorkDetalisation(params: SummaryAnalyticsSearchParam, type: string): string {
    const queryString = Urls.createQueryString({
      ...params,
      type,
    });
    return `${baseUrl}/analytics/projects-in-work/details?${queryString}`;
  }

  public static exportProjectInWorkDetalisation(params: SummaryAnalyticsSearchParam, type: string): string {
    const queryString = Urls.createQueryString({
      ...params,
      type,
    });
    return `${baseUrl}/analytics/projects-in-work/export?${queryString}`;
  }

  public static getDocumentProhibitionsByDocumentPackage(packageId: number): string {
    return `${baseUrl}/document-prohibitions/by-document-package/${packageId}`;
  }

  public static getDocumentProhibitionsForCurrentEmployeeByDocumentPackage(packageId: number): string {
    return `${baseUrl}/document-prohibitions/for-current-employee-by-document-package/${packageId}`;
  }

  public static getDocumentProhibitionsByDocument(documentId: number): string {
    return `${baseUrl}/document-prohibitions/${documentId}`;
  }

  public static postDocumentProhibitions(): string {
    return `${baseUrl}/document-prohibitions`;
  }

  public static deleteDocumentProhibitions(prohibitionsId: number): string {
    return `${baseUrl}/document-prohibitions/${prohibitionsId}`;
  }

  public static deleteDocumentProhibitionsByDocumentIds(documentIds: number[]): string {
    const ids = documentIds.toString();
    return `${baseUrl}/document-prohibitions/by-document-ids?docIds=${ids}`;
  }

  public static canEditDocumentAccessType(): string {
    return `${baseUrl}/document-prohibitions/documents-with-uneditable-prohibition-type`;
  }

  /** Получение всех типов ограничений для документов */
  public static getDocumentProhibitionTypes(): string {
    return `${baseUrl}/dictionaries/document-prohibition-types`;
  }

  /** Возвращает URL для получения ближайшей даты заседания по типу заседания (ПМ/ППМ) */
  public static getMinMeetingDateByMeetingTypeUrl(meetingType: number): string {
    return `${baseUrl}/review-dates/closest-acceptable/${meetingType}`;
  }

  /** Получение ближайшей возможной планируемой даты рассмотрения ПД */
  public static getNearestPotentialDeadlineForDocumentPackage(dpId: number): string {
    return `${baseUrl}/review-dates/closest-acceptable/document-package/${dpId}`;
  }

  /** Ссылка для создания заявки на изменение даты рассмотрения ПД */
  public static postReviewDateChangeRequest(): string {
    return `${baseUrl}/review-dates/change-request`;
  }

  /** Проверка доступности изменения даты рассмотрения ПД без создания заявки */
  public static postReviewDatesCanEdit(): string {
    return `${baseUrl}/review-dates/can-edit`;
  }

  /** Проверка доступности изменения даты рассмотрения ПД без создания заявки */
  public static canEditReviewDates(documentPackageId: number): string {
    return `${baseUrl}/review-dates/${documentPackageId}/can-edit`;
  }

  /** Получение заявок на изменение даты рассмотрения ПД */
  public static getReviewDateRequests(): string {
    return `${baseUrl}/review-dates/change-requests`;
  }

  /** Вынесение вердикта по переносу даты */
  public static determineReviewDate(): string {
    return `${baseUrl}/review-dates/change-request/close`;
  }

  public static markDocumentPackageAsRead(documentPackageId: number): string {
    return `${baseUrl}/read-document-packages?documentPackageId=${documentPackageId}`;
  }

  public static getPrivileges(): string {
    return `${baseUrl}/personal-cabinet/privileges`;
  }

  /** Ссылка на скачивание инструкции */
  public static downloadInstructionUrl(id: number): string {
    return `${baseUrl}/personal-cabinet/instructions/${id}`;
  }

  /** Ссылка на список типов шаблона сопроводительного текста */
  public static getTemplateCoveringTextTypes(): string {
    return `${baseUrl}/dictionaries/template-types`;
  }

  /** Ссылка на список типов поручения для исполнителя */
  public static getAssignmentTypes(): string {
    return `${baseUrl}/dictionaries/assignment-from-types`;
  }

  /** Ссылка на список часто назначаемых исполнителей */
  public static getAssignedExecutors(): string {
    return `${baseUrl}/personal-cabinet/assigned-executors`;
  }

  /** Ссылка на добавление часто назначаемых исполнителей */
  public static postAssignedExecutors(): string {
    return `${baseUrl}/personal-cabinet/assigned-executors`;
  }

  /** Ссылка на удаление часто назначаемых исполнителей */
  public static deleteAssignedExecutors(id: number): string {
    return `${baseUrl}/personal-cabinet/assigned-executors/${id}`;
  }

  /** Ссылка на список часто назначаемых исполнителей по типу поручения */
  public static getFavoriteAssignedExecutors(): string {
    return `${baseUrl}/personal-cabinet/assigned-executors/favorites`;
  }

  /** Ссылка на получение, создание и обновление шаблона сопроводительнго текста */
  public static getCoveringText(): string {
    return `${baseUrl}/personal-cabinet/template-description`;
  }

  public static deleteCoveringText(id: number): string {
    return `${baseUrl}/personal-cabinet/template-description/${id}`;
  }

  /** Получение списка обращений по параметрам */
  public static getHpsmAppeals(): string {
    return `${hpsmBaseUrl}/appeal/search`;
  }

  /** Получение списка типов обращений */
  public static getAppealTypes(): string {
    return `${hpsmBaseUrl}/appeal/types`;
  }

  /** Получение списка статусов для поиска обращений */
  public static getAppealStatuses(): string {
    return `${hpsmBaseUrl}/appeal/statuses`;
  }

  /** Получение статуса обращения по id */
  public static getAppealStatusById(appealId: number): string {
    return `${hpsmBaseUrl}/appeal/condition/${appealId}`;
  }

  /** Создание обращения */
  public static createAppeal(): string {
    return `${hpsmBaseUrl}/appeal`;
  }

  /** Роут на получение полезных ресурсов */
  public static getUsefulSourcesRoute(): string {
    return `${baseUrl}/useful-resources/search`;
  }

  /** Роут на добавление ресурса в избранное */
  public static postToFavoriteAdd(resourceId: number): string {
    return `${baseUrl}/useful-resource/favorite/${resourceId}`;
  }

  /** Роут на удаление ресурса из избранного */
  public static deleteFromFavorite(resourceId: number): string {
    return `${baseUrl}/useful-resource/favorite/${resourceId}`;
  }

  /** Роут на получение иконки из FileStore */
  public static getIconFromStorageRoute(): string {
    return `${baseUrl}/useful-resources/icon`;
  }

  /** Роут на получение инструкции */
  public static getUsefulResourceInstructionRoute(): string {
    return `${baseUrl}/useful-resource/instruction`;
  }

  /** Ссылка на получение списка папок текущего пользователя */
  public static getFolders(): string {
    return `${baseUrl}/folder/user-list`;
  }

  /** Ссылка на создание/редактирование папки */
  public static postFolder(): string {
    return `${baseUrl}/folder`;
  }

  /** Ссылка на удаление папки */
  public static deleteFolder(id: number): string {
    return `${baseUrl}/folder/${id}`;
  }

  /** Ссылка на доступные папки для текущего пользователя */
  public static getFoldersAccess(): string {
    return `${baseUrl}/folder/available-list`;
  }

  /** Ссылка на просмотр/обновление прав доступа папки */
  public static folderAccess(id: number | string): string {
    return `${baseUrl}/folder/${id}/access`;
  }

  /** Ссылка на добавление ДП в папку */
  public static folderAddDocumentPackage(): string {
    return `${baseUrl}/folder/add-document-package`;
  }

  /** Ссылка на удаление ПД из папки */
  public static folderRemoveDocumentPackage(): string {
    return `${baseUrl}/folder/remove-document-package`;
  }

  public static calendarDocumentVersions(documentId: number): string {
    return `${baseUrl}/calendar/document-versions/by-document-id/${documentId}`;
  }

  public static includeQuestionFinalAgenda(questionId: number): string {
    return `${baseUrl}/calendar/agenda-package/questions/include-in-result-agenda/${questionId}`;
  }

  /** Получение коллекции документов для вопроса в составе пакета повестки */
  public static getCalendarQuestionDocuments(): string {
    return `${baseUrl}/calendar/documents`;
  }

  public static postCalendarDocumentsImport(params: string): string {
    return `${baseUrl}/calendar/documents/import?${params}`;
  }

  public static getReservationStatuses(): string {
    return `${baseUrl}/dictionaries/reserved-number-statuses`;
  }

  /** Проверка наличия категории подписанта на маршруте */
  public static checkSignerCategories(routeId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/signer-categories/check`;
  }

  /** Получение курирующих комплексов */
  public static getOivComplex(organizationId: number): string {
    return `${baseUrl}/oiv-complexes/by-organization/${organizationId}`;
  }

  /** Получение сертификатов облачной подписи **/
  static getDssCertificates() {
    return `${baseUrl}/cryptopro-dss/certificates`;
  }

  /** Получение ОИВ-разработчика ПД по идентификатору ПД */
  public static getOIVDeveloper(documentPackageId: number): string {
    return `${baseUrl}/oiv-developer/by-document-package-id?documentPackageId=${documentPackageId}`;
  }

  /** Получение отчета об истории расссмотрений */
  public static getHistoryConsiderations(params: HistoryConsiderationsFiltersModel): string {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/oau-review-history/search?${queryString}`;
  }

  public static exportDocumentPackagesNumberReport(): string {
    return `${baseUrl}/count-document-package-report/export`;
  }

  public static getDocumentPackagesNumberReport(): string {
    return `${baseUrl}/count-document-package-report/`;
  }

  /** Права доступа для раздела/подраздела */
  public static getDashboardAccess(): string {
    return `${baseUrl}/available-subsection/access`;
  }

  /**  */
  public static sharedFiltersList(): string {
    return `${baseUrl}/available-subsection/`;
  }

  /** Получение данных документа для подписи */
  public static getDocumentSignData(ldeId: string, ldeRevision: string): string {
    return `${baseUrl}/documents/${ldeId}/${ldeRevision}/signData`;
  }

  /** Получение всех настроек */
  public static getSettingAll(): string {
    return `${baseUrl}/setting/all`;
  }

  /** Получение списка закрытых дат по типу проекта / Закрытие даты для типа проекта */
  public static сlosedDatesByProjectType(): string {
    return `${baseUrl}/registration-date/closed-date`;
  }

  /** Получение открытой даты по типу проекта */
  public static getOpenedDateByProjectType(): string {
    return `${baseUrl}/registration-date/open-date`;
  }

  /** Получение последней закрытой даты по типу проекта */
  public static getClosedDatesByProjectTypeId(): string {
    return `${baseUrl}/registration-date/closed-date/by-project-type-id`;
  }

  /** Изменение порядкового номера приложений */
  public static changeAdditionalOrder(): string {
    return `${baseUrl}/documents/change-serial-number`;
  }

  /** Создание версий для опубликования в ПД */
  public static createPublicationVersion(documentPackageId: number): string {
    return `${baseUrl}/publication-version/${documentPackageId}/create`;
  }

  /** Получение версий для опубликования для документа */
  public static deletePublicationVersion(documentId: number): string {
    return `${baseUrl}/publication-version/${documentId}`;
  }

  /** Изменение дат этапа */
  public static configurePhaseDeadlines(routeId: number): string {
    return `${baseUrl}/agreement-route-phases/${routeId}/configure-dates`;
  }

  /** Обновление даты рассмотрения ПД */
  public static updateReviewDates(): string {
    return `${baseUrl}/review-dates`;
  }

  /** Получение данных по документу для передачи в редактор */
  public static getDocumentPackageLdeConfig(): string {
    return `${baseUrl}/lde-document`;
  }

  /** Получение данных по документу пакета повестки для передачи в редактор */
  public static getDocumentPackageConsiderationEditor(documentId: number): string {
    return `${baseUrl}/lde-document/agenda-package/document/${documentId}`;
  }

  /** Экспорт отчета 'Реестр внешних нормативных правовых актов' */
  public static exportExternalRegistryToCsv(): string {
    return `${baseUrl}/external-regulatory-legal-acts-registry/export/csv`;
  }

  /** Экспорт отчета 'Реестр внешних нормативных правовых актов' */
  public static exportExternalRegistryToXlsx(): string {
    return `${baseUrl}/external-regulatory-legal-acts-registry/export/xlsx`;
  }

  public static exportAnalyticsDevelopmentOfRegulatoryActsRegistryToCsv(): string {
    return `${baseUrl}/reports/analytics-development-of-regulatory-acts-registry/export/csv`;
  }

  public static exportAnalyticsDevelopmentOfRegulatoryActsRegistryToXlsx(): string {
    return `${baseUrl}/reports/analytics-development-of-regulatory-acts-registry/export/xlsx`;
  }

  /** Получение списка руководителей на маршрутах */
  public static getExternalRegistryLeaders(): string {
    return `${baseUrl}/external-regulatory-legal-acts-registry/leaders`;
  }

  /** Поиск в списке руководителей на маршрутах */
  public static findExternalRegistryLeaders(): string {
    return `${baseUrl}/external-regulatory-legal-acts-registry/leaders/search`;
  }

  /** Экспорт отчета 'Мониторинг' в csv */
  public static exportMonitoringReportToCSV(): string {
    return `${baseUrl}/monitoring/export/csv`;
  }

  /** Экспорт отчета 'Мониторинг' в xlsx */
  public static exportMonitoringReportToXLSX(): string {
    return `${baseUrl}/monitoring/export/xlsx`;
  }

  /** Получение списка руководителей на маршрутах */
  public static getMonitorLeaders(): string {
    return `${baseUrl}/monitoring/leaders`;
  }

  public static getFolderViewAccessTypes(): string {
    return `${baseUrl}/dictionaries/folder-view-access-types`;
  }

  public static getFolderEditRightTypes(): string {
    return `${baseUrl}/dictionaries/folder-edit-right-types`;
  }

  /** Создание мастер-версии(-й) документа(-ов) */
  public static getCreateDocumentMasterVersionUrl(): string {
    return `${baseUrl}/documents/master-version`;
  }

  /** Получение списка поручений из ИС ПРО */
  public static getIsProAssignments(): string {
    return `${baseUrl}/ispro/assignments`;
  }

  /** Создание связи между поручением из ИС ПРО и ПД */
  public static createIsProAssignmentRelation(): string {
    return `${baseUrl}/ispro/relation`;
  }

  /** Получение списка созданных связей между поручением из ИС ПРО и ПД */
  public static getIsProAssignmentRelations(): string {
    return `${baseUrl}/ispro/relations`;
  }

  /** Удаление связи между поручением из ИС ПРО и ПД */
  public static removeAssignmentRelation(): string {
    return `${baseUrl}/ispro/remove-relation`;
  }

  public static getSharedFolder(id: number): string {
    return `${environment.baseUrl}/folder/${id}/folder-info`;
  }

  public static getSharedSubsection(id: number): string {
    return `${environment.baseUrl}/available-subsection/${id}/available-subsection-info`;
  }

  public static getAllowedToSignDocumentsId(): string {
    return `${baseUrl}/documents/signable`;
  }

  /**
   * Возвращает сотрудника для финального этапа на маршруте
   *
   * @param projectTypeGroup - id группы проектов [Опциональный]
   */
  public static getFinalPhaseEmployeeUrl(projectTypeGroup: ProjectGroupType = 1): string {
    return `${baseUrl}/employee/final-user/${projectTypeGroup}`;
  }

  public static addQuestionWithoutDocumentPackage(): string {
    return `${baseUrl}/calendar/agenda-package/questions/without-relation`;
  }

  /** Изменение решения к вопросу пакета повестки */
  public static editConsiderationQuestionResolution(resolutionId: number): string {
    return `${baseUrl}/calendar/agenda-package/resolutions/type/${resolutionId}`;
  }

  public static getChartDetails(): string {
    return `${baseUrl}/analytics/chart-details`;
  }

  public static getPhaseDuration(params: ReportOnDurationFilterModel) {
    const queryString = Urls.createQueryString(params);
    return `${baseUrl}/analytics/phase-duration?${queryString}`;
  }

  public static getDelegationPositions(): string {
    return `${baseUrl}/dictionaries/delegation-positions`;
  }

  public static postMarkOwnVersion(): string {
    return `${baseUrl}/documents/versions/own`;
  }

  public static getGroupedDocumentVersions(documentId: number): string {
    return `${baseUrl}/documents/${documentId}/versions/grouped`;
  }

  public static setResponsibleExecutor(routeId: number, pointId: number): string {
    return `${baseUrl}/agreement-routes/${routeId}/responsible-executor/${pointId}`;
  }

  public static actualVersion(): string {
    return `${baseUrl}/documents/versions/actual`;
  }

  public static multipleApprove(): string {
    return `${baseUrl}/document-packages/multiple-approve`;
  }

  public static refreshToken(): string {
    return `${baseUrl}/refresh`;
  }

  public static getImportFormatsByDocumentTypeId(): string {
    return `${baseUrl}/dictionaries/document-types/import-formats`;
  }

  public static sendToRegistration(documentPackageId: number, organizationId: number): string {
    return `${baseUrl}/document-package/send-to-registration/${documentPackageId}/organisation-id/${organizationId}`;
  }

  public static returnFromRegistration(documentPackageId: number): string {
    return `${baseUrl}/document-package/return-from-registration/${documentPackageId}`;
  }

  public static checkRegistrationNumberAvailability(availableNumberId: number): string {
    return `${baseUrl}/document-package/check-availability/${availableNumberId}`;
  }

  public static reserveRegistrationNumber(): string {
    return `${baseUrl}/document-package/reserve-registration-number`;
  }

  public static registerDocumentPackage(): string {
    return `${baseUrl}/document-package/register`;
  }

  public static cancelNumberReservation(documentPackageId: number): string {
    return `${baseUrl}/document-package/${documentPackageId}/cancel-reservation`;
  }

  public static cancelDocumentPackageRegistration(documentPackageId: number): string {
    return `${baseUrl}/document-package/${documentPackageId}/cancel-registration`;
  }

  public static createRegistrationNumberRoute(): string {
    return `${baseUrl}/document-package/registration-number`;
  }

  public static getAllRegistrationNumberRoute(): string {
    return `${baseUrl}/document-package/registration-number`;
  }

  public static getAllRegistrationNumberChoiceRoute(): string {
    return `${baseUrl}/document-package/registration-number-choice`;
  }

  public static checkIndexByDateRoute(): string {
    return `${baseUrl}/document-package/registration-number/check-index-by-date`;
  }

  public static checkSendToRegistration(dpId: number): string {
    return `${baseUrl}/document-package/${dpId}/check-send-to-registration`;
  }

  public static checkCancelRegistration(dpId: number): string {
    return `${baseUrl}/document-package/${dpId}/check-cancel-registration`;
  }

  public static deleteRegistrationNumberRoute(id: number): string {
    return `${baseUrl}/document-package/registration-number/${id}`;
  }

  public static findRegistrationNumberRoute(): string {
    return `${baseUrl}/document-package/registration-number/find`;
  }

  public static checkNumberByDateAndCounter(): string {
    return `${baseUrl}/document-package/registration-number/check-number-by-date-and-counter`;
  }

  public static getNomenclatures(): string {
    return `${baseUrl}/nomenclature-index`;
  }

  public static getAvailableNomenclatures(): string {
    return `${baseUrl}/nomenclature-index/get-available`;
  }

  public static getNomenclaturesByDpId(dpId: number): string {
    return `${baseUrl}/nomenclature-index/doc-package/${dpId}`;
  }

  public static getDocumentPackageDecisions(id: number): string {
    return `${baseUrl}/document-packages/${id}/make-decisions`;
  }

  public static getDpRegistrationNumberStatus(dpId: number): string {
    return `${baseUrl}/document-package/${dpId}/registration-number-status`;
  }

  public static getRegistrationNumberStatus(registrationNumberId: number): string {
    return `${baseUrl}/document-package/registration-number-status/${registrationNumberId}`;
  }

  public static getRegistraionNumberMaxDate(nomenclatureId: number): string {
    return `${baseUrl}/document-package/registration-number/max-date?nomenclatureIndexId=${nomenclatureId}`;
  }

  public static getNomenclatureIndexesRoute(): string {
    return `${baseUrl}/nomenclature-index`;
  }

  public static getWafMessage(): string {
    return `${baseUrl}/waf`;
  }

  public static getRegistrationNumberFreeDates(): string {
    return `${baseUrl}/document-package/registration-number/free-dates`;
  }

  public static putAssignedExecutors(): string {
    return `${baseUrl}/personal-cabinet/assigned-executors/favorites/order`;
  }

  /** Получение версии бэкэнда */
  public static getBackendVersion() {
    return `${baseUrl}/personal-cabinet/version`;
  }

  public static createSigningVersions(): string {
    return `${baseUrl}/signing-version`;
  }
}
