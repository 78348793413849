import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '../../../../core/store/cache-time.const';
import { DictionaryApiService } from '../../../../shared/api/dictionary/dictionary.api.service';
import {
  ProjectTypesAction,
  ProjectTypesActionTypes,
  ProjectTypesGetError,
  ProjectTypesGetSuccess,
} from './project-types.actions';

@Injectable()
export class ProjectTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetProjectTypes$: Observable<ProjectTypesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectTypesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() => this.dictionaryApi.getProjectTypes()),
      map((response) => new ProjectTypesGetSuccess(response)),
      catchError((err) => of(new ProjectTypesGetError()))
    )
  );
}
