export enum DocumentGroupsEnum {
  main = 'MAIN', // Проект, приложение и пояснительная записка
  additional = 'ADDITIONAL', // Согласования
  accompanying = 'ACCOMPANYING', // Сопроводительные документы
  oau = 'OAU', // Документы для опубликования
  localLegalPublication = 'LOCAL_LEGAL_PUBLICATION',
  /** Оригиналы импортированных документов */
  source = 'SOURCE',
  /** Согласительные документы */
  conciliation = 'CONCILIATION',
  /** Сопроводительные материалы */
  accompanyingMaterials = 'ACCOMPANYING_MATERIALS',
}
