import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { MassPrintTypesEnum } from '@appShared/enums/mass-print-types.enum';
import { getDocumentStages } from '@appShared/store/dictionaries/document-stages';
import { DocumentStagesGet } from '@appShared/store/dictionaries/document-stages/document-stages.actions';
import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mass-print-select-type-popup',
  templateUrl: './mass-print-select-type-popup.component.html',
  styleUrls: ['./mass-print-select-type-popup.component.scss'],
})
export class MassPrintSelectTypePopupComponent implements OnInit {
  @Input() isConsiderationDocuments = false;
  @Output()
  public onClosePopup = new EventEmitter<void>();
  @Output()
  public onConfirm = new EventEmitter<DictionaryWithDescriptionModel>();

  public stages$: Observable<DictionaryWithDescriptionModel[]>;
  public selectControl = new UntypedFormControl(null, Validators.required);

  constructor(private reduxService: ReduxService) {}

  ngOnInit() {
    this.reduxService.dispatchAction(new DocumentStagesGet());
    this.getDocumentStages();
  }

  public onConfirmClick() {
    const selectedType = this.selectControl.value as DictionaryWithDescriptionModel;
    if (!selectedType) {
      return;
    }

    this.onConfirm.emit(selectedType);
  }

  private getDocumentStages(): void {
    const documentsPrintTypes = [
      MassPrintTypesEnum.negotiation,
      MassPrintTypesEnum.review,
      MassPrintTypesEnum.redForm,
      MassPrintTypesEnum.blackForm,
      MassPrintTypesEnum.effectiveVersion,
    ];
    const considerationPrintTypes = [MassPrintTypesEnum.sittingAndProtocol, MassPrintTypesEnum.emptySittingAndProtocol];
    const availableTypes = this.isConsiderationDocuments ? considerationPrintTypes : documentsPrintTypes;

    this.stages$ = this.reduxService
      .selectStore(getDocumentStages)
      .pipe(
        map((stages: DictionaryWithDescriptionModel[]) => stages.filter((stage) => availableTypes.includes(stage.id)))
      );
  }
}
