import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  ReservationStatusesActionTypes,
  ReservationStatusesGetError,
  ReservationStatusesGetSuccess,
  ReservationStatusesTypesAction,
} from '@appShared/store/dictionaries/reservation-statuses/reservation-statuses.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class ReservationStatusesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetReservationStatuses$: Observable<ReservationStatusesTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ReservationStatusesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getReservationStatuses().pipe(
        map((response) => new ReservationStatusesGetSuccess(response)),
        catchError((err) => of(new ReservationStatusesGetError()))
      )
    )
  ));
}
