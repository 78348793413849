import { ExtensionDeadlinePopupModel } from '@appDocuments/documents-route/models/extension-deadline-popup.model';
import { RouteExtensionDeadlineActions, RouteExtensionDeadlineActionTypes } from '@appDocuments/documents-route/store/route-extension-deadline/route-extension-deadline.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DeadlineExtensionResponseDto } from '@models/route-extension-deadline/deadline-extension-response-dto.model';

export interface RouteExtensionDeadlineState {
  popupParams: ExtensionDeadlinePopupModel;
  updateStatus: StoreLoadStatus;
  acceptStatus: StoreLoadStatus;
  declineStatus: StoreLoadStatus;
  getStatus: StoreLoadStatus;
  extensionDeadlines: DeadlineExtensionResponseDto[];
  selectedExtensionDeadline: DeadlineExtensionResponseDto;
  error: any;
}

export const initialState: RouteExtensionDeadlineState = {
  popupParams: null,
  updateStatus: null,
  acceptStatus: null,
  declineStatus: null,
  getStatus: null,
  extensionDeadlines: [],
  selectedExtensionDeadline: null,
  error: null
};

export function routeExtensionDeadlinePopupReducer(state = initialState, action: RouteExtensionDeadlineActions) {
  switch (action.type) {
    case RouteExtensionDeadlineActionTypes.UpdateExtensionPopupParams:
      return {
        ...state,
        popupParams: action.payload
      };

    case RouteExtensionDeadlineActionTypes.GetExtensionDeadline:
      return {
        ...state,
        getStatus: StoreLoadStatus.inProgress
      };

    case RouteExtensionDeadlineActionTypes.UpdateExtensionDeadline:
      return {
        ...state,
        updateStatus: StoreLoadStatus.inProgress
      };

    case RouteExtensionDeadlineActionTypes.UpdateExtensionDeadlineSuccess:
      return {
        ...state,
        updateStatus: StoreLoadStatus.loaded
      };

    case RouteExtensionDeadlineActionTypes.AcceptExtensionDeadline:
      return {
        ...state,
        acceptStatus: StoreLoadStatus.inProgress
      };

    case RouteExtensionDeadlineActionTypes.AcceptExtensionDeadlineSuccess:
      return {
        ...state,
        acceptStatus: StoreLoadStatus.loaded
      };

    case RouteExtensionDeadlineActionTypes.DeclineExtensionDeadline:
      return {
        ...state,
        declineStatus: StoreLoadStatus.inProgress
      };

    case RouteExtensionDeadlineActionTypes.DeclineExtensionDeadlineSuccess:
      return {
        ...state,
        declineStatus: StoreLoadStatus.loaded
      };

    case RouteExtensionDeadlineActionTypes.GetExtensionDeadlineSuccess:
      return {
        ...state,
        getStatus: StoreLoadStatus.loaded,
        extensionDeadlines: action.payload
      };

    case RouteExtensionDeadlineActionTypes.GetExtensionDeadlineError:
      return {
        ...initialState,
        error: action.error,
        getStatus: StoreLoadStatus.error
      };

    case RouteExtensionDeadlineActionTypes.UpdateExtensionDeadlineError:
      return {
        ...state,
        error: action.error,
        updateStatus: StoreLoadStatus.error
      };

    case RouteExtensionDeadlineActionTypes.ResetExtensionDeadlines:
      return initialState;

    case RouteExtensionDeadlineActionTypes.ResetExtensionDeadlineResponseStatuses:
      return {
        ...state,
        acceptStatus: null,
        declineStatus: null
      };

    case RouteExtensionDeadlineActionTypes.SelectExtensionDeadline:
      return {
        ...state,
        selectedExtensionDeadline: action.payload
      };

    default:
      return state;
  }
}
