import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import {
  MeetingDatesState,
  meetingDatesReducer
} from '@appShared/components/controls/input-calendar/store/meeting-dates/meeting-dates.reducer';
import { CalendarHolidaysStoreModel } from '@appShared/components/controls/input-calendar/models/holiday.model';
import { holidaysReducer } from '@appShared/components/controls/input-calendar/store/holidays/holidays.reducer';

export interface InputCalendarModuleState {
  holidays: CalendarHolidaysStoreModel;
  meetingDates: MeetingDatesState;
}

export const inputCalendarModuleReducers: ActionReducerMap<InputCalendarModuleState> = {
  holidays: holidaysReducer,
  meetingDates: meetingDatesReducer
};

export const selectInputCalendarModule = createFeatureSelector<InputCalendarModuleState>('inputCalendarModule');
