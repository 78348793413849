import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as fromRouterActions from './router.actions';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router, private location: Location) {}

  
  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.Go),
    map((action: fromRouterActions.Go) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      this.router.navigate(path, { queryParams, ...extras });
    })
  ), { dispatch: false });

  
  changeQuery$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.ChangeQuery),
    tap((action: fromRouterActions.ChangeQuery) =>
      this.router.navigate([], {
        queryParams: action.payload,
        queryParamsHandling: 'merge'
      })
    )
  ), { dispatch: false });

  
  toggleParams$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.ToggleQuery),
    tap((action: fromRouterActions.ToggleParams) => {
      const query = Object.keys(action.payload).reduce((acc, paramName) => {
        return { ...acc, [paramName]: action.payload[paramName] };
      }, {});

      return this.router.navigate([], {
        queryParams: query,
        queryParamsHandling: 'merge'
      });
    })
  ), { dispatch: false });

  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.Back),
    tap(() => this.location.back())
  ), { dispatch: false });

  
  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.Forward),
    tap(() => this.location.forward())
  ), { dispatch: false });

  
  navigateClear$ = createEffect(() => this.actions$.pipe(
    ofType(fromRouterActions.RouterActionTypes.Clear),
    tap(() => this.router.navigate([], {
      queryParams: {},
      queryParamsHandling: ''
    }))
  ), { dispatch: false });
}
