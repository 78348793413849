import { Injectable } from '@angular/core';
import { CookieProviderService } from '@appCore/services/cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthTokenService {
  constructor(private cookieService: CookieProviderService) {}

  public checkJwtAuthToken(): void {
    const JWTOnSession = this.cookieService.get('JWT-AUTH-TOKEN');
    if (!JWTOnSession) {
      this.insertJwtAuthToken();
    }
  }

  private insertJwtAuthToken(): void {
    const JWT = prompt(
      'Введите корректный JWT-AUTH-TOKEN с тестового стенда (строка 841 символ)? Для простой аторизации необходимо запустить скрипт "start"'
    );

    const isJWTCorrect = JWT?.length;

    if (!isJWTCorrect) {
      this.insertJwtAuthToken();
      return;
    }

    document.cookie = `JWT-AUTH-TOKEN=${JWT}`;
  }
}
