import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { PopupClose, PopupOpen } from '@appCore/store/popup/popup.actions';
import { RouteOpenSertificateList } from '@appDocuments/documents-route/store/route-send-to-approvement/route-send-to-approvement.actions';
import { InputKey, InputKeyCode } from '@libs/projects/component-lib/src/public-api';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent extends Unsubscriber implements OnInit, OnDestroy {
  /** сигнал на закрытие */
  @Output()
  onCloseEmit = new EventEmitter();

  /** тип внутреннего интерфейса */
  @Input()
  isSimpleInnerInterface: boolean;

  /** тип внутреннего интерфейса (короткий попап) */
  @Input()
  isShortInnerInterface: boolean;

  @Input()
  isLowInnerInterface: boolean;

  @Input()
  isAlertPopup: boolean;

  @Input()
  isDeadlinePopup: boolean;

  @Input()
  disableScroll: boolean;

  @Input()
  withoutPadding = false;

  @Input()
  isAutoWidth: boolean;

  @Input()
  notebookStyles: boolean;

  @Input()
  withScroll: boolean;

  @Input()
  isRelativeDocumentPopup: boolean;

  @Input()
  isGlbErrorPopup: boolean;

  constructor(private redux: ReduxService) {
    super();
  }

  /** Закрываем поп-ап по нажатию на 'Escape' */
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === InputKey.Escape) {
      this.onCloseEmit.emit();
    }
  }

  ngOnInit(): void {
    this.redux.dispatchAction(new PopupOpen());
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.redux.dispatchAction(new PopupClose());
    this.redux.dispatchAction(new RouteOpenSertificateList(false));
  }

  /** закрыть enter'ом окно при фокусе на крестике */
  public onBtnCloseKeyDown(event): void {
    const keyCode = event.keyCode ? event.keyCode : event.which;

    if (keyCode === InputKeyCode.enter) {
      this.onCloseEmit.emit();
    }
  }
}
