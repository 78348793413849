import { Action } from '@ngrx/store';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';

export enum NomenclaturesActionTypes {
  Get = '[Nomenclatures] Get',
  GetSuccess = '[Nomenclatures] GetSuccess',
  GetError = '[Nomenclatures] GetError',
}

export class NomenclaturesGet implements Action {
  readonly type = NomenclaturesActionTypes.Get;
}

export class NomenclaturesGetError implements Action {
  readonly type = NomenclaturesActionTypes.GetError;
}

export class NomenclaturesGetSuccess implements Action {
  readonly type = NomenclaturesActionTypes.GetSuccess;
  constructor(public payload: NomenclatureModel[]) {}
}

export type NomenclaturesAction = NomenclaturesGet | NomenclaturesGetError | NomenclaturesGetSuccess;
