import { DictionaryModel } from '@models/base/dictionary.model';
import {
  AgreementRoutePhasesAction,
  AgreementRoutePhasesActions,
} from '@appShared/store/dictionaries/agreement-route-phases/agreement-route-phases.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DictionaryStoreMapModel } from '@models/dictionary-store-map.model';

export const initialState = new DictionaryStoreMapModel<DictionaryModel>([], null, null, null);

export function agreementRoutePhasesReducer(
  state: DictionaryStoreMapModel<DictionaryModel> = initialState,
  action: AgreementRoutePhasesAction
): DictionaryStoreMapModel<DictionaryModel> {
  switch (action.type) {
    case AgreementRoutePhasesActions.Reset:
      return initialState;

    case AgreementRoutePhasesActions.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case AgreementRoutePhasesActions.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
        dictionaryMap: new Map(action.payload.map((v) => [v.id, v])),
      };
    case AgreementRoutePhasesActions.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}
