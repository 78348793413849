import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { PopupClose, PopupOpen } from '@appCore/store/popup/popup.actions';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {
  saveButtonDisabled: boolean;
  @Input()
  text: string;
  @Input()
  toggleConfirmationPopup: boolean;
  @Input()
  successBtnLabel = 'Да';
  @Input()
  isSmall: boolean;
  @Output()
  toggleConfirmationPopupChange: EventEmitter<boolean> = new EventEmitter();
  @Output()
  successCallback: EventEmitter<null> = new EventEmitter();
  @Output()
  cancelCallback: EventEmitter<null> = new EventEmitter();

  constructor(private redux: ReduxService) {}

  ngOnInit() {
    this.redux.dispatchAction(new PopupOpen());
  }

  ngOnDestroy() {
    this.redux.dispatchAction(new PopupClose());
  }

  public closePopup(): void {
    this.toggleConfirmationPopup = false;
    this.toggleConfirmationPopupChange.emit(this.toggleConfirmationPopup);
  }

  public confirmAction(): void {
    this.saveButtonDisabled = true;
    this.successCallback.emit();
    this.closePopup();
  }

  public cancelAction(): void {
    this.cancelCallback.emit();
    this.closePopup();
  }
}
