import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-loader',
  templateUrl: './small-loader.component.html',
  styleUrls: ['./small-loader.component.scss']
})
export class SmallLoaderComponent {
  @Input()
  isBlackTheme: boolean;

  @Input()
  hasParentSize: boolean;

  @Input()
  color: string;
}
