import { NotificationCounterListModel } from '@appMain/notifications/models/notification-counter-list.model';
import { NotificationsResponseModel } from '@models/notifications/notifications.response/notifications.response.model';
import { Action } from '@ngrx/store';

export enum NotificationsActionTypes {
  Load = '[Notifications] Load',
  LoadSuccess = '[Notifications] LoadSuccess',
  LoadError = '[Notifications] LoadError',
  Viewed = '[Notifications] Viewed',
  ViewedError = '[Notifications] ViewedError',
  MarkAsViewed = '[Notifications] MarkAsViewed',
  DeleteSuccess = '[Notifications] DeleteSuccess',
  GetCount = '[Notifications] GetCount',
  GetCountSuccess = '[Notifications] GetCountSuccess',
  GetCountError = '[Notifications] GetCountError',
  GetCounterData = '[Notifications] GetCounterData',
  GetCounterDataSuccess = '[Notifications] GetCounterDataSuccess',
  GetCounterDataError = '[Notifications] GetCounterDataError',
  ToggleArchiveState = '[Notifications] ToggleArchiveState',
  ToggleArchiveStateSuccess = '[Notifications] ToggleArchiveStateSuccess',
  ToggleArchiveStateError = '[Notifications] ToggleArchiveStateError',
  LoadLazy = '[Notifications] LoadLazy',
  LoadLazySuccess = '[Notifications] LoadLazySuccess',
  LoadLazyError = '[Notifications] LoadLazyError',
  ChangeSorting = '[Notifications] ChangeSorting'
}

export class NotificationsGetCount implements Action {
  readonly type = NotificationsActionTypes.GetCount;
}

export class NotificationsGetCountSuccess implements Action {
  readonly type = NotificationsActionTypes.GetCountSuccess;
  constructor(public payload: number) {}
}

export class NotificationsGetCountError implements Action {
  readonly type = NotificationsActionTypes.GetCountError;
  constructor(public error: any) {}
}

export class NotificationsGetCounterData implements Action {
  readonly type = NotificationsActionTypes.GetCounterData;
}

export class NotificationsGetCounterDataSuccess implements Action {
  readonly type = NotificationsActionTypes.GetCounterDataSuccess;
  constructor(public payload: NotificationCounterListModel) {}
}

export class NotificationsGetCounterDataError implements Action {
  readonly type = NotificationsActionTypes.GetCounterDataError;
  constructor(public error: any) {}
}

export class NotificationsActionsLoad implements Action {
  readonly type = NotificationsActionTypes.Load;
}

export class NotificationsActionsLoadSuccess implements Action {
  readonly type = NotificationsActionTypes.LoadSuccess;
  constructor(public notifications: NotificationsResponseModel) {}
}

export class NotificationsActionsLoadError implements Action {
  readonly type = NotificationsActionTypes.LoadError;
  constructor(public error: any) {}
}

export class NotificationsActionsViewed implements Action {
  readonly type = NotificationsActionTypes.Viewed;
  constructor(public payload: { id: number; viewed: boolean; needToDeleteNotification: boolean }) {}
}

export class NotificationsActionsViewedError implements Action {
  readonly type = NotificationsActionTypes.ViewedError;
  constructor(public error: any) {}
}

export class NotificationsActionsMarkAsViewed implements Action {
  readonly type = NotificationsActionTypes.MarkAsViewed;
  constructor(public payload: { id: number; viewed: boolean }) {}
}

export class NotificationsActionsDeleteSuccess implements Action {
  readonly type = NotificationsActionTypes.DeleteSuccess;
  /**
   *
   * @param payload id уведомления
   */
  constructor(public payload: number) {}
}

export class NotificationsActionsToggleArchiveState implements Action {
  readonly type = NotificationsActionTypes.ToggleArchiveState;
  constructor(public payload: { items: number[]; type: boolean }) {}
}

export class NotificationsActionsToggleArchiveStateSuccess implements Action {
  readonly type = NotificationsActionTypes.ToggleArchiveStateSuccess;
}

export class NotificationsActionsToggleArchiveStateError implements Action {
  readonly type = NotificationsActionTypes.ToggleArchiveStateError;
  constructor(public payload: any) {}
}

export class NotificationsActionsLoadLazy implements Action {
  readonly type = NotificationsActionTypes.LoadLazy;
}

export class NotificationsActionsLoadLazySuccess implements Action {
  readonly type = NotificationsActionTypes.LoadLazySuccess;
  constructor(public notifications: NotificationsResponseModel, public currentPage: number) {}
}

export class NotificationsActionsLoadLazyError implements Action {
  readonly type = NotificationsActionTypes.LoadLazyError;
  constructor(public error: any) {}
}

export class NotificationsActionsChangeSorting implements Action {
  readonly type = NotificationsActionTypes.ChangeSorting;
  constructor(
    public payload: {
      fieldName: string;
      order: string;
    }
  ) {}
}

export type NotificationsActions =
  | NotificationsActionsLoad
  | NotificationsActionsLoadSuccess
  | NotificationsActionsLoadError
  | NotificationsActionsViewed
  | NotificationsActionsViewedError
  | NotificationsActionsMarkAsViewed
  | NotificationsGetCount
  | NotificationsGetCountSuccess
  | NotificationsGetCountError
  | NotificationsGetCounterData
  | NotificationsGetCounterDataSuccess
  | NotificationsActionsDeleteSuccess
  | NotificationsGetCounterDataError
  | NotificationsActionsToggleArchiveState
  | NotificationsActionsToggleArchiveStateSuccess
  | NotificationsActionsToggleArchiveStateError
  | NotificationsActionsLoadLazy
  | NotificationsActionsLoadLazySuccess
  | NotificationsActionsLoadLazyError
  | NotificationsActionsChangeSorting;
