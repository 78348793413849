import { Pipe, PipeTransform } from '@angular/core';
import { calculateTotal } from '@appShared/helpers/calculate-total';

@Pipe({
  name: 'calculateTotal',
})
export class CalculateTotalPipe implements PipeTransform {
  transform(value: object[], key?: string): unknown {
    return calculateTotal(value, key);
  }
}
