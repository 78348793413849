import { Injectable } from '@angular/core';
import { MapTypeWithClassNameModel, ToastModel } from '@appCore/models/toast.model';
import { getActiveToastsCount } from '@appCore/store/toasts';
import { AddToast, RemoveToast } from '@appCore/store/toasts/toasts.actions';
import { NotificationType } from '@appMain/notifications/enums/notification-type.enum';
import moment from 'moment';
import { ReduxService } from '../redux/redux.service';

/**
 * Сервис для работы с тостами (Всплывающими уведомлениями)
 */
@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  private MAX_TOASTS_COUNT = 3;
  private TOAST_LIFETIME = 10000;
  private currentToastsCount = 0;
  private lastId = 0;
  private removeTimeouts = [];

  constructor(private redux: ReduxService) {
    redux.selectStore(getActiveToastsCount).subscribe((count) => (this.currentToastsCount = count));
  }

  /**
   * Показывает новый тост
   *
   * @param toast информация о тосте
   */
  public showToast(toast: ToastModel): void {
    this.addNewToast(toast);
  }

  /**
   * Показывает новый тост с заданным текстом без заголовка и времени
   *
   * @param text текст для отображения
   */
  public showText(text: string): void {
    this.addNewToast({
      text,
      header: '',
      date: moment().format(),
      projectTypeShortName: '',
      author: '',
      notificationId: null,
      isError: false,
      notificationType: null,
      projectGroupType: null,
      projectTypeDesignation: null,
      theme: null,
      accountNumber: null,
      documentPackageId: null,
      folderId: null,
      deadlineExtensionRequestId: null,
      agendaPackageId: null,
      placeholdersOrder: new Map(),
    });
  }

  /**
   * Показывает новый тост с ошибкой
   *
   * @param text текст для отображения
   */
  public showError(text: string): void {
    this.addNewToast({
      text,
      header: '',
      date: moment().format(),
      projectTypeShortName: '',
      author: '',
      notificationId: null,
      isError: true,
      notificationType: null,
      projectGroupType: null,
      projectTypeDesignation: null,
      theme: null,
      accountNumber: null,
      documentPackageId: null,
      folderId: null,
      deadlineExtensionRequestId: null,
      agendaPackageId: null,
      placeholdersOrder: new Map(),
    });
  }

  /**
   * Удаляет тост
   *
   * @param toast информация о тосте
   */
  public remove(toast: ToastModel): void {
    this.removeById(toast.id);
  }

  /** выдать стилевой класс по каждый тип нотификатора */
  public getStyleClassForType(typeId: NotificationType | undefined): string {
    const mapTypeWithClassName: MapTypeWithClassNameModel[] = [
      {
        className: 'red',
        notificationType: [NotificationType.extensionRequest],
      },
      {
        className: 'gray',
        notificationType: [NotificationType.recall],
      },
    ];

    const foundСlass = mapTypeWithClassName.find((map) => map.notificationType.includes(typeId));
    return foundСlass ? foundСlass.className : 'default';
  }

  /**
   * Функция для добавления нового тоста в стор.
   * Перед добавлением проставляет id тоста и проверяет их количество
   *
   * @param toast тост для добавления
   */
  private addNewToast(toast: ToastModel): void {
    // зацикливаем id
    this.lastId = (this.lastId + 1) % this.MAX_TOASTS_COUNT;
    // проставляем id новому тосту
    toast.id = this.lastId;

    // если больше тостов добавлять нельзя надо удалить самый старый тост перед добавлением
    if (this.currentToastsCount === this.MAX_TOASTS_COUNT) {
      this.removeById(this.lastId);
    }

    this.redux.dispatchAction(new AddToast(toast));

    // Таймаут надо созранить для отмены его у случае удления тоста по иным причинам
    this.removeTimeouts[this.lastId] = setTimeout(() => {
      this.remove(toast);
    }, this.TOAST_LIFETIME);
  }

  /**
   * Функция для удаления тоста из стора
   */
  private removeById(id: number): void {
    this.redux.dispatchAction(new RemoveToast(id));
    clearTimeout(this.removeTimeouts[id]);
  }
}
