import { Injectable } from '@angular/core';
import { NotificationsFilterFormValueModel } from '@appMain/notifications/models/notification-filters-form-value.model';
import { NotificationsQueryParamsModel } from '@appMain/notifications/models/notification-query-params.model';
import { PaginationDefaultData } from '@models/enums/pagination-default-data.enum';
import { NotificationFiltersDataModel } from '@models/notifications/notification-filters-data.model';
import { NotificationsFilterDataRequestModel } from '@models/notifications/notifications.request/notification-filters-data.request.model';

@Injectable()
export class NotificationsMapperService {
  /** Мапим данные формы фильтров в значения для фильтрации */
  public static mapFilterFormValueToQueryData(
    formValue: NotificationsFilterFormValueModel
  ): NotificationFiltersDataModel {
    const result = {
      fromDate: formValue.dateBegin,
      toDate: formValue.dateEnd,
      initiatorId: formValue.author ? formValue.author.id : null,
      text: formValue.text,
      documentPackageNumber: formValue.packageNumber,
      documentPackageId: formValue.documentPackage ? formValue.documentPackage.id : null,
    };

    Object.keys(result).forEach((key) => !result[key] && delete result[key]);

    return result;
  }

  /** Мапим квери параметры в данные для загрузки уведомлений */
  public static mapQueryParamsToFilterLoadData(
    params: NotificationsQueryParamsModel
  ): NotificationsFilterDataRequestModel {
    const { widget, ...filterData } = params;

    filterData.pageSize = PaginationDefaultData.pageSize;

    return { counterType: widget, ...filterData };
  }

  /** Мапим квери параметры в данные для заполнения формы фильтров */
  public static mapQueryParamsToFilerFormData(
    params: NotificationsQueryParamsModel
  ): NotificationsFilterFormValueModel {
    const result = {
      dateBegin: params.fromDate || null,
      dateEnd: params.toDate || null,
      text: params.text || null,
      packageNumber: params.documentPackageNumber || null,
    };

    return result;
  }
}
