import { Action } from '@ngrx/store';
import { CalendarHolidayModel } from '../../models/holiday.model';

export enum HolidaysActionTypes {
  Get = '[Holidays] Get',
  Load = '[Holidays] Load',
  GetSuccess = '[Holidays] GetSuccess',
  Reset = '[Holidays] Reset',
}

/** запросить список праздников/выходных
 * @param payload - год списка
 */
export class HolidaysGet implements Action {
  readonly type = HolidaysActionTypes.Get;
  constructor(public payload: number) {}
}

/** начать подгрузку праздников */
export class HolidaysLoad implements Action {
  readonly type = HolidaysActionTypes.Load;
}

/** получить список праздников/выходных  */
export class HolidaysGetSuccess implements Action {
  readonly type = HolidaysActionTypes.GetSuccess;
  constructor(public payload: CalendarHolidayModel[]) {}
}

/** сбросить список праздников/выходных */
export class HolidaysReset implements Action {
  readonly type = HolidaysActionTypes.Reset;
}

export type HolidaysActions = HolidaysGet | HolidaysLoad | HolidaysGetSuccess | HolidaysReset;
