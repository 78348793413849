import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DictionaryModel } from '@models/base/dictionary.model';
import { OibRoles } from '@models/enums/oib-roles.enum';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';

export const accessDocumentPackageByType =
  (projectGroupType: DictionaryModel, governmentRoleCheck: boolean, mustRequireEmployee = false): ValidatorFn =>
  (control: UntypedFormControl): ValidationErrors | null => {
    const employee = control.value;

    if (!employee && mustRequireEmployee) {
      return { required: true };
    }

    if ((!projectGroupType && !governmentRoleCheck) || (!employee && !mustRequireEmployee)) {
      return null;
    }

    const isAdmin = employee?.roles?.includes(OibRoles.npaAdmin);
    if (isAdmin) {
      return null;
    }

    const rolesMap = {
      [ProjectGroupType.LEGAL_ACTS]: OibRoles.governmentUser,
      [ProjectGroupType.LOCAL_LEGAL_ACTS]: OibRoles.internalUser,
    };
    /** TODO при включенном боковом меню = "Все проекты", возможно предоставить доступ только пользователю с обеими ролями. /временное решение/ */
    const isAllProjectGroup =
      employee?.roles?.includes(rolesMap[ProjectGroupType.LEGAL_ACTS]) &&
      employee?.roles?.includes(rolesMap[ProjectGroupType.LOCAL_LEGAL_ACTS]);

    const isEmployeeGovernmentUser = employee?.roles?.includes(OibRoles.governmentUser);
    const error = { hasNotRole: 'Указанный пользователь не работает с данным типом ПД. Выберите другого пользователя' };

    if (governmentRoleCheck) {
      return !isEmployeeGovernmentUser ? error : null;
    } else {
      return !employee?.roles?.includes(rolesMap[projectGroupType.id]) && !isAllProjectGroup ? error : null;
    }
  };
