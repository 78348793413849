import { FolderModel } from '@models/folder/folder.model';
import { createAction, props } from '@ngrx/store';

export enum PersonalFoldersAccessTypes {
  GetAllAccessFolders = '[Personal Folders Access] GetAllAccessFolders',
  GetAllAccessFoldersSuccess = '[Personal Folders Access] GetAllAccessFoldersSuccess',
  GetAllAccessFoldersError = '[Personal Folders Access] GetAllAccessFoldersError',

  GetAccessFoldersById = '[Personal Folders Access] GetAccessFoldersById',
  GetAccessFoldersByIdSuccess = '[Personal Folders Access] GetAccessFoldersByIdSuccess',
  GetAccessFoldersByIdError = '[Personal Folders Access] GetAccessFoldersByIdError',

  PutAccessFoldersById = '[Personal Folders Access] PutAccessFoldersById ',
  PutAccessFoldersByIdSuccess = '[Personal Folders Access] PutAccessFoldersByIdSuccess',
  PutAccessFoldersByIdError = '[Personal Folders Access] PutAccessFoldersByIdError',
}

/** Список всех доступных папок для текущего пользователя */
export const getAvailableFolders = createAction(PersonalFoldersAccessTypes.GetAllAccessFolders);
export const getAvailableFoldersSuccess = createAction(
  PersonalFoldersAccessTypes.GetAllAccessFoldersSuccess,
  props<{ availableFoldersList: FolderModel[] }>()
);
export const getAvailableFoldersError = createAction(PersonalFoldersAccessTypes.GetAllAccessFoldersError);

export const PersonalFoldersAccessActions = {
  getAvailableFolders,
  getAvailableFoldersSuccess,
  getAvailableFoldersError,
};
