import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { DomEventService } from '@appShared/services/dom-event.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ControlBaseComponent } from '../control-base/control-base.component';

/**
 * Компонент мультивыбора
 */
@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent
  extends ControlBaseComponent
  implements OnDestroy, OnInit, ControlValueAccessor, AfterViewInit
{
  /**Список элементов выпадающего списка */
  public optionsList: Object[] = [];
  @Input()
  isNeedPreSelectAllOptions = false;

  /** Статично показать список чекбоксов */
  @Input()
  canShowStaticList = false;

  @Input()
  showClearButton: boolean;
  @Input('optionsList')
  set setOptionsList(optionsList: Object[]) {
    if (optionsList && Array.isArray(optionsList)) {
      this.optionsList = optionsList;
      this.mapOptionsObjectsToOptionsFrom();
      if (this.isNeedPreSelectAllOptions) {
        this.checkAllControl.setValue(true);
      }
    }
  }
  /** список выделеных элементов */
  public checkedOptions: Object[];
  /** Параметр для просмотра опции */
  @Input()
  optionLabel: string;
  /** надпись для "Выделить всё" */
  @Input()
  labelCheckAll: string;
  /** флаг, включающий отображение выбраных названий */
  @Input()
  isShowSelectedLabels: boolean;
  /** Макс. высота списка */
  @Input()
  maxHeight: number;
  /** класс темной темы */
  @HostBinding('class.black-theme')
  isBlackTheme;

  /** массив контролов для чекбоксов */
  public formOptions: UntypedFormArray = new UntypedFormArray([]);
  /** отключение компоненты */
  public disabled: boolean;
  /** флаг активности мапинга отметок */
  public isClosed$ = new BehaviorSubject(true);
  /** надпись о выбранном количестве */
  public labelOfCountChecked$ = new BehaviorSubject('');
  /** контрол для Выделить все */
  public checkAllControl: UntypedFormControl = new UntypedFormControl(false);

  public subscr: Subscription = new Subscription();
  /** список названий выбранных значений */
  public selectedLabelList: Object[] = [];
  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (valueList: Object[]) => {};
  public onTouched = () => {};

  constructor(private domEventService: DomEventService, private elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.checkAllControl.updateValueAndValidity();
    this.subscribeToCheckAll();
    if (this.isNeedPreSelectAllOptions) {
      this.checkAllControl.setValue(true);
    }
  }

  ngAfterViewInit(): void {
    this.closeListBoxWhenClickedOutside();
    if (this.canShowStaticList) {
      this.isClosed$.next(false);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.subscr.unsubscribe();
  }

  /** поймать передаваемое значение через NG_VALUE_ACCESSOR */
  public writeValue(checkedOptions: Object[]): void {
    if (!Array.isArray(checkedOptions)) {
      checkedOptions = [];
    }
    const optionsIfTransmittingDataIsEmpty =
      this.checkedOptions?.length && !checkedOptions?.length ? this.checkedOptions : checkedOptions;
    this.checkedOptions = optionsIfTransmittingDataIsEmpty;

    if (this.formOptions.length) {
      this.checkOptions();
    }
  }

  /** определить функцию onChange из NG_VALUE_ACCESSOR */
  public registerOnChange(fn: (valueList: Object[]) => void): void {
    this.onChange = fn;
  }

  /** определить функцию onTouched из NG_VALUE_ACCESSOR */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** поймать отключение формы через NG_VALUE_ACCESSOR */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** событие клика по компоненте */
  public onClick(): void {
    this.isClosed$.next(this.canShowStaticList ? false : !this.isClosed$.value);
    this.onTouched();
  }

  /** отметить все, когда активен Выбрать все */
  public subscribeToCheckAll(): void {
    this.checkAllControl.valueChanges.subscribe((checkAll) => {
      if (!this.labelCheckAll) {
        return;
      }
      if (checkAll) {
        this.checkedOptions = this.optionsList;
      } else {
        this.checkedOptions = [];
      }

      this.checkOptions();
    });
  }

  /** убрать в инпуте выделенное название */
  public removeLabelInInput(label: Object): void {
    const indexLabel = this.optionsList.indexOf(label);
    this.formOptions.controls[indexLabel].setValue(false);
  }

  public deselectAll(): void {
    this.checkAllControl.setValue(false);
  }

  public isControlEmpty(): boolean {
    return !this.formOptions.value.some(Boolean);
  }

  /** мапинг списка объектов в список контролов */
  protected mapOptionsObjectsToOptionsFrom() {
    this.subscr.unsubscribe();
    this.formOptions = new UntypedFormArray(this.optionsList.map((o) => new UntypedFormControl(false)));
    this.subscribeToChecked();

    if (this.checkedOptions) {
      this.checkOptions();
    }
  }

  /** отметить опции списка */
  protected async checkOptions() {
    const formValue = this.optionsList.map((option, i) =>
      this.checkedOptions.some((checkedOption) => JSON.stringify(checkedOption) === JSON.stringify(option))
    );
    await Promise.resolve();
    this.formOptions.setValue(formValue);
  }

  /** подписаться на чеки аользователя */
  protected subscribeToChecked(): void {
    this.subscr = this.formOptions.valueChanges
      .pipe(filter((values: Object[]) => values.length === this.optionsList.length))
      .subscribe((isCheckedvalues: boolean[]) => {
        const controlValue = [];
        this.selectedLabelList = [];
        this.optionsList.forEach((option, i) => {
          if (isCheckedvalues[i]) {
            controlValue.push(option);
            this.selectedLabelList.push(option);
          }
        });

        this.onChange(controlValue);
        this.setLabelOfCountChecked(controlValue.length);
        this.setCheckAll(controlValue.length);
      });
  }

  /** отметить чекбокс Выбрать все */
  protected setCheckAll(countChecked: number): void {
    if (!this.labelCheckAll) {
      return;
    }

    if (this.optionsList.length === countChecked) {
      this.checkAllControl.setValue(true, {
        emitEvent: false,
      });
    } else {
      this.checkAllControl.setValue(false, {
        emitEvent: false,
      });
    }
  }

  /** сфромировать надпись о выбранном количестве */
  protected setLabelOfCountChecked(count: number): void {
    if (!count) {
      this.labelOfCountChecked$.next('');
    } else {
      this.labelOfCountChecked$.next(`Выбрано: ${count}`);
    }
  }

  /** закрывать селект при клике вне его области */
  protected closeListBoxWhenClickedOutside(): void {
    this.domEventService
      .confirmOwnershipOfClick(this.elementRef.nativeElement)
      .pipe(
        filter((isOwnClick) => !isOwnClick && !this.isClosed$.value),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => this.onClick());
  }
}
