import { CoordinatorsConnectionType } from '@appDocuments/documents-route/enums/coordinators-connection-type.enum';
import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { RoutePopupTypes } from '@appDocuments/documents-route/enums/route-popup-types.enum';
import { AddToTreeParamsModel } from '@appDocuments/documents-route/models/add-to-tree-params.model';
import { ChangeEmployeePopupParamsModel } from '@appDocuments/documents-route/models/change-employee-popup-params-model';
import { Action } from '@ngrx/store';

export enum DocumentRoutePageActionTypes {
  PopupType = '[Document Route Page] PopupType',
  PopupOpen = '[Document Route Page] PopupOpen',
  ChangeEmployeeActiveType = '[Document Route Page] ChangeEmployeeActiveType',
  ChangeAvailableAddTypes = '[Document Route Page] ChangeAvailableAddTypes',
  UpdateConnectionType = '[Document Route Page] UpdateConnectionType',
  AddToTreeParams = '[Document Route Page] AddToTreeParams',
  SetChangeEmployeePopup = '[Document Route Page] SetChangeEmployeePopup',
  EditRoute = '[Document Route Page] EditRoute',
  EditPhaseIds = '[Document Route Page] EditPhaseIds',
  CloseDssPopup = '[Document Route Page] closeDssPopup',
  SetOutstandingTaskFlag = '[Document Route Page] SetOutstandingTaskFlag',
  SetOutstandingTaskFlagPassed = '[Document Route Page] SetOutstandingTaskFlagPassed',
  ScrollToActivePoint = '[Document Route Page] ScrollToActivePoint',
  EditRouteReset = '[Document Route Page] EditRouteReset',
}

/** Тип попапа */
export class RoutePopupType implements Action {
  static startOuterDevelopment: RoutePopupTypes;
  readonly type = DocumentRoutePageActionTypes.PopupType;

  constructor(public payload: RoutePopupTypes) {}
}

export class RoutePopupOpen implements Action {
  readonly type = DocumentRoutePageActionTypes.PopupOpen;

  constructor(public payload: RoutePopupTypes) {}
}

export class ChangeEmployeeActiveType implements Action {
  readonly type = DocumentRoutePageActionTypes.ChangeEmployeeActiveType;

  constructor(public payload: EmployeeRouteTypes) {}
}

export class ChangeAvailableAddTypes implements Action {
  readonly type = DocumentRoutePageActionTypes.ChangeAvailableAddTypes;

  constructor(public payload: EmployeeRouteTypes[]) {}
}

export class UpdateConnectionType implements Action {
  readonly type = DocumentRoutePageActionTypes.UpdateConnectionType;

  constructor(public payload: CoordinatorsConnectionType) {}
}

export class AddToTreeParams implements Action {
  readonly type = DocumentRoutePageActionTypes.AddToTreeParams;

  constructor(public payload: AddToTreeParamsModel) {}
}

export class ChangeEmployeeForPoint implements Action {
  readonly type = DocumentRoutePageActionTypes.SetChangeEmployeePopup;

  constructor(public payload: ChangeEmployeePopupParamsModel) {}
}

export class EditRoute implements Action {
  readonly type = DocumentRoutePageActionTypes.EditRoute;

  constructor(public payload: boolean) {}
}

export class EditPhaseIds implements Action {
  readonly type = DocumentRoutePageActionTypes.EditPhaseIds;

  constructor(public payload: number[]) {}
}

export class CloseDssPopup implements Action {
  readonly type = DocumentRoutePageActionTypes.CloseDssPopup;
}

export class SetOutstandingTaskFlag implements Action {
  readonly type = DocumentRoutePageActionTypes.SetOutstandingTaskFlag;
  constructor(public payload: boolean) {}
}

export class SetOutstandingTaskFlagPassed implements Action {
  readonly type = DocumentRoutePageActionTypes.SetOutstandingTaskFlagPassed;
}

export class ScrollToActivePoint implements Action {
  readonly type = DocumentRoutePageActionTypes.ScrollToActivePoint;
  constructor(public payload: boolean) {}
}

export class EditRouteReset implements Action {
  readonly type = DocumentRoutePageActionTypes.EditRouteReset;
}

export type DocumentPackageRouteActions =
  | RoutePopupType
  | RoutePopupOpen
  | ChangeEmployeeActiveType
  | ChangeAvailableAddTypes
  | UpdateConnectionType
  | AddToTreeParams
  | ChangeEmployeeForPoint
  | EditRoute
  | EditPhaseIds
  | CloseDssPopup
  | SetOutstandingTaskFlag
  | SetOutstandingTaskFlagPassed
  | ScrollToActivePoint
  | EditRouteReset;
