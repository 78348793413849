import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionLabelName'
})
export class OptionLabelNamePipe implements PipeTransform {

  transform(value: any, label: string | ((value: any) => string)): string {
    if (!value || !label) {
      return null;
    }
    return typeof label === 'string' ? value[label] || value.shortName : label;
  }

}
