import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { DocumentTypeModel } from '@models/document/document-type.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentTypesAvailableAction, DocumentTypesAvailableActionTypes } from './document-types-available.actions';

export const initialState = new DictionaryStoreModel<DocumentTypeModel>([], null, null);

export const documentTypesAvailableReducer = (
  state: DictionaryStoreModel<DocumentTypeModel> = initialState,
  action: DocumentTypesAvailableAction
): DictionaryStoreModel<DocumentTypeModel> => {
  switch (action.type) {
    case DocumentTypesAvailableActionTypes.Reset:
      return initialState;

    case DocumentTypesAvailableActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentTypesAvailableActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case DocumentTypesAvailableActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
};
