export enum PermissionType {
  documentPackage = 'DOCUMENT_PACKAGE',
  document = 'DOCUMENT',
  summaryAnalytics = 'SUMMARY_ANALYTICS_PANEL',
  sending = 'SENDING',
  journalRegistrationDocuments = 'JOURNAL_REGISTRATION_DOCUMENTS',
  dashboard = 'DASHBOARD',
  agendaPackage = 'AGENDA_PACKAGE',
  agendaPackageDocument = 'AGENDA_PACKAGE_DOCUMENT',
  routePoint = 'ROUTE_POINT',
  reviewDateChange = 'REVIEW_DATE_CHANGE_REQUEST',
  calendar = 'CALENDAR',
  electronicArchive = 'ELECTRONIC_ARCHIVE',
  registrationNumber = 'REGISTRATION_NUMBER',
}
