import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  DelegatingActionTypes,
  DelegatingActionsLoad,
  DelegatingActionsLoadSuccess,
  DelegatingActionsLoadError,
  DelegatingActionsCreate,
  DelegatingActionsCreateSuccess,
  DelegatingActionsCreateError,
  DelegatingActionsEdit,
  DelegatingActionsEditSuccess,
  DelegatingActionsEditError,
  DelegatingActionsDelete,
  DelegatingActionsDeleteSuccess,
  DelegatingActionsDeleteError
} from '@appCore/store/delegating/delegating.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { DelegatingApiService } from '@appShared/api/delegating/delegating.api.service';

@Injectable()
export class DelegatingEffects {
  constructor(private actions$: Actions, private delegatingApi: DelegatingApiService) {}

  
  delegatingLoad$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DelegatingActionTypes.Load),
    switchMap((action: DelegatingActionsLoad) =>
      this.delegatingApi.getFrom().pipe(
        map(response => new DelegatingActionsLoadSuccess(response)),
        catchError(error => of(new DelegatingActionsLoadError(error)))
      )
    )
  ));

  
  delegatingCreate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DelegatingActionTypes.Create),
    switchMap((action: DelegatingActionsCreate) =>
      this.delegatingApi.create(action.payload).pipe(
        map(response => new DelegatingActionsCreateSuccess(response)),
        catchError(error => of(new DelegatingActionsCreateError(error)))
      )
    )
  ));

  
  delegatingEdit$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DelegatingActionTypes.Edit),
    switchMap((action: DelegatingActionsEdit) =>
      this.delegatingApi.update(action.payload).pipe(
        map(response => new DelegatingActionsEditSuccess(response)),
        catchError(error => of(new DelegatingActionsEditError(error)))
      )
    )
  ));

  
  delegatingDelete$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(DelegatingActionTypes.Delete),
    switchMap((action: DelegatingActionsDelete) =>
      this.delegatingApi.delete(action.payload).pipe(
        map(response => new DelegatingActionsDeleteSuccess(action.payload)),
        catchError(error => of(new DelegatingActionsDeleteError(error)))
      )
    )
  ));
}
