import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { Action, createReducer, on } from '@ngrx/store';
import { PersonalFoldersStoreModel } from './models/personal-folders-store.model';
import { PersonalFoldersActions } from './personal-folders.actions';

export const initialState: PersonalFoldersStoreModel = {
  personalFoldersList: [],
  foldersListStatus: null
};

const _personalFoldersReducer = createReducer(
  initialState,
  on(PersonalFoldersActions.getPersonalFolders, (state) => ({
    ...state,
    foldersListStatus: StoreLoadStatus.inProgress,
  })),
  on(PersonalFoldersActions.getPersonalFoldersSuccess, (state, { personalFoldersList }) => ({
    ...state,
    personalFoldersList,
    foldersListStatus: StoreLoadStatus.loaded,
  })),
  on(PersonalFoldersActions.getPersonalFoldersError, (state) => ({
    ...state,
    foldersListStatus: StoreLoadStatus.error,
  }))
);

// Note: The exported reducer function is necessary as function calls are not supported by the AOT compiler.
// https://ngrx.io/guide/store/reducers
export function personalFoldersReducer(state: PersonalFoldersStoreModel | undefined, action: Action) {
  return _personalFoldersReducer(state, action);
}
