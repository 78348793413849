import { Injectable } from '@angular/core';
import { PersonalFoldersAccessApiService } from '@appShared/api/personal-cabinet/personal-folders-access-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SharedFilter } from './models/shared-filter.model';
import { SharedFiltersActions } from './shared-filters.actions';

@Injectable()
export class SharedFiltersEffects {
  constructor(private actions$: Actions, private personalFoldersAccessApiService: PersonalFoldersAccessApiService) {}

  getAllFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedFiltersActions.getSharedFilters),
      switchMap(() =>
        this.personalFoldersAccessApiService.getSharedFilters().pipe(
          map((folders: SharedFilter[]) => SharedFiltersActions.getSharedFiltersSuccess({ sharedFilters: folders })),
          catchError((error) => of(SharedFiltersActions.getSharedFiltersError()))
        )
      )
    )
  );
}
