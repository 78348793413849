import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { TaskResolutionsActions, TaskResolutionsActionTypes } from './task-resolutions.actions';

export const initialState = new DictionaryStoreModel<DictionaryModel>();

/** обрабатываем экшены */
export function taskResolutionsReducer(
  state: DictionaryStoreModel<DictionaryModel> = initialState,
  action: TaskResolutionsActions
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case TaskResolutionsActionTypes.Reset:
      return initialState;

    case TaskResolutionsActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress
      };
    case TaskResolutionsActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded
      };

    case TaskResolutionsActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error
      };

    default:
      return state;
  }
}
