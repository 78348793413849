import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { Urls } from '@appCore/urls/urls';
import { getDocumentPackageId } from '@appDocuments/core/store/document-package';
import { DocumentEditingParticipantResponseModel } from '@appShared/api/document-editing-participants/models/document-editing-participant-response.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DocumentEditingParticipantsService extends DefaultDataService<DocumentEditingParticipantResponseModel> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private redux: ReduxService) {
    super('Hero', http, httpUrlGenerator);
  }

  public getAll(): Observable<DocumentEditingParticipantResponseModel[]> {
    return this.redux.selectStore(getDocumentPackageId).pipe(
      first(),
      mergeMap(id => {
        const url = Urls.getDocumentsPackageEditingParticipants(id);
        return this.http.get<DocumentEditingParticipantResponseModel[]>(url).pipe(
          map(participants =>
            participants.map((p, i) => ({
              ...p,
              id: i
            }))
          )
        );
      })
    );
  }
}
