import {
  ConsiderationPMMeetingQuestionsTypesAction,
  ConsiderationPMMeetingQuestionsTypesActionTypes,
} from '@appShared/store/dictionaries/consideration-pm-meeting-questions-types/consideration-pm-meeting-questions-types.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryModel>([], null);

export function consideratonPMMeetingQuestionTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: ConsiderationPMMeetingQuestionsTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case ConsiderationPMMeetingQuestionsTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case ConsiderationPMMeetingQuestionsTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case ConsiderationPMMeetingQuestionsTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case ConsiderationPMMeetingQuestionsTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (consideratonPMMeetingQuestionTypes) => consideratonPMMeetingQuestionTypes.saveStatus;
/** Получение словаря */
export const getConsiderationPMMeetingQuestionsTypes = (consideratonPMMeetingQuestionTypes) =>
  consideratonPMMeetingQuestionTypes.dictionary;
