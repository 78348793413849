import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  FolderViewAccessTypes,
  FolderViewAccessTypesAction,
  FolderViewAccessTypesGetError,
  FolderViewAccessTypesGetSuccess,
} from '@appShared/store/dictionaries/folder/folder-view-access-types/folder-view-access-types.actions';
import { CACHE_TIME } from '@appCore/store/cache-time.const';

@Injectable()
export class FolderViewAccessTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentTypes$: Observable<FolderViewAccessTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(FolderViewAccessTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() => {
      return this.dictionaryApi.getFolderViewAccessTypes().pipe(
        map((response) => new FolderViewAccessTypesGetSuccess(response)),
        catchError((err) => of(new FolderViewAccessTypesGetError()))
      );
    })
  ));
}
