import {
  RouteTemplateActions,
  RouteTemplateActionTypes,
} from '@appDocuments/documents-route/store/route-template/route-template.actions';
import { AgreementRouteTemplateResponseModel } from '@appShared/api/route-template/models/route-template.response.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface RouteTemplateState extends EntityState<AgreementRouteTemplateResponseModel> {
  loadStatus: StoreLoadStatus;
}

export const routeTemplateEntityAdapter = createEntityAdapter<AgreementRouteTemplateResponseModel>();

const initialState: RouteTemplateState = routeTemplateEntityAdapter.getInitialState({
  loadStatus: null,
});

export function routeTemplaterReducer(state = initialState, action: RouteTemplateActions): RouteTemplateState {
  switch (action.type) {
    case RouteTemplateActionTypes.RouteTemplateReset:
      return initialState;

    case RouteTemplateActionTypes.RouteTemplateGet:
    case RouteTemplateActionTypes.RouteTemplateCreate:
    case RouteTemplateActionTypes.RouteTemplateCreateInitiation:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };

    case RouteTemplateActionTypes.RouteTemplateGetSuccess:
      return routeTemplateEntityAdapter.setAll(action.payload, { ...state, loadStatus: StoreLoadStatus.loaded });

    case RouteTemplateActionTypes.RouteTemplateCreateSuccess:
      return routeTemplateEntityAdapter.addOne(action.payload, { ...state, storeLoadStatus: StoreLoadStatus.loaded });

    default: {
      return state;
    }
  }
}
