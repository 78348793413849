import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserInfoAction, UserInfoActionTypes, UserInfoError, UserInfoGetSuccess } from './user-info.actions';

import { UserInfoApiService } from '@appShared/api/user-info/user-info.api.service';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';

@Injectable()
export class UserInfoEffects {
  constructor(
    private actions$: Actions,
    private userInfoApi: UserInfoApiService,
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker
  ) {}

  UserInfoEffects$: Observable<UserInfoAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserInfoActionTypes.GetUser),
      switchMap(() =>
        this.userInfoApi.getInfo().pipe(
          map((response) => new UserInfoGetSuccess(response)),
          catchError((err) => of(new UserInfoError(err)))
        )
      )
    )
  );

  UserInfoGetSuccessSetTrackingId$: Observable<UserInfoAction> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserInfoActionTypes.GetUserSuccess),
        switchMap((userInfo: UserInfoGetSuccess) => {
          if (environment.enableAnalytics) {
            const userId = userInfo.payload.currentUser.employee.login;
            this.matomoTracker.setUserId(userId);
            this.matomoInjector.init(environment.analyticsUrl, environment.analyticsSiteId);
          }
          return of(null);
        })
      ),
    { dispatch: false }
  );
}
