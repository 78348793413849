import {
  QuestionResolutionTypesAction,
  QuestionResolutionTypesActionTypes,
} from '@appShared/store/dictionaries/question-resolution-types/question-resolution-types.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryModel>([], null);

export function questionResolutionTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: QuestionResolutionTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case QuestionResolutionTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case QuestionResolutionTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case QuestionResolutionTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case QuestionResolutionTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (questionResolutionTypes) => questionResolutionTypes.saveStatus;
/** Получение словаря */
export const getQuestionResolutionTypes = (questionResolutionTypes) => questionResolutionTypes.dictionary;
