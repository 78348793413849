import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { CalendarHolidaysStoreModel } from '../../models/holiday.model';
import { HolidaysActions, HolidaysActionTypes } from './holidays.actions';

const DEFAULT_STATE = new CalendarHolidaysStoreModel();

export function holidaysReducer(
  state: CalendarHolidaysStoreModel = DEFAULT_STATE,
  action: HolidaysActions
): CalendarHolidaysStoreModel {
  switch (action.type) {
    case HolidaysActionTypes.Reset:
      return DEFAULT_STATE;

    case HolidaysActionTypes.Load:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case HolidaysActionTypes.GetSuccess:
      state.holidays = state.holidays.concat(action.payload);

      return {
        ...state,
        loadStatus: StoreLoadStatus.loaded,
      };

    case HolidaysActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;
  }
}
