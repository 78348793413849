import { Component, Input } from '@angular/core';
import { ControlBaseDirective } from '../control-base.directive';

/**
 * Компонент кастомного чекбокса
 */
@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['../control-base.directive.scss', './input-checkbox.component.scss'],
})
export class InputCheckboxComponent extends ControlBaseDirective {
  @Input()
  isWarning = false;
  @Input()
  isPartiallyChecked = false;
  @Input()
  canShowCheckBox = true;
  @Input()
  isInvalid = false;
  @Input()
  isDisabled = false;

  constructor() {
    super();
  }
}
