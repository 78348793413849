import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  DocumentStatusValuesActions,
  DocumentStatusValuesActionTypes,
  DocumentStatusValuesError,
  DocumentStatusValuesGetSuccess,
} from './document-status-values.actions';

@Injectable()
export class DocumentStatusValuesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentStatusValues$: Observable<DocumentStatusValuesActions> = createEffect(() => this.actions$.pipe(
    ofType(DocumentStatusValuesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getDocumentPackageStatusValues().pipe(
        map((response) => new DocumentStatusValuesGetSuccess(response)),
        catchError(() => of(new DocumentStatusValuesError()))
      )
    )
  ));
}
