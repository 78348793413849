import { Directive, ElementRef, OnInit } from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getRouteSendToApprovementPhasesOpenSertificatteList } from '@appDocuments/documents-route/store/route-send-to-approvement';
import { interval } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appScrollOffsetTop]',
})
export class ScrollOffsetTopDirective extends Unsubscriber implements OnInit {
  constructor(private el: ElementRef, private redux: ReduxService) {
    super();
  }

  ngOnInit(): void {
    this.redux
      .selectStore(getRouteSendToApprovementPhasesOpenSertificatteList)
      .pipe(
        filter((value) => value),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        this.scrollBottom();
      });
  }

  private scrollBottom(): void {
    const source = interval(100);
    source.pipe(first()).subscribe(() => (this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight));
  }
}
