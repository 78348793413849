import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ModalWindowRef } from '@appShared/modals-helper/modal-window-ref';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ModalWindowBaseComponent } from '@appShared/modals-helper/modal-window-base/modal-window-base.component';

@Injectable({
  providedIn: 'root',
})
export class ModalWindowService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  public open<R, T>(content: string | TemplateRef<any> | Type<any>, data: T): ModalWindowRef {
    const strategy = this.overlay.position().global().centerHorizontally().centerVertically();
    const configs = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'light-backdrop',
      positionStrategy: strategy,
    });

    const overlayRef = this.overlay.create(configs);
    const myOverlayRef = new ModalWindowRef<R, T>(overlayRef, content, data);
    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(ModalWindowBaseComponent, null, injector));

    return myOverlayRef;
  }

  private createInjector(ref: ModalWindowRef, inj: Injector): PortalInjector {
    const injectorTokens = new WeakMap([[ModalWindowRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }
}
