import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-middle-button',
  templateUrl: './middle-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './middle-button.component.scss'],
})
export class MiddleButtonComponent extends ButtonBaseComponent {
  @Input()
  isLoading = false;

  @Input()
  isWide: boolean;

  constructor() {
    super();
  }
}
