import { ChangeDetectionStrategy, Component, OnInit, Input, OnChanges } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { OrganizationModel } from '@models/base/organization.model';
import { Observable } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-delegating-position',
  templateUrl: './delegating-position.component.html',
  styleUrls: ['./delegating-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegatingPositionComponent implements OnInit, OnChanges {
  /** Контрол */
  @Input()
  control: UntypedFormControl;
  /** Использовать или нет темную тему */
  @Input()
  isBlackTheme: boolean;
  /** Плейсхолдер */
  @Input()
  placeholder: string;
  @Input()
  isRequired = true;
  public delegationPositions$: Observable<OrganizationModel[]>;
  public canShowPerfomanceDutiesForm = false;

  constructor(public dictionaryApi: DictionaryApiService) {}

  ngOnInit(): void {
    this.getDelegationPositions();
    if (this.control?.value) {
      this.canShowPerfomanceDutiesForm = true;
    }
  }

  ngOnChanges(): void {
    this.checkValidators();
  }

  public showForm(): void {
    this.canShowPerfomanceDutiesForm = !this.canShowPerfomanceDutiesForm;

    if (!this.canShowPerfomanceDutiesForm) {
      this.control.setValue(null);
    }
    this.checkValidators();
  }

  private checkValidators(): void {
    if (this.isRequired && this.canShowPerfomanceDutiesForm) {
      this.control.addValidators(Validators.required);
      this.control.updateValueAndValidity();
      return;
    }

    this.control.clearValidators();
    this.control.updateValueAndValidity();
  }

  private getDelegationPositions(): void {
    this.delegationPositions$ = this.dictionaryApi.getDelegationPositions(true);
  }
}
