import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  OrderTypesAction,
  OrderTypesActionTypes,
  OrderTypesGetError,
  OrderTypesGetSuccess,
} from './order-types.actions';

@Injectable()
export class OrderTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetOrderTypes$: Observable<OrderTypesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderTypesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() =>
        this.dictionaryApi.getAssignmentTypes().pipe(
          map((response) => new OrderTypesGetSuccess(response)),
          catchError((err) => of(new OrderTypesGetError()))
        )
      )
    )
  );
}
