import { TreePhaseModel } from '@appDocuments/documents-route/models/tree/tree-phase.model';
import { TreeStepModel } from '@appDocuments/documents-route/models/tree/tree-step.model';
import { TreeSubPhaseModel } from '@appDocuments/documents-route/models/tree/tree-sub-phase.model';
import { DocumentRouteModuleState, selectDocumentRouteModule } from '@appDocuments/documents-route/store';
import { DocumentRouteState } from '@appDocuments/documents-route/store/document-route/document-route.reducer';
import { PhaseDateModel } from '@appShared/api/documents-route/models/route-tree.response/phase-dates.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createSelector } from '@ngrx/store';

export const getDocumentRouteStore = createSelector(
  selectDocumentRouteModule,
  (store: DocumentRouteModuleState) => store.documentRoute
);

export const getDocumentRouteStoreData = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.documentRoute
);

export const getDocumentRouteTree = createSelector(getDocumentRouteStore, (state: DocumentRouteState) => state.tree);

export const getDocumentRoute = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.documentRoute
);

export const getDocumentRouteId = createSelector(getDocumentRouteStoreData, (state) => (state ? state.id : null));

export const getDocumentRouteStoreStatus = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.status
);

export const getDocumentRouteStoreVertexes = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.documentRoute?.vertexes
);

export const getDocumentRouteIsInProgressStatus = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.status === StoreLoadStatus.inProgress
);

export const getDocumentRouteStoreError = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.error
);

export const getAllRouteSubphases = createSelector(getDocumentRouteTree, (tree) => {
  if (!tree) {
    return [];
  }

  return tree.reduce((a: TreeSubPhaseModel[], c: TreePhaseModel) => a.concat(c.subPhases), []);
});

export const getAllControllers = createSelector(getDocumentRouteStoreData, (state) => state && state.controllers);

export const getControllerForVertex = (id: number) =>
  createSelector(getAllControllers, (controllers) =>
    controllers.find((controller) => controller.pointIds.includes(id))
  );

export const getAllRoutePoints = createSelector(getAllRouteSubphases, (phases) =>
  phases.reduce((a: TreeStepModel[], c: TreeSubPhaseModel) => a.concat(...c.steps), [])
);

export const getParallelTasksStoreData = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.parallelTasks
);

export const getParallelTasksForId = (id: number) =>
  createSelector(getParallelTasksStoreData, (tasks) => (tasks && tasks.hasOwnProperty(id) ? tasks[id] : null));

export const getEsigningState = createSelector(getDocumentRouteStore, (state: DocumentRouteState) => state.isEsigning);

export const getDates = createSelector(getDocumentRouteStoreData, (state) => state && state.phaseDates);

export const getPhaseDatesModel = (phaseId: number) =>
  createSelector(getDates, (dates: Record<number, PhaseDateModel>) => dates && dates[phaseId]);

export const getChangedUsers = createSelector(getDocumentRouteStore, (state) => state.changedUsers);

export const employeesYouCanChangeStore = createSelector(
  getDocumentRouteStore,
  (state: DocumentRouteState) => state.employeesYouCanChange
);
