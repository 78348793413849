import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup-buttons-block',
  templateUrl: './popup-buttons-block.component.html',
  styleUrls: ['./popup-buttons-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupButtonsBlockComponent {
  @Input()
  disableSubmitButton: boolean;
  @Input()
  showDeleteButton: boolean;

  /** Отображать ли кнопку "Отмена" */
  @Input()
  showCancelButton = true;

  @Output()
  onSubmitClick: EventEmitter<void> = new EventEmitter();

  @Output()
  onDeleteClick: EventEmitter<void> = new EventEmitter();

  @Output()
  onCancelClick: EventEmitter<void> = new EventEmitter();
}
