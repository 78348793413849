/** стилевые классы контролов*/
export enum ControlStyle {
  dark = 'dark',
  inputTextBlue = 'input-text-blue',
  checkboxLabelSmallText = 'checkbox-label-small-text',
  controlForDocumentAside = 'control-for-document-aside',
  checkboxInLabelInline = 'checkbox-in-label-inline',
  /** селект по высоте равный маленькой кнопке */
  selectOnLineSmallBtn = 'select-on-line-small-btn',
  inputBlueBackground = 'input-blue-background',
  transparent = 'transparent',
}
