import { FolderModel } from '@models/folder/folder.model';
import { createAction, props } from '@ngrx/store';

export enum PersonalFoldersTypes {
  GetAll = '[Personal Folders] GetAllFolders',
  GetAllSuccess= '[Personal Folders] GetAllSuccessFolders',
  GetAllError = '[Personal Folders] GetAllErrorFolders',
}

/** Список личных папок текущего пользователя */
export const getPersonalFolders = createAction(PersonalFoldersTypes.GetAll);
export const getPersonalFoldersSuccess = createAction(
  PersonalFoldersTypes.GetAllSuccess,
  props<{ personalFoldersList: FolderModel[] }>()
);
export const getPersonalFoldersError = createAction(PersonalFoldersTypes.GetAllError);


export const PersonalFoldersActions = {
  getPersonalFolders,
  getPersonalFoldersSuccess,
  getPersonalFoldersError
};
