import {
  ParticipantTypesAction,
  ParticipantTypesActionTypes,
} from '@appShared/store/dictionaries/participant-types/participant-types.actions';
import { ConsiderationParticipantTypeModel } from '@models/calendar-consideration/consideration-participant-type.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<ConsiderationParticipantTypeModel>([], null);

export function participantTypesReducer(
  state: DictionaryStoreModel<ConsiderationParticipantTypeModel> = DEFAULT_STATE,
  action: ParticipantTypesAction
): DictionaryStoreModel<ConsiderationParticipantTypeModel> {
  switch (action.type) {
    case ParticipantTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case ParticipantTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case ParticipantTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case ParticipantTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (participantTypes) => participantTypes.saveStatus;
/** Получение словаря */
export const getParticipantTypes = (participantTypes) => participantTypes.dictionary;
