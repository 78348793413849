import { Injectable } from '@angular/core';
import { ESignResponseModel } from '@models/crypto-pro/crypto-pro.response/esign-response.model';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http/http.service';
import { baseUrl } from '../../../core/urls/urls';
@Injectable({ providedIn: 'root' })
export class EsignApiService {
  constructor(private http: HttpService) {}

  public getInfoAboutSignersDocuments(id: number): Observable<ESignResponseModel[]> {
    const url = `${baseUrl}/document-packages/${id}/signers`;

    return this.http.getObservable(url);
  }
}
