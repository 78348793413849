import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getNotViewedNotificationsCount } from '@appCore/store/notifications';
import { getPopupIsOpened } from '@appCore/store/popup';

import { MENU_ITEMS } from '@appShared/components/menu/const/menu-items.const';
import { MenuItemsNames } from '@appShared/components/menu/enums/menu-items-names.enum';
import { ExternalUrlsInterface } from '@appShared/components/menu/interfaces/external-urls.interface';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { SettingCodeEnum } from '@appShared/enums/setting-code.enum';
import { SettingsHelperService } from '@appShared/services/settings-helper.service';
import { takeUntil } from 'rxjs/operators';
import { IN_PROGRESS_PARAMS } from '@appMain/dashboard/const/default-dashboard-params.const';
import { FILTER_DECISION_KEY } from '@appShared/const/filter-decision-key.const';
import { DecisionFiltersHelperService } from '@appMain/dashboard/services/decision-filters.helper.service';

/**
 * Компонент меню
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends Unsubscriber implements OnInit, OnDestroy {
  public menuItems: MenuItemModel[];
  public notificationsCount: number;
  public isOutOfRangeNotificationsCount: boolean;
  public displayedNotificationsCount: string;
  public externalUrls: ExternalUrlsInterface = {};
  public currentUrl: string;

  /** флаг закрытия подменю при клике на нем */
  public isSubmenuHidden: boolean;
  public tabIndex = -1;

  constructor(
    private router: Router,
    private redux: ReduxService,
    private settingsHelperService: SettingsHelperService,
    private decisionFiltersHelperService: DecisionFiltersHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.menuItems = this.getMenuItems();
    this.currentUrl = this.router.routerState.snapshot.url;

    this.redux.selectStore(getNotViewedNotificationsCount).subscribe((count) => {
      this.notificationsCount = count;
      this.isOutOfRangeNotificationsCount = count && count > 999;
      this.displayedNotificationsCount = this.isOutOfRangeNotificationsCount ? '999+' : `${this.notificationsCount}`;
    });

    this.redux
      .selectStore(getPopupIsOpened)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        setTimeout(() => {
          this.tabIndex = res ? -1 : 0;
        });
      });

    this.externalUrls[MenuItemsNames.electronicArchive] = this.settingsHelperService.getSettingValueByCode(
      SettingCodeEnum.eaLink
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public onDocumentSubMenuClick(): void {
    this.isSubmenuHidden = true;
    setTimeout(() => {
      this.isSubmenuHidden = false;
    }, 100);
  }

  public selectItem(item: MenuItemModel): void {
    // если кликаем по пункту дашборда, то подчищаем любые парметры и сетим изначальные
    if (item.name === MenuItemsNames.dashboard) {
      const filterParamsFromStorage = JSON.parse(localStorage.getItem(FILTER_DECISION_KEY));

      this.router.navigate(['main/dashboard'], {
        replaceUrl: true,
        queryParams: filterParamsFromStorage
          ? { ...IN_PROGRESS_PARAMS, ...filterParamsFromStorage }
          : IN_PROGRESS_PARAMS,
      });
      return;
    }
    this.router.navigate([item.routerLink]);
  }

  /** Возвращает пункты меню */
  private getMenuItems(): MenuItemModel[] {
    return MENU_ITEMS;
  }
}
