import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ComponentsInteractionTopics } from '@appShared/enums/components-interaction-topics.enum';

/**
 * Сервис "общения" компонентов с помощью Топиков, перечисленных в ComponentsInteractionTopics
 */
@Injectable({
  providedIn: 'root',
})
export class ComponentsInteractionService {
  // Транслятор типиков
  public static topic$: Subject<ComponentsInteractionTopics> = new Subject<ComponentsInteractionTopics>();
}
