import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getErrorMessageInfo } from '@appCore/store/error-message';
import { ResetErrorMessage } from '@appCore/store/error-message/error-message.actions';
import { CreateAppealPopupComponent } from '@appShared/components/create-appeal-popup/create-appeal-popup.component';
import { ModalWindowService } from '@appShared/modals-helper/modal-window.service';
import { ErrorMessageModel } from '@models/error-message.model';
import { Subscription } from 'rxjs';
import { GENERAL_TEXT_ERRORS } from '@appShared/const/global-text-errors.const';

@Component({
  selector: 'app-glb-error-popup',
  templateUrl: './glb-error-popup.component.html',
  styleUrls: ['./glb-error-popup.component.scss'],
  preserveWhitespaces: true,
})
export class GlbErrorPopupComponent implements OnInit, OnDestroy {
  @ViewChild('stackTrace')
  stackTraceRef: ElementRef;

  public errorMessage: ErrorMessageModel;
  public showDetails: boolean;
  public readonly unexpectedError = GENERAL_TEXT_ERRORS.unexpectedError;

  private subscription = new Subscription();

  constructor(private redux: ReduxService, private modalWindowService: ModalWindowService) {}

  ngOnInit(): void {
    this.subscription.add(this.redux.selectStore(getErrorMessageInfo).subscribe((em) => (this.errorMessage = em)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openAppealPopup(): void {
    this.modalWindowService.open(CreateAppealPopupComponent, { stackTrace: this.errorMessage.stackTrace });
    this.onClosePopup();
  }

  onClosePopup() {
    this.redux.dispatchAction(new ResetErrorMessage());
  }

  public copyStackTraceToClipboard(): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.errorMessage.stackTrace);

      return;
    }

    window.getSelection().selectAllChildren(this.stackTraceRef.nativeElement);

    try {
      document.execCommand('copy');
    } catch (e) {
      console.log(e);
    }
    window.getSelection().removeAllRanges();
  }

  public get formatedStackTrace(): string {
    if (!this.errorMessage || !this.errorMessage.stackTrace) {
      return '';
    }
    return this.errorMessage.stackTrace.trim().replace(/\t/gm, ' ');
  }
}
