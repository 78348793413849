import { Pipe, PipeTransform } from '@angular/core';
import { ChangedUserAgreementRoutes } from '@appShared/api/documents-route/models/documents-route.request/changed-user-agreement-routes';
import { employeeShortName } from '@appShared/pipes/employee-short-name.pipe';
import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { ActiveTasksTabNamesEnum } from '@appDocuments/documents-route/enums/active-tasks-tab-names.enum';
import { capitalize } from '@appShared/helpers/capitalize';
import moment from 'moment';

/**
 * @description <strong>Руководитель изменен</strong>
 * <br>
 * <strong>Предыдущий руководитель:</strong> Солдатов А.С.,
 * Правовое управление Правительства Москвы.
 * <br>
 * <strong>Изменил:</strong> Иванов Иван Иванович 02.02.2021
 */
@Pipe({
  name: 'employeeChangesInfo',
})
export class EmployeeChangesInfoPipe implements PipeTransform {
  transform(changedUsers: ChangedUserAgreementRoutes[]): string {
    if (!changedUsers || !changedUsers.length) {
      return '';
    }

    const user = changedUsers[changedUsers.length - 1];
    const userRole: string = this.getUserRole(user);
    const userRoleCap = capitalize(userRole);

    const changedUserFullName = `${user.changedBy.lastName} ${user.changedBy.firstName} ${user.changedBy.patronymic}`;
    const changedDate = moment(user.date).format('DD.MM.YYYY');

    const userRoleLabel = `${userRoleCap} изменен`.fontsize(4).bold();
    const prevEmployeeLabel = `<strong>Предыдущий ${userRole}:</strong> ${employeeShortName(user.employee)}, ${
      user.employee.organization.shortName
    }`;
    const changedUserLabel = `<strong>Изменил:</strong> ${changedUserFullName} ${changedDate}`;

    return `
        <div>${userRoleLabel}</div>
        <br>
        <div>${prevEmployeeLabel}</div>
        <br>
        <div>${changedUserLabel}</div>
     `;
  }

  private getUserRole(user: ChangedUserAgreementRoutes): string {
    const userRoleId: string = EmployeeRouteTypes[user.roleId];
    const userRole: string = ActiveTasksTabNamesEnum[userRoleId];

    return userRole;
  }
}
