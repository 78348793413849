import { Action } from '@ngrx/store';

export enum DocumentRouteActiveTasksActionTypes {
  GetActiveTasks = '[DocumentRoute] Get active tasks',
  GetActiveTasksSuccess = '[DocumentRoute] Get active tasks Success',
  GetActiveTasksError = '[DocumentRoute] Get active tasks Error'
}

export class RouteGetActiveTasks implements Action {
  readonly type = DocumentRouteActiveTasksActionTypes.GetActiveTasks;
  constructor(public payload: number) {}
}

export class RouteGetActiveTasksSuccess implements Action {
  readonly type = DocumentRouteActiveTasksActionTypes.GetActiveTasksSuccess;
  constructor(public payload: any[]) {}
}

export class RouteGetActiveTasksError implements Action {
  readonly type = DocumentRouteActiveTasksActionTypes.GetActiveTasksError;
  constructor(public payload: any) {}
}

export type DocumentRouteActiveTasksActions =
  | RouteGetActiveTasks
  | RouteGetActiveTasksSuccess
  | RouteGetActiveTasksError;
