import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { environment } from '@environments/environment';
import { UNAUTHORIZED } from 'http-status-codes';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, switchMap } from 'rxjs/operators';
import { GlbHttpErrorHandlerService } from './glb-http-error-handler.service';
import { WINDOW } from '@appShared/tokens/window.token';
import { FILTER_DECISION_KEY } from '@appShared/const/filter-decision-key.const';

@Injectable()
export class GlbHttpErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private errorHandler: GlbHttpErrorHandlerService,
    private http: HttpService,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((requestError: HttpErrorResponse) => {
        if (requestError.error instanceof Error) {
          console.error('An error occurred:', requestError.error.message);
        }

        if (requestError?.status === UNAUTHORIZED) {
          if (requestError.url === `${environment.authBaseUrl}/refresh`) {
            this.errorHandler.handleError(requestError);
          }

          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              first((res) => !!res),
              switchMap(() => next.handle(request))
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(false);

            return this.http.postObservable<void>(`${environment.authBaseUrl}/refresh`).pipe(
              switchMap(() => {
                if (request.url.includes('switch-to-user')) {
                  this.windowRef.location.reload();
                  return next.handle(null);
                }
                // при устаревании токена сессии отчищаем фильтры вкладки Вынести решение
                localStorage.removeItem(FILTER_DECISION_KEY);

                this.refreshTokenSubject.next(true);
                return next.handle(request);
              }),
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        } else {
          this.errorHandler.handleError(requestError, request);
        }
        return throwError(() => requestError);
      })
    );
  }
}

export const glbHttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: GlbHttpErrorInterceptor,
  multi: true,
};
