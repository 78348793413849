import {
  QuestionCategoriesAction,
  QuestionCategoriesActionTypes,
} from '@appShared/store/dictionaries/question-categories/question-categories.actions';
import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryWithCodeModel>([], null);

export function questionCategoriesReducer(
  state: DictionaryStoreModel<DictionaryWithCodeModel> = DEFAULT_STATE,
  action: QuestionCategoriesAction
): DictionaryStoreModel<DictionaryWithCodeModel> {
  switch (action.type) {
    case QuestionCategoriesActionTypes.Reset:
      return DEFAULT_STATE;

    case QuestionCategoriesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case QuestionCategoriesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case QuestionCategoriesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (questionResolutionTypes) => questionResolutionTypes.saveStatus;
/** Получение словаря */
export const getQuestionCategories = (questionResolutionTypes) => questionResolutionTypes.dictionary;
