import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionModel } from '@appPermissions/models/permission.model';
import { PermissionsService } from '@appPermissions/permissions.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appPermission]',
})
export class PermissionsDirective implements OnInit, OnDestroy {
  @Input()
  set appPermission(condition: PermissionModel) {
    if (!!condition) {
      this.permissions = condition.permissions;
      this.type = condition.type;
      this.id = condition.id;
    } else {
      this.showElement = true;
    }
    this.updateViewContaienr();
  }

  /** Пермишны приходящие в директиву для текущего элемента */
  private permissions: string[] | string;
  /** Тип пермишна */
  private type: string;
  /** Id */
  private id: string | number;
  /** Все пермишны пользователя */
  private showElement: boolean;
  private hasPermissionSubscription: Subscription;

  constructor(
    private permissionsService: PermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    if (this.permissions) {
      this.hasPermissionSubscription = this.permissionsService
        .getPermissions(this.permissions, this.type, this.id)
        .subscribe((hasPermission) => {
          this.showElement = hasPermission;
          this.updateViewContaienr();
        });
    }
  }

  ngOnDestroy() {
    if (!!this.hasPermissionSubscription) {
      this.hasPermissionSubscription.unsubscribe();
    }
  }

  /**
   * Метод отображающий/скрывающий элемент по пермишнам
   * https://angular.io/guide/structural-directives#the-appunless-property
   */
  private updateViewContaienr(): void {
    if (this.showElement) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
