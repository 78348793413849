// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-size: 0.85rem;
  color: #fff;
  background: #000;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

:host-context(.top) {
  margin-bottom: 0.5rem;
}

:host-context(.bottom) {
  margin-top: 0.5rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtY2RrLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usa0JBQUE7RUFDQSxXQUFBO0VBQ0EsZ0JBQUE7RUFDQSxzQkFBQTtFQUNBLHNCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxxQkFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7QUFDRiIsImZpbGUiOiJ0b29sdGlwLWNkay5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZm9udC1zaXplOiAwLjg1cmVtO1xuICBjb2xvcjogI2ZmZjtcbiAgYmFja2dyb3VuZDogIzAwMDtcbiAgYm9yZGVyLXJhZGl1czogMC4yNXJlbTtcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbn1cblxuOmhvc3QtY29udGV4dCgudG9wKSB7XG4gIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcbn1cblxuOmhvc3QtY29udGV4dCguYm90dG9tKSB7XG4gIG1hcmdpbi10b3A6IDAuNXJlbTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/tooltip-cdk/tooltip-cdk.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,sBAAA;EACA,sBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACA,orBAAorB","sourcesContent":[":host {\n  font-size: 0.85rem;\n  color: #fff;\n  background: #000;\n  border-radius: 0.25rem;\n  box-sizing: border-box;\n}\n\n:host-context(.top) {\n  margin-bottom: 0.5rem;\n}\n\n:host-context(.bottom) {\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
