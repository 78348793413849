import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringsArrayFilter'
})
export class StringsArrayFilterPipe implements PipeTransform {
  transform(value: any, filterString: string): string[] {
    if (!value) {
      return [];
    }

    if (!filterString) {
      return value;
    }

    return value.filter(i => i.toLowerCase().includes(filterString.toLocaleLowerCase()));
  }
}
