/** идентификаторы клавиш клавиатуры, передаваемых в $event.code */

/**
 * @deprecated
 */
export enum InputKeyCode {
  arrowDown = 40,
  arrowUp = 38,
  enter = 13,
  tab = 9,
  escape = 27
}

export enum InputKey {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Enter = 'Enter',
  Tab = 'Tab',
  Escape = 'Escape'
}
