import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { baseUrl } from '@appCore/urls/urls';
import { Observable } from 'rxjs';
import { AnnouncementModel } from '@models/announcement/announcement.model';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementApiService {
  private readonly url = `${baseUrl}/announcements`;

  constructor(private http: HttpService) {}

  getActiveAnnouncement(): Observable<AnnouncementModel> {
    return this.http.getObservable<AnnouncementModel>(`${this.url}/active`);
  }
}
