import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DelegatingApiService } from '@appShared/api/delegating/delegating.api.service';
import { UserInfoApiService } from '@appShared/api/user-info/user-info.api.service';
import { WINDOW } from '@appShared/tokens/window.token';
import { DelegatingInfoModel } from '@models/delegating/delegating-info.model';
import { UserInfoResponseModel } from '@models/user-info.model';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ReduxService } from '../../../core/services/redux/redux.service';
import { getUserInfo } from '../../../core/store/user-info';
import { ANNOUNCEMENT_KEY } from '@appShared/const/announcement-key.const';
import { FILTER_DECISION_KEY } from '@appShared/const/filter-decision-key.const';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  public userInfo: UserInfoResponseModel;
  public delegatingInfo: DelegatingInfoModel[] = [];
  public isCurrentUserReal = true;

  private subscription = new Subscription();

  constructor(
    private redux: ReduxService,
    private userInfoService: UserInfoApiService,
    private delegatingApi: DelegatingApiService,
    @Inject(WINDOW) private windowRef: Window
  ) {}

  ngOnInit() {
    this.getUserInfoFromStore();
    this.getDelegationInfo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /** Переход на страницу логаута */
  public logout(): void {
    this.userInfoService.logout();
    localStorage.removeItem('ldeDocumentType');
    localStorage.removeItem(ANNOUNCEMENT_KEY);
    localStorage.removeItem(FILTER_DECISION_KEY);
  }

  public switchToDelegating(employeeId) {
    localStorage.removeItem('ldeDocumentType');
    this.delegatingApi.switchToDelegating(employeeId).subscribe(this.userInfoService.reloadApp(this.windowRef));
  }

  public switchToYourSelf(): void {
    localStorage.removeItem('ldeDocumentType');
    this.delegatingApi
      .switchToYourself()
      .pipe(switchMap((res) => this.userInfoService.resetMockUser()))
      .subscribe(this.userInfoService.reloadApp(this.windowRef));
  }

  public getProfileDelegatingTitle(): string {
    return this.isCurrentUserReal ? '' : this.userInfo.isActingUser ? 'и.о. ' : 'от имени ';
  }

  public getMenuDelegatingTitle(delegatingInfo: DelegatingInfoModel): string {
    return delegatingInfo.acting ? ', и.о.' : ', от имени';
  }

  /** Получение данных пользователя */
  private getUserInfoFromStore(): void {
    this.subscription.add(
      this.redux
        .selectStore(getUserInfo)
        .pipe(filter((i) => !!i))
        .subscribe((userInfo) => {
          this.userInfo = userInfo;
          this.isCurrentUserReal = this.checkIsCurrentUserReal(userInfo);
        })
    );
  }

  private checkIsCurrentUserReal(userInfo): boolean {
    if (!userInfo) {
      return true;
    }

    return userInfo.currentUser.employee.id === userInfo.realUser.employee.id;
  }

  private getDelegationInfo(): void {
    this.subscription.add(
      this.delegatingApi.getTo().subscribe((delegatingInfo) => (this.delegatingInfo = delegatingInfo))
    );
  }
}
