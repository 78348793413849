import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BigLoaderComponent } from './big-loader/big-loader.component';
import { SmallLoaderComponent } from './small-loader/small-loader.component';
import { LoaderComponent } from '@appShared/components/loaders/loader/loader.component';

@NgModule({
  imports: [CommonModule],
  exports: [BigLoaderComponent, SmallLoaderComponent, LoaderComponent],
  declarations: [BigLoaderComponent, SmallLoaderComponent, LoaderComponent],
})
export class LoadersModule {}
