import { Action } from '@ngrx/store';
import { DelegatingInfoModel } from '@models/delegating/delegating-info.model';

export enum DelegatingActionTypes {
  Load = '[Delegating] Load',
  LoadSuccess = '[Delegating] LoadSuccess',
  LoadError = '[Delegating] LoadError',

  Create = '[Delegating] Create',
  CreateSuccess = '[Delegating] CreateSuccess',
  CreateError = '[Delegating] CreateError',

  Edit = '[Delegating] Edit',
  EditSuccess = '[Delegating] EditSuccess',
  EditError = '[Delegating] EditError',

  Delete = '[Delegating] Delete',
  DeleteSuccess = '[Delegating] DeleteSuccess',
  DeleteError = '[Delegating] DeleteError',

  SetActiveId = '[Delegating] SetActiveId',

  Reset = '[Delegating] Reset'
}

export class DelegatingActionsLoad implements Action {
  readonly type = DelegatingActionTypes.Load;
}

export class DelegatingActionsLoadSuccess implements Action {
  readonly type = DelegatingActionTypes.LoadSuccess;
  constructor(public payload: DelegatingInfoModel[]) {}
}

export class DelegatingActionsLoadError implements Action {
  readonly type = DelegatingActionTypes.LoadError;
  constructor(public error: any) {}
}

export class DelegatingActionsCreate implements Action {
  readonly type = DelegatingActionTypes.Create;
  constructor(public payload: DelegatingInfoModel) {}
}

export class DelegatingActionsCreateSuccess implements Action {
  readonly type = DelegatingActionTypes.CreateSuccess;
  constructor(public payload: DelegatingInfoModel) {}
}

export class DelegatingActionsCreateError implements Action {
  readonly type = DelegatingActionTypes.CreateError;
  constructor(public payload: any) {}
}

export class DelegatingActionsEdit implements Action {
  readonly type = DelegatingActionTypes.Edit;
  constructor(public payload: DelegatingInfoModel) {}
}

export class DelegatingActionsEditSuccess implements Action {
  readonly type = DelegatingActionTypes.EditSuccess;
  constructor(public payload: DelegatingInfoModel) {}
}

export class DelegatingActionsEditError implements Action {
  readonly type = DelegatingActionTypes.EditError;
  constructor(public payload: any) {}
}

export class DelegatingActionsDelete implements Action {
  readonly type = DelegatingActionTypes.Delete;
  /** В пейлоад передаем айдишник удаляемого делегирования */
  constructor(public payload: number) {}
}

export class DelegatingActionsDeleteSuccess implements Action {
  readonly type = DelegatingActionTypes.DeleteSuccess;
  constructor(public payload: number) {}
}

export class DelegatingActionsDeleteError implements Action {
  readonly type = DelegatingActionTypes.DeleteError;
  constructor(public payload: any) {}
}

export class DelegatingActionsSetActiveId implements Action {
  readonly type = DelegatingActionTypes.SetActiveId;
  /** В пейлоад передаем айдишник делегирования */
  constructor(public payload: number) {}
}

export class DelegatingActionsReset implements Action {
  readonly type = DelegatingActionTypes.Reset;
}

export type DelegatingActions =
  | DelegatingActionsLoad
  | DelegatingActionsLoadSuccess
  | DelegatingActionsLoadError
  | DelegatingActionsCreate
  | DelegatingActionsCreateSuccess
  | DelegatingActionsCreateError
  | DelegatingActionsEdit
  | DelegatingActionsEditSuccess
  | DelegatingActionsEditError
  | DelegatingActionsDelete
  | DelegatingActionsDeleteSuccess
  | DelegatingActionsDeleteError
  | DelegatingActionsSetActiveId
  | DelegatingActionsReset;
