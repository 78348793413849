import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestStatus } from '@appShared/enums/request-status.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WafMessageService {
  private wafMessage$ = new BehaviorSubject('');

  public setWafMessage(error: HttpErrorResponse): void {
    const element = document.createElement('div');
    element.innerHTML = error.error;
    const message = element.getElementsByTagName('pre')[0].innerText.replace(/Request ID: /g, '');
    this.wafMessage$.next(message);
  }

  public getWafMessage(): Observable<string> {
    return this.wafMessage$;
  }

  public isBlocked(error: HttpErrorResponse): boolean {
    const requestStatus = error.headers.get('X-Requeststatus');
    return requestStatus === RequestStatus.blocked;
  }
}
