import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent extends ButtonBaseComponent {
  @Input()
  colorClassName: string;
  @Input()
  withHover: boolean;
  @HostBinding('class.loading')
  @Input()
  isLoading: boolean;

  constructor() {
    super();
  }
}
