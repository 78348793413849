import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { SharedFilter } from '@appCore/store/shared-filters/models/shared-filter.model';
import { Urls } from '@appCore/urls/urls';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';
import { Observable } from 'rxjs';
import { FolderAccessRequestDto } from './models/personal-folders-access/shared-folder.request.model';
import { SharedFolderResponseModel } from './models/personal-folders-access/shared-folder.response.model';
import { SharedFoldersResponseModel } from './models/personal-folders-access/shared-folders.response.model';

@Injectable({ providedIn: 'root' })
export class PersonalFoldersAccessApiService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  public getAll(): Observable<SharedFoldersResponseModel[]> {
    const url = Urls.getFoldersAccess();

    return this.http.get<SharedFoldersResponseModel[]>(url);
  }

  public getById(folderId: number): Observable<SharedFolderResponseModel[]> {
    const url = Urls.folderAccess(folderId);

    return this.http.get<SharedFolderResponseModel[]>(url);
  }

  public updateById(folderAccessRequestDto: FolderAccessRequestDto): Observable<null> {
    const url = Urls.folderAccess(folderAccessRequestDto.folderId);

    return this.http.put<null>(url, folderAccessRequestDto.folderAccess);
  }

  /**
   * Возвращает всех сотрудников имеющих доступ к папке
   *
   * @param code - inProgress, allReviewed
   * @param projectGroupTypeId - тип группы проектов
   */
  public getDashboardAccess(
    code: string,
    projectGroupTypeId: ProjectGroupType
  ): Observable<SharedFolderResponseModel[]> {
    const url = Urls.getDashboardAccess();
    const params = projectGroupTypeId ? { code, projectGroupTypeId } : { code }
    return this.httpService.getObservable<SharedFolderResponseModel[]>(url, { params });
  }

  /**
   * Обновляет доступ к папке
   *
   * @param folderAccessRequestDto - папка с сотрудниками, имеющими доступ к ней
   * @param projectGroupTypeId - тип группы проектов
   */
  public updateDashboardAccess(
    folderAccessRequestDto: FolderAccessRequestDto,
    projectGroupTypeId: ProjectGroupType
  ): Observable<null> {
    const url = Urls.getDashboardAccess();
    const params = projectGroupTypeId ? {
        code: folderAccessRequestDto.code,
        projectGroupTypeId,
      } : { code: folderAccessRequestDto.code };
    return this.httpService.putObservable(url, folderAccessRequestDto.folderAccess, { params });
  }

  public getSharedFilters(): Observable<SharedFilter[]> {
    const url = Urls.sharedFiltersList();

    return this.http.get<SharedFilter[]>(url);
  }
}
