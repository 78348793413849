import { Injectable } from '@angular/core';
import { AppealsApiService } from '@appShared/api/personal-cabinet/appeals-api.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { DictionaryModel } from '@models/base/dictionary.model';
import { publishReplay, refCount } from 'rxjs/operators';
import {
  AppealModel,
} from '@appPersonalCabinet/appeals/models/appeal-search-response.model';
import { PaginationWithParamsModel, SearchParamsModel } from '@appPersonalCabinet/appeals/models/search-params.model';
import { AppealRequestModel } from '@appPersonalCabinet/appeals/models/appeal-request.model';
import { AppealStatusRequestModel } from '@appPersonalCabinet/appeals/models/appeal-status-request.model';
import { PaginationResponseModel } from '@models/pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class AppealsLogicService {

  private appealTypes$: Observable<DictionaryModel[]>;
  private appealStatuses$: Observable<DictionaryModel[]>;
  private onAddAppealSubj$ = new Subject<AppealModel>();
  private onSearchAppealsByParams$ = new Subject<SearchParamsModel>();
  private counterTotalResults$ = new Subject<number>();
  private isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(private appealsApi: AppealsApiService) {
    this._buildAppealTypes();
    this._buildAppealStatuses();
  }

  private _buildAppealTypes(): void {
    this.appealTypes$ = this.appealsApi.getAppealTypesList().pipe(
      publishReplay(),
      refCount()
    );
  }

  private _buildAppealStatuses(): void {
    this.appealStatuses$ = this.appealsApi.getAppealStatusesList().pipe(
      publishReplay(),
      refCount()
    );
  }

  public getAppealTypes(): Observable<DictionaryModel[]> {
    return this.appealTypes$;
  }

  public getAppealStatuses(): Observable<any> {
    return this.appealStatuses$;
  }

  // Вернет список обращенний по параметрам
  public resolveAppeals(params: PaginationWithParamsModel): Observable<PaginationResponseModel<AppealModel>> {
    return this.appealsApi.getHpsmAppealsList(params);
  }

  // Создаст новое обращение
  public createNewAppeal(data: AppealRequestModel): Observable<AppealModel> {
    return this.appealsApi.createAppeal(data);
  }

  public setNewAppeal(appeal: AppealModel): void {
    this.onAddAppealSubj$.next(appeal);
  }

  public getNewAppeal(): Observable<AppealModel> {
    return this.onAddAppealSubj$.asObservable();
  }

  public setParamsForSearchAppeals(params: any): void {
    this.onSearchAppealsByParams$.next(params);
  }
  public getParamsForSearchAppeals(): Observable<SearchParamsModel> {
    return this.onSearchAppealsByParams$.asObservable();
  }

  public getStatusById(appealId: number): Observable<AppealStatusRequestModel> {
    return this.appealsApi.getAppealStatusById(appealId);
  }

  public setCounterTotalResults(count: number): void {
    this.counterTotalResults$.next(count);
  }

  public getCounterTotalResults(): Observable<number> {
    return this.counterTotalResults$.asObservable();
  }

  public setIsLoading(val: boolean): void {
    this.isLoading$.next(val);
  }

  public getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
