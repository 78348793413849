import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ControlBaseDirective } from '../control-base.directive';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['../control-base.directive.scss', './search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent extends ControlBaseDirective implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  alwaysShowSearchIcon = false;
  @Input()
  isOpened = false;
  @Output()
  onEnter: EventEmitter<void> = new EventEmitter();
  @Output()
  onInputChange: EventEmitter<void> = new EventEmitter();
  @Output()
  onClear: EventEmitter<void> = new EventEmitter();
  @Output()
  onInputDelayedChange: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onScroll: EventEmitter<void> = new EventEmitter();

  /** Значение поля */
  public model = '';
  public disabled = false;

  private inputSubject: Subject<string> = new Subject();
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.inputSubject
      .pipe(
        debounceTime(1000),
        filter((input) => input.length > 2),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.onInputDelayedChange.emit();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.model = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Эмитит событие скроллинга при скроллинге */
  public onScrollDown(): void {
    this.onScroll.emit();
  }

  /** Срабатывает на изменение модели */
  public onModelChange(): void {
    this.onChange(this.model);
    this.inputSubject.next(this.model);
    this.onInputChange.emit();
  }

  /** Обнуляет модель, эмитит соответствующие события */
  public clear(): void {
    this.model = '';
    this.onChange(this.model);
    this.onInputChange.emit();
    this.onClear.emit();
  }

  /** поймать отключение формы через NG_VALUE_ACCESSOR */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public get canSearch(): boolean {
    return !!this.model && !this.disabled;
  }

  public search(): void {
    if (this.canSearch) {
      this.onEnter.emit();
    }
  }
}
