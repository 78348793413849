import { Pipe, PipeTransform } from '@angular/core';
import { CalendarDaysResponseModel } from '@models/calendar/calendar.response/calendar-events.response.model';
import moment from 'moment';

@Pipe({
  name: 'calendarFilter',
})
export class CalendarCurrentMonthFilter implements PipeTransform {
  /**Функция фильтра дней текущего месяца */
  transform(array: CalendarDaysResponseModel[]): CalendarDaysResponseModel[] {
    if (!array) {
      return [];
    }
    const firstDayOfMonth = array.find((i) => this.isFirstDay(i));
    const result = array.filter((i) => this.isDayOfCurrentMonth(i, firstDayOfMonth.date));
    return result;
  }

  /**Поиск первого дня месяца */
  private isFirstDay(element): boolean {
    const startOfMonth = moment(element.date).startOf('month');
    const elementDate = moment(element.date);
    if (elementDate.isSame(startOfMonth, 'day')) {
      return true;
    }
  }

  /**Функиця фильтрации дней текущего месяца */
  private isDayOfCurrentMonth(element, first) {
    if (moment(element.date).isSame(first, 'month')) {
      return element;
    }
  }
}
