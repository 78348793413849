import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  CoveringTextTypesAction,
  CoveringTextTypesActionTypes,
  CoveringTextTypesGetError,
  CoveringTextTypesGetSuccess,
} from './covering-text-types.actions';

@Injectable()
export class CoveringTextTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetCoveringtextTypes$: Observable<CoveringTextTypesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(CoveringTextTypesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() =>
        this.dictionaryApi.getCoveringTextTypes().pipe(
          map((response) => new CoveringTextTypesGetSuccess(response)),
          catchError((err) => of(new CoveringTextTypesGetError()))
        )
      )
    )
  );
}
