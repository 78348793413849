import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapperPipe implements PipeTransform {
  transform(value: any, mappingFunction: Function, context?: any): any {
    if (context) {
      mappingFunction = mappingFunction.bind(context);
    }

    return mappingFunction(value);
  }
}
