import { Pipe, PipeTransform } from '@angular/core';
import { isQuestionMoved } from '@appMain/calendar-consideration/pipes/is-question-moved.pipe';
import { ConsiderationQuestionResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-question.response.model';

@Pipe({
  name: 'mapperWithIndex',
})
export class MapperWithIndexPipe implements PipeTransform {
  transform(question: ConsiderationQuestionResponseModel, questions: ConsiderationQuestionResponseModel[]): any {
    if (!question) {
      return;
    }

    if (isQuestionMoved(question, question.movedOnDate)) {
      return;
    }
    const notDisabledQuestions = questions.filter((ndQuestion) => !isQuestionMoved(ndQuestion, ndQuestion.movedOnDate));
    const requiredItem = notDisabledQuestions.find((ndQuestion) => ndQuestion.id === question.id);

    if (!requiredItem) {
      return;
    }

    return notDisabledQuestions.indexOf(requiredItem) + 1;
  }
}
