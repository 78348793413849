export enum DashboardStatisticsNames {
  /** Проекты в работе */
  inProgress = 'inProgress',

  /** Реестр */
  registry = 'registry',

  /** Разработка */
  working = 'working',
  workingAuthor = 'workingAuthor',
  workingAuthorWorking = 'workingAuthorWorking',
  workingAuthorApprovement = 'workingAuthorApprovement',
  workingAuthorRework = 'workingAuthorRework',
  workingAuthorInitiation = 'workingAuthorInitiation',
  workingCoexecutor = 'workingCoexecutor',
  workingCoexecutorWorking = 'workingCoexecutorWorking',
  workingCoexecutorApprovement = 'workingCoexecutorApprovement',
  workingCoexecutorRework = 'workingCoexecutorRework',
  workingCoauthor = 'workingCoauthor',
  workingCoauthorInside = 'workingCoauthorInside',
  workingCoauthorOutside = 'workingCoauthorOutside',
  workingCoauthorNotAccepted = 'workingCoauthorNotAccepted',

  /** Согласование */
  approvement = 'approvement',
  approvementInternal = 'approvementInternal',
  approvementInternalWithoutSign = 'approvementInternalWithoutSign',
  approvementInternalWithSign = 'approvementInternalWithSign',
  approvementInternalWrite = 'approvementInternalWrite',
  approvementExternal = 'approvementExternal',
  approvementExternalWithoutSign = 'approvementExternalWithoutSign',
  approvementExternalWithSign = 'approvementExternalWithSign',
  approvementExternalWrite = 'approvementExternalWrite',

  /** Контроль */
  control = 'control',
  controlControl = 'controlControl',
  controlAssignment = 'controlAssignment',
  controlRedirected = 'controlRedirected',

  /** Избранное */
  favourite = 'favourite',

  /** Все рассмотренные */
  allReviewed = 'allReviewed',

  /** Раздел "Регистрация" */
  registration = 'registration',
  reserved = 'reserved',
  reservedToday = 'reservedToday',
  reservedYesterday = 'reservedYesterday',
  reservedPreviously = 'reservedPreviously',
  registered = 'registered',
  registeredToday = 'registeredToday',
  registeredYesterday = 'registeredYesterday',
  registeredPreviously = 'registeredPreviously',
  registrationToday = 'registrationToday',
  registrationYesterday = 'registrationYesterday',
  registrationPreviously = 'registrationPreviously',
  registrationRegistry = 'registrationRegistry',

  /** Вынести решение */
  makeDecision = 'makeDecision',
  makeDecisionToSign = 'makeDecisionToSign',
  makeDecisionToSignInternal = 'makeDecisionToSignInternal',
  makeDecisionToSignExternal = 'makeDecisionToSignExternal',
  makeDecisionApproveWithoutSignature = 'makeDecisionApproveWithoutSignature',
  makeDecisionApproveWithoutSignatureInternal = 'makeDecisionApproveWithoutSignatureInternal',
  makeDecisionApproveWithoutSignatureExternal = 'makeDecisionApproveWithoutSignatureExternal',
}
