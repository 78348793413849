import { AfterViewChecked, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UnderscoreType } from '../enums/underscore-type.enum';

@Directive({
  selector: '[appTextUnderscores]',
})
export class TextUnderscoresDirective implements OnChanges, AfterViewChecked {
  @Input()
  searchText = '';
  @Input()
  underscoreType = UnderscoreType.backgroundColor;
  @Input()
  underscoreColor = 'yellow';
  @Input()
  fontWeight = 400;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { previousValue, currentValue } = changes['searchText'];
    if (previousValue && !currentValue) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.el.nativeElement.textContent);
    }
  }

  ngAfterViewChecked(): void {
    if (this.searchText) {
      const textContent = this.el.nativeElement.textContent;

      // обработка спецсимволов
      const regexMask = new RegExp(this.searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');

      const newStr = this.getColoredText(textContent, regexMask, this.underscoreType);

      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', newStr);
    }
  }

  private getColoredText(textContent: string, regexMask: RegExp, type: UnderscoreType): string {
    const styleType = {
      [UnderscoreType.backgroundColor]: 'background-color',
      [UnderscoreType.fontColor]: 'color',
    };
    return textContent.replace(
      regexMask,
      (match) =>
        `<span style="font-weight: ${this.fontWeight}; ${styleType[type]}: ${this.underscoreColor}">${match}</span>`
    );
  }
}
