import { TreeTapModel } from '@appDocuments/documents-route/models/tree/tree-tap.model';
import { Action } from '@ngrx/store';

export enum DocumentRouteTapActionTypes {
  Reset = '[Document Route Tap] Reset',
  Update = '[Document Route Tap] Update',
  StepChange = '[Document Route Tap] Mark That Steps Changed',
}

export class RouteTapReset implements Action {
  readonly type = DocumentRouteTapActionTypes.Reset;
}

export class RouteTapUpdate implements Action {
  readonly type = DocumentRouteTapActionTypes.Update;

  constructor(
    public payload: {
      routeId: number;
      phaseId: number;
      tap: TreeTapModel;
    }
  ) { }
}

export class SetStepChange implements Action {
  readonly type = DocumentRouteTapActionTypes.StepChange;

  constructor(
    public payload: boolean
  ) { }
}

export type DocumentRouteTapActions = RouteTapReset | RouteTapUpdate | SetStepChange;
