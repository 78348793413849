import { AbstractControl } from '@angular/forms';

export class OptionItem {
  control: AbstractControl;
  index: number;
  label: string;

  constructor(control: AbstractControl, index: number, label: string) {
    this.control = control;
    this.index = index;
    this.label = label;
  }
}
