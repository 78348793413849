import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentStagesActions, DocumentStagesActionTypes } from './document-stages.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryWithDescriptionModel>();

export function documentStagesReducer(
  state: DictionaryStoreModel<DictionaryWithDescriptionModel> = DEFAULT_STATE,
  action: DocumentStagesActions
): DictionaryStoreModel<DictionaryWithDescriptionModel> {
  switch (action.type) {
    case DocumentStagesActionTypes.Reset:
      return DEFAULT_STATE;

    case DocumentStagesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentStagesActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case DocumentStagesActionTypes.Error:
      return {
        ...DEFAULT_STATE,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса загрузк */
export const getLoadStatus = (store) => store.loadStatus;
/** Получение словаря */
export const getDocumentStages = (store) => store.dictionary;
