import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-validation-error-popup',
  templateUrl: './form-validation-error-popup.component.html',
  styleUrls: ['./form-validation-error-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormValidationErrorPopupComponent {
  @Input()
  invalidContolsNames: string[];
  @Input()
  togglePopup: boolean;
  @Output()
  togglePopupChange: EventEmitter<boolean> = new EventEmitter();

  /** переключатель попапа с ошибками валидации */
  public closePopup() {
    this.togglePopup = false;
    this.togglePopupChange.emit(this.togglePopup);
  }
}
