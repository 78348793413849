import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlBaseComponent } from '../control-base/control-base.component';

/**
 * Компонент кастомных радиокнопок
 */
@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['../control-base/control-base.component.scss', './input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputRadioComponent,
    },
  ],
})
export class InputRadioComponent extends ControlBaseComponent implements ControlValueAccessor {
  @Input()
  options: Map<any, any>;
  @Input()
  columnStyle: boolean;
  @Input()
  name: string;
  @Input() dividingLine: boolean;

  public selectedOption: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {
    super();
  }

  writeValue(value: any): void {
    this.selectedOption = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public change(val: any): void {
    this.selectedOption = val;
    this.onChange(val);
    this.onTouched();
  }
}
