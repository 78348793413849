import { Pipe, PipeTransform } from '@angular/core';
import { DocumentCopyItemModel } from '@appDocuments/documents-composition/models/document-copy-item.model';
import { DocumentVersionState } from '@models/document-composition/document-version-state.enum';
import { DocumentsItemModel } from '@models/document-composition/documents-item.model';
import { DocumentsVersionBaseModel } from '@models/document-composition/documents-version-base.model';

@Pipe({ name: 'documentActualVersion' })
export class DocumentActualVersionPipe implements PipeTransform {
  transform = documentActualVersion;
}

export const documentActualVersion = (
  document: DocumentsItemModel | DocumentCopyItemModel
): DocumentsVersionBaseModel => {
  if (!document || (!document.versions && !document.activeVersion)) {
    return null;
  }

  let activeVersion = document.activeVersion;
  if (!activeVersion) {
    activeVersion = document.versions[0];
  }

  const versionStates: DocumentVersionState[] = [DocumentVersionState.COLLABORATION];
  const actualVersion = document.versions && document.versions.find((v) => versionStates.includes(v.state));

  if (actualVersion) {
    return actualVersion;
  }

  return activeVersion;
};
