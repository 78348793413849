import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** Справочник мест проведения пакетов повестки */
export enum ConsiderationPlacesActionTypes {
  GetTopTen = '[Consideration Places] GetTopTen',
  GetTopTenSuccess = '[Consideration Places] GetTopTenSuccess',
  GetTopTenError = '[Consideration Places] GetTopTenError',
  Reset = '[Consideration Places] Reset',
}

export class ConsiderationPlacesGetTopTen implements Action {
  readonly type = ConsiderationPlacesActionTypes.GetTopTen;
}

export class ConsiderationPlacesGetTopTenError implements Action {
  readonly type = ConsiderationPlacesActionTypes.GetTopTenError;
}

export class ConsiderationPlacesGetTopTenSuccess implements Action {
  readonly type = ConsiderationPlacesActionTypes.GetTopTenSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class ConsiderationPlacesReset implements Action {
  readonly type = ConsiderationPlacesActionTypes.Reset;
}

export type ConsiderationPlacesAction =
  | ConsiderationPlacesGetTopTen
  | ConsiderationPlacesGetTopTenSuccess
  | ConsiderationPlacesGetTopTenError
  | ConsiderationPlacesReset;
