import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';

@Pipe({
  name: 'getRouterLink',
})
export class GetRouterLinkPipe implements PipeTransform {
  transform(item: MenuItemModel): string | null {
    return item.withRouterLink ? item.routerLink : null;
  }
}
