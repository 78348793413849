import { Directive, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonClassNames } from '../../enums/common-class-names.enum';
import { TooltipPositions } from '../../enums/tooltip-position.enum';

/**
 * Базовый класс для работы с инпутами, селектами, текстовыми полями.
 * В случае необходимости можно применить в других элементах формы.
 */
@Directive()
export abstract class ControlBaseDirective {
  /**Содержание лейбла для инпута */
  @Input()
  label = '';

  /** Сообщение об ошибке, если таковая имеется */
  @Input()
  errorMsg = '';

  /** Контрол */
  @Input()
  control = new UntypedFormControl();

  /**Имя контролла */
  @Input()
  controlName = '';

  /** Стиль элемента */
  @Input()
  controlStyle = '';

  /** Использовать или нет темную тему */
  @Input()
  isBlackTheme = false;

  /** Плейсхолдер */
  @Input()
  placeholder = '';

  /** Максимальное количество поддерживаемых символов */
  @Input()
  maxLengthInput = null;

  /** Нужно ли скрывать кнопку сброса */
  @Input()
  hasLabelIndents = true;

  /** Подсказка */
  @Input()
  hint = '';

  /** Id элемента */
  @Input()
  elementId = '';

  /** Запускаем директиву на проверку и удаление пробелов в начале строки */
  @Input()
  canDropSpaces = false;

  /** Флаг для абсолютного позиционирования елемнта валидации */
  // TODO: подумть над реализацией без приминения флага
  @Input()
  isErrMsgAbsolute = false;

  /** Текст подсказки у лейбла (пока используется только в input-text) */
  @Input()
  labelTooltipText: string | HTMLElement = '';

  @Input()
  tooltipWidth = 0;

  /** Замена стандартной иконки на кастомную */
  @Input()
  canChangeTipIcon = false;

  /** Позиция подсказки у лейбла */
  @Input()
  labelTooltipPosition = TooltipPositions.right;

  /** Отображать счетчик введенных символов */
  @Input()
  displayMaxLengthCount = false;

  /** Начинать с заглавной буквы */
  @Input()
  capitalLetter = false;

  /** Стилизация ошибки (связанной со значениями в других контролах) - перекрестная валидация */
  @Input()
  crossFieldError: { [key: string]: any } | null = null;

  /** Указывая данный параметр, говорим компоненту, что не надо подсвечивать контрол красным, своими внутренними проверками
   * Контрол будет подсвечен если есть ассоциированные с данным контролом ( через form-field ) ошибки
   *
   * @example
   *   <app-form-field>
   *      <app-textarea
   *        appErrorMark
   *        [formControl]="parentForm.get('name')"
   *        [isFlexibleValidation]="true"
   *      ></app-textarea>
   *      <app-error-alert *ngIf="parentForm.get('name').touched && parentForm.get('name').invalid"></app-error-alert>
   *    </app-form-field>
   */
  @Input()
  isFlexibleErrorIndication = false;

  @Input()
  warningMsg = '';

  public commonClassNames = CommonClassNames;
  public currentLetterCount = 0;

  protected constructor() {
    this.placeholder = '';
  }

  /**
   * Проверка заполненно ли обязательное поле
   *
   * @returns {boolean}
   */
  isControlInvalidAndTouched(): boolean {
    const control = this.control;
    return control.invalid && control.touched;
  }

  /** Удаление пробелов в начале и конце строки */
  protected spaceTrim(value: string) {
    if (!value) {
      return;
    }

    this.control.setValue(value.trim());
  }

  /** Автозамена кавычек на ёлочки */
  protected frenchQuotes(value: string, element: HTMLInputElement): string {
    let result = value;

    if (!result || !result.includes('"')) {
      result = value;
    } else {
      if (result[0] === '"') {
        result = result.replace(/"/, '«');
      }
      result = result.replace(/ "/g, ' «').replace(/"/g, '»');
    }

    setTimeout(() => {
      // TODO: в админке и в НПА указаны разные позиции. Надо стандартизировать
      const position = element.selectionStart;
      element.setSelectionRange(position, position, 'none');
    });

    return result;
  }

  protected toCapitalLetter(value: string): void {
    if (!value) {
      return;
    }

    const formattedName = value[0].toUpperCase() + value.slice(1);

    this.control.setValue(formattedName);
  }

  /** общее название класса для темной темы */
  get blackThemeClassName() {
    return this.isBlackTheme ? CommonClassNames.blackTheme : '';
  }

  /** общее название класса для подсказки */
  get placeholderClassName() {
    return CommonClassNames.placeholder;
  }

  /** общее название класса для заголовка */
  get labelClassName() {
    return CommonClassNames.label;
  }

  /**
   * очистка значения контрола
   */
  public onClearControlValue(): void {
    this.control.setValue(null);
    this.control.markAsTouched();
  }
}
