import { Action } from '@ngrx/store';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';

export enum AvailableNomenclaturesActionTypes {
  Get = '[AvailableNomenclatures] Get',
  GetSuccess = '[AvailableNomenclatures] GetSuccess',
  GetError = '[AvailableNomenclatures] GetError',
}

export class AvailableNomenclaturesGet implements Action {
  readonly type = AvailableNomenclaturesActionTypes.Get;
}

export class AvailableNomenclaturesGetError implements Action {
  readonly type = AvailableNomenclaturesActionTypes.GetError;
}

export class AvailableNomenclaturesGetSuccess implements Action {
  readonly type = AvailableNomenclaturesActionTypes.GetSuccess;
  constructor(public payload: NomenclatureModel[]) {}
}

export type AvailableNomenclaturesAction = AvailableNomenclaturesGet | AvailableNomenclaturesGetError | AvailableNomenclaturesGetSuccess;
