import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPopupService } from '@appCore/services/error-popup/error-popup.service';
import { WafMessageService } from '@appShared/services/waf-message.service';
import { FORBIDDEN, LOCKED, NOT_ACCEPTABLE, UNAUTHORIZED } from 'http-status-codes';
import { environment } from '@environments/environment';
import { DelegatingService } from '../../services/delegeting/delegating.service';
import { Urls } from '@appCore/urls/urls';
import { WINDOW } from '@appShared/tokens/window.token';
import { GENERAL_TEXT_ERRORS } from '@appShared/const/global-text-errors.const';

@Injectable({
  providedIn: 'root',
})
export class GlbHttpErrorHandlerService {
  constructor(
    private router: Router,
    private errorPopup: ErrorPopupService,
    private delegatingService: DelegatingService,
    @Inject(WINDOW) private readonly windowRef: Window,
    private wafMessageService: WafMessageService
  ) {}

  /** Обработчик ошибок */
  public handleError(error: HttpErrorResponse, request?: HttpRequest<any>): void {
    if (!environment.production) {
      console.error(error);
    }

    if (error.status === UNAUTHORIZED) {
      this.redirectToLoginPage();
      return;
    }

    if (error.status === FORBIDDEN) {
      const isBlocked = this.wafMessageService.isBlocked(error);
      if (isBlocked) {
        this.wafMessageService.setWafMessage(error);
        this.router.navigateByUrl('/main/blocked');
        return;
      }
      this.router.navigateByUrl('/main/forbidden', { replaceUrl: true });
      return;
    }

    if (error.status === LOCKED) {
      this.delegatingService.showDelegatingErrorPopup();
      return;
    }

    if (error.status === NOT_ACCEPTABLE && error.error) {
      this.errorPopup.show(error.error);
      return;
    }

    /** Пропал интернет */
    if (error.status === 0) {
      this.errorPopup.show({
        message: GENERAL_TEXT_ERRORS.problemsInternetConnection,
      });
    }
  }

  /** Редирект на страницу логина */
  private redirectToLoginPage(): void {
    const returnURI = this.windowRef.location.href;
    this.windowRef.location.href = Urls.loginPage(returnURI);
  }
}
