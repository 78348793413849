import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'employeeShortName',
})
export class EmployeeShortNamePipe implements PipeTransform {
  transform(employee: EmployeeBaseModel): string {
    return employeeShortName(employee);
  }
}

export function employeeShortName(employee: EmployeeBaseModel): string {
  if (!employee) {
    return '';
  }

  const lastName = employee.lastName || '';
  const shortFirstName = employee.firstName ? `${employee.firstName[0]}.` : '';
  const shortPatronymic = employee.patronymic ? `${employee.patronymic[0]}.` : '';

  return `${lastName} ${shortFirstName}${shortPatronymic}`;
}
