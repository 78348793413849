import {
  RouteSendToApprovementActions,
  RouteSendToApprovementActionTypes,
} from '@appDocuments/documents-route/store/route-send-to-approvement/route-send-to-approvement.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export interface RouteSendToApprovementState {
  phases: any[];
  status: StoreLoadStatus;
  error: any;
  isOpenedSerificateList: boolean
}

export const initialState: RouteSendToApprovementState = {
  phases: null,
  status: null,
  error: null,
  isOpenedSerificateList: false,
};

export function routeSendToApprovementReducer(
  state = initialState,
  action: RouteSendToApprovementActions
): RouteSendToApprovementState {
  switch (action.type) {
    case RouteSendToApprovementActionTypes.GetPhases:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
        error: null,
      };

    case RouteSendToApprovementActionTypes.GetPhasesSuccess: {
      return {
        ...state,
        phases: action.payload,
        status: StoreLoadStatus.loaded,
        error: null,
      };
    }

    case RouteSendToApprovementActionTypes.GetPhasesError: {
      return {
        ...state,
        status: StoreLoadStatus.error,
        error: action.payload,
      };
    }

    case RouteSendToApprovementActionTypes.OpenSertificateList: {
      return {
        ...state,
        isOpenedSerificateList: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
