import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WheelScrollService {
  private wheelScroll$: Subject<void> = new Subject();

  public emitEvent(): void {
    this.wheelScroll$.next();
  }

  public getScrollWheelEvent(): Observable<void> {
    return this.wheelScroll$.pipe(throttleTime(1000));
  }
}
