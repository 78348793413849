import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  DocumentStatusItemsActions,
  DocumentStatusItemsActionTypes,
  DocumentStatusItemsError,
  DocumentStatusItemsGetSuccess,
} from './document-status-items.actions';

@Injectable()
export class DocumentStatusItemsEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentStatusItems$: Observable<DocumentStatusItemsActions> = createEffect(() => this.actions$.pipe(
    ofType(DocumentStatusItemsActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getDocumentPackageStatusItems().pipe(
        map((response) => new DocumentStatusItemsGetSuccess(response)),
        catchError(() => of(new DocumentStatusItemsError()))
      )
    )
  ));
}
