import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { EventBusService } from '@appCore/services/event-bus/event-bus.service';
import { OptionItem } from '@appShared/components/controls/filtered-multi-select/option-item';
import { MultiSelectComponent } from '@appShared/components/controls/multi-select/multi-select.component';
import { FilteredMultiSelectEvents } from '@appShared/enums/filtered-multi-select-events.enum';
import { DomEventService } from '@appShared/services/dom-event.service';
import { EmployeeWithFullNameModel } from '@models/employee/employee-with-full-name.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

/**
 * Компонент мультивыбора
 */
@Component({
  selector: 'app-filtered-multi-select',
  templateUrl: './filtered-multi-select.component.html',
  styleUrls: ['./filtered-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilteredMultiSelectComponent),
      multi: true,
    },
  ],
})
export class FilteredMultiSelectComponent
  extends MultiSelectComponent
  implements OnDestroy, ControlValueAccessor, AfterViewInit, OnInit
{
  @ViewChild('searchField')
  set setSearchElement(elementRef: ElementRef) {
    if (elementRef) {
      elementRef.nativeElement.focus();
    }
  }

  /**Список элементов выпадающего списка */
  public optionsList: EmployeeWithFullNameModel[] = [];
  @Input()
  isNeedPreSelectAllOptions = false;
  @Input('optionsList')
  set setOptionsList(optionsList: EmployeeWithFullNameModel[]) {
    if (optionsList && Array.isArray(optionsList)) {
      if (this.isSearching) {
        this.optionsList = optionsList;
      } else {
        const optionListWithoutSearch = [...this.optionsList, ...optionsList];
        const difference = optionListWithoutSearch.filter((el) => !this.chosenAfterSearch.find((x) => x.id === el.id));
        this.optionsList = [...this.chosenAfterSearch, ...difference];
        this.chosenAfterSearch = [];
      }
      this.mapOptionsObjectsToOptionsFrom();
    }
  }
  /** Параметр для просмотра опции */
  @Input()
  optionLabel: string;
  /** надпись для "Выделить всё" */
  @Input()
  labelCheckAll: string;
  /** флаг, включающий отображение выбраных названий */
  @Input()
  isShowSelectedLabels: boolean;
  /** класс темной темы */
  @HostBinding('class.black-theme')
  isBlackTheme;

  /** массив контролов для чекбоксов */
  public formOptions: UntypedFormArray = new UntypedFormArray([]);
  public controlsList: OptionItem[] = [];
  public filteredControlsList: OptionItem[] = [];
  /** отключение компоненты */
  public disabled: boolean;
  /** флаг активности мапинга отметок */
  public isClosed$ = new BehaviorSubject(true);
  /** надпись о выбранном количестве */
  public labelOfCountChecked$ = new BehaviorSubject('');
  /** контрол для Выделить все */
  public checkAllControl: UntypedFormControl = new UntypedFormControl(false);
  public searchFieldValue = '';
  public subscr: Subscription = new Subscription();
  /** список названий выбранных значений */
  public selectedLabelList: Object[] = [];
  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (valueList: Object[]) => {};
  public onTouched = () => {};
  public onSearchChanging$ = new Subject<string>();

  private page = 0;
  private isSearching = false;
  private chosenAfterSearch: EmployeeWithFullNameModel[] = [];
  private preselectedBeforeSearch: EmployeeWithFullNameModel[] = [];

  constructor(domEventService: DomEventService, elementRef: ElementRef, private eventBusService: EventBusService) {
    super(domEventService, elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.searchSubscription();
  }

  public onClick(): void {
    if (this.searchFieldValue) {
      this.onSearchChange('');
    }
    super.onClick();
  }

  public onTouchClick(event): void {
    event.stopPropagation();
    super.onClick();
    if (!this.isClosed$.getValue() && this.searchFieldValue?.length) {
      this.searchFieldValue = '';
      this.onSearchChange(this.searchFieldValue);
    }
  }

  /** убрать в инпуте выделенное название */
  public removeLabelInInput(label: EmployeeWithFullNameModel): void {
    const indexLabel = this.optionsList.indexOf(label);
    this.formOptions.controls[indexLabel].setValue(false);
  }

  public onScrollDown(): void {
    if (!this.isSearching) {
      this.page++;
      this.eventBusService.emit({ name: FilteredMultiSelectEvents.onPageChange, value: this.page });
    }
  }

  public onSeacrhClick(event): void {
    if (!this.isClosed$.getValue()) {
      event.stopPropagation();
    }
  }

  public onSearchChange(value: string) {
    this.onSearchChanging$.next(value);
  }

  /** мапинг списка объектов в список контролов */
  protected mapOptionsObjectsToOptionsFrom() {
    this.subscr.unsubscribe();
    const checkAll = this.checkAllControl?.value;
    const options = [];
    this.controlsList = [];
    this.checkedOptions = [];

    const selectedOptions = this.findSelectedOptions(checkAll);
    this.checkedOptions = selectedOptions;

    this.optionsList.forEach((item, index) => {
      const control = new UntypedFormControl(false);
      options.push(control);
      this.controlsList.push(new OptionItem(control, index, item[this.optionLabel]));
    });
    this.formOptions = new UntypedFormArray(options);
    this.filteredControlsList = this.controlsList;
    this.subscribeToChecked();

    if (this.checkedOptions) {
      this.checkOptions();
    }
  }

  /** отметить опции списка */
  protected async checkOptions() {
    const formValue = this.optionsList.map((option, i) =>
      this.checkedOptions.some((checkedOption) => (checkedOption as { id: number }).id === option.id)
    );
    await Promise.resolve();
    if (formValue?.length) {
      this.formOptions?.setValue(formValue);
    }
  }

  protected setCheckAll(countChecked: number): void {
    if (!this.labelCheckAll) {
      return;
    }

    if (this.optionsList.length === countChecked && countChecked > 0) {
      this.checkAllControl.setValue(true, {
        emitEvent: false,
      });
    } else {
      this.checkAllControl.setValue(false, {
        emitEvent: false,
      });
    }
  }

  /** Подписка на ввод значения в поиск */
  private searchSubscription(): void {
    this.onSearchChanging$.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
      this.searchFieldValue = value;
      const checkAll = this.checkAllControl?.value;
      this.preselectedBeforeSearch = [...this.preselectedBeforeSearch, ...this.findSelectedOptions(checkAll)];

      if (value?.trim()) {
        this.isSearching = true;
        this.eventBusService.emit({ name: FilteredMultiSelectEvents.onSearch, value });
      } else {
        this.endOfSearch();
      }
    });
  }

  /** Найти все выбранные опции */
  private findSelectedOptions(checkAll: boolean): EmployeeWithFullNameModel[] {
    const options: EmployeeWithFullNameModel[] = [];
    this.optionsList.forEach((item, index) => {
      if (checkAll) {
        options.push(item);
        return;
      }
      const filtredControl = this.filteredControlsList.find((el) => el.label === item.fullName);
      if (filtredControl?.control?.value && !checkAll) {
        options.push(item);
      }
    });
    this.preselectedBeforeSearch.forEach((item, index) => {
      const found = options.find((r) => r.id === item.id);
      if (!checkAll && !found) {
        options.push(item);
      }
    });
    return options;
  }

  /** Завершение поиска */
  private endOfSearch(): void {
    this.chosenAfterSearch = [];
    this.optionsList.forEach((item, index) => {
      if (this.filteredControlsList[index]?.control?.value) {
        this.chosenAfterSearch.push(this.optionsList[index]);
      }
    });
    this.preselectedBeforeSearch.forEach((el) => {
      const found = this.chosenAfterSearch.find((r) => r.id === el.id);
      if (!found) {
        this.chosenAfterSearch.push(el);
      }
    });

    this.optionsList = [];
    this.isSearching = false;
    this.checkAllControl.setValue(false);
    this.eventBusService.emit({ name: FilteredMultiSelectEvents.onSearchEnd, value: this.page + 1 });
  }
}
