import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompositionDocumentLoadModel } from '@appDocuments/documents-composition/models/composition-document-load.model';
import { DocumentCopyItemModel } from '@appDocuments/documents-composition/models/document-copy-item.model';
import { DocumentRevisionInfoModel } from '@appDocuments/documents-composition/models/documents-revision-info.model';
import { OwnVersionRequestModel } from '@appDocuments/documents-composition/models/own-version-request.model';
import { CompositionDownloadDocumentRequestModel } from '@appShared/api/document-composition/models/document-composition.request/composition-download-document-request.model';
import { CompositionDocumentMarkAsEtalonRequestModel } from '@appShared/api/document-composition/models/document-composition.request/composition-mark-as-etalon.request.model';
import { CreateDocumentMasterVersionRequestModel } from '@appShared/api/document-composition/models/document-composition.request/create-document-master-version-request.model';
import { DocumentCreateDraftsRequestModel } from '@appShared/api/document-composition/models/document-composition.request/document-create-drafts-request.model';
import { DocumentsPrintRequestModel } from '@appShared/api/document-composition/models/document-composition.request/documents-print-request.model';
import { CompositionDownloadDocumentResponseModel } from '@appShared/api/document-composition/models/document-composition.response/composition-download-document-response.model';
import { DocumentVersionsModel } from '@models/document-composition/DocumentVersionsModel';
import { DocumentVersionMarker } from '@models/document-composition/document-version-marker.enum';
import { DocumentsItemModel } from '@models/document-composition/documents-item.model';
import { DocumentEditorVersionModel } from '@models/document/document-editor-version.model';
import { DocumentEtalonResModel } from '@models/document/document-etalon.res.model';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http/http.service';
import { Urls, baseUrl } from '../../../core/urls/urls';
import { CompositionAddFileDataModel } from '../../../documents/documents-composition/models/composition-add-file-data.model';
import { CompositionLdeEventModel } from '../../../documents/documents-composition/models/composition-lde-event.model';
import { TaskResponseModel } from '../task/models/task.response';
import { AdditionalReorderingRequest } from './models/document-composition.request/additional-reordering-request.model';
import { CompositionAddDocumentRequestModel } from './models/document-composition.request/composition-add-document-request.model';
import { CompositionImportRevisionRequestModel } from './models/document-composition.request/composition-import-revision-request.model';
import { CompositionUpdateDocumntRequestModel } from './models/document-composition.request/composition-update-document-request.model';
import { DocumentCompositionCommentRequestModel } from './models/document-composition.request/document-composition-comment-request.model';
import { DocumentCompositionCommentResponseModel } from './models/document-composition.response/document-composition-comment-response.model';
import { DocumentCompositionFileResponseModel } from './models/document-composition.response/document-composition-file-response.model';
import { DocumentMergeResponseModel } from './models/document-composition.response/document-merge-response.model';
import { DocumentSignDataResponseModel } from './models/document-composition.response/document-sign-data-response.model';
import { DocumentVersionListResponseModel } from './models/document-composition.response/document-version-list-response.model';
import { DocumentsSigningVersionModel } from '@models/document-composition/documents-version-base.model';

@Injectable({ providedIn: 'root' })
export class DocumentCompositionApiService {
  public readonly url = `${baseUrl}/documents`;

  constructor(private httpService: HttpService) {}

  /** Получение списка комментариев */
  public getCommentList(documentPackageId: number): Observable<DocumentCompositionCommentResponseModel[]> {
    const url = Urls.getDocumentCommentsList(documentPackageId);

    return this.httpService.getObservable(url);
  }

  /** Сохранение комментария */
  public postComment(
    commentData: DocumentCompositionCommentRequestModel
  ): Observable<DocumentCompositionCommentResponseModel> {
    const url: string = Urls.postDocumentComment();

    return this.httpService.postObservable(url, commentData);
  }

  /** Удаление комментария */
  public deleteComment(commentId: number): Observable<null> {
    const url: string = Urls.deleteDocumentComment(commentId);

    return this.httpService.deleteObservable(url);
  }

  /** Импорт документа из файла */
  public importDocument(formData: FormData): Observable<DocumentsItemModel> {
    const url = `${this.url}/import`;
    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
    };

    return this.httpService.postObservable(url, formData, options);
  }

  public importRevisionDocument(data: CompositionImportRevisionRequestModel, versionType?: string): Observable<null> {
    const url = `${this.url}/${data.documentId}/revision-import`;
    let params = new HttpParams().append('format', data.format);

    if (data.fixPageMargins) {
      params = params.append('fixPageMargins', data.fixPageMargins);
    }

    params = versionType ? params.set('versionType', versionType) : params;

    return this.httpService.postObservable(url, data.attachment, { params });
  }

  /**
   * Загрузка файла
   *
   * @param files
   * @returns {Promise<T>}
   */
  public upload(data: CompositionAddFileDataModel): Observable<DocumentCompositionFileResponseModel> {
    const url: string = Urls.uploadFiles();

    const formData: FormData = new FormData();
    formData.append('attachment', data.file, data.file.name);
    formData.append('documentPackageId', data.documentPackageId.toString());
    formData.append('documentTypeId', data.documentTypeId.toString());
    if (data.documentName) {
      formData.append('documentName', data.documentName);
    }

    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
    };

    return this.httpService.postObservable(url, formData, options);
  }

  /**
   * Удаление файла
   *
   * @param fileId
   * @returns {Promise<T>}
   */
  public delete(fileId: number): Observable<null> {
    const url: string = Urls.deleteFile(fileId);

    return this.httpService.deleteObservable(url);
  }

  /** Добавление документа в состав пакета */
  public addDocument(data: CompositionAddDocumentRequestModel): Observable<DocumentsItemModel> {
    const url = `${this.url}/`;

    return this.httpService.postObservable(url, data);
  }

  /** Редактирование документа в составе пакета */
  public updateDocument(data: CompositionUpdateDocumntRequestModel): Observable<DocumentsItemModel> {
    const url = `${this.url}/`;

    return this.httpService.putObservable(url, data);
  }

  /** Удаление документа из состава пакета */
  public deleteDocument(documentId: number): Observable<null> {
    const url = `${this.url}/${documentId}`;

    return this.httpService.deleteObservable(url);
  }

  /** Получение списка документов в составе пакета */
  public getDocumentsList(params: CompositionDocumentLoadModel): Observable<DocumentsItemModel[]> {
    const url: string = Urls.getCompositionDocuments(params.documentPackageId);
    return this.httpService.getObservable(url, { params });
  }

  public getAttachmentsList(documentPackageId: number): Observable<any[]> {
    const url: string = Urls.getAttachmentsList(documentPackageId);
    return this.httpService.getObservable(url);
  }

  /** Получение информации о прочих версии документа */
  public getOtherDocumentVersion(docId: number): Observable<DocumentVersionListResponseModel> {
    const url = `${this.url}/${docId}/versions/other`;
    return this.httpService.getObservable(url);
  }

  /** Получение информации о основных версиях документов   */
  public getDocumentVersions(docId: number): Observable<DocumentVersionsModel> {
    const url = `${this.url}/${docId}/versions`;

    return this.httpService.getObservable(url);
  }

  /** Получение списка действующих редакций доступных пользователю */
  public getSharedActingVersions(dpId: number): Observable<DocumentEditorVersionModel[]> {
    const url = Urls.getSharedActingVersions(dpId);

    return this.httpService.getObservable(url);
  }

  /** Отправка списка документов для формирования документа на печать */
  public postDocumentToPrintList(data: DocumentsPrintRequestModel): Observable<CompositionLdeEventModel> {
    const url = `${this.url}/print`;

    return this.httpService.postObservable(url, data);
  }

  public printDocumentAmendment(dpId: number): Observable<CompositionLdeEventModel> {
    const url = `${this.url}/print-amendment`;
    return this.httpService.getObservable(url, { params: { documentPackageId: dpId } });
  }

  public getDocumentEditorVersion(documentPackageId: number): Observable<DocumentEditorVersionModel[]> {
    const url = `${Urls.documentEditorVersion()}/${documentPackageId}`;

    return this.httpService.getObservable(url);
  }

  /** получение списка связей документов (копий) */
  public getCompositionDocumentsCopies(documentPackageId: number): Observable<DocumentCopyItemModel[]> {
    const url = Urls.getCompositionDocumentsCopies(documentPackageId);

    return this.httpService.getObservable(url);
  }

  public postDocumentEditorVersion(data: DocumentEditorVersionModel[]): Observable<DocumentEditorVersionModel[]> {
    const url: string = Urls.documentEditorVersion();

    return this.httpService.postObservable(url, data);
  }

  public deleteDocumentEditorVersion(versionId: number): Observable<void> {
    const url = `${Urls.documentEditorVersion()}/${versionId}`;

    return this.httpService.deleteObservable(url);
  }

  public refreshDocument(documentId: number, revisions: DocumentRevisionInfoModel[]): Observable<DocumentsItemModel> {
    const url = `${this.url}/${documentId}/refresh/`;

    return this.httpService.postObservable(url, revisions);
  }

  public refreshDocumentHeader(documentId: number, marker?: DocumentVersionMarker): Observable<DocumentsItemModel> {
    const url = `${this.url}/${documentId}/refresh-header/`;
    const params = marker && new HttpParams().set('versionType', marker);

    return this.httpService.postObservable(url, null, { params });
  }

  public refreshAmendment(documentId: number): Observable<DocumentsItemModel> {
    const url = `${this.url}/${documentId}/refresh-amendment/`;

    return this.httpService.postObservable(url, {});
  }

  /** Отправка запроса на скачивание документа */
  public postDownloadDocumentRequest(
    params: CompositionDownloadDocumentRequestModel
  ): Observable<CompositionDownloadDocumentResponseModel> {
    const url = `${this.url}/export`;

    return this.httpService.postObservable(url, {}, { params });
  }

  public postDownloadDocumentsBundleRequest(
    documentPackageId: number,
    params: DocumentRevisionInfoModel[]
  ): Observable<CompositionDownloadDocumentResponseModel> {
    const url = `${this.url}/export-bundle?documentPackageId=${documentPackageId}`;

    return this.httpService.postObservable(url, params);
  }

  /** Создать эталонную версию */
  public postDocumentMarkEtalon(data: CompositionDocumentMarkAsEtalonRequestModel[]): Observable<null> {
    const url: string = Urls.postDocumentEtalon();

    return this.httpService.postObservable(url, data);
  }

  /** Отправить в электронный архив */
  public sendToElectronicArchive(
    dpId: number,
    data: DocumentEditorVersionModel[]
  ): Observable<DocumentEtalonResModel[]> {
    const url: string = Urls.sendToElectronicArchiveRoute(dpId);

    return this.httpService.postObservable(url, data);
  }

  /** Удалить из электронного архива */
  public removeFromElectronicArchive(
    dpId: number,
    data: DocumentEditorVersionModel[]
  ): Observable<DocumentEtalonResModel[]> {
    const url: string = Urls.removeFromElectronicArchiveRoute(dpId);

    return this.httpService.deleteObservable(url, { body: data });
  }

  /** Создаёт мастер-версии документов */
  public createDocumentMasterVersion(documents: CreateDocumentMasterVersionRequestModel[]): Observable<null> {
    const url: string = Urls.getCreateDocumentMasterVersionUrl();
    return this.httpService.postObservable(url, documents);
  }

  public postCreateCurrentVersion(documentIds: number[], documentPackageId: number): Observable<void> {
    const url = Urls.postCurrentVersion();

    return this.httpService.postObservable(url, null, {
      params: {
        documentIds,
        documentPackageId,
      },
    });
  }

  public updateVisibilityActingVersion(documentActingVersionId: number, shared: boolean): Observable<void> {
    const url = Urls.updateVisibilityActingVersion();

    let params = new HttpParams();
    if (documentActingVersionId) {
      params = params.append('documentActingVersionId', documentActingVersionId.toString());
    }
    params = params.append('shared', shared.toString());

    return this.httpService.putObservable(url, null, { params });
  }

  public updateVisibilityActingAllVersions(documentActingVersionId: number[], shared: boolean): Observable<void> {
    const url = Urls.updateVisibilityActingVersionsAll();

    let params = new HttpParams();
    if (documentActingVersionId) {
      params = params.append('actingVersionIds', documentActingVersionId.toString());
    }
    params = params.append('shared', shared.toString());

    return this.httpService.patchObservable(url, null, { params });
  }

  public deleteCurrentVersion(documentId: number): Observable<void> {
    const url = Urls.deleteCurrentVersion();

    return this.httpService.deleteObservable(url, {
      params: {
        documentId,
      },
    });
  }

  /** Запрос на снятие с версии документа признака "эталонный" */
  public unselectEtalonMark(items: CompositionDocumentMarkAsEtalonRequestModel[]): Observable<null> {
    const url = Urls.unselectEtalonMark();
    return this.httpService.postObservable(url, items);
  }

  public documentPackageHasMainDocument(documentId: number): Observable<boolean> {
    const url: string = Urls.documentPackageHasMainDocument(documentId);
    return this.httpService.getObservable(url);
  }

  public getDocumentSignData(ldeId: string, ldeRevision: string): Observable<DocumentSignDataResponseModel> {
    const url = Urls.getDocumentSignData(ldeId, ldeRevision);

    return this.httpService.getObservable(url);
  }

  /** Мердж ревизий документа */
  public mergeDocumentRevisions(documentId: string, revisions: string[]): Observable<DocumentMergeResponseModel> {
    const url = Urls.postMergeRevisions(documentId);
    return this.httpService.postObservable(url, revisions);
  }

  /** Создание черновика */
  public createDocumentDrafts(data: DocumentCreateDraftsRequestModel[]): Observable<void> {
    const url = `${this.url}/draft`;

    return this.httpService.postObservable(url, data);
  }

  /** Изменение порядка документов с типом "Приложение" */
  public changeAdditionalOrder(reqBody: AdditionalReorderingRequest[]): Observable<void> {
    const url = Urls.changeAdditionalOrder();

    return this.httpService.postObservable(url, reqBody);
  }

  /** Создание версий для опубликования в ПД */
  public createPublicationVersion(
    documentPackageId: number,
    revisions: DocumentRevisionInfoModel[]
  ): Observable<DocumentsItemModel[]> {
    const url: string = Urls.createPublicationVersion(documentPackageId);

    return this.httpService.postObservable(url, revisions);
  }

  /** Удаление версии для опубликования */
  public deletePublicationVersion(documentId: number): Observable<DocumentVersionListResponseModel> {
    const url: string = Urls.deletePublicationVersion(documentId);

    return this.httpService.deleteObservable(url);
  }

  public getAllowedToSignDocs(documentPackageId: number, pointId: number): Observable<number[]> {
    const url = Urls.getAllowedToSignDocumentsId();

    let params = new HttpParams();
    params = params.append('documentPackageId', documentPackageId.toString()).append('pointId', pointId.toString());

    return this.httpService.getObservable(url, { params });
  }

  public markOwnVersion(data: { versionData: OwnVersionRequestModel[]; markAsCandidates: boolean }): Observable<void> {
    const url = Urls.postMarkOwnVersion();
    const params = new HttpParams().append('mark-as-candidates', data.markAsCandidates);

    return this.httpService.postObservable(url, data.versionData, { params });
  }

  public getGroupedDocumentVersions(documentId: number): Observable<DocumentVersionListResponseModel> {
    const url = Urls.getGroupedDocumentVersions(documentId);
    return this.httpService.getObservable(url);
  }

  public getDocumentPackageDecisions(documentId: number, pointId: number): Observable<TaskResponseModel[]> {
    const url = Urls.getDocumentPackageDecisions(documentId);
    const params = new HttpParams().append('pointId', pointId);
    return this.httpService.getObservable(url, { params });
  }

  public getDocumentSigningVersions(
    documentPackageId: number,
    agreementRoutePointId: number,
    versionsWithRemarks: DocumentRevisionInfoModel[]
  ): Observable<DocumentsSigningVersionModel[]> {
    const url = Urls.createSigningVersions();

    return this.httpService.postObservable(url, { documentPackageId, agreementRoutePointId, versionsWithRemarks });
  }
}
