import { CryptoProEffects } from '@appCore/store/crypto-pro/crypto-pro.effects';
import { DelegatingEffects } from '@appCore/store/delegating/delegating.effects';
import { NotificationsEffects } from '@appCore/store/notifications/notifications.effects';
import { PersonalFoldersAccessEffects } from '@appCore/store/personal-folders-access/personal-folders-access.effects';
import { PersonalFoldersEffects } from '@appCore/store/personal-folders/personal-folders.effects';
import { RouterEffects } from '@appCore/store/router/router.effects';
import { UserInfoEffects } from '@appCore/store/user-info/user-info.effects';
import { SharedFiltersEffects } from './shared-filters/shared-filters.effects';

export const rootEffects = [
  UserInfoEffects,
  CryptoProEffects,
  RouterEffects,
  NotificationsEffects,
  DelegatingEffects,
  PersonalFoldersAccessEffects,
  PersonalFoldersEffects,
  SharedFiltersEffects,
];
