import { Injectable } from '@angular/core';
import { ToastDocumentModel } from '@appCore/models/toast-document.model';
import { BehaviorSubject } from 'rxjs';
/**
 * Сервис для работы с тостами в модуле документов (Всплывающими уведомлениями)
 */
@Injectable({
  providedIn: 'root'
})
export class ToastsDocumentService {
  private MAX_TOASTS_COUNT = 10;
  private TOAST_LIFETIME = 6000;
  private currentToastsCount = 0;
  private lastId = 0;
  public toasts: BehaviorSubject<ToastDocumentModel[]> = new BehaviorSubject([]);

  /**
   * Показывает новый тост с ошибкой
   * @param text текст для отображения
   */
  public addToast(toast: ToastDocumentModel): void {
    this.toasts.next([...this.toasts.value, toast]);

    // зацикливаем id
    this.lastId = (this.lastId + 1) % this.MAX_TOASTS_COUNT;
    // проставляем id новому тосту
    toast.id = this.lastId;

    // если больше тостов добавлять нельзя надо удалить самый старый тост перед добавлением
    if (this.currentToastsCount === this.MAX_TOASTS_COUNT) {
      this.toasts.next(this.toasts.value.splice(this.lastId, 1));
    }

    setTimeout(() => {
      this.remove(toast);
    }, this.TOAST_LIFETIME);
  }

  public getToasts(): BehaviorSubject<ToastDocumentModel[]> {
    return this.toasts;
  }

  public remove(toast: ToastDocumentModel): void {
    const index = this.toasts.value.indexOf(toast);
    this.toasts.value.splice(index, 1);
  }
}
