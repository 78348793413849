import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { Action } from '@ngrx/store';

export enum DocumentRouteReworkActionTypes {
  DocumentRouteCreateRework = '[Document Route Rework] DocumentRouteCreateRework',
  DocumentRouteCreateReworkSuccess = '[Document Route Rework] DocumentRouteCreateReworkSuccess',
  DocumentRouteStartRework = '[Document Route Rework] DocumentRouteStartRework',
  DocumentRouteStartReworkSuccess = '[Document Route Rework] DocumentRouteStartReworkSuccess',
}

export class DocumentRouteCreateRework implements Action {
  readonly type = DocumentRouteReworkActionTypes.DocumentRouteCreateRework;
}

export class DocumentRouteCreateReworkSuccess implements Action {
  readonly type = DocumentRouteReworkActionTypes.DocumentRouteCreateReworkSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class DocumentRouteStartRework implements Action {
  readonly type = DocumentRouteReworkActionTypes.DocumentRouteStartRework;
}

export class DocumentRouteStartReworkSuccess implements Action {
  readonly type = DocumentRouteReworkActionTypes.DocumentRouteStartReworkSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export type DocumentRouteReworkActions =
  | DocumentRouteCreateRework
  | DocumentRouteCreateReworkSuccess
  | DocumentRouteStartRework
  | DocumentRouteStartReworkSuccess;
