import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { OrganizationModel } from '@models/base/organization.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-organization',
  templateUrl: './autocomplete-organization.component.html',
})
export class AutocompleteOrganizationComponent extends ControlBaseComponent implements OnInit {
  /** Показывать крестик сброса */
  @Input()
  isShowClearBtn = false;
  /** Текст ошибки поиска */
  @Input()
  searchErrorMsg: string;

  private exclusionEmployeeListId: number[];
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public optionsList$: Observable<OrganizationModel[]>;
  public optionLabel: string;
  public isLoaderActive: boolean;

  /** организации, требующие исключения из списка */
  @Input()
  set exclusionOrganizationId(organizationId: number | number[]) {
    this.exclusionEmployeeListId = [].concat(organizationId);
  }

  constructor(private dictionaryApi: DictionaryApiService) {
    super();
  }

  ngOnInit() {
    this.optionLabel = 'name';
    this.subscribeToInputFilter();
  }

  /** подписка на изменение поля фильрации */
  private subscribeToInputFilter() {
    this.optionsList$ = this.filterControl.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.isLoaderActive = true;
      }),
      debounceTime(300),
      switchMap((filterString) =>
        this.dictionaryApi.getOrganizations(filterString).pipe(
          finalize(() => {
            this.isLoaderActive = false;
          })
        )
      ),
      map((organizations) => this.filtredOrganizations(organizations))
    );
  }

  /** вычесть исключения из массива сотрудников */
  private filtredOrganizations(employeeList: OrganizationModel[]): OrganizationModel[] {
    if (this.exclusionEmployeeListId && this.exclusionEmployeeListId.length) {
      return employeeList.filter((organization) =>
        this.exclusionEmployeeListId.find((exclusionId) => exclusionId !== organization.id)
      );
    }

    return employeeList;
  }
}
