import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsIncludedDocumentPipe } from '@appDocuments/documents-composition/pipes/is-included-document.pipe';
import { PermissionsModule } from '@appPermissions/permissions.module';
import { SharedComponentsModule } from '@appShared/components/shared-components.module';
import { ShowIfLocalLegalActDirective } from '@appShared/directives/show-if-local-legal-act.directive';
import { ShowIfNotLocalLegalActDirective } from '@appShared/directives/show-if-not-local-legal-act.directive';
import { AdDirective } from '@appShared/dynamic-component/ad.directive';
import { ModalsHelperModule } from '@appShared/modals-helper/modals-helper.module';
import { ReportGridModule } from '@appShared/modules/report-grid/report-grid.module';
import { SharedPipesModule } from '@appShared/pipes/shared-pipes.module';
import { AgreementRoutePhasesEffects } from '@appShared/store/dictionaries/agreement-route-phases/agreement-route-phases.effects';
import { AlertPeriodTypesEffects } from '@appShared/store/dictionaries/alert-period-types/alert-period-types.effects';
import { CaseStatusesEffects } from '@appShared/store/dictionaries/case-statuses/case-statuses.effects';
import { ConsiderationDocumentTypesEffects } from '@appShared/store/dictionaries/consideration-document-types/consideration-document-types.effects';
import { ConsiderationPlacesEffects } from '@appShared/store/dictionaries/consideration-places/consideration-places.effects';
import { ConsiderationPMMeetingQuestionsTypesEffects } from '@appShared/store/dictionaries/consideration-pm-meeting-questions-types/consideration-pm-meeting-questions-types.effects';
import { ConsiderationQuestionsTypesEffects } from '@appShared/store/dictionaries/consideration-questions-types/consideration-questions-types.effects';
import { ConsiderationTypesEffects } from '@appShared/store/dictionaries/consideration-types/consideration-types.effects';
import { DocumentPackageRelationTypesEffects } from '@appShared/store/dictionaries/document-package-relation-types/document-package-relation-types.effects';
import { DocumentStagesEffects } from '@appShared/store/dictionaries/document-stages/document-stages.effects';
import { DocumentTemplatesEffects } from '@appShared/store/dictionaries/document-templates/document-templates.effects';
import { FolderEditRightTypesEffects } from '@appShared/store/dictionaries/folder/folder-edit-right-types/folder-edit-right-types.effects';
import { FolderViewAccessTypesEffects } from '@appShared/store/dictionaries/folder/folder-view-access-types/folder-view-access-types.effects';
import { NomenclaturesEffects } from '@appShared/store/dictionaries/nomenclatures/nomenclatures.effects';
import { OrganizationsLeaderEffects } from '@appShared/store/dictionaries/organizations-leader/organizations-leader.effects';
import { ParticipantTypesEffects } from '@appShared/store/dictionaries/participant-types/participant-types.effects';
import { QuestionResolutionTypesEffects } from '@appShared/store/dictionaries/question-resolution-types/question-resolution-types.effects';
import { ReservationStatusesEffects } from '@appShared/store/dictionaries/reservation-statuses/reservation-statuses.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TabsetComponent } from './components/tabset/tabset.component';
import { InputRestrictionDirective } from './directives/input-restriction.directive';
import { LetDirective } from './directives/let.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { dictionariesReducer } from './store/dictionaries';
import { ApprovalFormTypesEffects } from './store/dictionaries/approval-form-types/approval-form-types.effects';
import { CoveringTextTypesEffects } from './store/dictionaries/covering-text-types/covering-text-types.effects';
import { DocumentProhibitionTypesEffects } from './store/dictionaries/document-prohibition-types/document-prohibition-types.effects';
import { DocumentStatusItemsEffects } from './store/dictionaries/document-status-items/document-status-items.effects';
import { DocumentStatusValuesEffects } from './store/dictionaries/document-status-values/document-status-values.effects';
import { DocumentStatusesEffects } from './store/dictionaries/document-statuses/document-statuses.effects';
import { DocumentTypesAvailableEffects } from './store/dictionaries/document-types-available/document-types-available.effects';
import { DocumentTypesEffects } from './store/dictionaries/document-types/document-types.effects';
import { OrderTypesEffects } from './store/dictionaries/order-types/order-types.effects';
import { OrganizationsEffects } from './store/dictionaries/organizations/organizations.effects';
import { ProjectTypesEffects } from './store/dictionaries/project-types/project-types.effects';
import { QuestionCategoriesEffects } from './store/dictionaries/question-categories/question-categories.effects';
import { SignerCategoriesEffects } from './store/dictionaries/signer-categories/signer-categories.effects';
import { ScrollToTreePhase } from '@appShared/directives/scroll-to-tree-phase.directive';
import { MaxInputLengthDirective } from './helpers/directives/max-input-length.directive';
import { ReplaceLeadingZeroDirective } from './helpers/directives/replace-leading-zero.directive';
import { AvailableNomenclaturesEffects } from '@appShared/store/dictionaries/availablenomenclatures/available-nomenclatures.effects';

const directives = [
  InputRestrictionDirective,
  MaxInputLengthDirective,
  ReplaceLeadingZeroDirective,
  ScrollSpyDirective,
  AdDirective,
  PreventDoubleClickDirective,
  LetDirective,
  ShowIfNotLocalLegalActDirective,
  ShowIfLocalLegalActDirective,
];

/**
 * https://angular.io/guide/styleguide#shared-feature-module
 */
@NgModule({
  imports: [
    StoreModule.forFeature('dictionaries', dictionariesReducer),
    EffectsModule.forFeature([
      ProjectTypesEffects,
      DocumentStatusesEffects,
      ApprovalFormTypesEffects,
      OrganizationsEffects,
      ConsiderationTypesEffects,
      OrderTypesEffects,
      CoveringTextTypesEffects,
      ParticipantTypesEffects,
      QuestionResolutionTypesEffects,
      SignerCategoriesEffects,
      DocumentTypesEffects,
      DocumentTypesAvailableEffects,
      PermissionsModule,
      DocumentStatusValuesEffects,
      DocumentStatusItemsEffects,
      ConsiderationQuestionsTypesEffects,
      ConsiderationPMMeetingQuestionsTypesEffects,
      DocumentPackageRelationTypesEffects,
      ConsiderationDocumentTypesEffects,
      DocumentTemplatesEffects,
      DocumentStagesEffects,
      CaseStatusesEffects,
      OrganizationsLeaderEffects,
      AlertPeriodTypesEffects,
      ConsiderationPlacesEffects,
      QuestionCategoriesEffects,
      ReservationStatusesEffects,
      DocumentProhibitionTypesEffects,
      FolderViewAccessTypesEffects,
      FolderEditRightTypesEffects,
      AgreementRoutePhasesEffects,
      NomenclaturesEffects,
      AvailableNomenclaturesEffects,
    ]),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedComponentsModule,
    SharedPipesModule,
    ReportGridModule,
    ModalsHelperModule,
  ],
  declarations: [TabsetComponent, ScrollToTreePhase, IsIncludedDocumentPipe, ...directives],
  exports: [
    SharedComponentsModule,
    PermissionsModule,
    SharedPipesModule,
    ReportGridModule,
    ModalsHelperModule,
    TabsetComponent,
    IsIncludedDocumentPipe,
    ScrollToTreePhase,
    ...directives,
  ],
})
export class SharedModule {}
