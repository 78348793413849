import { Pipe, PipeTransform } from '@angular/core';
import { ConsiderationQuestionResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-question.response.model';

/** Пайп для проверки вопроса на "перенесенность"
 * перенесенным считается вопрос у которого поле movedOnDate !== null и если эта дата переноса есть, то она должна быть отлична от даты заседания
 */
@Pipe({
  name: 'isQuestionMoved',
})
export class IsQuestionMovedPipe implements PipeTransform {
  transform(question: ConsiderationQuestionResponseModel, considerationDate: string): boolean {
    return isQuestionMoved(question, considerationDate);
  }
}

export function isQuestionMoved(question: ConsiderationQuestionResponseModel, considerationDate: string): boolean {
  return !!question.movedOnDate && considerationDate !== question.movedOnDate;
}
