/** Словарь плэйсхолдеров */
export enum ToastPlaceholders {
  /** Тип проекта, по которому создано уведомление */
  projectType = 1,
  /** Тема */
  theme = 2,
  /** От кого */
  author = 3,
  /** Тип отправляемого уведомления */
  type = 4,
  /** Значение статуса по данному типу уведомления */
  status = 5,
  /** Предельный срок */
  date = 6,
  /** Учетный номер */
  accountNumber = 7,
  /** Текст */
  text = 8,
  /** Перейти */
  link = 9,
}
