import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import {
  DocumentProhibitionTypesActionTypes,
  DocumentProhibitionTypesUnion,
} from './document-prohibition-types.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryWithCodeModel>([], null);

export function documentProhibitionTypesReducer(
  state: DictionaryStoreModel<DictionaryWithCodeModel> = DEFAULT_STATE,
  action: DocumentProhibitionTypesUnion
) {
  switch (action.type) {
    case DocumentProhibitionTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case DocumentProhibitionTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case DocumentProhibitionTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);

    case DocumentProhibitionTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (documentProhibitionTypes) => documentProhibitionTypes.saveStatus;

/** Получение словаря */
export const getDocumentProhibitionTypes = (documentProhibitionTypes) => documentProhibitionTypes.dictionary;
