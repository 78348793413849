import { TreePhaseModel } from '@appDocuments/documents-route/models/tree/tree-phase.model';
import { ChangedUserAgreementRoutes } from '@appShared/api/documents-route/models/documents-route.request/changed-user-agreement-routes';
import { EmployeeYouCanChangeModel } from '@appShared/api/documents-route/models/employee-you-can-change.model';
import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { TaskResponseModel } from '@appShared/api/task/models/task.response';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentRouteReworkActionTypes } from './document-route-rework.actions';
import { DocumentRouteActions, DocumentRouteActionTypes } from './document-route.actions';

export interface DocumentRouteState {
  documentRoute: RouteTreeResponseModel;
  status: StoreLoadStatus;
  tree: TreePhaseModel[];
  isEsigning: boolean;
  parallelTasks: { [key: number]: TaskResponseModel[] };
  activeCurrentUserPointId: number;
  error: any;
  changedUsers: ChangedUserAgreementRoutes[];
  employeesYouCanChange: EmployeeYouCanChangeModel[];
}

export const initialState: DocumentRouteState = {
  documentRoute: null,
  status: null,
  tree: null,
  isEsigning: false,
  parallelTasks: null,
  activeCurrentUserPointId: null,
  error: null,
  changedUsers: null,
  employeesYouCanChange: [],
};

export const documentRouteReducer = (state = initialState, action: DocumentRouteActions): DocumentRouteState => {
  switch (action.type) {
    case DocumentRouteActionTypes.Reset:
      return initialState;

    case DocumentRouteActionTypes.Approve:
    case DocumentRouteActionTypes.ApproveWithRemark:
    case DocumentRouteActionTypes.Introduce:
    case DocumentRouteActionTypes.SendComment:
    case DocumentRouteActionTypes.AddIncome:
    case DocumentRouteActionTypes.AddIntroducer:
    case DocumentRouteActionTypes.RemoveIntroducer:
    case DocumentRouteActionTypes.AddBefore:
    case DocumentRouteActionTypes.AddAfter:
    case DocumentRouteActionTypes.AddBetween:
    case DocumentRouteActionTypes.Delete:
    case DocumentRouteActionTypes.Get:
    case DocumentRouteActionTypes.InitDeadline:
    case DocumentRouteActionTypes.InitiationAdd:
    case DocumentRouteActionTypes.StartDevelopment:
    case DocumentRouteActionTypes.TotalReturn:
    case DocumentRouteActionTypes.StartApprovement:
    case DocumentRouteActionTypes.InternalReturn:
    case DocumentRouteActionTypes.ExternalReturn:
    case DocumentRouteActionTypes.AddPhases:
    case DocumentRouteActionTypes.AddPhasesParallel:
    case DocumentRouteActionTypes.RemoveInspector:
    case DocumentRouteActionTypes.AddCoauthor:
    case DocumentRouteActionTypes.AddController:
    case DocumentRouteActionTypes.RemoveCoauthor:
    case DocumentRouteActionTypes.SendToOuterApprovement:
    case DocumentRouteActionTypes.SendToOauLeader:
    case DocumentRouteActionTypes.ChangeSignerCategories:
    case DocumentRouteActionTypes.ChangeAccessoryToInvestmentProject:
    case DocumentRouteActionTypes.Repair:
    case DocumentRouteActionTypes.ChangeEmployeeForPoint:
    case DocumentRouteActionTypes.RemovePhase:
    case DocumentRouteActionTypes.ChangedUsers:
    case DocumentRouteReworkActionTypes.DocumentRouteCreateRework:
    case DocumentRouteReworkActionTypes.DocumentRouteStartRework:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
        error: null,
      };

    case DocumentRouteActionTypes.UpdateRoute:
    case DocumentRouteActionTypes.InitDeadlineSuccess:
    case DocumentRouteActionTypes.AddIncomeSuccess:
    case DocumentRouteActionTypes.AddIntroducerSuccess:
    case DocumentRouteActionTypes.RemoveIntroducerSuccess:
    case DocumentRouteActionTypes.AddAfterSuccess:
    case DocumentRouteActionTypes.AddBeforeSuccess:
    case DocumentRouteActionTypes.AddBetweenSuccess:
    case DocumentRouteActionTypes.DeleteSuccess:
    case DocumentRouteActionTypes.GetSuccess:
    case DocumentRouteActionTypes.InitiationAddSuccess:
    case DocumentRouteActionTypes.StartDevelopmentSuccess:
    case DocumentRouteActionTypes.TotalReturnSuccess:
    case DocumentRouteActionTypes.StartApprovementSuccess:
    case DocumentRouteActionTypes.InternalReturnSuccess:
    case DocumentRouteActionTypes.ExternalReturnSuccess:
    case DocumentRouteActionTypes.AddPhasesSuccess:
    case DocumentRouteActionTypes.AddPhasesParallelSuccess:
    case DocumentRouteActionTypes.RemoveInspectorSuccess:
    case DocumentRouteActionTypes.AddCoauthorSuccess:
    case DocumentRouteActionTypes.AddControllerSuccess:
    case DocumentRouteActionTypes.RemoveCoauthorSuccess:
    case DocumentRouteActionTypes.SendToOuterApprovementSuccess:
    case DocumentRouteActionTypes.SendToOauLeaderSuccess:
    case DocumentRouteActionTypes.ChangeSignerCategoriesSuccess:
    case DocumentRouteActionTypes.RepairSuccess:
    case DocumentRouteActionTypes.ChangeEmployeeForPointSuccess:
    case DocumentRouteActionTypes.RemovePhaseSuccess:
    case DocumentRouteReworkActionTypes.DocumentRouteCreateReworkSuccess:
    case DocumentRouteReworkActionTypes.DocumentRouteStartReworkSuccess:
    case DocumentRouteActionTypes.SkipPhaseSuccess:
    case DocumentRouteActionTypes.MarkResponsibleExecutorSuccess:
      return {
        ...state,
        documentRoute: action.payload,
        status: StoreLoadStatus.loaded,
        error: null,
      };

    case DocumentRouteActionTypes.UpdateTree: {
      return {
        ...state,
        tree: action.payload,
      };
    }

    case DocumentRouteActionTypes.Error:
    case DocumentRouteActionTypes.SkipPhaseError:
      return {
        ...state,
        status: StoreLoadStatus.error,
        error: action.payload,
      };

    case DocumentRouteActionTypes.AddParallelTasks: {
      const tasks = action.payload;
      const parallelTasks = tasks.reduce((prev, curr) => ({ ...prev, [curr.id]: tasks }), {});

      return {
        ...state,
        parallelTasks: { ...state.parallelTasks, ...parallelTasks },
      };
    }

    case DocumentRouteActionTypes.UpdateEsigningState: {
      return {
        ...state,
        isEsigning: action.payload,
      };
    }

    case DocumentRouteActionTypes.SetActiveCurrentPointId: {
      return {
        ...state,
        activeCurrentUserPointId: action.payload,
      };
    }

    case DocumentRouteActionTypes.ChangedUsersSuccess: {
      return {
        ...state,
        changedUsers: action.payload,
        status: StoreLoadStatus.loaded,
      };
    }

    case DocumentRouteActionTypes.GetEmployeesYouCanChangeSuccess: {
      return {
        ...state,
        employeesYouCanChange: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
