import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OibRoles } from '@models/enums/oib-roles.enum';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';

@Injectable({
  providedIn: 'root',
})
export class SwitchEditorService {
  public getEditorScriptSrc(userRoles: string[], endingSrc: string): string {
    const ldeDocumentToken = Number(localStorage.getItem('ldeDocumentType'));

    let ldeScriptSrc;

    if (!ldeDocumentToken) {
      if (this.isLocalRole(userRoles)) {
        ldeScriptSrc = `${environment.pathToLdeScriptsLpa}/${endingSrc}`;
        localStorage.setItem('ldeDocumentType', `${ProjectGroupType.LOCAL_LEGAL_ACTS}`);
      } else {
        ldeScriptSrc = `${environment.pathToLdeScriptsNpa}/${endingSrc}`;
        localStorage.setItem('ldeDocumentType', `${ProjectGroupType.LEGAL_ACTS}`);
      }
    }

    // сетим ресурсы NPA
    if (ldeDocumentToken === ProjectGroupType.LEGAL_ACTS) {
      ldeScriptSrc = `${environment.pathToLdeScriptsNpa}/${endingSrc}`;
    }

    // сетим ресурсы LPA
    if (ldeDocumentToken === ProjectGroupType.LOCAL_LEGAL_ACTS) {
      ldeScriptSrc = `${environment.pathToLdeScriptsLpa}/${endingSrc}`;
    }

    return ldeScriptSrc;
  }

  public getPathToModule(): string {
    let pathToModule;
    const ldeDocumentToken = Number(localStorage.getItem('ldeDocumentType'));

    if (ldeDocumentToken === ProjectGroupType.LEGAL_ACTS) {
      pathToModule = `${environment.pathToLdeScriptsNpa}/lde/lde`;
    }

    if (ldeDocumentToken === ProjectGroupType.LOCAL_LEGAL_ACTS) {
      pathToModule = `${environment.pathToLdeScriptsLpa}/lde/lde`;
    }
    return pathToModule;
  }

  private isLocalRole(userRoles: string[]): boolean {
    return userRoles.includes(OibRoles.internalUser) && !userRoles.includes(OibRoles.governmentUser);
  }
}
