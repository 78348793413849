import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { CoveringTextTypesAction, CoveringTextTypesActionTypes } from './covering-text-types.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryModel>([], null);

export function coveringTextTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: CoveringTextTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case CoveringTextTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case CoveringTextTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case CoveringTextTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case CoveringTextTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение словаря */
export const getCoveringTextTypes = (coveringTextTypes) => coveringTextTypes.dictionary;
