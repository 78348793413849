import { CalendarPeriodParamsModel } from '@models/calendar/calendar.request/calendar-period-params.model';
import { Action } from '@ngrx/store';

export enum MeetingDatesActionTypes {
  get = '[Meeting Dates] get meetings',
  getSuccess = '[Meeting Dates] get success',
  getError = '[Meeting Dates] get success',
  reset = '[Meeting Dates] reset',
}

export class MeetingDatesGet implements Action {
  readonly type = MeetingDatesActionTypes.get;
  constructor(public payload: { loadInterval: CalendarPeriodParamsModel; meetingTypeIds: number | number[] }) {}
}

export class MeetingDatesGetSuccess implements Action {
  readonly type = MeetingDatesActionTypes.getSuccess;
  constructor(public payload: string[]) {}
}

export class MeetingDatesGetError implements Action {
  readonly type = MeetingDatesActionTypes.getError;
  constructor(public payload: any) {}
}

export class MeetingDatesReset implements Action {
  readonly type = MeetingDatesActionTypes.reset;
}

export type MeetingDatesActions = MeetingDatesGet | MeetingDatesGetSuccess | MeetingDatesGetError | MeetingDatesReset;
