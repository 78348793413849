import {
  InputCalendarModuleState,
  selectInputCalendarModule
} from '@appShared/components/controls/input-calendar/store';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { CalendarHolidaysStoreModel } from '../../models/holiday.model';

/** селектор на хранилище праздников  */
export const getHolidaysStore = createSelector(
  selectInputCalendarModule,
  (state: InputCalendarModuleState) => state.holidays
);

/** Селект на список праздников */
export const getHolidays = createSelector(getHolidaysStore, (state: CalendarHolidaysStoreModel) => state.holidays);

/** Селект на сосотояние загрузки */
export const getIsInProgress = createSelector(
  getHolidaysStore,
  (state: CalendarHolidaysStoreModel) => state.loadStatus === StoreLoadStatus.inProgress
);

export const getNextWorkingDateAfterDate = (date: Date) =>
  createSelector(getHolidays, days => {
    const nextWorkingDay = days.find(day => day.isWeekend === false && moment(day.date).isAfter(date));
    return nextWorkingDay;
  });
