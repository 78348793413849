import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { TemplateDocumentTypeModel } from '@models/document/template-document-type.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentTemplatesActionTypes, DocumentTemplatesTypesAction } from './document-templates.actions';

export const initialState = new DictionaryStoreModel<TemplateDocumentTypeModel>([], null, null);

export function documentTemplatesReducer(
  state = initialState,
  action: DocumentTemplatesTypesAction
): DictionaryStoreModel<TemplateDocumentTypeModel> {
  switch (action.type) {
    case DocumentTemplatesActionTypes.Reset:
      return initialState;

    case DocumentTemplatesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentTemplatesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case DocumentTemplatesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (state) => state.loadStatus;
/** Получение словаря */
export const getDocumentTemplates = (state) => state.dictionary;
