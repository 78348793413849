import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { PageNotFoundModule } from 'app/page-not-found/page-not-found.module';
import { AuthGuard } from './guards/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () => import('app/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () => import('app/documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'personal-cabinet',
    loadChildren: () => import('app/personal-cabinet/personal-cabinet.module').then((m) => m.PersonalCabinetModule),
    canActivate: [AuthGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

/**
 * https://angular.io/guide/router
 */
@NgModule({
  imports: [
    CommonModule,
    PageNotFoundModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules }),
  ],
  providers: [AuthGuard],
  declarations: [],
})
export class AppRoutingModule {}
