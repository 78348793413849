/** Типы рассмотрения/заседания */
export enum ConsiderationTypes {
  /** совещание */
  conference = 1,
  /** заседание ПМ */
  meetingPM = 2,
  /** согласительное совещание */
  consensusConference = 3,
  /** заседание ППМ */
  meetingPPM = 5,
}
