import {
  AlertPeriodTypesAction,
  AlertPeriodTypesActionTypes,
} from '@appShared/store/dictionaries/alert-period-types/alert-period-types.actions';
import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState = new DictionaryStoreModel<DictionaryWithDescriptionModel>([], null, null);

export function alertPeriodTypesReducer(
  state = initialState,
  action: AlertPeriodTypesAction
): DictionaryStoreModel<DictionaryWithDescriptionModel> {
  switch (action.type) {
    case AlertPeriodTypesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case AlertPeriodTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case AlertPeriodTypesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (state) => state.loadStatus;
/** Получение словаря */
export const getAlertPeriodTypes = (state) => state.dictionary;
