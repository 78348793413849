import { DictionaryModel } from '@models/base/dictionary.model';
import { Action } from '@ngrx/store';

export enum CaseStatusesActionTypes {
  Get = '[Case Statuses] Get',
  GetSuccess = '[Case Statuses] GetSuccess',
  GetError = '[Case Statuses] GetError',
}

export class CaseStatusesGet implements Action {
  readonly type = CaseStatusesActionTypes.Get;
}

export class CaseStatusesGetError implements Action {
  readonly type = CaseStatusesActionTypes.GetError;
}

export class CaseStatusesGetSuccess implements Action {
  readonly type = CaseStatusesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export type CaseStatusesTypesAction = CaseStatusesGet | CaseStatusesGetError | CaseStatusesGetSuccess;
