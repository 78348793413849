// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-list {
  max-height: 450px;
}

.select-list-is-empty {
  padding: 5px 20px;
  background: #fff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyb3VwLWl0ZW0tc2VhcmNoLXNlbGVjdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxpQkFBQTtFQUNBLGdCQUFBO0FBQ0YiLCJmaWxlIjoiZ3JvdXAtaXRlbS1zZWFyY2gtc2VsZWN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRlbnQtbGlzdCB7XHJcbiAgbWF4LWhlaWdodDogNDUwcHg7XHJcbn1cclxuXHJcbi5zZWxlY3QtbGlzdC1pcy1lbXB0eSB7XHJcbiAgcGFkZGluZzogNXB4IDIwcHg7XHJcbiAgYmFja2dyb3VuZDogI2ZmZjtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/controls/select/group-item-search-select/group-item-search-select.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;AACA,ogBAAogB","sourcesContent":[".content-list {\r\n  max-height: 450px;\r\n}\r\n\r\n.select-list-is-empty {\r\n  padding: 5px 20px;\r\n  background: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
