import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  QuestionCategoriesAction,
  QuestionCategoriesActionTypes,
  QuestionCategoriesGet,
  QuestionCategoriesGetError,
  QuestionCategoriesGetSuccess,
} from '@appShared/store/dictionaries/question-categories/question-categories.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class QuestionCategoriesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetQuestionCategories$: Observable<QuestionCategoriesAction> = createEffect(() => this.actions$.pipe(
    ofType(QuestionCategoriesActionTypes.Get),
    switchMap((action: QuestionCategoriesGet) =>
      this.dictionaryApi.getConsiderationQuestionCategories().pipe(
        map((response) => new QuestionCategoriesGetSuccess(response)),
        catchError((err) => of(new QuestionCategoriesGetError()))
      )
    )
  ));
}
