import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkObjectForNotNullableKey'
})
export class CheckObjectForNotNullableKeyPipe implements PipeTransform {
  transform = isObjectHaveNotNullableKey;
}

export function isObjectHaveNotNullableKey(object: Object): boolean {
  if (!object) {
    return false;
  }

  return Object.keys(object).some(i => !!object[i]);
}
