import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'environments/environment';

Sentry.init({
  dsn: environment.ravenDns,
  enabled: environment.sentryEnabled,
});

/**Сервис для обработки ошибок */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(public injector: Injector) {}

  handleError(error: any): void {
    try {
      if (environment.ravenDns) {
        Sentry.captureException(error.originalError || error);
      }

      console.error('An error occured', error);
    } catch (error) {}
  }
}
