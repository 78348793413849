import { Action } from '@ngrx/store';
import { DictionaryFullNameModel } from '@models/base/dictionary-full-name.model';

export enum ApprovalFormTypesActionTypes {
  Get = '[Approval Form Types] Get',
  GetSuccess = '[Approval Form Types] GetSuccess',
  GetError = '[Approval Form Types] GetError',
  Reset = '[Approval Form Types] Reset',
}

/** Обновление типа проекта */
export class ApprovalFormTypesGet implements Action {
  readonly type = ApprovalFormTypesActionTypes.Get;
}

/** Обновление типа проекта */
export class ApprovalFormTypesGetError implements Action {
  readonly type = ApprovalFormTypesActionTypes.GetError;
}

/** Обновление типа проекта */
export class ApprovalFormTypesGetSuccess implements Action {
  readonly type = ApprovalFormTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryFullNameModel[]) {}
}

/** Обновление типа проекта */
export class ApprovalFormTypesReset implements Action {
  readonly type = ApprovalFormTypesActionTypes.Reset;
}

export type ApprovalFormTypesAction =
  | ApprovalFormTypesGet
  | ApprovalFormTypesGetSuccess
  | ApprovalFormTypesGetError
  | ApprovalFormTypesReset;
