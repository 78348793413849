import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  ConsiderationQuestionsTypesAction,
  ConsiderationQuestionsTypesActionTypes,
  ConsiderationQuestionsTypesGetSuccess,
  ConsiderationQuestionsTypesReset,
} from '@appShared/store/dictionaries/consideration-questions-types/consideration-questions-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class ConsiderationQuestionsTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetConsiderationQuestionTypes$: Observable<ConsiderationQuestionsTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ConsiderationQuestionsTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getAgendaPackageQuestionsTypes().pipe(
        map((response) => new ConsiderationQuestionsTypesGetSuccess(response)),
        catchError((err) => of(new ConsiderationQuestionsTypesReset()))
      )
    )
  ));
}
