export enum DocumentPermissions {
  DOCUMENT_DELETE = 'DOCUMENT_DELETE',
  REMARKS_SHEET_DELETE = 'REMARKS_SHEET_DELETE',
  /** Просмотр удаленных документов */
  DOCUMENT_VIEW_DELETED = 'DOCUMENT_VIEW_DELETED',
  DOCUMENT_VIEW = 'DOCUMENT_VIEW',
  /** редактирование атрибутов документа */
  DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',
  DOCUMENT_EXPORT = 'DOCUMENT_EXPORT',
  /** Добавление новой версии документа */
  DOCUMENT_REVISION_IMPORT = 'DOCUMENT_REVISION_IMPORT',
  /** Обновление документа */
  DOCUMENT_REFRESH = 'DOCUMENT_REFRESH',
  DOCUMENT_REFRESH_HEADER = 'DOCUMENT_REFRESH_HEADER',
  /** Перемещение документов */
  DOCUMENT_EDIT_SERIAL_NUMBER = 'DOCUMENT_EDIT_SERIAL_NUMBER',
  /** Удаление ОАУ ВДО */
  DOCUMENT_PUBLICATION_IN_OAU_DOCUMENT_SECTION_DELETE = 'DOCUMENT_PUBLICATION_IN_OAU_DOCUMENT_SECTION_DELETE',
  /** Удаление Документов для опубликования ВДО */
  DOCUMENT_PUBLICATION_IN_DOCUMENT_PUBLICATION_SECTION_DELETE = 'DOCUMENT_PUBLICATION_IN_DOCUMENT_PUBLICATION_SECTION_DELETE',
  /** Правила удаления Документа: "Действующая редакция" при наличии других версий Документа */
  UNMARK_ACTING_FROM_DOCUMENT_REVISION = 'UNMARK_ACTING_FROM_DOCUMENT_REVISION',
  UNMARK_ETALON_FROM_DOCUMENT_REVISION = 'UNMARK_ETALON_FROM_DOCUMENT_REVISION',
  MARK_DOCUMENT_REVISION_AS_ACTING = 'MARK_DOCUMENT_REVISION_AS_ACTING',
  MARK_DOCUMENT_REVISION_AS_ETALON = 'MARK_DOCUMENT_REVISION_AS_ETALON',
  MARK_DOCUMENT_VERSION_AS_MASTER = 'MARK_DOCUMENT_VERSION_AS_MASTER',
  EDITOR_VERSION_DOCUMENT_DELETE = 'EDITOR_VERSION_DOCUMENT_DELETE',
  DOWNLOAD_OAU_DOCUMENTS = 'DOWNLOAD_OAU_DOCUMENTS',
  /** редактирование атрибутов документа ЛПА для ВДО */
  EDIT_DOCUMENT_PUBLICATION = 'EDIT_DOCUMENT_PUBLICATION',
}
