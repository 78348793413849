import { getUserInfo } from '@appCore/store/user-info';
import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { RoutePhaseTypes } from '@appDocuments/documents-route/enums/route-phase-types.enum';
import { VertexState } from '@appDocuments/documents-route/enums/vertex-state.enum';
import { TreeSubPhaseModel } from '@appDocuments/documents-route/models/tree/tree-sub-phase.model';
import {
  getAllRouteSubphases,
  getDocumentRouteStoreData,
} from '@appDocuments/documents-route/store/document-route/selectors/common-selectors';
import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { VertexResponseModel } from '@appShared/api/documents-route/models/route-tree.response/vertex.response.model';
import { SystemPointIds } from '@models/enums/system-point-ids.enum';
import { UserInfoResponseModel } from '@models/user-info.model';
import { createSelector } from '@ngrx/store';

export const getActivePointOnMainVertical = createSelector(getDocumentRouteStoreData, (route) =>
  route ? route.vertexes.find((v) => v.active && v.level === 1) : null
);

export const getActivePointsOnMainVertical = createSelector(getDocumentRouteStoreData, (route) =>
  route
    ? route.vertexes.filter((v) => [VertexState.active, VertexState.redirected].includes(v.state) && v.level === 1)
    : []
);

/** Получение активной точки авторизованного пользователя */
export const getActivePointForCurrentEmployee = createSelector(
  getDocumentRouteStoreData,
  getUserInfo,
  (route, userInfo) => {
    if (route && userInfo) {
      let activeUserPoints = route.vertexes
        .filter((v) => !v.isSystem)
        .filter(
          (v) =>
            [VertexState.active, VertexState.redirected, VertexState.waitingForApprovement].includes(v.state) &&
            v.employee.id === userInfo.currentUser.employee.id
        );

      const isAuthor = route.vertexes.some(
        (v) => v.employee?.id === userInfo.currentUser.employee.id && v.pointRoleId === EmployeeRouteTypes.author
      );

      const projectCreatedPhase = [
        RoutePhaseTypes.projectCreated,
        RoutePhaseTypes.projectCreatedInitiation,
        RoutePhaseTypes.projectCreatedDevelopment,
        RoutePhaseTypes.projectCreatedApprovement,
        RoutePhaseTypes.projectCreatedRework,
      ];

      const externalPointsUser = activeUserPoints.filter((point) => !projectCreatedPhase.includes(point.phaseTypeId));
      const isActiveExternalPointsUser = externalPointsUser.some((point) =>
        [VertexState.active, VertexState.redirected].includes(point.state)
      );

      /** Отображение кнопок согласующих у автора, пока все активные задачи на внешних этапах не будут закрыты */
      if (isAuthor && !!externalPointsUser && isActiveExternalPointsUser) {
        activeUserPoints = activeUserPoints.filter((point) => point.pointRoleId !== EmployeeRouteTypes.author);
      }

      if (activeUserPoints.length > 1) {
        return getUserPointFromManyActive(activeUserPoints, route);
      }

      return activeUserPoints[0];
    }

    return null;
  }
);

const getUserPointFromManyActive = (
  activeUserPoints: VertexResponseModel[],
  route: RouteTreeResponseModel
): VertexResponseModel =>
  activeUserPoints
    .sort((a, b) => b.id - a.id)
    .reduce((point, currentPoint) => {
      const phasePoint = route.phases.find((p) => p.id === point.phaseId);
      const phaseCurrentPoint = route.phases.find((p) => p.id === currentPoint.phaseId);

      const pointDeadLineDate = new Date(point.datesModel.deadlineDate).getTime();
      const currentPointDeadLineDate = new Date(currentPoint.datesModel.deadlineDate).getTime();

      /** Сравнение точек если даты равны */
      if (phasePoint && phaseCurrentPoint && pointDeadLineDate === currentPointDeadLineDate) {
        /** Выбираем точку самую дальную по уровню вложенности, но меньшую по id */
        if (point.level > currentPoint.level) {
          return point.id > currentPoint.id ? point : currentPoint;
        }

        return phasePoint.parentPhase.id <= phaseCurrentPoint.parentPhase.id ? point : currentPoint;
      }

      /** Выбираем самую раннюю точку */
      return pointDeadLineDate < currentPointDeadLineDate ? point : currentPoint;
    });

export const getAllCurrentUserPoints = createSelector(getDocumentRouteStoreData, getUserInfo, (route, userInfo) =>
  route && userInfo
    ? route.vertexes.filter((v) => !v.isSystem).filter((v) => v.employee.id === userInfo.currentUser.employee.id)
    : []
);

/** Является ли текущий пользователь последним на этапе инициации (нужно для отображения/сокрытия кнопок на этапе) */
export const getIsLastEmployeeInRouteOnInitiation = createSelector(
  getAllRouteSubphases,
  getUserInfo,
  (subphases: TreeSubPhaseModel[], userInfo: UserInfoResponseModel) => {
    if (!subphases || !userInfo) {
      return false;
    }

    const employee = userInfo.currentUser.employee;

    const initiationSubPhase = subphases
      .filter((i) => i.phaseType.id === RoutePhaseTypes.projectCreatedInitiation)
      .slice(-1)[0];

    const isInitiationSubphaseLength =
      !initiationSubPhase || !initiationSubPhase.steps || !initiationSubPhase.steps.length;

    if (isInitiationSubphaseLength) {
      return false;
    }

    const lastInitiationPoint = initiationSubPhase.steps[initiationSubPhase.steps.length - 1][0];

    return lastInitiationPoint.metadata.employee.id === employee.id;
  }
);

export const getCanDecide = createSelector(
  getDocumentRouteStoreData,
  getActivePointForCurrentEmployee,
  (route, activePoint) => {
    if (!route || !activePoint) {
      return false;
    }

    const currentEdge = route.edges.find((edge) => edge[0] === activePoint.id);

    const isLastPointInRoute = currentEdge[1] === SystemPointIds.STOP_POINT_ID;

    if (isLastPointInRoute) {
      return false;
    }

    return true;
  }
);

export const getIsCurrentUserController = createSelector(getDocumentRouteStoreData, getUserInfo, (route, userInfo) => {
  if (!route || !userInfo) {
    return false;
  }

  return route.controllers.some((c) => c.employee.id === userInfo.currentUser.employee.id);
});

/**
 * Возвращает список неактивных точек, следующих за точкой тек. пользователя, добавленных из шаблона
 * (тек. пользователь добавил исполнителя и соисполнителя из шаблона,
 * эти точки неактивные, а точка текущего пользователя активная)
 * */
export const getInactiveInitiationPoints = createSelector(
  getDocumentRouteStoreData,
  getUserInfo,
  getActivePointForCurrentEmployee,
  (route, userInfo, activePoint) => {
    if (!route || !userInfo || !activePoint) {
      return [];
    }

    const typesInitiation = [
      RoutePhaseTypes.projectCreatedInitiation,
      RoutePhaseTypes.responsibleExecutorInitiation,
      RoutePhaseTypes.questionProvidedInitiation,
      RoutePhaseTypes.puPmInitiation,
      RoutePhaseTypes.oauPmInitiation,
      RoutePhaseTypes.projectApprovedInitiation,
      RoutePhaseTypes.oauRegistrationInitiation,
      RoutePhaseTypes.preliminaryApprovementInitiation,
    ];

    return route.vertexes
      .filter((v) => !v.isSystem)
      .filter(
        (v) =>
          typesInitiation.includes(v.phaseTypeId) &&
          v.phaseId === activePoint.phaseId &&
          v.id !== activePoint.id &&
          v.state === VertexState.inactive
      );
  }
);

export const getRecallInitiatorPoint = createSelector(
  getAllCurrentUserPoints,
  getActivePointsOnMainVertical,
  (currentUserPoints, activePoints) => {
    const allowedPhaseIds = currentUserPoints.map((point) => point.phaseId);
    const activePoint = activePoints.find((point) => allowedPhaseIds.includes(point.phaseId));

    return currentUserPoints.find((point) => point.phaseId === activePoint.phaseId);
  }
);

export const hasOauRegistrationApprovementPoints = createSelector(getAllCurrentUserPoints, (points) =>
  points.some((point) => point.phaseTypeId === RoutePhaseTypes.oauRegistrationApprovement)
);
