import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@appShared/tokens/window.token';
import { environment } from '@environments/environment';
import { UserInfoResponseModel } from '@models/user-info.model';
import { UNAUTHORIZED } from 'http-status-codes';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpService } from '../../../core/services/http/http.service';
import { baseUrl } from '../../../core/urls/urls';

@Injectable({
  providedIn: 'root',
})
export class UserInfoApiService {
  private readonly logoutUrl = `${environment.authBaseUrl}/logout`;
  private readonly url = `${baseUrl}/principals`;

  constructor(private http: HttpService, @Inject(WINDOW) private readonly windowRef: Window) {}

  getInfo(): Observable<UserInfoResponseModel> {
    return this.http.getObservable<UserInfoResponseModel>(this.url);
  }

  public canChangeUser(): boolean {
    return environment.enableMockUser;
  }

  public logout(): void {
    this.http
      .getObservable(this.url)
      .pipe(first())
      .subscribe(() => {
        window.location.href = this.logoutUrl;
      });
  }

  public changeMockUser(login: string): void {
    if (!this.canChangeUser()) {
      return;
    }

    this.http.postObservable(`${this.url}/switch-to-user/${login}`).subscribe(this.reloadApp(this.windowRef));
  }

  public reloadApp(windowRef: Window): { [key: string]: (param: any) => void | null } {
    return {
      next: () => windowRef.location.reload(),
      error: (err) => {
        if (err.status === UNAUTHORIZED) {
          windowRef.location.reload();
        }
      },
    };
  }

  public resetMockUser(): Observable<void> {
    return this.http.postObservable(`${this.url}/switch-to-yourself`);
  }

  public updateInfoAboutDelegating() {
    return this.http.postObservable(`${baseUrl}/security/delegating/refresh-delegating`);
  }
}
