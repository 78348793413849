import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { Action } from '@ngrx/store';

/** Справочник "типы согласования" */

export enum OrderTypesActionTypes {
  Get = '[Order Types] Get',
  GetSuccess = '[Order Types] GetSuccess',
  GetError = '[Order Types] GetError',
  Reset = '[Order Types] Reset',
}

export class OrderTypesGet implements Action {
  readonly type = OrderTypesActionTypes.Get;
}

export class OrderTypesGetError implements Action {
  readonly type = OrderTypesActionTypes.GetError;
}

export class OrderTypesGetSuccess implements Action {
  readonly type = OrderTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithCodeModel[]) {}
}

export class OrderTypesReset implements Action {
  readonly type = OrderTypesActionTypes.Reset;
}

export type OrderTypesAction = OrderTypesGet | OrderTypesGetSuccess | OrderTypesGetError | OrderTypesReset;
