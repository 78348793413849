import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomEventService } from '@appShared/services/dom-event.service';
import { UnderscoreType } from '@libs/projects/component-lib/src/public-api';
import { DictionaryModel } from '@models/base/dictionary.model';
import { takeUntil } from 'rxjs/operators';
import { ControlBaseComponent } from '../control-base/control-base.component';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['../control-base/control-base.component.scss', './autocomplete.component.scss'],
})
export class AutocompleteComponent extends ControlBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Список элементов выпадающего списка */
  @Input()
  optionLabel: string;
  @Input()
  additionalOptionLabel: string;
  /** Контрол по которому будет осуществляться фильтрация */
  @Input()
  filterControl: UntypedFormControl;
  /** Список опций */
  @Input()
  optionsList: DictionaryModel[];
  /** Асинхронный список опций */
  @Input()
  optionsListAsync: DictionaryModel[];
  /** Текст ошибки поиска */
  @Input()
  searchErrorMsg: string;
  /** Показывать крестик сброса */
  @Input()
  isShowClearBtn = false;
  /** флаг актиности загрузчика */
  @Input()
  isLoaderActive: boolean;

  /** Подсказка о максимально отображаемой длине массива */
  @Input()
  canShowAttentionLength: boolean;

  /** Лимит передаваемого массива */
  @Input()
  limit: number;

  /** Подсветить отфильтрованный текст в опциях цветом: */
  @Input()
  highLightOptionColor = null;

  /** Подсказка для поиска */
  @Input()
  searchTooltip: string;

  /** Отображение стрелочки выпадающего списка */
  @Input()
  withArrow: boolean;

  @ViewChild('filterControlElement')
  filterControlElement: ElementRef;
  @ViewChild('selectBoxDivEl')
  selectBoxDivEl: ElementRef;

  /** Отобразить опции или нет */
  public showOptions: boolean;
  /** Свойство которое отображается в лэйбле */

  public virtualScrollCount = 10;
  public underscoreType = UnderscoreType;

  constructor(private domEventService: DomEventService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.showOptions = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.closeOptionsListWhenClickedOutside();
  }

  /**
   * Выбрать опцию
   *
   * @param {number} value
   * @param {string} title
   */
  public selectOption(option: any): void {
    this.control.setValue(option);

    this.showOptions = false;
  }

  /** Показать список опций */
  public showOptionsList(): void {
    if (this.control.disabled) {
      return;
    }

    this.showOptions = true;
    this.filterControl.setValue('');

    setTimeout(() => this.filterControlElement.nativeElement.focus(), 0);
  }

  public isNeedAdditionalOptionInfo(option: any): boolean {
    return this.additionalOptionLabel && !!option[this.additionalOptionLabel];
  }

  public getToolTip(option: any): string {
    if (!option[this.additionalOptionLabel]) {
      return option[this.optionLabel];
    }

    return `${option[this.optionLabel]} - ${option[this.additionalOptionLabel]}`;
  }

  /**
   * Метод для отображения и скрытия списка опций
   */
  public hideOptionsList(): void {
    if (this.showOptions) {
      this.control.markAsTouched();
      this.showOptions = false;
    }
  }

  /** очистить выбранное значение  */
  public onClearSelectedValue(event): void {
    event.stopPropagation();
    this.control.setValue(null);
    this.filterControl.setValue(null);
  }

  public toggleOptionsList(): void {
    if (!this.showOptions) {
      this.showOptionsList();
    } else {
      this.hideOptionsList();
    }
  }

  /** закрывать список опций при клике вне области компоненты */
  private closeOptionsListWhenClickedOutside(): void {
    if (this.selectBoxDivEl?.nativeElement) {
      this.domEventService
        .confirmOwnershipOfClick(this.selectBoxDivEl.nativeElement)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((isOwnClick: boolean) => {
          if (!isOwnClick) {
            this.hideOptionsList();
            this.changeDetectorRef.detectChanges();
          }
        });
    }
  }
}
