import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Urls } from '@appCore/urls/urls';
import { HttpService } from '@appCore/services/http/http.service';
import { DictionaryModel } from '@models/base/dictionary.model';
import {
  AppealModel,
} from '@appPersonalCabinet/appeals/models/appeal-search-response.model';
import { PaginationWithParamsModel } from '@appPersonalCabinet/appeals/models/search-params.model';
import { AppealRequestModel } from '@appPersonalCabinet/appeals/models/appeal-request.model';
import { AppealStatusRequestModel } from '@appPersonalCabinet/appeals/models/appeal-status-request.model';
import { PaginationResponseModel } from '@models/pagination-response.model';

@Injectable({
  providedIn: 'root',
})
export class AppealsApiService {

  constructor(private httpService: HttpService) {}

  /** Получение списка обращений по параметрам */
  public getHpsmAppealsList(params: PaginationWithParamsModel): Observable<PaginationResponseModel<AppealModel>> {
    const url = Urls.getHpsmAppeals();
    return this.httpService.getObservable(url, {
      params: this.httpService.getRequestParams(params, false),
    });
  }

  /** Получение списка типов обращений */
  public getAppealTypesList(): Observable<DictionaryModel[]> {
    const url = Urls.getAppealTypes();
    return this.httpService.getObservable(url);
  }

  /** Получение списка статусов для поиска обращений */
  public getAppealStatusesList(): Observable<DictionaryModel[]> {
    const url = Urls.getAppealStatuses();
    return this.httpService.getObservable(url);
  }

  /** Получить статус обращения */
  public getAppealStatusById(appealId: number): Observable<AppealStatusRequestModel> {
    const url = Urls.getAppealStatusById(appealId);
    return this.httpService.getObservable(url);
  }

  /** Добавление нового обращения */
  public createAppeal(data: AppealRequestModel): Observable<AppealModel> {
    const url = Urls.createAppeal();
    return this.httpService.postObservable(url, data);
  }



}
