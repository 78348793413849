import { DictionaryEnumModel } from '@models/base/dictionary-enum.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { SignerCategoriesAction, SignerCategoriesActionTypes } from './signer-categories.actions';

export const initialState = new DictionaryStoreModel<DictionaryEnumModel>([], null, null);

export function signerCategoriesReducer(
  state: DictionaryStoreModel<DictionaryEnumModel> = initialState,
  action: SignerCategoriesAction
): DictionaryStoreModel<DictionaryEnumModel> {
  switch (action.type) {
    case SignerCategoriesActionTypes.Reset:
      return initialState;

    case SignerCategoriesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case SignerCategoriesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case SignerCategoriesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (projectTypes) => projectTypes.loadStatus;
/** Получение словаря */
export const getSignerCategories = (projectTypes) => projectTypes.dictionary;
