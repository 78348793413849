import { Action } from '@ngrx/store';

/** Учетный номер пакета */
export enum RegistrationNumberActionTypes {
  Set = '[Registration Number] Set',
  Reset = '[Registration Number] Reset',
}

export class RegistrationNumberSet implements Action {
  readonly type = RegistrationNumberActionTypes.Set;
  constructor(public payload: string) {}
}

export class RegistrationNumberReset implements Action {
  readonly type = RegistrationNumberActionTypes.Reset;
}

export type RegistrationNumberAction = RegistrationNumberSet | RegistrationNumberReset;
