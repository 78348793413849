import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  DocumentTypesAction,
  DocumentTypesActionTypes,
  DocumentTypesGetError,
  DocumentTypesGetSuccess,
} from './document-types.actions';

@Injectable()
export class DocumentTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentTypes$: Observable<DocumentTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(DocumentTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getDocumentTypes().pipe(
        map((response) => new DocumentTypesGetSuccess(response)),
        catchError((err) => of(new DocumentTypesGetError()))
      )
    )
  ));
}
