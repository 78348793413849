import { SendToApprovementResponseModel } from '@appDocuments/documents-route/models/send-to-approvement-response.model';
import { Action } from '@ngrx/store';

export enum RouteSendToApprovementActionTypes {
  GetPhases = '[Document Route] GetPhases',
  GetPhasesSuccess = '[Document Route] GetPhasesSuccess',
  GetPhasesError = '[Document Route] GetPhasesError',
  OpenSertificateList = '[Document Route] OpenSertificateList'
}

export class RouteSendToApprovement implements Action {
  readonly type = RouteSendToApprovementActionTypes.GetPhases;
}

export class RouteGetPhases implements Action {
  readonly type = RouteSendToApprovementActionTypes.GetPhases;
}

export class RouteSendToApprovementSuccess implements Action {
  readonly type = RouteSendToApprovementActionTypes.GetPhasesSuccess;
  constructor(public payload: SendToApprovementResponseModel[]) {}
}

export class RouteSendToApprovementError implements Action {
  readonly type = RouteSendToApprovementActionTypes.GetPhasesError;
  constructor(public payload: any) {}
}

export class RouteOpenSertificateList implements Action {
  readonly type = RouteSendToApprovementActionTypes.OpenSertificateList;
  constructor(public payload: boolean) {}
}

export type RouteSendToApprovementActions =
  | RouteSendToApprovement
  | RouteSendToApprovementSuccess
  | RouteSendToApprovementError
  | RouteGetPhases
  | RouteOpenSertificateList;
