import { ExtensionDeadlinePopupModel } from '@appDocuments/documents-route/models/extension-deadline-popup.model';
import { DeadlineAcceptanceDto } from '@models/route-extension-deadline/deadline-acceptance-dto.model';
import { DeadlineExtensionRequestCreateDto } from '@models/route-extension-deadline/deadline-extension-request-create-dto.model';
import { DeadlineExtensionResponseDto } from '@models/route-extension-deadline/deadline-extension-response-dto.model';
import { Action } from '@ngrx/store';

export enum RouteExtensionDeadlineActionTypes {
  UpdateExtensionPopupParams = '[Route Extension Deadline] UpdateExtensionPopupParams',
  ResetExtensionDeadlines = '[Route Extension Deadline] ResetExtensionDeadlines',
  GetExtensionDeadline = '[Route Extension Deadline] GetExtensionDeadline',
  GetExtensionDeadlineSuccess = '[Route Extension Deadline] GetExtensionDeadlineSuccess',
  GetExtensionDeadlineError = '[Route Extension Deadline] getExtensionDeadlineError',
  UpdateExtensionDeadline = '[Route Extension Deadline] UpdateExtensionDeadline',
  UpdateExtensionDeadlineSuccess = '[Route Extension Deadline] UpdateExtensionDeadlineSuccess',
  UpdateExtensionDeadlineError = '[Route Extension Deadline] UpdateExtensionDeadlineError',
  SelectExtensionDeadline = '[Route Extension Deadline] SelectExtensionDeadline',
  AcceptExtensionDeadline = '[Route Extension Deadline] AcceptExtensionDeadline',
  AcceptExtensionDeadlineSuccess = '[Route Extension Deadline] AcceptExtensionDeadlineSuccess',
  DeclineExtensionDeadline = '[Route Extension Deadline] DeclineExtensionDeadline',
  DeclineExtensionDeadlineSuccess = '[Route Extension Deadline] DeclineExtensionDeadlineSuccess',
  ResetExtensionDeadlineResponseStatuses = '[Route Extension Deadline] ResetExtensionDeadlineResponseStatuses',
  ExtensionDeadlineError = '[Route Extension Deadline] ExtensionDeadlineError'
}

export class UpdateExtensionPopupParams implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.UpdateExtensionPopupParams;

  constructor(public payload: ExtensionDeadlinePopupModel) {}
}

export class ResetExtensionDeadlines implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.ResetExtensionDeadlines;
}

export class UpdateExtensionDeadline implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.UpdateExtensionDeadline;
  constructor(public payload: { routeId: number; request: DeadlineExtensionRequestCreateDto }) {}
}

export class UpdateExtensionDeadlineSuccess implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.UpdateExtensionDeadlineSuccess;
}

export class UpdateExtensionDeadlineError implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.UpdateExtensionDeadlineError;
  constructor(public error: any) {}
}

export class GetExtensionDeadline implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.GetExtensionDeadline;
  constructor(public payload: number) {}
}

export class GetExtensionDeadlineSuccess implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.GetExtensionDeadlineSuccess;
  constructor(public payload: DeadlineExtensionResponseDto[]) {}
}

export class GetExtensionDeadlineError implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.GetExtensionDeadlineError;
  constructor(public error: any) {}
}

export class SelectExtensionDeadline implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.SelectExtensionDeadline;
  constructor(public payload: DeadlineExtensionResponseDto) {}
}

export class AcceptExtensionDeadline implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.AcceptExtensionDeadline;

  constructor(public payload: DeadlineAcceptanceDto) {}
}

export class AcceptExtensionDeadlineSuccess implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.AcceptExtensionDeadlineSuccess;
}

export class DeclineExtensionDeadline implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.DeclineExtensionDeadline;

  constructor(public payload: DeadlineAcceptanceDto) {}
}

export class DeclineExtensionDeadlineSuccess implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.DeclineExtensionDeadlineSuccess;
}

export class ResetExtensionDeadlineResponseStatuses implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.ResetExtensionDeadlineResponseStatuses;
}

export class ExtensionDeadlineError implements Action {
  readonly type = RouteExtensionDeadlineActionTypes.ExtensionDeadlineError;

  constructor(public payload: any) {}
}
export type RouteExtensionDeadlineActions =
  | UpdateExtensionPopupParams
  | ResetExtensionDeadlines
  | UpdateExtensionDeadline
  | UpdateExtensionDeadlineSuccess
  | UpdateExtensionDeadlineError
  | GetExtensionDeadline
  | GetExtensionDeadlineSuccess
  | GetExtensionDeadlineError
  | SelectExtensionDeadline
  | AcceptExtensionDeadline
  | AcceptExtensionDeadlineSuccess
  | DeclineExtensionDeadline
  | DeclineExtensionDeadlineSuccess
  | ResetExtensionDeadlineResponseStatuses
  | ExtensionDeadlineError;
