import {
  OrganizationsLeaderAction,
  OrganizationsLeaderActionTypes,
} from '@appShared/store/dictionaries/organizations-leader/organizations-leader.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState = new DictionaryStoreModel<DictionaryModel>([], null, null);

export function organizationsLeaderReducer(
  state = initialState,
  action: OrganizationsLeaderAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case OrganizationsLeaderActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case OrganizationsLeaderActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case OrganizationsLeaderActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (state) => state.loadStatus;
/** Получение словаря */
export const getOrganizationsLeader = (state) => state.dictionary;
