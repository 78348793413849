import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  DocumentProhibitionTypesActionTypes,
  DocumentProhibitionTypesGet,
  DocumentProhibitionTypesGetError,
  DocumentProhibitionTypesGetSuccess,
  DocumentProhibitionTypesUnion,
} from './document-prohibition-types.actions';

@Injectable()
export class DocumentProhibitionTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetQuestionCategories$: Observable<DocumentProhibitionTypesUnion> = createEffect(() => this.actions$.pipe(
    ofType(DocumentProhibitionTypesActionTypes.Get),
    switchMap((action: DocumentProhibitionTypesGet) =>
      this.dictionaryApi.getDocumentProhibitionTypes().pipe(
        map((response) => new DocumentProhibitionTypesGetSuccess(response)),
        catchError((err) => of(new DocumentProhibitionTypesGetError()))
      )
    )
  ));
}
