import { DocumentStatusValueModel } from '@models/document-statuses/document-status-value.model';
import { Action } from '@ngrx/store';

export enum DocumentStatusValuesActionTypes {
  Get = '[Document Status Values] Get',
  GetSuccess = '[Document Status Values] GetSuccess',
  Error = '[Document Status Values] Error',
  Reset = '[Document Status Values] Reset',
}

/** запросить список всех возможных значений статуса ПД  */
export class DocumentStatusValuesGet implements Action {
  readonly type = DocumentStatusValuesActionTypes.Get;
}

/** получить исключения */
export class DocumentStatusValuesError implements Action {
  readonly type = DocumentStatusValuesActionTypes.Error;
}

/** получить список всех возможных значений статуса ПД  */
export class DocumentStatusValuesGetSuccess implements Action {
  readonly type = DocumentStatusValuesActionTypes.GetSuccess;
  constructor(public payload: DocumentStatusValueModel[]) {}
}

/** сбросить список всех возможных значений статуса ПД */
export class DocumentStatusValuesReset implements Action {
  readonly type = DocumentStatusValuesActionTypes.Reset;
}

export type DocumentStatusValuesActions =
  | DocumentStatusValuesGet
  | DocumentStatusValuesGetSuccess
  | DocumentStatusValuesError
  | DocumentStatusValuesReset;
