import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(
    value: any[],
    isReversed?: boolean,
    filterField?: string
  ): any[] {
    if (value.length === 0) {
      return;
    }

    if (filterField === undefined || null) {
      return value;
    }

    if (isReversed) {
      return value.reverse();
    }

    return value.sort(sortByField(filterField));
  }
}

export function sortByField(field) {
  return (a, b) => (a[field] > b[field] ? 1 : -1);
}
