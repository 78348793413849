import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-collapse-arrow',
  templateUrl: './collapse-arrow.component.html',
  styleUrls: ['./collapse-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseArrowComponent {
  @Input()
  showVersions = false;

  @Output()
  collapse: EventEmitter<boolean> = new EventEmitter();

  public toggle(): void {
    this.showVersions = !this.showVersions;
    this.collapse.emit(this.showVersions);
  }
}
