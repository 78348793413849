import { DocumentTypeModel } from '@models/document/document-type.model';
import { Action } from '@ngrx/store';

export enum ConsiderationDocumentTypesActionTypes {
  Get = '[Consideration Document Types] Get',
  GetSuccess = '[Consideration Document Types] GetSuccess',
  GetError = '[Consideration Document Types] GetError',
  Reset = '[Consideration Document Types] Reset',
}

export class ConsiderationDocumentTypesGet implements Action {
  readonly type = ConsiderationDocumentTypesActionTypes.Get;
  constructor(public payload: number) {}
}

export class ConsiderationDocumentTypesGetError implements Action {
  readonly type = ConsiderationDocumentTypesActionTypes.GetError;
}

export class ConsiderationDocumentTypesGetSuccess implements Action {
  readonly type = ConsiderationDocumentTypesActionTypes.GetSuccess;
  constructor(public payload: DocumentTypeModel[]) {}
}

export class ConsiderationDocumentTypesReset implements Action {
  readonly type = ConsiderationDocumentTypesActionTypes.Reset;
}

export type ConsiderationDocumentTypesAction =
  | ConsiderationDocumentTypesGet
  | ConsiderationDocumentTypesGetError
  | ConsiderationDocumentTypesGetSuccess
  | ConsiderationDocumentTypesReset;
