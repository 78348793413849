import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, MemoizedSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

/** Абстракция над ngrx */
@Injectable({
  providedIn: 'root'
})
export class ReduxService {
  constructor(private store: Store<any>, private actions$: Actions) {}
  /**
   * Возвращает выбраное хранилище
   * @param selector селектор хранилища
   * @returns {Observable<R>} возвращает observable данного хранилища
   */
  selectStore<T>(selector: MemoizedSelector<any, T>): Observable<T> {
    return this.store.select(selector);
  }

  /**
   * Обновление состояния хранилища
   * @param action тип действия
   */
  dispatchAction(action: Action) {
    this.store.dispatch(action);
  }

  /**
   * Подписка на экшон по типу
   */
  getActionByType(actionType: string): Observable<Action> {
    return this.actions$.pipe(ofType(actionType));
  }

  /**
   * Подписка на первый экшон по типу
   */
  getFirstActionByType(actionType: string): Observable<Action> {
    return this.actions$.pipe(
      ofType(actionType),
      first()
    );
  }
}
