/** Попапы маршрута согласования */
export enum RoutePopupTypes {
  assigmentCreation = 'assigmentCreation',
  approvalPackage = 'approvalPackage',
  approvalPackageWithRemark = 'approvalPackageWithRemark',
  addToTree = 'addToTree',
  deleteFromTreeAlert = 'deleteFromTreeAlert',
  deleteAgreementChainFromTreeAlert = 'deleteAgreementChainFromTreeAlert',
  deleteFromTreePrompt = 'deleteFromTreePrompt',
  routeFinishConfirm = 'routeFinishConfirm',
  totalReturn = 'totalReturn',
  totalRecall = 'totalRecall',
  internalReturn = 'internalReturn',
  startApprovement = 'startApprovement',
  sendToLeader = 'sendToLeader',
  decide = 'decide',
  decideOuter = 'decideOuter',
  decideOau = 'decideOau',
  addPhase = 'addPhase',
  addUserPhase = 'addUserPhase',
  addPhaseParallel = 'addPhaseParallel',
  addUserPhaseParallel = 'addUserPhaseParallel',
  deletePhase = 'deletePhase',
  startOuterApprovement = 'starOuterApprovement',
  startOuterApprovementWithRemark = 'starOuterApprovementWithRemark',
  addCoauthor = 'addCoauthor',
  deleteCoauthor = 'deleteCoauthor',
  addOauInitiation = 'addOauInitiation',
  addOuterApprovementIncome = 'addOuterApprovementIncome',
  addController = 'addController',
  deleteController = 'deleteController',
  deleteIntroducer = 'deleteIntroducer',
  extensionDeadline = 'extensionDeadline',
  extensionDeadlineResponse = 'extensionDeadlineresponse',
  activeTasks = 'activeTasks',
  graph = 'graph',
  routeTemplate = 'routeTemplate',
  routeTemplateInitiation = 'routeTemplateInitiation',
  applyTemplate = 'routeApplyTemplate',
  applyTemplateInitiation = 'applyTemplateInitiation',
  sendInitiation = 'sendInitiation',
  authorWarning = 'authorWarning',
  addIntroducer = 'addIntroducer',
  introduced = 'introduced',
  sendToApprovement = 'sendToApprovement',
  regulationsDeadlineRequest = 'regulationsDeadlineRequest',
  sendToOauLeader = 'sendToOauLeader',
  changeSignerCategory = 'changeSignerCategory',
  requestForCoauthor = 'requestForCoauthor',
  changeEmployeeForPoint = 'changeEmployeeForPoint',
  dssWait = 'dssWait',
  startOuterDevelopment = 'startOuterDevelopment',
  editDocumentPackageReviewDate = 'editDocumentPackageReviewDate',
  outstandingTask = 'outstandingTask',
  showOibRoleWarning = 'showOibRoleWarning',
  skipPhase = 'skipPhase',
  markResponsibleExecutor = 'markResponsibleExecutor',
}
