import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '../../../../core/store/cache-time.const';
import { DictionaryApiService } from '../../../../shared/api/dictionary/dictionary.api.service';
import {
  ApprovalFormTypesAction,
  ApprovalFormTypesActionTypes,
  ApprovalFormTypesGetError,
  ApprovalFormTypesGetSuccess,
} from './approval-form-types.actions';

@Injectable()
export class ApprovalFormTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetApprovalFormTypes$: Observable<ApprovalFormTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(ApprovalFormTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() => this.dictionaryApi.getMeetingTypes()),
    map((response) => new ApprovalFormTypesGetSuccess(response)),
    catchError((err) => of(new ApprovalFormTypesGetError()))
  ));
}
