import { NgModule } from '@angular/core';
import { AgreementRouteUsersService } from '@appShared/api/document-editing-participants/agreement-route-users.service';
import { DocumentEditingParticipantsService } from '@appShared/api/document-editing-participants/document-editing-participants.service';
import { AgreementEditUserModel } from '@appShared/api/document-editing-participants/models/agreement-route-user.model';
import { DocumentEditingParticipantResponseModel } from '@appShared/api/document-editing-participants/models/document-editing-participant-response.model';
import { AssignedExecutorsApiService } from '@appShared/api/personal-cabinet/assigned-executors-api.service';
import { CoveringTextApiService } from '@appShared/api/personal-cabinet/covering-text-api.service';
import { AssignedExecutorSendingModel } from '@appShared/api/personal-cabinet/models/assigned-executor-sending.model';
import { CoveringTextModel } from '@appShared/api/personal-cabinet/models/covering-text.model';
import { PersonalFoldersApiService } from '@appShared/api/personal-cabinet/personal-folders-api.service';
import { FolderModel } from '@models/folder/folder.model';
import { DefaultDataServiceConfig, EntityDataModule, EntityDataService, EntityMetadataMap } from '@ngrx/data';
import { environment } from 'environments/environment.prod';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.baseUrl,
};

export function selectId(entity: DocumentEditingParticipantResponseModel) {
  return entity.id;
}

export function selectAgreementUserId(entity: AgreementEditUserModel) {
  return entity.id;
}

export function selectAssignedExecutorId(entity: AssignedExecutorSendingModel) {
  if (!entity) {
    return;
  }

  return entity.id;
}

export function selectCoveringTextId(entity: CoveringTextModel) {
  if (!entity) {
    return;
  }

  return entity.id;
}

export function selectPersonalFolderId(entity: FolderModel) {
  if (!entity) {
    return;
  }

  return entity.id;
}

// Metadata for the entity model
export const entityMetadata: EntityMetadataMap = {
  DocumentPackageEditingParticipant: {
    selectId,
  },
  AgreementRouteUsers: {
    selectId: selectAgreementUserId,
  },
  AssignedExecutors: {
    selectId: selectAssignedExecutorId,
  },
  CoveringTextArray: {
    selectId: selectCoveringTextId,
  },
  PersonalFolders: {
    selectId: selectPersonalFolderId,
  },
  DashboardUserSearch: {},
};

@NgModule({
  imports: [
    EntityDataModule.forRoot({
      entityMetadata: entityMetadata,
    }),
  ],
  providers: [{ provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }],
})
export class EntityStoreModule {
  constructor(
    entityDataService: EntityDataService,
    documentEditingParticipantService: DocumentEditingParticipantsService,
    agreementRouteUsersService: AgreementRouteUsersService,
    assignedExecutorsApiService: AssignedExecutorsApiService,
    coveringTextApiService: CoveringTextApiService,
    personalFoldersApiService: PersonalFoldersApiService
  ) {
    entityDataService.registerServices({
      DocumentPackageEditingParticipant: documentEditingParticipantService,
      AgreementRouteUsers: agreementRouteUsersService,
      AssignedExecutors: assignedExecutorsApiService,
      CoveringTextArray: coveringTextApiService,
      PersonalFolders: personalFoldersApiService,
    });
  }
}
