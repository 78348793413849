import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

export enum FolderEditRightTypes {
  Get = '[Folder Edit Right Types] Get',
  GetSuccess = '[Folder Edit Right Types] GetSuccess',
  GetError = '[Folder Edit Right Types] GetError',
  Reset = '[Folder Edit Right Types] Reset',
}

export class FolderEditRightTypesGet implements Action {
  readonly type = FolderEditRightTypes.Get;
}

export class FolderEditRightTypesGetError implements Action {
  readonly type = FolderEditRightTypes.GetError;
}

export class FolderEditRightTypesGetSuccess implements Action {
  readonly type = FolderEditRightTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class FolderEditRightTypesReset implements Action {
  readonly type = FolderEditRightTypes.Reset;
}

export type FolderEditRightTypesAction =
  | FolderEditRightTypesGet
  | FolderEditRightTypesGetError
  | FolderEditRightTypesGetSuccess
  | FolderEditRightTypesReset;
