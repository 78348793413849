import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkShowErrorMsg',
  pure: false,
})
export class CheckShowErrorMsgPipe implements PipeTransform {
  transform(isInvalid: boolean, withCounter: boolean, content: string): boolean {
    if (!isInvalid) {
      return false;
    }

    if (!withCounter) {
      return true;
    }

    if (!content?.length) {
      return false;
    }

    const emptyString = content?.length === 0;

    /**если строка пустая - не показываем сообщение, о максимальном количестве символов*/
    const result = !emptyString;

    return result;
  }
}
