import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { UserInfoResponseModel } from '@models/user-info.model';
import { UserInfoAction, UserInfoActionTypes } from './user-info.actions';

export interface UserInfoState {
  userInfo: UserInfoResponseModel;
  error: any;
  status: StoreLoadStatus;
}

export const initialState: UserInfoState = {
  userInfo: null,
  error: null,
  status: null
};

export function userInfoReducer(state: UserInfoState = initialState, action: UserInfoAction): UserInfoState {
  switch (action.type) {
    case UserInfoActionTypes.GetUser:
      return {
        userInfo: null,
        error: null,
        status: StoreLoadStatus.inProgress
      };
    case UserInfoActionTypes.GetUserSuccess:
      return {
        userInfo: action.payload,
        error: null,
        status: StoreLoadStatus.loaded
      };
    case UserInfoActionTypes.ErrorUser:
      return {
        userInfo: null,
        error: action.payload,
        status: StoreLoadStatus.error
      };
    default:
      return state;
  }
}
