import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportGridCellValue',
})
export class ReportGridCellValuePipe implements PipeTransform {
  transform(cellValue: string, emptyCell: boolean, dashDisplay: boolean): string {
    if (cellValue) {
      return cellValue;
    }

    if (emptyCell) {
      return '';
    }

    if (dashDisplay) {
      return '\u2014';
    }

    return '0';
  }
}
