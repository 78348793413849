import { getQueryParams } from '@appCore/store/router';
import { NotificationsQueryParamsModel } from '@appMain/notifications/models/notification-query-params.model';
import { NotificationsMapperService } from '@appMain/notifications/services/notifications-mapper.service';
import { PaginationDefaultData } from '@models/enums/pagination-default-data.enum';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './notifications.reducer';
import { NotificationsState } from './notifications.reducer';

export const getNotificationsStore = createFeatureSelector<fromReducer.NotificationsState>('notifications');

/** Селектор для событий нотификаций */
export const getAllNotifications = createSelector(
  getNotificationsStore,
  fromReducer.selectAllNotifications
);

/** Селектор для отслеживания статуса загрузки нотификаций */
export const getIsNotificationsLoadInProgress = createSelector(
  getNotificationsStore,
  (state: NotificationsState) => state.status === StoreLoadStatus.inProgress
);

export const getNotViewedNotificationsCount = createSelector(
  getNotificationsStore,
  state => state.count
);

/** Селектор итогового количества уведомлений для пагинатора */
export const getNotViewedNotificationsTotalResultsCount = createSelector(
  getNotificationsStore,
  state => state.totalResultsCount
);

/** Селектор для получения информации виджетов нотификаций */
export const getNotificationsCounterData = createSelector(
  getNotificationsStore,
  state => state.countersData
);

/** Селектор для получения данных о пагинации  */
export const getNotificationPaginationData = createSelector(
  getNotificationsStore,
  state => ({
    page: state.currentPageNumber + 1,
    pageSize: PaginationDefaultData.pageSize,
    totalPagesCount: Math.ceil(state.totalResultsCount / PaginationDefaultData.pageSize)
  })
);

export const getNotificationsSortingParams = createSelector(
  getNotificationsStore,
  state => ({
    fieldName: state.sortingField,
    order: state.sortingOrder
  })
);

/** Селектор для получения загрузочных параметров уведомлений  */
export const getNotificationsLoadData = createSelector(
  getQueryParams,
  (query: NotificationsQueryParamsModel) => NotificationsMapperService.mapQueryParamsToFilterLoadData(query)
);
