import { ProjectGroupType } from '@models/enums/project-group-type.enum';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createSelector } from '@ngrx/store';
import { DocumentsModuleState, selectDocumentsModule } from '..';
import { DocumentPackageRelationTypesIds } from '@appDocuments/shared/enums/document-package-relation-type-ids.enum';

export const getDocumentPackageStore = createSelector(
  selectDocumentsModule,
  (state: DocumentsModuleState) => state?.documentPackage
);

/** Селект пакета документов */
export const getDocumentPackage = createSelector(getDocumentPackageStore, (state) => state?.documentPackage);
/** Селект ID пакета документов */
export const getDocumentPackageId = createSelector(getDocumentPackage, (documentPackage) =>
  documentPackage ? documentPackage.id : null
);
/** Селектор принадлжености ПД к Локальным Правовым Актам ( ЛПА ) */
export const getIsLocalLegalAct = createSelector(getDocumentPackage, (documentPackage) =>
  documentPackage ? documentPackage.main.projectType.projectGroupType.id === ProjectGroupType.LOCAL_LEGAL_ACTS : false
);
/** Селектор группы ПД по ID LEGAL_ACTS и LOCAL_LEGAL_ACTS */
export const getSelectedGroupByID = createSelector(
  getDocumentPackage,
  (documentPackage) => documentPackage?.projectType.projectGroupType.id
);

/** Селект ошибки пакета документов */
export const getDocumentPackageError = createSelector(getDocumentPackageStore, (state) => state.error);
/** Селект статуса загрузки */
export const getIsDocumentPackageLoading = createSelector(
  getDocumentPackageStore,
  (state) => state.status === StoreLoadStatus.inProgress
);

/** Селект информации о рассмотрении пакета документов */
export const getDocumentPackageConsiderationInfo = createSelector(
  getDocumentPackageStore,
  (state) => state.considerationInfo
);

export const getDocumentPackageConsiderationRequestChanges = createSelector(
  getDocumentPackageStore,
  (state) => state.considerationRequestChanges
);

/** Селект информации о статусе загрузки информации о рассмотрении пакета документов */
export const getDocumentPackageConsiderationInfoStatus = createSelector(
  getDocumentPackageStore,
  (state) => state.considerationInfoStatus
);

export const getDocumentPackageConsiderationRequestsChangeStatus = createSelector(
  getDocumentPackageStore,
  (state) => state.considerationRequestChangesStatus
);

/** Селект информации о типе редакции пакета документов */
export const getDocumentPackageRevisionTypeInfo = createSelector(
  getDocumentPackageStore,
  (state) => state.revisionTypeInfo
);

export const getDocumentPackageAllChangableDocuments = createSelector(
  getDocumentPackageStore,
  (state) => state.changableDocuments
);

export const getDocumentPackageChangeableDocuments = createSelector(getDocumentPackageStore, (state) =>
  state.changableDocuments.filter((item) => item.relationType.id !== DocumentPackageRelationTypesIds.missing)
);

export const getDocumentPackageMissingChangeableDocuments = createSelector(getDocumentPackageStore, (state) =>
  [...state.changableDocuments, ...state.affectingDocuments].filter(
    (item) => item.relationType.id === DocumentPackageRelationTypesIds.missing
  )
);

export const getDocumentPackageAffectingDocuments = createSelector(getDocumentPackageStore, (state) =>
  state.affectingDocuments.filter((item) => item.relationType.id !== DocumentPackageRelationTypesIds.missing)
);

export const getDocumentPackageReviewDateModel = createSelector(getDocumentPackage, (state) => state?.reviewDate);

export const getDocumentPackageReviewDate = createSelector(getDocumentPackageReviewDateModel, (reviewDateModel) => {
  if (!reviewDateModel) {
    return null;
  }
  return reviewDateModel.plannedReviewDate;
});
