import { Directive, Input } from '@angular/core';
import { CommonClassNames, TooltipPositions } from '@libs/projects/component-lib/src/public-api';

/**
 * Базовый класс для работы с кнопками.
 * В случае необходимости можно применить в других элементах формы.
 */
@Directive()
export abstract class ButtonBaseComponent {
  /**Содержание лейбла для кнопки */
  @Input()
  label: string;

  /** Стиль кнопки */
  @Input()
  buttonStyle;

  /** Стиль иконки */
  @Input()
  iconStyle = 'icon';

  /** Использовать или нет темную тему */
  @Input()
  isBlackTheme = false;

  /** состояние активности кнопки  */
  @Input()
  disabled: boolean;

  /** субмитет ли кнопка форму */
  @Input()
  isSubmitButton: boolean;

  /** всплывающая надпись при наведении курсором  */
  @Input()
  title = '';

  /** цвет заливки  */
  @Input()
  bgColorClassName = 'button-bgcolor-transparent';

  /** id кнопки */
  @Input()
  elementId = '';

  /** Флаг необходимости наличия блока предупреждения на кнопке */
  @Input()
  isNeedAttention: boolean;

  /** текст тултипа блока предупреждения на кнопке */
  @Input()
  attentionText: string;

  /* фиксированная ширина кнопки */
  @Input()
  buttonWidth: number;

  /* фиксированная высота кнопки */
  @Input()
  buttonHeight: number;

  public tooltipPositions = TooltipPositions;

  /** общее название класса для темной темы */
  get blackThemeClassName() {
    return this.isBlackTheme ? CommonClassNames.blackTheme : '';
  }

  /** общее название класса для заголовка */
  get labelClassName() {
    return CommonClassNames.label;
  }

  /** тип кнопки */
  get typeButton() {
    return this.isSubmitButton ? 'submit' : 'button';
  }

  /** сброс фокуса с элемента при клике */
  public dropFocus(ev) {
    ev.target.blur();
  }
}
