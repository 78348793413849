import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { Action, createReducer, on } from '@ngrx/store';
import { SharedFiltersStore } from './models/shared-filter-store.model';
import { SharedFiltersActions } from './shared-filters.actions';

/** В данном сторе "расшаренные фильтры"
 *
 * пользователь Иванов имеет возможность дать просматривать свои ПД пользователю Сидоров
 *
 * в данном сторе (под Сидоровым) находятся фильтры Иванова, которыми он поделился с Сидоровым
 */
export const initialState: SharedFiltersStore = {
  sharedFilters: [],
  sharedFiltersStatus: null,
};

const _sharedFiltersReducer = createReducer(
  initialState,
  on(SharedFiltersActions.getSharedFilters, (state) => ({
    ...state,
    foldersListStatus: StoreLoadStatus.inProgress,
  })),
  on(SharedFiltersActions.getSharedFiltersSuccess, (state, { sharedFilters }) => ({
    ...state,
    sharedFilters,
    foldersListStatus: StoreLoadStatus.loaded,
  })),
  on(SharedFiltersActions.getSharedFiltersError, (state) => ({
    ...state,
    foldersListStatus: StoreLoadStatus.error,
  }))
);

// Note: The exported reducer function is necessary as function calls are not supported by the AOT compiler.
// https://ngrx.io/guide/store/reducers
export function sharedFiltersReducer(state: any | undefined, action: Action) {
  return _sharedFiltersReducer(state, action);
}
