import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-big-loader',
  templateUrl: './big-loader.component.html',
  styleUrls: ['./big-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BigLoaderComponent {
  @Input()
  isHideBackground: boolean;
}
