import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { baseUrl } from '@appCore/urls/urls';
import { DelegatingInfoModel } from '@models/delegating/delegating-info.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DelegatingApiService {
  private readonly url = `${baseUrl}/security/delegating`;

  constructor(private http: HttpService) {}

  getFrom(): Observable<DelegatingInfoModel[]> {
    return this.http.getObservable<DelegatingInfoModel[]>(`${this.url}/from`);
  }

  getTo(): Observable<DelegatingInfoModel[]> {
    return this.http.getObservable<DelegatingInfoModel[]>(`${this.url}/to`);
  }

  create(delegating: DelegatingInfoModel): Observable<DelegatingInfoModel> {
    return this.http.postObservable<DelegatingInfoModel>(this.url, delegating);
  }

  update(delegating: DelegatingInfoModel): Observable<DelegatingInfoModel> {
    return this.http.putObservable<DelegatingInfoModel>(this.url, delegating);
  }

  delete(id: number): Observable<void> {
    return this.http.deleteObservable<void>(`${this.url}/${id}`);
  }

  switchToDelegating(id: number): Observable<void> {
    return this.http.postObservable<void>(`${this.url}/switch-to-delegating/${id}`);
  }

  switchToYourself(): Observable<void> {
    return this.http.postObservable<void>(`${this.url}/switch-to-yourself`);
  }
}
