import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isShowErrorPopup } from '@appCore/store/error-message';
import { getUserInfo } from '@appCore/store/user-info';
import { UserInfoApiService } from '@appShared/api/user-info/user-info.api.service';
import { employeeShortName } from '@appShared/pipes/employee-short-name.pipe';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { ErrorPopupService } from '../error-popup/error-popup.service';
import { ReduxService } from '../redux/redux.service';
import { SocketChannels } from '../websocket/constants/channel-names';
import { WebSocketService } from '../websocket/websocket.service';

@Injectable({
  providedIn: 'root',
})
export class DelegatingService {
  constructor(
    private errorPopup: ErrorPopupService,
    private redux: ReduxService,
    private router: Router,
    private userInfoService: UserInfoApiService,
    private websocket: WebSocketService
  ) {}

  public subscribeToDelegatingEvents(): void {
    this.userInfoService.getInfo().subscribe((user) => {
      this.websocket
        .selectChanel(SocketChannels.delegatingExpiredChanel(user.realUser.employee.id))
        .pipe(switchMap(() => this.redux.selectStore(getUserInfo)))
        .subscribe((res) => this.showErrorPopup(res.currentUser.employee));
    });

    this.redux
      .selectStore(getUserInfo)
      .pipe(
        filter((user) => !!user),
        map((user) => user.realUser),
        first(),
        switchMap((realUser) =>
          this.websocket
            .selectChanel(SocketChannels.delegatingUpdateChannel(realUser.employee.id))
            .pipe(switchMap(() => this.userInfoService.updateInfoAboutDelegating()))
        )
      )
      .subscribe((v) => window.location.reload());
  }

  public showDelegatingErrorPopup(): void {
    this.redux
      .selectStore(getUserInfo)
      .pipe(
        filter((i) => !!i),
        first()
      )
      .subscribe((res) => this.showErrorPopup(res.currentUser.employee));
  }

  private showErrorPopup(employee: EmployeeBaseModel): void {
    const fullName = employeeShortName(employee);
    const message = `Истек срок делегирования полномочий, указанный ${fullName}<br/><br/>Выполнение операций от имени ${fullName} невозможно.`;

    this.errorPopup.show({ message });
    this.subscribeToPopupClose();
  }

  private subscribeToPopupClose(): void {
    this.redux
      .selectStore(isShowErrorPopup)
      .pipe(
        filter((i) => !i),
        first(),
        switchMap(() => this.userInfoService.resetMockUser())
      )
      .subscribe(() => {
        this.router.navigateByUrl('/main/dashboard');
        window.location.reload();
      });
  }
}
