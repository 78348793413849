import { Action } from '@ngrx/store';
import { NumberOptionModel } from '@appDocuments/shared/models/register-form.model';

export enum DocumentPackageRegistrationActionTypes {
  GetNumberOptions = '[Document Package Registration]   GetNumberOptions',
  GetNumberOptionsSuccess = '[Document Package Registration]   GetNumberOptionsSuccess',
  GetNumberOptionsError = '[Document Package Registration]   GetNumberOptionsError',
}

export class DocumentPackageGetNumberOptions implements Action {
  readonly type = DocumentPackageRegistrationActionTypes.GetNumberOptions;
  constructor(public payload: { nomenclatureId: number; limit: number; date: string }) {}
}

export class DocumentPackageGetNumberOptionsSuccess implements Action {
  readonly type = DocumentPackageRegistrationActionTypes.GetNumberOptionsSuccess;
  constructor(public payload: { maxDate: string; numberOptions: NumberOptionModel[]; totalLength: number }) {}
}

export class DocumentPackageGetNumberOptionsError implements Action {
  readonly type = DocumentPackageRegistrationActionTypes.GetNumberOptionsError;
  constructor(public payload: { error: any }) {}
}

export type DocumentPackageRegistrationAction =
  | DocumentPackageGetNumberOptions
  | DocumentPackageGetNumberOptionsSuccess
  | DocumentPackageGetNumberOptionsError;
