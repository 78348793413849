import { RoutePhaseTypes } from '@appDocuments/documents-route/enums/route-phase-types.enum';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';

export const PHASES_FOR_DECISION_FILTER = [
  {
    id: RoutePhaseTypes.preliminaryApprovement,
    name: 'Предварительное согласование',
  },
  {
    id: RoutePhaseTypes.responsibleExecutor,
    name: 'Ответственный исполнитель',
  },
  {
    id: RoutePhaseTypes.questionProvided,
    name: 'Вопрос внесен',
  },
  {
    id: RoutePhaseTypes.projectApproved,
    name: 'Проект согласован',
  },
  {
    id: RoutePhaseTypes.puPm,
    name: 'ПУ ПМ',
  },
  {
    id: RoutePhaseTypes.oauPm,
    name: 'ОАУ ПМ',
  },
  {
    id: RoutePhaseTypes.oauRegistrationApprovement,
    name: 'ОАУ ПМ. Оформление и опубликование',
  },
  {
    id: RoutePhaseTypes.userPhase,
    name: 'Пользовательский этап',
  },
] as DictionaryWithShortNameModel[];
