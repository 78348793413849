import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlBaseDirective } from '../../control-base.directive';

@Component({
  template: '',
})
export class SelectBaseComponent extends ControlBaseDirective {
  /** Параметр для просмотра опции */
  @Input()
  optionLabel: string | ((value: any) => string) = '';
  /** Нужно ли скрывать кнопку сброса */
  @Input()
  hideClearButton: boolean = false;
  @Input()
  optionLabelForToolTip: string = '';
  /** Нужно ли вычислять ширину */
  @Input()
  canCalculateWidth = true;
  /** Ширина селект бокса */
  @Input()
  selectBoxWidth: number = 0;
  @Input()
  isReadonly = false;
  /** Триггер очистки селекта */
  @Output()
  clear: EventEmitter<any> = new EventEmitter();
  /** Триггер изменения значения селекта */
  @Output()
  change: EventEmitter<any> = new EventEmitter();
  /** Нужно ли скролить вниз при открытии списка */
  @Output()
  scrollToBottom: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('htmlDivElementSelectBox') htmlDivSelectBox?: ElementRef<HTMLDivElement>;
  /** Отобразить опции или нет */
  public isShowOptions = false;

  constructor(private elementRef: ElementRef) {
    super();
  }

  public selectOption(value: any): void {
    this.control.setValue(value);
    this.change.emit(value);
    this.hideOptionsList();
  }

  public getLabel(val: any) {
    if (!this.optionLabel) {
      return '';
    }

    if (typeof this.optionLabel === 'string') {
      return val[this.optionLabel];
    } else {
      return this.optionLabel(val);
    }
  }

  /** Коллбек фокуса на селекте */
  public onFocusSelect(): void {
    this.showOptionsList();
  }

  /**
   * Метод для отображения списка опций
   */
  public showOptionsList(): void {
    setTimeout(() => {
      if (this.control.disabled) {
        return;
      }
      this.calculatePosition();
    }, 0);

    this.isShowOptions = true;
    this.scrollToBottom.emit(true);
  }

  /**
   * Метод для скрытия списка опций
   */
  public hideOptionsList(): void {
    this.scrollToBottom.emit(false);
    this.isShowOptions = false;

    this.returnPosition();
  }

  /**
   * очистка значения контрола
   */
  public override onClearControlValue(): void {
    this.control.setValue(null);
    this.control.markAsTouched();
    this.change.emit(null);
    this.clear.emit(null);
  }

  /* Вычисляет позиционирование */
  private calculatePosition(): void {
    const el = this.elementRef.nativeElement;

    const selectBox = el.querySelector('.select-box');
    const selectBoxLayout = selectBox.getClientRects()[0];
    selectBox.style.top = `${window.scrollY + selectBoxLayout.top}px`;
    selectBox.style.left = `${window.scrollX + selectBoxLayout.left}px`;
    selectBox.style.position = 'sticky';
    selectBox.style.zIndex = 11;

    if (this.canCalculateWidth) {
      if (this.selectBoxWidth && selectBoxLayout.width >= this.selectBoxWidth) {
        selectBox.style.width = `${this.selectBoxWidth}px`;
        return;
      }
      selectBox.style.width = `${selectBoxLayout.width}px`;
    }
  }

  /* Возвращает прежнее позиционирование */
  private returnPosition(): void {
    const el = this.elementRef.nativeElement;
    const selectBox = el.querySelector('.select-box');
    selectBox.style.top = 0;
    selectBox.style.left = 0;
    selectBox.style.width = 'auto';
    selectBox.style.position = 'inherit';
    selectBox.style.zIndex = 0;
  }
}
