import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { AddErrorMessage, ResetErrorMessage } from '@appCore/store/error-message/error-message.actions';
import { ErrorMessageModel } from '@models/error-message.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorPopupService {
  constructor(private redux: ReduxService) {}

  show(error: ErrorMessageModel): void {
    this.redux.dispatchAction(new AddErrorMessage(error));
  }

  close(): void {
    this.redux.dispatchAction(new ResetErrorMessage());
  }
}
