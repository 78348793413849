import { ErrorMessageActions, ErrorMessageActionTypes } from '@appCore/store/error-message/error-message.actions';
import { ErrorMessageModel } from '@models/error-message.model';

const initialState: ErrorMessageModel = {
  message: null,
  stackTrace: null
};

export function errorMessageReducer(state = initialState, action: ErrorMessageActions): ErrorMessageModel {
  switch (action.type) {
    case ErrorMessageActionTypes.AddErrorMessage:
      return action.payload;

    case ErrorMessageActionTypes.ResetErrorMessage:
      return {
        message: null,
        stackTrace: null
      };

    default:
      return state;
  }
}
