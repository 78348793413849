import { DashboardStatisticsNames } from '@appMain/dashboard/enums/dashboard-statistics-names.enum';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';

export const IN_PROGRESS_PARAMS = {
  widget: DashboardStatisticsNames.approvement,
  statisticsName: DashboardStatisticsNames.approvement,
  workStatus: DashboardStatisticsNames.inProgress,
};

export const ALL_REVIEWED_PARAMS = {
  widget: DashboardStatisticsNames.allReviewed,
  statisticsName: DashboardStatisticsNames.allReviewed,
  workStatus: DashboardStatisticsNames.allReviewed,
};

export const REGISTRATION_PARAMS = {
  widget: DashboardStatisticsNames.registration,
  statisticsName: DashboardStatisticsNames.registration,
  workStatus: DashboardStatisticsNames.registration,
  projectGroupId: ProjectGroupType.LOCAL_LEGAL_ACTS,
};
