import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentPackageAction, DocumentPackageActionTypes } from './document-package.actions';
import { DocumentPackageStoreModel } from './document-package.store.model';

export const initialState = new DocumentPackageStoreModel(
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  [],
  [],
  [],
  []
);

export const documentPackageReducer = (
  state: DocumentPackageStoreModel = initialState,
  action: DocumentPackageAction
): DocumentPackageStoreModel => {
  switch (action.type) {
    case DocumentPackageActionTypes.Reset:
      return initialState;

    case DocumentPackageActionTypes.Save:
      return {
        ...initialState,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.SaveSuccess:
      return {
        ...state,
        documentPackage: action.payload,
        status: StoreLoadStatus.loaded,
        considerationInfoStatus: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.Get:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.GetSuccess:
      return {
        ...state,
        documentPackage: action.payload,
        status: StoreLoadStatus.loaded,
        changableDocuments: [
          ...(action.payload.relatedDocuments?.affectedDocuments || []),
          ...(action.payload.relatedDocuments?.links || []),
        ],
        affectingDocuments: action.payload.relatedDocuments ? action.payload.relatedDocuments.affectingDocuments : [],
      };

    case DocumentPackageActionTypes.Update:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.UpdateSuccess:
      return {
        ...state,
        documentPackage: action.payload,
        status: StoreLoadStatus.loaded,
        considerationInfoStatus: StoreLoadStatus.inProgress,
        changableDocuments: [
          ...(action.payload.relatedDocuments?.affectedDocuments || []),
          ...(action.payload.relatedDocuments?.links || []),
        ],
        affectingDocuments: action.payload.relatedDocuments ? action.payload.relatedDocuments.affectingDocuments : [],
      };

    case DocumentPackageActionTypes.UpdateError:
      return {
        ...state,
        error: action.payload,
        status: StoreLoadStatus.error,
      };

    case DocumentPackageActionTypes.BusinessVersionGetSuccess:
      return {
        ...state,
        documentPackage: {
          ...state.documentPackage,
          businessVersion: action.payload,
        },
      };

    case DocumentPackageActionTypes.BusinessVersionGetError:
      return {
        ...state,
        documentPackage: {
          ...state.documentPackage,
          businessVersion: null,
        },
      };

    case DocumentPackageActionTypes.ConsiderationInfoGet:
      return {
        ...state,
        considerationInfoStatus: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.ConsiderationInfoGetSuccess:
      return {
        ...state,
        considerationInfo: action.payload,
        considerationInfoStatus: StoreLoadStatus.loaded,
      };

    case DocumentPackageActionTypes.ConsiderationInfoGetError:
      return {
        ...state,
        considerationInfoError: action.payload,
        considerationInfoStatus: StoreLoadStatus.error,
      };

    case DocumentPackageActionTypes.ConsiderationInfoReset:
      return {
        ...state,
        considerationInfoStatus: null,
        considerationInfoError: null,
        considerationInfo: null,
      };

    case DocumentPackageActionTypes.ConsiderationRequestsChangeGet:
      return {
        ...state,
        considerationRequestChangesStatus: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.ConsiderationRequestsChangeGetSuccess:
      return {
        ...state,
        considerationRequestChanges: action.payload,
        considerationRequestChangesStatus: StoreLoadStatus.loaded,
      };

    case DocumentPackageActionTypes.ConsiderationRequestsChangeGetError:
      return {
        ...state,
        considerationRequestChangesError: action.payload,
        considerationRequestChangesStatus: StoreLoadStatus.error,
      };

    case DocumentPackageActionTypes.RevisionTypeGet:
      return {
        ...state,
        revisionTypeInfoStatus: StoreLoadStatus.inProgress,
      };

    case DocumentPackageActionTypes.RevisionTypeGetSuccess:
      return {
        ...state,
        revisionTypeInfo: action.payload,
        revisionTypeInfoStatus: StoreLoadStatus.loaded,
      };

    case DocumentPackageActionTypes.RevisionTypeGetError:
      return {
        ...state,
        revisionTypeInfo: null,
        revisionTypeInfoStatus: StoreLoadStatus.error,
      };

    case DocumentPackageActionTypes.RevisionTypeReset:
      return {
        ...state,
        revisionTypeInfo: null,
        revisionTypeInfoStatus: null,
      };

    case DocumentPackageActionTypes.Error:
      return {
        ...state,
        error: action.payload,
        status: StoreLoadStatus.error,
      };
    case DocumentPackageActionTypes.AddChangableDocument:
      return {
        ...state,
        changableDocuments: [...state.changableDocuments, ...action.payload],
      };

    case DocumentPackageActionTypes.DeleteChangableDocument:
      return {
        ...state,
        changableDocuments: state.changableDocuments.filter((i) => i !== action.payload),
      };
    case DocumentPackageActionTypes.UpdateChangableDocument:
      return {
        ...state,
        changableDocuments: action.payload,
      };

    case DocumentPackageActionTypes.SetSnapshotChangableDocument:
      return {
        ...state,
        changableDocumentsSnapshot: state.changableDocuments,
        affectingDocumentsSnapshot: state.affectingDocuments,
      };

    case DocumentPackageActionTypes.ResetChangableDocument:
      return {
        ...state,
        changableDocuments: state.changableDocumentsSnapshot,
        affectingDocuments: state.affectingDocumentsSnapshot,
      };

    case DocumentPackageActionTypes.UpdateDocumentCompositionTemplate:
      return {
        ...state,
        documentPackage: { ...state.documentPackage, compositionTemplate: action.payload },
      };

    case DocumentPackageActionTypes.UpdateDocumentCompositionCategories:
      return {
        ...state,
        documentPackage: { ...state.documentPackage, categories: action.payload },
      };

    case DocumentPackageActionTypes.UpdateDocumentPackageName: {
      const main = { ...state.documentPackage.main, name: action.payload };

      return {
        ...state,
        documentPackage: { ...state.documentPackage, main },
      };
    }

    case DocumentPackageActionTypes.UpdateDocumentPackageReviewDate: {
      const documentPackage = { ...state.documentPackage, reviewDate: action.payload };
      return { ...state, documentPackage };
    }

    case DocumentPackageActionTypes.UpdateRegistrationNumber: {
      const documentPackage = {
        ...state.documentPackage,
        registrationNumber: { ...state.documentPackage.registrationNumber, ...action.payload },
      };
      return { ...state, documentPackage };
    }

    case DocumentPackageActionTypes.ResetRegistrationNumber: {
      const documentPackage = {
        ...state.documentPackage,
        registrationNumber: null,
      };
      return { ...state, documentPackage };
    }

    case DocumentPackageActionTypes.UpdateRegistrationMark: {
      const documentPackage = {
        ...state.documentPackage,
        registrationMark: action.payload,
      };
      return { ...state, documentPackage };
    }

    default:
      return state;
  }
};
