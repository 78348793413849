import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { GroupedProjectTypeModel } from '@models/document-package/project-type.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { ProjectTypesAction, ProjectTypesActionTypes } from './project-types.actions';

export const initialState = new DictionaryStoreModel<GroupedProjectTypeModel>([], null);

export const projectTypesReducer = (
  state: DictionaryStoreModel<GroupedProjectTypeModel> = initialState,
  action: ProjectTypesAction
): DictionaryStoreModel<GroupedProjectTypeModel> => {
  switch (action.type) {
    case ProjectTypesActionTypes.Reset:
      return initialState;

    case ProjectTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case ProjectTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel<GroupedProjectTypeModel>(action.payload, StoreLoadStatus.loaded);

    case ProjectTypesActionTypes.GetError:
      return {
        ...initialState,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
};

/** Получение статуса */
export const getSaveStatus = (projectTypes) => projectTypes.saveStatus;
/** Получение словаря */
export const getGropedProjectTypes = (projectTypes) => projectTypes.dictionary;
