import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentStatusesAction, DocumentStatusesActionTypes } from './document-statuses.actions';

export const initialState = new DictionaryStoreModel<DictionaryModel>([], null);

export function documentStatusesReducer(
  state: DictionaryStoreModel<DictionaryModel> = initialState,
  action: DocumentStatusesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case DocumentStatusesActionTypes.Reset:
      return initialState;

    case DocumentStatusesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case DocumentStatusesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case DocumentStatusesActionTypes.GetError:
      return {
        ...initialState,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (projectTypes) => projectTypes.saveStatus;
/** Получение словаря */
export const getDocumentStatuses = (projectTypes) => projectTypes.dictionary;
