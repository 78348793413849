import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { DocumentTypeModel } from '@models/document/document-type.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentTypesAction, DocumentTypesActionTypes } from './document-types.actions';

export const initialState = new DictionaryStoreModel<DocumentTypeModel>([], null, null);

export function documentTypesReducer(
  state: DictionaryStoreModel<DocumentTypeModel> = initialState,
  action: DocumentTypesAction
): DictionaryStoreModel<DocumentTypeModel> {
  switch (action.type) {
    case DocumentTypesActionTypes.Reset:
      return initialState;

    case DocumentTypesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case DocumentTypesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}
