import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { ReportFieldOptionsModel } from '@models/reports/report-field-options.model';
import { ReportGridTitle, ReportGridTitleItem } from '@models/reports/report-grid-title.model';

/**
 * Универсальный грид для отчетов
 */
@Component({
  selector: 'app-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportGridComponent implements OnInit {
  /**
   * Массив из списка столбцов
   *   title: string - заголовок столбца;
   *   property: string - свойство в массиве данных;
   */
  @Input()
  title: ReportGridTitle;

  /**
   * Статус загрузки
   */
  @Input()
  loadStatus: StoreLoadStatus;

  /**
   * Данные
   * Надо смапить в массив модели отчета
   * В полях отчета могут быть или строки или  числа или опции.
   * В опциях должен быть link для ссылок и dateFormat для корректного вывода дат
   * Так-же в строках могут содержаться HTML теги например <br>
   */
  @Input()
  data: {
    [key: string]:
      | string
      | number
      | ReportFieldOptionsModel<string | number>
      | ReportFieldOptionsModel<string | number>[];
  }[];

  /**
   * Разметка грида отчета
   */
  @Input()
  gridTemplateColumns: string;

  /**
   * Максимальная высота отчета без учета шапки
   */
  @Input()
  maxHeight: string;

  /**
   * Текст для пустого отчета
   */
  @Input()
  noInputText = 'выберите фильтры';

  /**
   * Отображение прочерка
   */
  @Input()
  dashDisplay = true;

  @Input()
  emptyCell = false;

  @Input()
  allowStylesForPaginator = false;

  @Output()
  onScroll = new EventEmitter<void>();

  storeLoadStatus = StoreLoadStatus;

  /**
   * Пока сортировка только фронтовая, поэтому сделаны только реверсы
   */
  isReverse = false;
  sortByColumn: string = null;

  ngOnInit() {
    if (!this.gridTemplateColumns) {
      this.gridTemplateColumns = `repeat(${this.title.length}, 1fr)`;
    }
  }

  onHeaderCellClick(titleItem: ReportGridTitleItem) {
    if (titleItem.property === this.sortByColumn) {
      this.isReverse = !this.isReverse;
      return;
    }

    this.sortByColumn = titleItem.property;
  }

  public onScrollDown(): void {
    this.onScroll.emit();
  }
}
