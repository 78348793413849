import { Component } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-small-button',
  templateUrl: './small-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './small-button.component.scss'],
})
export class SmallButtonComponent extends ButtonBaseComponent {
  constructor() {
    super();
  }
}
