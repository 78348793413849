import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'npaDate',
})
export class NpaDatePipe implements PipeTransform {
  /** Функция форматирования даты */
  transform(value: string, args: string): string {
    return npaDate(value, args);
  }
}

export function npaDate(value: string, args: string): string {
  if (!value) {
    return '';
  }
  return moment(value).locale('ru').format(args);
}
