import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { OrganizationsActions, OrganizationsActionTypes } from './organizations.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryWithShortNameModel>();

export function organizationsReducer(
  state: DictionaryStoreModel<DictionaryWithShortNameModel> = DEFAULT_STATE,
  action: OrganizationsActions
): DictionaryStoreModel<DictionaryWithShortNameModel> {
  switch (action.type) {
    case OrganizationsActionTypes.Reset:
      return DEFAULT_STATE;

    case OrganizationsActionTypes.GetForCurrentUser:
    case OrganizationsActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case OrganizationsActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case OrganizationsActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса загрузк */
export const getLoadStatus = (projectTypes) => projectTypes.loadStatus;
/** Получение словаря */
export const getOrganizations = (projectTypes) => projectTypes.dictionary;
