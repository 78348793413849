import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';
import {
  AvailableNomenclaturesAction, AvailableNomenclaturesActionTypes
} from './available-nomenclatures.actions';

export const initialState = new DictionaryStoreModel<NomenclatureModel>([], null);

export const availableNomenclaturesReducer = (
  state: DictionaryStoreModel<NomenclatureModel> = initialState,
  action: AvailableNomenclaturesAction
): DictionaryStoreModel<NomenclatureModel> => {
  switch (action.type) {
    case AvailableNomenclaturesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case AvailableNomenclaturesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case AvailableNomenclaturesActionTypes.GetError:
      return {
        ...initialState,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
};

export const getSaveStatus = (state) => state.saveStatus;
export const getAvailableNomenclatures = (state): NomenclatureModel[] =>
  state.dictionary.filter((item) => !item.deleted && !item.markedDeleted);
