import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReduxService } from '../../../../core/services/redux/redux.service';
import { getIsNarrowAsideBar } from '../../../../core/store/document-aside';
import { ExpandAsideBar, NarrowAsideBar } from '../../../../core/store/document-aside/document-aside.actions';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss']
})
export class LayoutMenuComponent implements OnInit {
  isNarrowAsideBar$: Observable<boolean>;

  constructor(private redux: ReduxService) {}

  ngOnInit() {
    this.isNarrowAsideBar$ = this.redux.selectStore(getIsNarrowAsideBar);
  }

  /** переключатель ширины панели */
  public onWidthAsideBarToggled(): void {
    this.isNarrowAsideBar$.pipe(first()).subscribe(res => {
      if (res) {
        this.redux.dispatchAction(new ExpandAsideBar());
      } else {
        this.redux.dispatchAction(new NarrowAsideBar());
      }
    });
  }
}
