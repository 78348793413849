import { ActionReducer, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { DocumentsAsideState } from './document-aside.reducer';

/** создать селектор на хранилище состояний для левого сайдбара пакета документов  */
export const getDocumentAsideStore = createFeatureSelector<DocumentsAsideState>('documentsAside');

/** селектор на состояние ширины сайдбара  */
export const getIsNarrowAsideBar = createSelector(
  getDocumentAsideStore,
  (state: DocumentsAsideState) => state.isNarrowBar
);

export function localStorageSyncReducer(
  reducer: ActionReducer<{ documentsAside: DocumentsAsideState }>
): ActionReducer<{ documentsAside: DocumentsAsideState }> {
  return localStorageSync({ keys: ['documentsAside'] })(reducer);
}

export const documentsModuleMetaReducers: Array<MetaReducer<{ documentsAside: DocumentsAsideState }, any>> = [
  localStorageSyncReducer
];
