import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appReplaceLeadingZero]',
})
export class ReplaceLeadingZeroDirective {
  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent): any {
    const target = event.target as HTMLInputElement;
    target.value = target.value.replace(/^0+/, '');
  }
}
