import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryModel } from '@models/base/dictionary.model';

@Pipe({
  name: 'displayByLimit',
})
export class DisplayByLimit implements PipeTransform {
  transform(value: DictionaryModel[], limit: number): DictionaryModel[] {
    if (!limit || !value.length) {
      return value;
    }

    return value.slice(0, limit);
  }
}
