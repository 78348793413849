import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  ConsiderationTypesAction,
  ConsiderationTypesActionTypes,
  ConsiderationTypesGetError,
  ConsiderationTypesGetSuccess,
} from '@appShared/store/dictionaries/consideration-types/consideration-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class ConsiderationTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  GetConsiderationTypes$: Observable<ConsiderationTypesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsiderationTypesActionTypes.Get),
      throttleTime(CACHE_TIME),
      switchMap(() =>
        this.dictionaryApi.getAllAgendaPackageTypes().pipe(
          map((response) => new ConsiderationTypesGetSuccess(response)),
          catchError((err) => of(new ConsiderationTypesGetError()))
        )
      )
    )
  );
}
