import { TreeOptionItemModel } from '@appShared/components/controls/multi-select-tree/tree-option-item.model';

export class TreeOptionItem {
  item: TreeOptionItemModel;
  index: number;
  subItems: TreeOptionItem[];
  collapsed = false;
  selected = false;

  constructor(item: TreeOptionItemModel, index: number, subItems: TreeOptionItem[], selected = false) {
    this.item = item;
    this.index = index;
    this.subItems = subItems;
    this.selected = selected;
  }
}
