import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { Urls } from '@appCore/urls/urls';
import { Observable } from 'rxjs';
import { SettingApiResponceModel } from './models/setting-responce.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  constructor(private http: HttpService) {}

  /** Получение всех настроек */
  public getSettingAll(): Observable<SettingApiResponceModel[]> {
    const url = Urls.getSettingAll();
    return this.http.getObservable(url);
  }
}
