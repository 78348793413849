import { animate, state, style, transition, trigger } from '@angular/animations';

export const InputCalendarAnimations = [
  trigger('toggleCalendar', [
    state(
      'void',
      style({
        opacity: 0
      })
    ),
    state(
      'open',
      style({
        minWidth: '225px',
        maxWidth: '300px',
        width: '100%',
        maxHeight: '240px',
        minHeight: '0',
        top: '5px',
        right: '0',
        opacity: 1
      })
    ),
    state(
      'close',
      style({
        minWidth: '0',
        maxWidth: '0',
        width: '0',
        maxHeight: '0',
        minHeight: '0',
        top: '-10px',
        right: '10px',
        opacity: 0
      })
    ),
    transition('close <=> open', animate('200ms ease-in'))
  ])
];
