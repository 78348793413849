import {
  CaseStatusesActionTypes,
  CaseStatusesTypesAction,
} from '@appShared/store/dictionaries/case-statuses/case-statuses.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState = new DictionaryStoreModel<DictionaryModel>([], null, null);

export function caseStatusesReducer(
  state = initialState,
  action: CaseStatusesTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case CaseStatusesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case CaseStatusesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case CaseStatusesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getLoadStatus = (state) => state.loadStatus;
/** Получение словаря */
export const getCaseStatuses = (state) => state.dictionary;
