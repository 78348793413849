import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionModel } from '@appPermissions/models/permission.model';
import { environment } from '@environments/environment';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { DocumentPackagePermissions } from './enums/document-package-permissions.enum';
import { PermissionType } from './enums/permission-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissionsFromServer = {};
  private permissionsMapByType = new Map<string, string[]>();
  private permissionsSubject$ = new BehaviorSubject<{ value: boolean }>(null);

  constructor(private http: HttpClient, private router: Router) {}

  /** Получение пермишнов и маппинг с проверкой */
  public getPermissions(
    permissions: string | string[],
    permissionsType: string,
    id?: string | number
  ): Observable<boolean> {
    return this.getPermissionsFromServer(permissionsType, id).pipe(
      catchError((e) => {
        console.log('ошибка при получении доступов: ' + e);
        return EMPTY;
      }),
      map((p) => {
        const resultPermissions = [].concat(permissions).filter((i) => !!i);
        if (!resultPermissions.length) {
          return false;
        }
        this.permissionsMapByType.set(permissionsType, p);
        this.permissionsSubject$.next({ value: true });
        return p.some((i) => resultPermissions.includes(i));
      })
    );
  }

  public getPermissionsFromServer(permissionsType: string, id?: string | number): Observable<string[]> {
    const permissionName = `${permissionsType}_${id}`;

    const url = `${environment.baseUrl}/security/authorization/permissions/${permissionsType}`;
    const urlId = id ? `/${id}` : '';

    if (!this.permissionsFromServer[permissionName]) {
      const get = this.http.get<string[]>(url + urlId);
      this.permissionsFromServer[permissionName] = get.pipe(share());
    }

    return this.permissionsFromServer[permissionName];
  }

  // Получаем синхронно пермишены по типу
  public getPermissionsByType(permissionsType: PermissionType) {
    return this.permissionsMapByType.get(permissionsType);
  }
  /** Навигация на страницу по умолчанию по роли */
  public navigateToDefaultUrlByRole(): void {
    this.router.navigate(['main']);
  }

  public getDocumentPackagePermission(
    permission: DocumentPackagePermissions,
    documentPackageId: number
  ): PermissionModel {
    return {
      permissions: permission,
      type: PermissionType.documentPackage,
      id: documentPackageId,
    };
  }

  /** Разрешает отобразить кнопку/чекбокс Принятые документы */
  public hasAcceptedDocuments$(documentPackageId: number): Observable<boolean> {
    return this.getPermissionsFromServer(PermissionType.documentPackage, documentPackageId).pipe(
      map((p) => p.includes(DocumentPackagePermissions.displayingAcceptedDocuments))
    );
  }

  public hasSharedSourceOwners(documentPackage: DocumentPackageResponseModel): Observable<boolean> {
    return this.getPermissions(
      DocumentPackagePermissions.documentPackageViewWithoutSharedSource,
      PermissionType.documentPackage,
      documentPackage?.id
    ).pipe(map((hasAccess) => !hasAccess && !!documentPackage?.sharedSourceOwners?.length));
  }

  public hasDeadlineRoutePhaseEdit$(documentPackageId: number): Observable<boolean> {
    return this.getPermissionsFromServer(PermissionType.documentPackage, documentPackageId).pipe(
      map((p) => p.includes(DocumentPackagePermissions.deadlineRoutePhaseEdit))
    );
  }

  public onPermissionsUpdate(): Observable<{ value: boolean }> {
    return this.permissionsSubject$.asObservable();
  }
}
