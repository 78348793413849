import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  DocumentStagesActions,
  DocumentStagesActionTypes,
  DocumentStagesError,
  DocumentStagesGetSuccess,
} from './document-stages.actions';

@Injectable()
export class DocumentStagesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentStages$: Observable<DocumentStagesActions> = createEffect(() => this.actions$.pipe(
    ofType(DocumentStagesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getDocumentStages().pipe(
        map((response) => new DocumentStagesGetSuccess(response)),
        catchError(() => of(new DocumentStagesError()))
      )
    )
  ));
}
