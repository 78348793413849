import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceQuotesWithFrench',
})
export class ReplaceQuotesWithFrenchPipe implements PipeTransform {
  transform(text: string): string {
    return replaceQuotesWithFrench(text);
  }
}
export function replaceQuotesWithFrench(text, iteration = 0): string {
  text = iteration % 2 === 0 ? replaceWithLeftQuote(text) : replaceWithRightQuote(text);
  if (text.includes('"')) {
    text = replaceQuotesWithFrench(text, ++iteration);
  }
  return text;
}
function replaceWithLeftQuote(text: string): string {
  return text.replace(/"/, '«');
}
function replaceWithRightQuote(text: string): string {
  return text.replace(/"/, '»');
}
