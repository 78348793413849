import {
  DocumentPackageRelationTypesActions,
  DocumentPackageRelationTypesActionTypes,
} from '@appShared/store/dictionaries/document-package-relation-types/document-package-relation-types.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryModel>();

export function documentPackageRelationTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: DocumentPackageRelationTypesActions
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case DocumentPackageRelationTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case DocumentPackageRelationTypesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentPackageRelationTypesActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case DocumentPackageRelationTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса загрузк */
export const getLoadStatus = (projectTypes) => projectTypes.loadStatus;
/** Получение словаря */
export const getDocumentPackageRelationTypes = (projectTypes) => projectTypes.dictionary;
