import { ReviewDateFormModel } from '@appDocuments/shared/models/review-date-form.model';
import { DocumentPackageConsiderationInfoModel } from '@appShared/api/document-package/models/document-package/document-package-consideration-info.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { CompositionTemplateModel } from '@models/document-package/composition-template/composition-template.model';
import { DocumentPackageBusinessVersionModel } from '@models/document-package/document-package-business-version.model';
import { DocumentPackageRequestModel } from '@models/document-package/document-package-request.model';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { DocumentPackageMarkAsReadResponseModel } from '@models/document-package/mark-as-read/document-package-mark-as-read.response.model';
import { RegistrationMarkModel } from '@models/document-package/registration-mark/registration-mark.model';
import { RegistrationNumberModel } from '@models/document-package/registration-number/registration-number.model';
import { RelatedDocumentsModelItem } from '@models/document-package/related-documents/related-documents.model';
import { DocumentStatusModel } from '@models/document-statuses/document-status.model';
import { Action } from '@ngrx/store';
import { ReviewDateChangeRequestResponseModel } from '../../../shared/models/review-date-change-request-response.model';

export enum DocumentPackageActionTypes {
  Reset = '[Document Package] Reset',
  Save = '[Document Package] Save',
  SaveSuccess = '[Document Package] SaveSuccess',
  Get = '[Document Package] Get',
  GetSuccess = '[Document Package] GetSuccess',
  Update = '[Document Package] Update',
  UpdateSuccess = '[Document Package] UpdateSuccess',
  UpdateError = '[Document Package] UpdateError',
  BusinessVersionGet = '[Document Package] BusinessVersionGet',
  BusinessVersionGetSuccess = '[Document Package] BusinessVersionGetSuccess',
  BusinessVersionGetError = '[Document Package] BusinessVersionGetError',
  ConsiderationInfoGet = '[Document Package] ConsiderationInfoGet',
  ConsiderationInfoGetSuccess = '[Document Package] ConsiderationInfoGetSuccess',
  ConsiderationInfoGetError = '[Document Package] ConsiderationInfoGetError',
  ConsiderationRequestsChangeGet = '[Document Package] ConsiderationRequestsChangeGet',
  ConsiderationRequestsChangeGetSuccess = '[Document Package] ConsiderationRequestsChangeGetSuccess',
  ConsiderationRequestsChangeGetError = '[Document Package] ConsiderationRequestsChangeGetError',
  ConsiderationInfoReset = '[Document Package] ConsiderationInfoReset',
  RevisionTypeGet = '[Document Package] RevisionTypeGet',
  RevisionTypeGetSuccess = '[Document Package] RevisionTypeGetSuccess',
  RevisionTypeGetError = '[Document Package] RevisionTypeGetError',
  RevisionTypeReset = '[Document Package] RevisionTypeReset',
  Error = '[Document Package] Error',
  AddChangableDocument = '[Document Package] AddChangableDocument',
  DeleteChangableDocument = '[Document Package] DeleteChangableDocument',
  UpdateChangableDocument = '[Document Package] UpdateChangableDocument',
  SetSnapshotChangableDocument = '[Document Package] SetSnapshotChangableDocument',
  ResetChangableDocument = '[Document Package] ResetChangableDocument',
  UpdateDocumentCompositionTemplate = '[Document Package] UpdateDocumentCompositionTemplate',
  UpdateDocumentCompositionCategories = '[Document Package] UpdateDocumentCompositionCategories',
  MarkAsRead = '[Document Package] MarkAsRead',
  MarkAsReadSuccess = '[Document Package] MarkAsReadSuccess',
  UpdateDocumentPackageName = '[Document Package] UpdateDocumentPackageName',
  UpdateDocumentPackageReviewDate = '[Document Package] UpdateDocumentPackageReviewDate',
  UpdateRegistrationNumber = '[Document Package] UpdateRegistrationNumber',
  ResetRegistrationNumber = '[Document Package] ResetRegistrationNumber',
  UpdateRegistrationMark = '[Document Package] UpdateRegistrationMark',
}

export class DocumentPackageReset implements Action {
  readonly type = DocumentPackageActionTypes.Reset;
}

export class DocumentPackageSave implements Action {
  readonly type = DocumentPackageActionTypes.Save;
  constructor(public payload: DocumentPackageRequestModel) {}
}

export class DocumentPackageSaveSuccess implements Action {
  readonly type = DocumentPackageActionTypes.SaveSuccess;
  constructor(public payload: DocumentPackageResponseModel) {}
}

export class DocumentPackageGet implements Action {
  readonly type = DocumentPackageActionTypes.Get;
  constructor(public payload: { documentPackageId: number }) {}
}

export class DocumentPackageGetSuccess implements Action {
  readonly type = DocumentPackageActionTypes.GetSuccess;
  constructor(public payload: DocumentPackageResponseModel) {}
}

export class DocumentPackageUpdate implements Action {
  readonly type = DocumentPackageActionTypes.Update;
  constructor(public payload: DocumentPackageRequestModel) {}
}

export class DocumentPackageUpdateSuccess implements Action {
  readonly type = DocumentPackageActionTypes.UpdateSuccess;
  constructor(public payload: DocumentPackageResponseModel) {}
}

export class DocumentPackageUpdateError implements Action {
  readonly type = DocumentPackageActionTypes.UpdateError;
  constructor(public payload: any) {}
}

export class DocumentPackageError implements Action {
  readonly type = DocumentPackageActionTypes.Error;
  constructor(public payload: any) {}
}

export class DocumentPackageGetBusinessVersion implements Action {
  readonly type = DocumentPackageActionTypes.BusinessVersionGet;
}

export class DocumentPackageGetBusinessVersionSuccess implements Action {
  readonly type = DocumentPackageActionTypes.BusinessVersionGetSuccess;
  constructor(public payload: DocumentPackageBusinessVersionModel) {}
}

export class DocumentPackageGetBusinessVersionError implements Action {
  readonly type = DocumentPackageActionTypes.BusinessVersionGetError;
  constructor(public payload: any) {}
}

export class DocumentPackageGetConsiderationInfo implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationInfoGet;
}

export class DocumentPackageGetConsiderationInfoSuccess implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationInfoGetSuccess;
  constructor(public payload: DocumentPackageConsiderationInfoModel[]) {}
}

export class DocumentPackageGetConsiderationInfoError implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationInfoGetError;
  constructor(public payload: any) {}
}

export class DocumentPackageGetConsiderationRequestsChange implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationRequestsChangeGet;
}

export class DocumentPackageGetConsiderationRequestsChangeSuccess implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationRequestsChangeGetSuccess;
  constructor(public payload: ReviewDateChangeRequestResponseModel[]) {}
}

export class DocumentPackageGetConsiderationRequestsChangeError implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationRequestsChangeGetError;
  constructor(public payload: any) {}
}

export class DocumentPackageResetConsiderationInfo implements Action {
  readonly type = DocumentPackageActionTypes.ConsiderationInfoReset;
}

export class DocumentPackageGetRevisionTypeInfo implements Action {
  readonly type = DocumentPackageActionTypes.RevisionTypeGet;
}

export class DocumentPackageGetRevisionTypeInfoSuccess implements Action {
  readonly type = DocumentPackageActionTypes.RevisionTypeGetSuccess;
  constructor(public payload: DocumentStatusModel) {}
}

export class DocumentPackageGetRevisionTypeInfoError implements Action {
  readonly type = DocumentPackageActionTypes.RevisionTypeGetError;
}

export class DocumentPackageResetRevisionTypeInfo implements Action {
  readonly type = DocumentPackageActionTypes.RevisionTypeReset;
}

export class DocumentPackageAddChangableDocument implements Action {
  readonly type = DocumentPackageActionTypes.AddChangableDocument;
  constructor(public payload: RelatedDocumentsModelItem[]) {}
}

export class DocumentPackageDeleteChangableDocument implements Action {
  readonly type = DocumentPackageActionTypes.DeleteChangableDocument;
  constructor(public payload: RelatedDocumentsModelItem) {}
}

export class DocumentPackageUpdateChangableDocuments implements Action {
  readonly type = DocumentPackageActionTypes.UpdateChangableDocument;
  constructor(public payload: RelatedDocumentsModelItem[]) {}
}

export class DocumentPackageSetSnapshotChangableDocument implements Action {
  readonly type = DocumentPackageActionTypes.SetSnapshotChangableDocument;
}

export class DocumentPackageResetChangableDocument implements Action {
  readonly type = DocumentPackageActionTypes.ResetChangableDocument;
}

export class UpdateDocumentCompositionTemplate implements Action {
  readonly type = DocumentPackageActionTypes.UpdateDocumentCompositionTemplate;
  constructor(public payload: CompositionTemplateModel) {}
}

export class UpdateDocumentCompositionCategories implements Action {
  readonly type = DocumentPackageActionTypes.UpdateDocumentCompositionCategories;
  constructor(public payload: DictionaryModel[]) {}
}

export class DocumentPackageMarkAsRead implements Action {
  readonly type = DocumentPackageActionTypes.MarkAsRead;
  constructor(public payload: number) {}
}

export class DocumentPackageMarkAsReadSuccess implements Action {
  readonly type = DocumentPackageActionTypes.MarkAsReadSuccess;
  constructor(public payload: DocumentPackageMarkAsReadResponseModel) {}
}

export class DocumentPackageMarkAsReadError implements Action {
  readonly type = DocumentPackageActionTypes.SaveSuccess;
  constructor(public payload: any) {}
}

export class UpdateDocumentPackageName implements Action {
  readonly type = DocumentPackageActionTypes.UpdateDocumentPackageName;
  constructor(public payload: string) {}
}

export class UpdateDocumentPackageReviewDateModel implements Action {
  readonly type = DocumentPackageActionTypes.UpdateDocumentPackageReviewDate;
  constructor(public payload: ReviewDateFormModel) {}
}

export class UpdatePackageRegistrationNumber implements Action {
  readonly type = DocumentPackageActionTypes.UpdateRegistrationNumber;
  constructor(public payload: Partial<RegistrationNumberModel>) {}
}

export class ResetPackageRegistrationNumber implements Action {
  readonly type = DocumentPackageActionTypes.ResetRegistrationNumber;
}

export class UpdateRegistrationMark implements Action {
  readonly type = DocumentPackageActionTypes.UpdateRegistrationMark;
  constructor(public payload: RegistrationMarkModel) {}
}

export type DocumentPackageAction =
  | DocumentPackageReset
  | DocumentPackageSave
  | DocumentPackageSaveSuccess
  | DocumentPackageGet
  | DocumentPackageGetSuccess
  | DocumentPackageUpdate
  | DocumentPackageUpdateSuccess
  | DocumentPackageUpdateError
  | DocumentPackageError
  | DocumentPackageGetBusinessVersion
  | DocumentPackageGetBusinessVersionSuccess
  | DocumentPackageGetBusinessVersionError
  | DocumentPackageGetConsiderationInfo
  | DocumentPackageGetConsiderationInfoSuccess
  | DocumentPackageGetConsiderationInfoError
  | DocumentPackageResetConsiderationInfo
  | DocumentPackageGetRevisionTypeInfo
  | DocumentPackageGetRevisionTypeInfoSuccess
  | DocumentPackageGetRevisionTypeInfoError
  | DocumentPackageResetRevisionTypeInfo
  | DocumentPackageAddChangableDocument
  | DocumentPackageDeleteChangableDocument
  | DocumentPackageUpdateChangableDocuments
  | DocumentPackageSetSnapshotChangableDocument
  | DocumentPackageResetChangableDocument
  | UpdateDocumentCompositionTemplate
  | UpdateDocumentCompositionCategories
  | UpdateDocumentPackageName
  | DocumentPackageGetConsiderationRequestsChange
  | DocumentPackageGetConsiderationRequestsChangeSuccess
  | DocumentPackageGetConsiderationRequestsChangeError
  | UpdateDocumentPackageReviewDateModel
  | UpdatePackageRegistrationNumber
  | ResetPackageRegistrationNumber
  | UpdateRegistrationMark;
