import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { Action } from '@ngrx/store';

/** Справочник "типы ограничений для документов" */

export enum DocumentProhibitionTypesActionTypes {
  Get = '[Document Prohibition Types] Get',
  GetSuccess = '[Document Prohibition Types] GetSuccess',
  GetError = '[Document Prohibition Types] GetError',
  Reset = '[Document Prohibition Types] Reset',
}

export class DocumentProhibitionTypesGet implements Action {
  readonly type = DocumentProhibitionTypesActionTypes.Get;
}

export class DocumentProhibitionTypesGetError implements Action {
  readonly type = DocumentProhibitionTypesActionTypes.GetError;
}

export class DocumentProhibitionTypesGetSuccess implements Action {
  readonly type = DocumentProhibitionTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithCodeModel[]) {}
}

export class DocumentProhibitionTypesReset implements Action {
  readonly type = DocumentProhibitionTypesActionTypes.Reset;
}

export type DocumentProhibitionTypesUnion =
  | DocumentProhibitionTypesGet
  | DocumentProhibitionTypesGetError
  | DocumentProhibitionTypesGetSuccess
  | DocumentProhibitionTypesReset;
