import { DictionaryModel } from '@models/base/dictionary.model';

export class AppealModel {
  createdFromPersonalCabinet: boolean;
  dateCreate: Date;
  dateUpdate: Date;
  description: string;
  hpsmId: number;
  id: number;
  status: DictionaryModel;
  textError: string;
  topic: string;
  type: DictionaryModel;

  constructor(obj: AppealModel) {
    this.createdFromPersonalCabinet = obj.createdFromPersonalCabinet;
    this.id = obj.id;
    this.dateCreate = obj.dateCreate;
    this.dateUpdate = obj.dateUpdate;
    this.description = obj.description;
    this.hpsmId = obj.hpsmId;
    this.status = obj.status;
    this.textError = obj.textError;
    this.topic = obj.topic;
    this.type = obj.type;
  }
}
