import { Injectable } from '@angular/core';
import { PersonalFoldersAccessApiService } from '@appShared/api/personal-cabinet/personal-folders-access-api.service';
import { FolderModel } from '@models/folder/folder.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PersonalFoldersAccessActions } from './personal-folders-access.actions';

@Injectable()
export class PersonalFoldersAccessEffects {
  constructor(private actions$: Actions, private personalFoldersAccessApiService: PersonalFoldersAccessApiService) {}

  getAllFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalFoldersAccessActions.getAvailableFolders),
      switchMap(() =>
        this.personalFoldersAccessApiService.getAll().pipe(
          map((response) => {
            const sharedFolders: FolderModel[] = response.map(({ folder, employee, notReadCount, editRights }) => ({
              ...folder,
              author: `${employee.lastName} ${employee.firstName} ${employee.patronymic}`,
              notReadCount,
              editRights,
              documentPackageIds: folder.documentPackageIds,
            }));
            return PersonalFoldersAccessActions.getAvailableFoldersSuccess({ availableFoldersList: sharedFolders });
          }),
          catchError(() => of(PersonalFoldersAccessActions.getAvailableFoldersError()))
        )
      )
    )
  );
}
