export enum DocumentPackagePermissions {
  documentPackageCreate = 'DOCUMENT_PACKAGE_CREATE',
  documentPackageEdit = 'DOCUMENT_PACKAGE_EDIT',
  documentPackageView = 'DOCUMENT_PACKAGE_VIEW',
  documentPackageViewWithoutSharedSource = 'DOCUMENT_PACKAGE_VIEW_WITHOUT_SHARED_SOURCE',
  documentCreate = 'DOCUMENT_CREATE',
  editorVersionDocumentCreate = 'EDITOR_VERSION_DOCUMENT_CREATE',
  editorVersionDocumentView = 'EDITOR_VERSION_DOCUMENT_VIEW',
  massPrintSelectType = 'MASS_PRINT_SELECT_TYPE',
  massPrintDocumentPolicy = 'MASS_PRINT_DOCUMENT_POLICY',
  oauToolsView = 'OAU_TOOLS_VIEW',
  /** раздел Документы ОАУ для НПА */
  viewOauDocuments = 'VIEW_OAU_DOCUMENTS',
  /** раздел Документы для опубликования для ЛПА */
  viewDocumentPublicationSection = 'VIEW_DOCUMENT_PUBLICATION_SECTION',
  globalTemplateSave = 'GLOBAL_TEMPLATE_SAVE',
  createOauDocuments = 'CREATE_OAU_DOCUMENTS',
  concilationMeetingView = 'CONCILATION_MEETING_VIEW',
  accompanyingDocumentsCreate = 'ACCOMPANYING_DOCUMENTS_CREATE',
  documentDraftCreate = 'DOCUMENT_DRAFT_CREATE',
  createAdditionalDocuments = 'CREATE_ADDITIONAL_DOCUMENTS',
  createPublicationRevisions = 'CREATE_PUBLICATION_REVISIONS',
  documentPackageReviewDateEdit = 'DOCUMENT_PACKAGE_REVIEW_DATE_EDIT',
  sendingEdit = 'SENDING_EDIT',
  packageStatusView = 'PACKAGE_STATUS_VIEW',
  skipPhase = 'SKIP_PHASE',
  displayingDocumentSections = 'DISPLAYING_DOCUMENT_SECTIONS',
  displayingDocumentFilters = 'DISPLAYING_DOCUMENT_FILTERS',
  displayingAcceptedDocuments = 'AVAILABILITY_BUTTON_AND_CHECKBOX_DISPLAY_IN_ACCEPTED_DOCUMENT_SECTION',
  /** доступность запроса в соавторы */
  requestInCoauthor = 'REQUEST_IN_COAUTHOR',
  createOwnVersion = 'MARK_DOCUMENT_VERSION_TO_CREATE_OWN_VERSION',
  deadlineRoutePhaseEdit = 'DEADLINE_ROUTE_PHASE_EDIT',
  documentPackageRegister = 'DOCUMENT_PACKAGE_REGISTER',
  /** Отображение кнопки Вернуть с регистрации */
  documentPackageRegisterReturn = 'DOCUMENT_PACKAGE_REGISTER_RETURN',
  /** Отображение кнопки "Отправить в Электронный архив" */
  sendToElectronicArchive = 'SEND_TO_ELECTRONIC_ARCHIVE',

  /** Отображение кнопки "Удалить из Электронного архива" */
  deleteFromElectronicArchive = 'DELETE_FROM_ELECTRONIC_ARCHIVE',
  /** Отображение ссылки на карточку ПД в ЭА */
  linkToCardElectronicArchive = 'LINK_TO_CARD_ELECTRONIC_ARCHIVE',
  /** Редактирование маршрута согласования Пакета Документа */
  routeEdit = 'ROUTE_EDIT',
  /** Редактирование этапа маршрута согласования Пакета Документа */
  routePhaseEdit = 'ROUTE_PHASE_EDIT',
  /** Добавление параллельных этапов к этапам маршрута согласования Пакета Документа, кроме «ПУ ПМ» и «ОАУ ПМ» */
  routePhaseParallelAdd = 'ROUTE_PHASE_PARALLEL_ADD',
  /** Добавление параллельных этапов к этапу «ПУ ПМ» на маршруте согласования Пакета Документа */
  routePhasePuPmParallelAdd = 'ROUTE_PHASE_PU_PM_PARALLEL_ADD',
  /** Добавление параллельных этапов к этапу «ОАУ ПМ» на маршруте согласования Пакета Документа */
  routePhaseOauPmParallelAdd = 'ROUTE_PHASE_OAU_PM_PARALLEL_ADD',
}
