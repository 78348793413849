import { DictionaryModel } from '@models/base/dictionary.model';
import { Action } from '@ngrx/store';

export enum ReservationStatusesActionTypes {
  Get = '[Reservation Statuses] Get',
  GetSuccess = '[Reservation Statuses] GetSuccess',
  GetError = '[Reservation Statuses] GetError',
}

export class ReservationStatusesGet implements Action {
  readonly type = ReservationStatusesActionTypes.Get;
}

export class ReservationStatusesGetError implements Action {
  readonly type = ReservationStatusesActionTypes.GetError;
}

export class ReservationStatusesGetSuccess implements Action {
  readonly type = ReservationStatusesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export type ReservationStatusesTypesAction =
  | ReservationStatusesGet
  | ReservationStatusesGetError
  | ReservationStatusesGetSuccess;
