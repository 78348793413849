import { Injectable } from '@angular/core';
import { NotificationCounterListModel } from '@appMain/notifications/models/notification-counter-list.model';
import { NotificationsConfigModel } from '@appMain/notifications/models/notifications-config.model';
import { NotificationsFilterDataRequestModel } from '@models/notifications/notifications.request/notification-filters-data.request.model';
import { NotificationsResponseModel } from '@models/notifications/notifications.response/notifications.response.model';
import { PaginationDefaultDataModel } from '@models/pagination-default-data.model';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/services/http/http.service';
import { Urls } from '../../../core/urls/urls';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  constructor(private httpService: HttpService) {}
  /**Получение списка уведомлений */
  public getNotifications(
    params: NotificationsFilterDataRequestModel,
    sortField?: string,
    sortOrder?: string,
    paginationData?: PaginationDefaultDataModel
  ): Observable<NotificationsResponseModel> {
    const url = Urls.getNotifications();

    const query = { ...params } as any;
    if (paginationData) {
      query.page = paginationData.page;
      query.pageSize = paginationData.pageSize;
    }

    if (!!sortField) {
      query.sortField = sortField;
      query.sortDirection = sortOrder || 'ASC';
    }

    return this.httpService.getObservable(url, {
      params: query,
    });
  }

  /** Получение количества уведомлений */
  public getCount(): Observable<number> {
    const url = Urls.notificationsCount();

    return this.httpService.getObservable(url);
  }

  /** Пометить нотификацию прочитанной*/
  public markNotificationViewed(id: number, viewed: boolean): Observable<null> {
    const url = Urls.markNotificationViewed(id, viewed);
    return this.httpService.putObservable(url);
  }

  /** Получаем данные по виджетам уведомлений */
  public getNotificationCounterData(): Observable<NotificationCounterListModel> {
    const url = Urls.getNotificationsCounterData();

    return this.httpService.getObservable(url);
  }

  public toggleNotificationsArchiveState(data: { items: number[]; type: boolean }): Observable<null> {
    const url = Urls.toggleNotificationsArchiveState(data.type);

    return this.httpService.postObservable(url, data.items);
  }

  public getNotificationsConfig(): Observable<any[]> {
    const url = Urls.getAndSaveNotificationsConfig();
    return this.httpService.getObservable(url);
  }

  public saveNotificationsConfig(data: NotificationsConfigModel[]): Observable<NotificationsConfigModel[]> {
    const url = Urls.getAndSaveNotificationsConfig();
    return this.httpService.putObservable(url, data);
  }
}
