/**
 * Считает сумму полей по массиву объектов, с изменяемым ключом
 */
export const calculateTotal = (data?: object[], quantityKey = 'quantity') =>
  data?.reduce((total, currentItem) => {
    const currentItemQuantity = parseInt(currentItem[quantityKey], 10);
    if (Number.isNaN(currentItemQuantity)) {
      throw new TypeError(`Невозможно вычислить сумму, т.к. одно из значений - NaN`);
    }
    return total + currentItemQuantity;
  }, 0) ?? 0;
