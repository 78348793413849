import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import {
  CaseStatusesActionTypes,
  CaseStatusesGetError,
  CaseStatusesGetSuccess,
  CaseStatusesTypesAction,
} from '@appShared/store/dictionaries/case-statuses/case-statuses.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

@Injectable()
export class CaseStatusesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetCaseStatuses$: Observable<CaseStatusesTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(CaseStatusesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getCaseStatuses().pipe(
        map((response) => new CaseStatusesGetSuccess(response)),
        catchError((err) => of(new CaseStatusesGetError()))
      )
    )
  ));
}
