import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup-title',
  templateUrl: './popup-title.component.html',
  styleUrls: ['./popup-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupTitleComponent {
  @Input()
  title: string;
  @Input()
  errorMessage: string;
  @Input()
  withoutBorder = false;
}
