import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@appShared/components/buttons/buttons.module';
import { InputCalendarBaseComponent } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar.base';
import { InputCalendarComponent } from '@appShared/components/controls/input-calendar/components/input-calendar/input-calendar.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InputCalendarOnlyIconComponent } from './components/input-calendar-only-icon/input-calendar-only-icon.component';
import { InputCalendarWindowComponent } from './components/input-calendar-window/input-calendar-window.component';
import { DatePickerService } from './services/date-picker.service';
import { HolidaysEffects } from './store/holidays/holidays.effects';
import { inputCalendarModuleReducers } from '@appShared/components/controls/input-calendar/store';
import { MeetingDatesEffects } from '@appShared/components/controls/input-calendar/store/meeting-dates/meeting-dates.effects';
import { LoadersModule } from '@appShared/components/loaders/loaders.component';

@NgModule({
  imports: [
    StoreModule.forFeature('inputCalendarModule', inputCalendarModuleReducers),
    EffectsModule.forFeature([HolidaysEffects, MeetingDatesEffects]),
    CommonModule,
    FormsModule,
    ButtonsModule,
    LoadersModule,
  ],
  providers: [DatePickerService],
  declarations: [
    InputCalendarComponent,
    InputCalendarBaseComponent,
    InputCalendarWindowComponent,
    InputCalendarOnlyIconComponent,
  ],
  exports: [InputCalendarComponent, InputCalendarBaseComponent, InputCalendarOnlyIconComponent],
})
export class InputCalendarModule {}
