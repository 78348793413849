import {
  documentsControlAlternativesReducer,
  DocumentsControlAlternativesState,
} from '@appDocuments/documents-route/store/document-control-alternatives/document-control-alternatives.reducer';
import {
  documentRouteActiveTasksReducer,
  DocumentRouteActiveTasksState,
} from '@appDocuments/documents-route/store/document-route-active-tasks/document-route-active-tasks.reducer';
import {
  documentRoutePageReducer,
  RoutePageState,
} from '@appDocuments/documents-route/store/document-route-page/document-route-page.reducer';
import {
  documentRouteTapReducer,
  DocumentRouteTapState,
} from '@appDocuments/documents-route/store/document-route-tap/document-route-tap.reducer';
import {
  documentRouteReducer,
  DocumentRouteState,
} from '@appDocuments/documents-route/store/document-route/document-route.reducer';
import {
  regulationsDeadlineReducer,
  RegulationsDeadlineState,
} from '@appDocuments/documents-route/store/regulations-deadline/regulations-deadline.reducer';
import {
  routeExtensionDeadlinePopupReducer,
  RouteExtensionDeadlineState,
} from '@appDocuments/documents-route/store/route-extension-deadline/route-extension-deadline.reducer';
import {
  routeSendToApprovementReducer,
  RouteSendToApprovementState,
} from '@appDocuments/documents-route/store/route-send-to-approvement/route-send-to-approvement.reducer';
import {
  routeTemplaterReducer,
  RouteTemplateState,
} from '@appDocuments/documents-route/store/route-template/route-template.reducer';
import { ActionReducer, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface DocumentRouteModuleState {
  documentRoute: DocumentRouteState;
  documentRoutePage: RoutePageState;
  documentRouteTap: DocumentRouteTapState;
  routeExtensionDeadline: RouteExtensionDeadlineState;
  routeTemplate: RouteTemplateState;
  activeTasks: DocumentRouteActiveTasksState;
  routeSendToApprovement: RouteSendToApprovementState;
  regulationsDeadline: RegulationsDeadlineState;
  documentsControlAlternatives: DocumentsControlAlternativesState;
}

export const documentRouteModuleReducers = {
  documentRoute: documentRouteReducer,
  documentRoutePage: documentRoutePageReducer,
  documentRouteTap: documentRouteTapReducer,
  routeExtensionDeadline: routeExtensionDeadlinePopupReducer,
  routeTemplate: routeTemplaterReducer,
  activeTasks: documentRouteActiveTasksReducer,
  routeSendToApprovement: routeSendToApprovementReducer,
  regulationsDeadline: regulationsDeadlineReducer,
  documentsControlAlternatives: documentsControlAlternativesReducer,
};

export const selectDocumentRouteModule = createFeatureSelector<DocumentRouteModuleState>('documentRouteModule');

export function localStorageSyncReducer(
  reducer: ActionReducer<{ documentRouteTap: DocumentRouteTapState }>
): ActionReducer<{ documentRouteTap: DocumentRouteTapState }> {
  return localStorageSync({ keys: ['documentRouteTap'] })(reducer);
}

export const documentsRouteModuleMetaReducers: Array<MetaReducer<{ documentRouteTap: DocumentRouteTapState }, any>> = [
  localStorageSyncReducer,
];

export const getRouteEmployeesYouCanChange = createSelector(
  selectDocumentRouteModule,
  (state) => state.documentRoute.employeesYouCanChange
);
