import { Pipe, PipeTransform } from '@angular/core';
import { AssignedExecutorSendingModel } from '@appShared/api/personal-cabinet/models/assigned-executor-sending.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { AssignedExecutorTableModel } from 'app/personal-cabinet/personal-settings/models/assigned-executor-table.model';

@Pipe({
  name: 'assignedExecutorsRemapPipe',
})
export class AssignedExecutorsRemapPipe implements PipeTransform {
  transform(value: AssignedExecutorSendingModel[], formType?: DictionaryModel): AssignedExecutorTableModel[] {
    if (value.length === 0) {
      return;
    }

    const data = formType
      ? value.filter((res) => {
          return res.assignmentFormType.id === formType.id;
        })
      : value;

    return data.map((taskPackage) => {
      const { firstName, lastName, patronymic, jobPosition, organization } = taskPackage.executor;
      const fullName = `${lastName} ${firstName} ${patronymic}`;

      return {
        taskId: taskPackage.id,
        orderTypeName: taskPackage.assignmentFormType.name,
        fullName: fullName,
        jobPosition: jobPosition.name,
        organization: organization.name,
      };
    });
  }
}
