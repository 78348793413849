import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '@models/tabset/tabs.model';

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsetComponent {
  @Input()
  tabs: Tab[];
  @Input()
  activeTab: string;
  @Input()
  invalidForms: boolean[];
  @Output()
  onSelectTab: EventEmitter<string> = new EventEmitter();

  public selectTab(id: string): void {
    this.activeTab = id;
    this.onSelectTab.emit(id);
  }
}
