import { Action } from '@ngrx/store';
import { DashboardSearchResponseModel } from '@appShared/api/dashboard/models/dashboard.responce/dashboard-search-responce.model';
import { Params } from '@angular/router';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';
import { SharedFolderResponseModel } from '@appShared/api/personal-cabinet/models/personal-folders-access/shared-folder.response.model';

export enum DashboardPageActionTypes {
  ToggleFilterVisible = '[Dashboard Page] ToggleFilterVisible',
  SetDashboardRouteParams = '[Dashboard Page] SetRouteParams',
  GetDashboardFilterResults = '[Dashboard Page] GetFilterResults',
  GetDashboardFilterResultsSuccess = '[Dashboard Page] GetFilterResultsSuccess',
  GetDashboardFilterResultsError = '[Dashboard Page] GetFilterResultsError',
  GetAccessSharedFolders = '[Dashboard Page] GetAccessSharedFolder',
  GetAccessSharedFoldersSuccess = '[Dashboard Page] GetAccessSharedFolderSuccess',
  SetAccessCount = '[Dashboard Page] SetAccessCount',
  ChangeSelectedDashboardStatistic = '[Dashboard Page] ChangeSelectedDashboardStatistic',
  DashboardPageResetView = '[Dashboard Page] ResetView',
  GetLazyDashboardItems = '[Dashboard Page] GetLazyDashboardItems',
  GetLazyDashboardItemsSuccess = '[Dashboard Page] GetLazyDashboardItemsSuccess',
  GetLazyDashboardItemsError = '[Dashboard Page] GetLazyDashboardItemsError',
  RemoveDashboardItems = '[Dashboard Page] RemoveDashboardItems',
  ExpandSidebar = '[Dashboard Page] Expand Sidebar',
  CollapseSidebar = '[Dashboard Page] Collapse Sidebar',
}

/** показываем/скрываем фильтр дашборда */
export class ToggleDashboardFilterVisible implements Action {
  readonly type = DashboardPageActionTypes.ToggleFilterVisible;
  constructor(public payload?: boolean) {}
}

/** задаем парметры роута */
export class SetDashboardRouteParams implements Action {
  readonly type = DashboardPageActionTypes.SetDashboardRouteParams;
  /**
   * @param payload набор параметров
   */
  constructor(public payload: Params) {}
}

/** Запросить список результатов фильтрации */
export class GetDashboardFilterResults implements Action {
  readonly type = DashboardPageActionTypes.GetDashboardFilterResults;
}

/** Подтверждение успешной загрузки списока результатов фильтрации */
export class GetDashboardFilterResultsSuccess implements Action {
  readonly type = DashboardPageActionTypes.GetDashboardFilterResultsSuccess;
  /**
   * @param payload набор найденных результатов и информация о пагинации
   */
  constructor(public payload: DashboardSearchResponseModel) {}
}

/** Получение списка доступных папок */
export class GetAccessSharedFolders implements Action {
  readonly type = DashboardPageActionTypes.GetAccessSharedFolders;
  constructor(public payload: { statisticName: string; activeProjectGroupType: ProjectGroupType }) {}
}

export class GetAccessSharedFoldersSuccess implements Action {
  readonly type = DashboardPageActionTypes.GetAccessSharedFoldersSuccess;
  constructor(public payload: SharedFolderResponseModel[]) {}
}

/** Установка занчения счетчика дооступа */
export class SetAccessCount implements Action {
  readonly type = DashboardPageActionTypes.SetAccessCount;
  constructor(public payload: number) {}
}

/** ошибка при загрузке списка документов */
export class GetDashboardFilterResultsError implements Action {
  readonly type = DashboardPageActionTypes.GetDashboardFilterResultsError;
  /**
   * @param payload ошибка
   */
  constructor(public payload: any) {}
}

/** Подгрузка элементов дашборда путем lazy loading */
export class GetLazyDashboardItems implements Action {
  readonly type = DashboardPageActionTypes.GetLazyDashboardItems;
}

export class GetLazyDashboardItemsSuccess implements Action {
  readonly type = DashboardPageActionTypes.GetLazyDashboardItemsSuccess;
  /**
   * @param payload набор найденных результатов и информация о пагинации
   */
  constructor(public payload: DashboardSearchResponseModel) {}
}

export class RemoveDashboardItems implements Action {
  readonly type = DashboardPageActionTypes.RemoveDashboardItems;
  /**
   * @param payload набор айдишников документов на удаление
   */
  constructor(public payload: number[]) {}
}

export class GetLazyDashboardItemsError implements Action {
  readonly type = DashboardPageActionTypes.GetLazyDashboardItemsError;
  /**
   * @param payload ошибка
   */
  constructor(public payload: any) {}
}

/**Выбираем виджет */
export class ChangeSelectedDashboardStatistic implements Action {
  readonly type = DashboardPageActionTypes.ChangeSelectedDashboardStatistic;
  /**
   * @param payload название выбранной статистики
   */
  constructor(public payload: string) {}
}

/** Сброс дэшборда в изначальное значение */
export class DashboardPageResetView implements Action {
  readonly type = DashboardPageActionTypes.DashboardPageResetView;
}

/** Сворачивание Dashboard Sidebar */
export class CollapseSidebar implements Action {
  readonly type = DashboardPageActionTypes.CollapseSidebar;
}

/** Разворачивание Dashboard Sidebar */
export class ExpandSidebar implements Action {
  readonly type = DashboardPageActionTypes.ExpandSidebar;
}

export type DashboardPageAction =
  | ToggleDashboardFilterVisible
  | SetDashboardRouteParams
  | GetDashboardFilterResults
  | GetDashboardFilterResultsSuccess
  | GetDashboardFilterResultsError
  | GetAccessSharedFolders
  | GetAccessSharedFoldersSuccess
  | SetAccessCount
  | ChangeSelectedDashboardStatistic
  | DashboardPageResetView
  | GetLazyDashboardItems
  | GetLazyDashboardItemsSuccess
  | GetLazyDashboardItemsError
  | RemoveDashboardItems
  | CollapseSidebar
  | ExpandSidebar;
