import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { DocumentsVersionBaseModel } from '@models/document-composition/documents-version-base.model';

@Component({
  selector: 'app-select-radio',
  templateUrl: './select-radio.component.html',
  styleUrls: ['./select-radio.component.scss'],
})
export class SelectRadioComponent extends ControlBaseComponent {
  @Input() public id: string;
  @Input() public name: string;
  @Input() public title: string;
  @Input() public value: DocumentsVersionBaseModel;

  @Output() public changeEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    super();
  }
}
