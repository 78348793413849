import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { Urls } from '@appCore/urls/urls';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { Observable } from 'rxjs';
import { AssignedExecutorSendingModel } from '../personal-cabinet/models/assigned-executor-sending.model';

@Injectable({ providedIn: 'root' })
export class EmployeeApiService {
  constructor(private http: HttpService) {}

  public getEmployeeList(
    organizationId: string,
    positionId: string,
    filterInput: string,
    positionName?: string,
    historyDateTime?: string
  ): Observable<EmployeeBaseModel[]> {
    const url = Urls.getEmployeeList(organizationId, positionId, filterInput, positionName, historyDateTime);
    return this.http.getObservable<EmployeeBaseModel[]>(url);
  }

  /** получение пользователя по его айди */
  public getSingleEmployee(employeeId: number): Observable<EmployeeBaseModel> {
    const url = Urls.getSingleEmployee(employeeId);

    return this.http.getObservable(url);
  }
  /** получение всех пользователей по id организации */
  public getEmployeesByOrganizationId(organizationId: number): Observable<EmployeeBaseModel[]> {
    const url = Urls.getAllEmployeesByOrganizationId(organizationId);

    return this.http.getObservable(url);
  }

  /** проверка наличия оиб роли у пользователя */
  public checkEmployeeOibRole(employeeId: number, roleName: string): Observable<boolean> {
    const url = Urls.checkEmployeeOibRole(employeeId, roleName);

    return this.http.getObservable(url);
  }

  public getFavoriteEmployeeList(assignmentTypeId: number): Observable<AssignedExecutorSendingModel[]> {
    const url = Urls.getFavoriteAssignedExecutors();
    const params = { assignmentTypeId };

    return this.http.getObservable<AssignedExecutorSendingModel[]>(url, { params });
  }
}
