import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { DelegatingInfoModel } from '@models/delegating/delegating-info.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DelegatingActions, DelegatingActionTypes } from '@appCore/store/delegating/delegating.actions';

export interface DelegatingState extends EntityState<DelegatingInfoModel> {
  loadStatus: StoreLoadStatus;
  saveStatus: StoreLoadStatus;
  deleteStatus: StoreLoadStatus;
  activeDelegationId: number;
}

const DelegatingAdapter = createEntityAdapter<DelegatingInfoModel>({
  selectId: (e) => e.id,
});

const DEFAULT_STATE: DelegatingState = DelegatingAdapter.getInitialState({
  loadStatus: null,
  saveStatus: null,
  deleteStatus: null,
  activeDelegationId: null,
});

export function delegatingReducer(state: DelegatingState = DEFAULT_STATE, action: DelegatingActions): DelegatingState {
  switch (action.type) {
    case DelegatingActionTypes.Load:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };

    case DelegatingActionTypes.LoadSuccess:
      return DelegatingAdapter.setAll(action.payload, {
        ...state,
        loadStatus: StoreLoadStatus.loaded,
      });

    case DelegatingActionTypes.LoadError:
      return {
        ...state,
        loadStatus: StoreLoadStatus.error,
      };

    case DelegatingActionTypes.Create:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };

    case DelegatingActionTypes.CreateSuccess:
      return DelegatingAdapter.addOne(action.payload, {
        ...state,
        activeDelegationId: action.payload.id,
        saveStatus: StoreLoadStatus.loaded,
      });

    case DelegatingActionTypes.CreateError:
      return {
        ...state,
        saveStatus: StoreLoadStatus.error,
      };

    case DelegatingActionTypes.Edit:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };

    case DelegatingActionTypes.EditSuccess:
      return DelegatingAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload,
        },
        {
          ...state,
          saveStatus: StoreLoadStatus.loaded,
        }
      );

    case DelegatingActionTypes.EditError:
      return {
        ...state,
        saveStatus: StoreLoadStatus.error,
      };

    case DelegatingActionTypes.Delete: {
      return {
        ...state,
        deleteStatus: StoreLoadStatus.inProgress,
      };
    }

    case DelegatingActionTypes.DeleteSuccess: {
      return DelegatingAdapter.removeOne(action.payload, {
        ...state,
        deleteStatus: StoreLoadStatus.loaded,
      });
    }

    case DelegatingActionTypes.DeleteError: {
      return {
        ...state,
        deleteStatus: StoreLoadStatus.error,
      };
    }

    case DelegatingActionTypes.SetActiveId: {
      return {
        ...state,
        activeDelegationId: action.payload,
      };
    }
    case DelegatingActionTypes.Reset: {
      return {
        ...DEFAULT_STATE,
      };
    }

    default:
      return state;
  }
}

export const { selectAll: selectAll } = DelegatingAdapter.getSelectors();
