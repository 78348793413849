import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { HighlightedDateModel } from '@appShared/components/controls/input-calendar/models/highlighted-date.model';
import moment, { Moment } from 'moment';
import { forkJoin, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MIN_YEAR } from '../const/min-year.const';
import { CalendarDateColors } from '../enums/calendar-date-colors.enum';
import { CalendarFontFamily } from '../enums/calendar-date-font-family.enum';
import { DaysOfWeek } from '../enums/days-of-week.enum';
import { DisplayPositionModel, UserInputStreamModel } from '../models/date-picker.model';
import { CalendarHolidayModel } from '../models/holiday.model';
import { getHolidays } from '../store/holidays';
CalendarFontFamily;

/** вспомогательный сервис для выпадающего календаря */
@Injectable()
export class DatePickerService {
  public dateFormat = 'DD.MM.YYYY';

  constructor(private redux: ReduxService) {}

  /** добавить разделитель к дате в правильный момент */
  insertDateSeparator(newDateString: string, lastDateString: string): string {
    const pattern = /(^\d{2}$)|(^\d{2}\.\d{2}$)/;
    const patternLastState = /(^\d{2}\.$)|(^\d{2}\.\d{2}\.$)/;

    if (pattern.test(newDateString) && !patternLastState.test(lastDateString)) {
      newDateString += '.';
    }

    return newDateString;
  }

  /** добавить 0 перед цифрой, когда пользователь поленился это сделать сам */
  public addZeroInsteadOfUser(inputValue: UserInputStreamModel): UserInputStreamModel {
    inputValue.inputString = inputValue.inputString.replace(/^([1-9]\.)$/, '0$1');
    inputValue.inputString = inputValue.inputString.replace(/^(\d{2}\.)([1-9]\.)$/, '$10$2');
    return inputValue;
  }

  /** очистить текущую дату, если она выходит за разрешенный период */
  public clearOutCurrentDate(inputValue: UserInputStreamModel, noClearDate: boolean): UserInputStreamModel {
    if (!inputValue.currentDate.isValid()) {
      return inputValue;
    }

    const isAfterDate = inputValue.currentDate.isAfter(inputValue.maxDate, 'day');
    const isBeforeDate = inputValue.currentDate.isBefore(inputValue.minDate, 'day');
    const isSameOrBefore = inputValue.currentDate.isSameOrBefore(inputValue.minDate, 'day');

    if (!noClearDate) {
      if (isAfterDate || isBeforeDate) {
        inputValue.currentDate = moment(null);
        inputValue.isValidPeriod = false;
      }
    } else {
      if (isAfterDate || isSameOrBefore) {
        inputValue.isValidPeriod = false;
      }
    }

    return inputValue;
  }

  /** получить последний понедельник до первого числа месяца указаной даты */
  public getLastMondayInLeftMonth(date: Moment): Moment {
    const firstDayofMonth = moment({ year: date.year(), month: date.month(), day: 1 });

    const offset = 1 - firstDayofMonth.isoWeekday();
    return firstDayofMonth.add(offset, 'days');
  }

  /** отметить неактивные для выбора дни */
  public isInactiveDay(displayPosition: DisplayPositionModel, day: Moment) {
    if (displayPosition.minDate.isValid() && day.isBefore(displayPosition.minDate, 'day')) {
      return true;
    }

    return displayPosition.maxDate.isValid() && day.isAfter(displayPosition.maxDate, 'day');
  }

  /** проверка правильности ввода данных (частичный ввод) */
  public checkInputString(inputValue: UserInputStreamModel): UserInputStreamModel {
    const testStringDate = inputValue.inputString + '31.12.1996'.slice(inputValue.inputString.length);
    const testMoment = moment(testStringDate, this.dateFormat, true);
    inputValue.isValidInput = testMoment.isValid() && testMoment.year() >= MIN_YEAR;
    inputValue.currentDate = moment(inputValue.inputString, this.dateFormat, true);
    return inputValue;
  }

  /** подписаться на совместное получение праздников и позиции календаря */
  public subscribeToCurrentHolidays(displayPosition: DisplayPositionModel) {
    const displayedYear = displayPosition.datePosition.year();
    const currentHolidays = this.redux.selectStore(getHolidays).pipe(
      filter((holidays: CalendarHolidayModel[]) => this.isCurrentYearForHolidays(holidays, displayedYear)),
      first()
    );

    return forkJoin(of(displayPosition), currentHolidays);
  }

  /** флаг на выходной день */
  public isHolyday(date: Moment, holidays: CalendarHolidayModel[]): boolean {
    const dateString = date.format('YYYY-MM-DD');
    const day = holidays.find((dateBlock) => dateString === dateBlock.date);

    if (!day) {
      return false;
    }

    return day.isWeekend;
  }

  /** получение цвета дня */
  public getHighlightedColor(date: Moment, highlightedDates: HighlightedDateModel[]): string {
    if (!highlightedDates) {
      return null;
    }

    const day = highlightedDates.find((i) => moment(i.date).isSame(date, 'days'));

    return day ? day.color : null;
  }

  public getHighlightedFontWeight(date: Moment, highlightedDates: HighlightedDateModel[]): string {
    if (!highlightedDates) {
      return null;
    }

    const day = highlightedDates.find((i) => moment(i.date).isSame(date, 'days'));

    return day ? day.fontFamily : null;
  }

  /** сдвинуть позицию в начало разрешенного периода  */
  public movePositionToPermittedPeriod(inputValue: UserInputStreamModel): UserInputStreamModel {
    if (!inputValue.datePosition.isValid()) {
      inputValue.datePosition = inputValue.currentDate.isValid() ? inputValue.currentDate : moment();
    }

    if (inputValue.minDate.isAfter(inputValue.minDate, 'day')) {
      inputValue.minDate = inputValue.maxDate = moment(null);
    }

    if (inputValue.minDate.isAfter(inputValue.datePosition, 'day')) {
      inputValue.datePosition = inputValue.minDate;
    }

    if (inputValue.maxDate.isBefore(inputValue.datePosition, 'day')) {
      inputValue.datePosition = inputValue.maxDate;
    }

    return inputValue;
  }

  /** получить дефолтный список праздников */
  public getDefaultHolidaysList(year: number | string): CalendarHolidayModel[] {
    const holidays: CalendarHolidayModel[] = [];
    for (let month = 1; month <= 12; month++) {
      for (let day = 1; day <= moment(`${year}-${month}`, 'YYYY-M').daysInMonth(); day++) {
        const momentDay = moment(`${year}-${month}-${day}`, 'YYYY-M-D');
        const isHoliday = momentDay.isoWeekday() > 5 ? true : false;
        holidays.push({ date: momentDay.format('YYYY-MM-DD'), isWeekend: isHoliday });
      }
    }

    return holidays;
  }

  /** формирование текущей даты */
  public mapCurrentDate(inputValue: UserInputStreamModel): UserInputStreamModel {
    inputValue.currentDate = moment(inputValue.inputString, this.dateFormat);

    return inputValue;
  }

  public setMeetingDateColor(meetingDates: string[], dayOfWeek: DaysOfWeek): HighlightedDateModel[] {
    return meetingDates.map((date) => {
      // если есть параметр dayOfWeek, то для запланированныз заседаний применяем особую раскраску
      // а дни подходящие под dayOfWeek - в зеленый цвет

      const isWeekend = [DaysOfWeek.saturday, DaysOfWeek.sunday].includes(moment(date).day());
      const weekdayColor = isWeekend ? CalendarDateColors.red : CalendarDateColors.gray;

      if (dayOfWeek) {
        return { date, color: weekdayColor };
      }

      return { date, color: CalendarDateColors.green };
    });
  }

  public clearColorWhenWeekHasMeetings(
    meetingDates: HighlightedDateModel[],
    dayOfWeek: DaysOfWeek
  ): HighlightedDateModel[] {
    meetingDates.forEach((meetingDate) => {
      const startWeekDay = moment(meetingDate.date).startOf('week');
      const endWeekDay = moment(meetingDate.date).endOf('week');
      const isHaveMeetingOnWeek = meetingDates.some((meetingDate) =>
        moment(meetingDate.date).isBetween(startWeekDay, endWeekDay, 'days', '[]')
      );

      const date = startWeekDay.add(dayOfWeek - 1, 'days').format('YYYY-MM-DD');
      if (isHaveMeetingOnWeek) {
        meetingDates.push({
          date,
          color: CalendarDateColors.default,
          fontFamily: CalendarFontFamily.roboRegular,
        });
      }
    });
    return meetingDates;
  }

  /** выходит ли новая дата за пределы минимального и максимального ограничения */
  public isOutOfRestriction(newPosition: moment.Moment, minDate: moment.Moment, maxDate: moment.Moment): boolean {
    const isBeforeMinDate =
      minDate && minDate.isSameOrAfter(newPosition, 'year') && minDate.isAfter(newPosition, 'month');
    const isAfterMaxDate =
      maxDate && maxDate.isSameOrBefore(newPosition, 'year') && maxDate.isBefore(newPosition, 'month');

    return isBeforeMinDate || isAfterMaxDate;
  }

  /** флаг пристутствия выбранного года в списке дат с празниками */
  private isCurrentYearForHolidays(holidays: CalendarHolidayModel[], displayedYear: number): boolean {
    return !!holidays.find((dateBlock) => dateBlock.date.substr(0, 4) === displayedYear.toString());
  }
}
