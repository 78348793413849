import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

/**
 * Сервис со вспомогательными методами для форм
 */
@Injectable({
  providedIn: 'root',
})
export class FormsHelperService {
  /**
   * Включение контрола и добавление валидаторов
   *
   * @param control - абстрактный контрол
   * @param validators - массив с валидаторами
   */
  public enableControlAndSetValidators(
    control: AbstractControl,
    validators?: ValidatorFn[],
    emitEvent: boolean = true
  ): void {
    this.enableControl(control, emitEvent);
    if (validators && validators.length) {
      this.setValidators(control, validators, emitEvent);
    }
  }

  /**
   * Обновление занчение контрола и отметка как его как использованного
   *
   * @param control - абстрактный контрол
   * @param newValue - новое значение
   */
  public updateControlValueAndMarkAsTouched(control: AbstractControl, newValue: string): void {
    this.updateControlValue(control, newValue);
    control.markAsDirty();
    control.markAsTouched();
  }

  /**
   * Обновление занчение контрола
   *
   * @param control - абстрактный контрол
   * @param newValue - новое значение
   */
  public updateControlValue(control: AbstractControl, newValue: string): void {
    if (!control) {
      return;
    }

    control.setValue(newValue);
    control.updateValueAndValidity();
  }

  /**
   * Блокирование контрола и сброс его валидаторов
   *
   * @param control - абстрактный контрол
   */
  public disableControlAndClearValidators(control: AbstractControl, emitEvent: boolean = true): void {
    this.clearValidators(control, emitEvent);
    this.disableControl(control, emitEvent);
  }

  /**
   * Включение контрола и сброс его значения
   *
   * @param control - абстрактный контрол
   */
  public enableControlAndResetValue(control: AbstractControl): void {
    this.enableControl(control);
    this.resetControlValue(control);
  }

  /**
   * Проверка контрола на валидность
   *
   * @param control - абстрактный контролы
   */
  public isControlValid(control: AbstractControl): boolean {
    const result: boolean = control.valid && control.touched;
    return result;
  }

  /**
   * Метод делает все помечает все поля как активированые. Нужно для обновления CSS классов валидации формы
   *
   * @param formGroup - выбранная форма
   */
  public markFormGroupAsTouched(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((controlName) => {
      formGroup.get(controlName).markAsTouched();
      formGroup.get(controlName).markAsDirty();
    });
  }

  /**
   * Метод делает все помечает все поля как активированые. Нужно для обновления CSS классов валидации формы
   *
   * @param formArray - выбранная форма
   */
  public markFormArrayWithFormGroupsAsTouched(formArray: UntypedFormArray): void {
    if (!formArray) {
      return;
    }

    formArray.controls.forEach((group: UntypedFormGroup) => {
      this.markFormGroupAsTouched(group);
    });
  }

  /**
   * Метод делает все помечает все поля формы и всех дочерних элементов как активированые. Нужно для обновления CSS классов валидации формы
   */
  public markAsTouchedDeep(control: AbstractControl): void {
    this.executeDeep(control, (c) => {
      c.markAsTouched();
      c.markAsDirty();
    });
  }

  /**
   * Метод обновляет поля формы и валидирует. Нужно для обновления CSS классов валидации формы
   */
  public updateValueAndValidityDeep(control: AbstractControl): void {
    this.executeDeep(control, (c) => {
      c.updateValueAndValidity();
    });
  }

  /**
   * Добавление валидаторов для контрола реактивных форм
   *
   * @param control - абстрактный контрол
   * @param validators - массив с валидаторами
   * @param emitEvent - флаг рассылки событий
   */
  public setValidators(control: AbstractControl, validators: ValidatorFn[], emitEvent: boolean = true): void {
    control.setValidators(validators);
    control.updateValueAndValidity({ emitEvent });
  }

  /**
   * Удаление валидаторов для контрола реактивных форм
   *
   * @param control - абстрактный контрол
   */
  public clearValidators(control: AbstractControl, emitEvent: boolean = true): void {
    control.clearValidators();
    control.updateValueAndValidity({ emitEvent });
  }

  /**
   * Сброс значения для контрола реактивных форм
   *
   * @param control - абстрактный контрол
   */
  public resetControlValue(control: AbstractControl): void {
    control.reset();
  }

  /**
   * Дизейбл значения для контрола реактивных форм
   *
   * @param control - абстрактный контрол
   */
  public disableControl(control: AbstractControl, emitEvent: boolean = true): void {
    control.disable({ emitEvent });
  }

  /**
   * Включение значения для контрола реактивных форм
   *
   * @param control - абстрактный контрол
   */
  public enableControl(control: AbstractControl, emitEvent: boolean = true): void {
    control.enable({ emitEvent });
  }

  /** Дизейбл значения для группы отдельных контролов реактивных форм */
  public disableGroupOfSeparateControl(constrols: AbstractControl[]): void {
    constrols.forEach((control) => this.disableControl(control));
  }

  /** Включение значения для группы отдельных контролов реактивных форм */
  public enableGroupOfSeparateControl(constrols: AbstractControl[]): void {
    constrols.forEach((control) => this.enableControl(control));
  }

  /** Сброс значения для группы отдельных контролов реактивных форм */
  public resetGroupOfSeparateControl(constrols: AbstractControl[]): void {
    constrols.forEach((control) => this.resetControlValue(control));
  }

  /**
   *  Функция прогоняет набор контролов через набор валидаторов,
   *  устанавливая ошибки соответствующим контролам
   *
   *  @param controls - набор контролов
   *  @param validators - набор валидаторов
   */
  public validate(controls: AbstractControl | AbstractControl[], validators: ValidatorFn | ValidatorFn[]): void {
    this.asArray(controls).forEach((c) =>
      c.setErrors(
        this.asArray(validators)
          .map((validator) => validator(c))
          .reduce(
            (acc, cur) =>
              cur
                ? {
                    ...acc,
                    ...cur,
                  }
                : acc,
            null
          )
      )
    );
  }

  /**
   * Функция для сброса ошибок у набора контролов
   *
   * @param controls - набор контролов
   */
  public clearErrors(controls: AbstractControl | AbstractControl[]): void {
    this.asArray(controls).forEach((c) => c.setErrors(null));
  }

  /** Удаление ошибки из контрола по имени ошибки */
  public deleteErrorByName(control: AbstractControl, errorName: string): void {
    const errors = { ...control.errors };

    if (control.hasError(errorName)) {
      delete errors[errorName];
    }

    if (errors && !Object.keys(errors).length) {
      control.setErrors(null);
      return;
    }

    control.setErrors(errors);
  }

  /** Добавление ошибки в контрол */
  setErrors(control: AbstractControl, error: Object): void {
    if (!control.errors) {
      control.setErrors(error);
      return;
    }

    control.setErrors({ ...control.errors, ...error });
  }

  /**
   * Функция для сброса ошибок у набора контролов и всех их детей и их детей и т.д.
   *
   * @param controls - набор контролов
   */
  public clearErrorsDeep(controls: AbstractControl | AbstractControl[]): void {
    this.executeDeep(controls, (c) => c.setErrors(null));
  }

  /**
   * Проверяет пустая ли форма.
   * Форма считается пустой, если все ее контролы пустые.
   *
   * @param controls - контролы для проверки
   */
  public isEmpty(controls: AbstractControl | AbstractControl[]): boolean {
    let empty = true;

    this.executeDeep(
      controls,
      (c) => {
        empty = empty && !c.value;
      },
      false
    );

    return empty;
  }

  /**
   * todo В дальнейшем переделать validator: string на validator: ValidatorFn
   *
   * Проверяет если control имеет validator в списке валидаторов
   *
   * @param control - проверяемый контрол
   * @param validator - текстовое обозначение искомого валидатора
   */
  public doesControlHaveValidator(control: AbstractControl, validator: string): boolean {
    return (
      typeof control?.validator === 'function' &&
      control.validator({} as AbstractControl) &&
      control.validator({} as AbstractControl)[validator]
    );
  }

  /** Вспомогательная функция для приведения аргументов функций к массиву */
  private asArray<T>(arg: T | T[]): T[] {
    return [].concat(arg);
  }

  /** Вспомогательная функция для обзода формы и всех дочерних элементов */
  private executeDeep(
    controls: AbstractControl | AbstractControl[],
    callback: (control: AbstractControl) => void,
    includeGroups: boolean = true
  ): void {
    this.asArray(controls).forEach((c) => {
      if (c instanceof UntypedFormControl || includeGroups) {
        callback(c);
      }
      let childControls: AbstractControl[] = null;
      if (c instanceof UntypedFormGroup) {
        const group = c as UntypedFormGroup;
        childControls = Object.keys(group.controls).map((controlName) => group.controls[controlName]);
      }
      if (c instanceof UntypedFormArray) {
        const array = c as UntypedFormArray;
        childControls = array.controls;
      }
      if (childControls) {
        childControls.forEach((childControl) => this.executeDeep(childControl, callback, includeGroups));
      }
    });
  }
}
