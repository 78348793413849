import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() isWarning: boolean;
  @Input() checked: boolean;
  @Input() isFirstAlert: boolean;
  @Input() invisible: boolean;
  @Input() noLabel: boolean;
  @Output() onChange = new EventEmitter<boolean>();

  change(): void {
    if (!this.isFirstAlert) {
      this.checked = !this.checked;
    }
    this.onChange.emit(this.checked);
  }
}
