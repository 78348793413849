import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { WheelScrollService } from '@appCore/services/DOM-services/wheel-scroll.service';
import { DomEventTypes } from '@appShared/enums/dom-event-types.enum';
import { WINDOW } from '@appShared/tokens/window.token';
import { EMPTY, Subject, of } from 'rxjs';
import { debounceTime, delay, filter, switchMap, takeUntil } from 'rxjs/operators';
import { TooltipClipboardComponent } from './tooltip-clipboard.component';

@Directive({
  selector: '[appTooltipClipboard]',
})
export class TooltipClipboardDirective extends Unsubscriber implements OnInit, OnDestroy {
  public delayHide = 10;
  public delayMoveEvent = 600;
  public delayHideAfterClick = 1000;
  private readonly maxDeltaHorizontalPosition = 180;
  private readonly maxDeltaVerticalPosition = 70;
  private tooltipComponentRef: ComponentRef<TooltipClipboardComponent>;
  private mouseLeave$: Subject<MouseEvent> = new Subject();
  private mouseMove$: Subject<MouseEvent> = new Subject();
  private isTooltipShow = false;
  private isMoveAround = false;
  private isCursorOverTooltip: boolean;
  private offset = 5;
  private ngUnsubscribeTooltip$: Subject<void> = new Subject();
  private copiedElement: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private _ngZone: NgZone,
    private wheelScroll: WheelScrollService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToMouseLeave();
    this.subscribeToMouseMove();
    this.createMouseEventsListeners();
    this.subscribeToWheel();
  }

  ngOnDestroy(): void {
    this.isTooltipShow = false;
    this.hide();
    this.unsubscribe();
    this.viewContainerRef.element.nativeElement.removeEventListener(DomEventTypes.mouseenter, Function);
    this.viewContainerRef.element.nativeElement.removeEventListener(DomEventTypes.mouseleave, Function);
  }

  private subscribeToWheel(): void {
    this.wheelScroll
      .getScrollWheelEvent()
      .pipe(
        filter(() => !!this.tooltipComponentRef?.instance),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        this.hide();
      });
  }

  private subscribeToMouseMove(): void {
    this.mouseMove$
      .pipe(
        filter(() => !this.isCursorOverTooltip),
        debounceTime(this.delayMoveEvent),
        filter(() => !this.isCursorOverTooltip),
        filter(() => this.isMoveAround),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((event: MouseEvent) => {
        if (!this.copiedElement) {
          this.hide();
          return;
        }

        if (this.isTooltipShow) {
          this.setTooltipPosition(event);
          return;
        }

        this.show(event);
      });
  }

  private subscribeToMouseLeave(): void {
    /* если пришло событие leave - скрываем, но при условии, что курсор не на тултипе */
    this.mouseLeave$.pipe(delay(this.delayHide), takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      if (this.isCursorOverTooltip) {
        return;
      }
      this.isMoveAround = false;
      this.hide();
    });
  }

  /* прослушивание событий курсора вне зоны ангуляра, что бы не плодить лишние проверки изменений */
  private createMouseEventsListeners(): void {
    this._ngZone.runOutsideAngular(() => {
      const parentHost = this.viewContainerRef.element.nativeElement;
      parentHost.addEventListener(
        DomEventTypes.mouseleave,
        (mouseEvent: MouseEvent) => {
          this.mouseLeave$.next(mouseEvent);
        },
        false
      );

      parentHost.addEventListener(
        DomEventTypes.mousemove,
        (mouseEvent: MouseEvent) => {
          this.isMoveAround = true;
          this.copiedElement = mouseEvent.composedPath().find((el: HTMLElement) => el?.dataset?.copy);
          this.mouseMove$.next(mouseEvent);
        },
        false
      );
    });
  }

  private show(mouseEvent: MouseEvent): void {
    if (this.isTooltipShow) {
      return;
    }
    this._ngZone.run(() => {
      const componentFactory = this.resolver.resolveComponentFactory(TooltipClipboardComponent);
      this.tooltipComponentRef = this.viewContainerRef.createComponent(componentFactory);
      const tooltipElementHtml = this.getHtmlElement();

      tooltipElementHtml.addEventListener('mouseenter', () => {
        this.isCursorOverTooltip = true;
      });

      tooltipElementHtml.addEventListener('mouseleave', () => {
        this.hide();
        this.isCursorOverTooltip = false;
      });

      this.tooltipComponentRef.instance.copiedEvent
        .pipe(
          switchMap(() => of(EMPTY).pipe(delay(this.delayHideAfterClick))),
          takeUntil(this.ngUnsubscribeTooltip$)
        )
        .subscribe(() => {
          this.isCursorOverTooltip = false;
          this.hide();
        });

      this.setTooltipPosition(mouseEvent);
      this.isTooltipShow = true;
      this.documentRef.body.appendChild(tooltipElementHtml);
    });
  }

  private hide(): void {
    this._ngZone.run(() => {
      if (!this.tooltipComponentRef) {
        return;
      }
      this.applicationRef.detachView(this.tooltipComponentRef.hostView);
      this.tooltipComponentRef.destroy();
      this.isTooltipShow = false;
      this.unsubscribeTooltip();
    });
  }

  private setTooltipPosition(mouseEvent: MouseEvent): void {
    this.tooltipComponentRef.instance.title = this.copiedElement.dataset.title;
    this.tooltipComponentRef.instance.data = this.copiedElement.dataset.value;
    const xPosition = mouseEvent.clientX;
    const yPosition = mouseEvent.clientY;
    const windowWidth = this.windowRef.innerWidth;
    const windowHeight = this.windowRef.innerHeight;
    const instance = this.tooltipComponentRef.instance;
    if (windowWidth - xPosition > this.maxDeltaHorizontalPosition) {
      instance.leftPosition = `${xPosition + this.offset}px`;
      instance.rightPosition = '';
    } else {
      instance.rightPosition = `${windowWidth - xPosition + this.offset}px`;
      instance.leftPosition = '';
    }

    if (windowHeight - yPosition > this.maxDeltaVerticalPosition) {
      instance.bottomPosition = '';
      instance.topPosition = `${yPosition + this.offset}px`;
    } else {
      instance.topPosition = '';
      instance.bottomPosition = `${windowHeight - yPosition + this.offset}px`;
    }

    this.cdr.markForCheck();
  }

  private unsubscribeTooltip(): void {
    this.ngUnsubscribeTooltip$.next();
    this.ngUnsubscribeTooltip$.complete();
  }

  private getHtmlElement(): HTMLElement {
    return (this.tooltipComponentRef.hostView as EmbeddedViewRef<any>)?.rootNodes[0] as HTMLElement;
  }
}
