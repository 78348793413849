import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY } from 'rxjs';
import { ANNOUNCEMENT_KEY } from '@appShared/const/announcement-key.const';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { getUserInfo } from '@appCore/store/user-info';
import { AnnouncementService } from '@appShared/services/announcement.service';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { NavigationStart, Router } from '@angular/router';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementComponent extends Unsubscriber implements OnInit, OnDestroy {
  public announcementOpened$ = this.announcementService.getIsAnnouncementOpened$();
  public announcement$ = this.announcementService.getAnnouncement$();
  public userInfo$ = this.redux.selectStore(getUserInfo);
  constructor(private announcementService: AnnouncementService, private redux: ReduxService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.subscribeRouteChanges();
    this.subscribeAnnouncement();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public onClose(): void {
    this.announcementService.setAnnouncementOpened(false);
  }

  private subscribeRouteChanges(): void {
    const routeChange = this.router.events.pipe(filter((item) => item instanceof NavigationStart));

    combineLatest([this.userInfo$, routeChange])
      .pipe(
        filter(([userInfo]) => !!userInfo),
        switchMap(() => this.announcementService.getActiveAnnouncement$()),
        catchError(() => {
          this.announcementService.setAnnouncementOpened(false);
          this.announcementService.setAnnouncement(null);
          return EMPTY;
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe();
  }

  private subscribeAnnouncement(): void {
    combineLatest([this.announcement$, this.userInfo$]).subscribe(([announcement, userInfo]) => {
      if (!userInfo || !announcement) {
        return;
      }
      const items = JSON.parse(localStorage.getItem(ANNOUNCEMENT_KEY)) || [];
      const isClosed = items.some((item) => item?.[userInfo?.currentUser.employee.id] === announcement.id);
      const hasText = !!announcement?.text?.trim().length;

      const isOpened = announcement?.active && !isClosed;
      this.announcementService.setAnnouncementOpened(isOpened && hasText);
    });
  }
}
