import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { OrderTypesAction, OrderTypesActionTypes } from './order-types.actions';
import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryWithCodeModel>([], null);

export function orderTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: OrderTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case OrderTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case OrderTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case OrderTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case OrderTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (orderTypes) => orderTypes.saveStatus;
/** Получение словаря */
export const getOrderTypes = (orderTypes) => orderTypes.dictionary;
