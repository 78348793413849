import { RegulationsDeadlineActions, RegulationsDeadlineActionTypes } from '@appDocuments/documents-route/store/regulations-deadline/regulations-deadline.actions';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { RegulationsDeadlineModel } from '@models/regulations-deadline/regulations-deadline.model';

export interface RegulationsDeadlineState {
  status: StoreLoadStatus;
  deadlines: RegulationsDeadlineModel;
  error: any;
}

export const initialState: RegulationsDeadlineState = {
  status: null,
  deadlines: null,
  error: null
};

export function regulationsDeadlineReducer(
  state = initialState,
  action: RegulationsDeadlineActions
): RegulationsDeadlineState {
  switch (action.type) {
    case RegulationsDeadlineActionTypes.RegulationsDeadlineGet:
    case RegulationsDeadlineActionTypes.RegulationsDeadlineUpdate:
      return {
        ...state,
        error: null,
        status: StoreLoadStatus.inProgress
      };

    case RegulationsDeadlineActionTypes.RegulationsDeadlineUpdateSuccess:
    case RegulationsDeadlineActionTypes.RegulationsDeadlineGetSuccess:
      return {
        status: StoreLoadStatus.loaded,
        deadlines: action.payload,
        error: null
      };

    case RegulationsDeadlineActionTypes.RegulationsDeadlineError:
      return {
        status: StoreLoadStatus.error,
        deadlines: null,
        error: action.payload
      };

    case RegulationsDeadlineActionTypes.RegulationsDeadlineReset:
      return initialState;

    default: {
      return state;
    }
  }
}
