import { delegatingReducer, DelegatingState } from '@appCore/store/delegating/delegating.reducer';
import { errorMessageReducer } from '@appCore/store/error-message/error-message.reducer';
import { notificationsReducer, NotificationsState } from '@appCore/store/notifications/notifications.reducer';
import { PersonalFoldersAccessStore } from '@appCore/store/personal-folders-access/models/shared-personal-folders-store.model';
import { personalFoldersAccessReducer } from '@appCore/store/personal-folders-access/personal-folders-access.reducer';
import { PersonalFoldersStoreModel } from '@appCore/store/personal-folders/models/personal-folders-store.model';
import { personalFoldersReducer } from '@appCore/store/personal-folders/personal-folders.reducer';
import { RouterStateUrl } from '@appCore/store/router/router';
import { ErrorMessageModel } from '@models/error-message.model';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { cryptoProReducer, CryptoProState } from './crypto-pro/crypto-pro.reducer';
import { documentsAsideReducer, DocumentsAsideState } from './document-aside/document-aside.reducer';
import { popupReducer, PopupState } from './popup/popup.reducer';
import { SharedFiltersStore } from './shared-filters/models/shared-filter-store.model';
import { sharedFiltersReducer } from './shared-filters/shared-filters.reducer';
import { toastsReducer, ToastsState } from './toasts/toasts.reducer';
import { userInfoReducer } from './user-info/user-info.reducer';

/** Интерфейс для хранилища */
export interface State {
  router?: RouterReducerState<RouterStateUrl>;
  userInfo?: any;
  cryptoPro?: CryptoProState;
  toasts?: ToastsState;
  popup?: PopupState;
  notifications?: NotificationsState;
  errorMessage?: ErrorMessageModel;
  delegating?: DelegatingState;
  documentsAside?: DocumentsAsideState;
  personalFoldersAccess?: PersonalFoldersAccessStore;
  personalFolders?: PersonalFoldersStoreModel;
  sharedFilters?: SharedFiltersStore;
}

/** Список редюсеров */
export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  /** Данные пользователя */
  userInfo: userInfoReducer,
  /**Подпись */
  cryptoPro: cryptoProReducer,
  /** Тосты */
  toasts: toastsReducer,
  /* активность попапа */
  popup: popupReducer,
  /** уведомления */
  notifications: notificationsReducer,
  /** Сообщение об ошибке для попапа */
  errorMessage: errorMessageReducer,
  /** делегирование */
  delegating: delegatingReducer,
  /** Левый сайдбар Пакета документов */
  documentsAside: documentsAsideReducer,
  /** Папки с предоставленным доступом */
  personalFoldersAccess: personalFoldersAccessReducer,
  /** Личные папки */
  personalFolders: personalFoldersReducer,
  /** Расшаренные фильтры (см. sharedFiltersReducer) */
  sharedFilters: sharedFiltersReducer,
};
