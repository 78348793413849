import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** компонент кастомизированной шапки при работе с пакетом документов */
@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderComponent {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  canReduceTitle: boolean;
}
