export enum DaysOfWeek {
  /** Воскресенье */
  sunday,
  /** Понедельник */
  monday,
  /** Вторник */
  tuesday,
  /** Среда */
  wednesday,
  /** Четверг */
  thursday,
  /** Пятница */
  friday,
  /** Суббота */
  saturday,
}
