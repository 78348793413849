import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastPlaceholders } from '@appCore/enums/toast-placeholders.enum';
import { ToastModel } from '@appCore/models/toast.model';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { ToastsService } from '@appCore/services/toasts/toasts.service';
import { getActiveToasts } from '@appCore/store/toasts';
import { NotificationType } from '@appMain/notifications/enums/notification-type.enum';
import { NotificationNavigateService } from '@appMain/notifications/services/notification-navigate.service';
import { DelegatingApiService } from '@appShared/api/delegating/delegating.api.service';
import { UserInfoApiService } from '@appShared/api/user-info/user-info.api.service';
import { ButtonStyles } from '@appShared/enums/button-styles.enum';
import { isLocalLegalAct } from '@appShared/pipes/project-group-type-classname.pipe';
import { WINDOW } from '@appShared/tokens/window.token';
import { NotificationCounterTypes } from '@models/notifications/notification-counter-types.enum';
import { NotificationLink } from '@models/notifications/notifications.response/notification-link.model';
import { SymbolIconListEnum } from '@sharedEnums/symbol-icon-list.enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  providers: [NotificationNavigateService],
  animations: [
    trigger('fadeInOut', [
      state(
        'in',
        style({
          opacity: 100,
        })
      ),
      transition('void => *', [style({ opacity: 0 }), animate(500)]),
      transition('* => void', [animate(500, style({ opacity: 0 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ToastsComponent implements OnInit {
  public readonly buttonStyles = ButtonStyles;
  public readonly counterTypes = NotificationCounterTypes;
  public placeholders = new Map();

  public toasts$: Observable<ToastModel[]>;
  public context = this;
  public canAllowedToSwitch = false;

  constructor(
    private redux: ReduxService,
    private toasts: ToastsService,
    private notificationNavigate: NotificationNavigateService,
    private delegatingApi: DelegatingApiService,
    private userInfoService: UserInfoApiService,
    @Inject(WINDOW) private windowRef: Window
  ) {}

  ngOnInit(): void {
    this.toasts$ = this.redux.selectStore(getActiveToasts);
  }

  public isTypeDisplaying(toast: ToastModel): boolean {
    return !toast.isError && toast.projectTypeShortName && toast.placeholdersOrder.has(ToastPlaceholders.projectType);
  }

  /** Удаление "тоста" */
  public removeToast(toast: ToastModel, event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.toasts.remove(toast);
  }

  /**
   *
   * @param toast уведомление
   */
  public getLink(toast: ToastModel): Observable<string> {
    const linkParams: NotificationLink = {
      documentPackageId: toast?.documentPackageId,
      deadlineExtensionRequestId: toast?.deadlineExtensionRequestId,
      agendaPackageId: toast?.agendaPackageId,
      folderId: toast?.folderId,
      notificationTypeId: toast?.notificationType.id,
      delegatingId: toast.delegatingId,
    };

    const allowedTypesToSwitch = [NotificationType.delegationOfAuthorityActing];
    this.canAllowedToSwitch = allowedTypesToSwitch.includes(toast.notificationType.id);

    return this.notificationNavigate.getLink(linkParams);
  }

  public launchEvent(ev: Event, toast: ToastModel, placeholder: string): void {
    ev.preventDefault();
    const isLinkPropExist = placeholder.hasOwnProperty('isLink');

    if (isLinkPropExist && this.canAllowedToSwitch) {
      localStorage.removeItem('ldeDocumentType');
      this.delegatingApi
        .switchToDelegating(toast.delegatingId)
        .subscribe(this.userInfoService.reloadApp(this.windowRef));
    }
  }

  /** выдать название стилевого класса, зависящий от типа уведомления */
  public getStyleClassForTypeNotification(notificationType: NotificationType | undefined): string {
    return this.toasts.getStyleClassForType(notificationType);
  }

  public getProjectTypeSrc(toast: ToastModel) {
    const isLocal = isLocalLegalAct(toast.projectGroupType?.id);
    if (!isLocal) {
      return SymbolIconListEnum.EMBLEM;
    }
    return SymbolIconListEnum[toast.projectTypeDesignation ?? 'CIRCLE'];
  }

  public isEmblemProjectType(toast: ToastModel) {
    return !isLocalLegalAct(toast.projectGroupType?.id);
  }
}
