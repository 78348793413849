import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnableByPermissionDirective } from './enable-by-permission.directive';
import { PermissionsGuardService } from './permissions-guard.service';
import { PermissionsDirective } from './permissions.directive';
import { PermissionsService } from './permissions.service';

@NgModule({
  imports: [CommonModule],
  declarations: [PermissionsDirective, EnableByPermissionDirective],
  exports: [PermissionsDirective, EnableByPermissionDirective],
  providers: [PermissionsService, PermissionsGuardService]
})
export class PermissionsModule {}
