import { Action } from '@ngrx/store';
import { DocumentsControlAlternativesByPhase } from './document-control-alternatives.reducer';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentsItemModel } from '@models/document-composition/documents-item.model';

export enum DocumentControlAlternativestActionTypes {
  GetDocumentControlAlternatives = '[Document Control Alternatives] GetDocumentControlAlternatives',
  GetDocumentsMainBlockControlAlternatives = '[Document Control Alternatives] GetDocumentsMainBlockControlAlternatives',
  GetDocumentControlAlternativesSuccess = '[Document Control Alternatives] GetDocumentControlAlternativesSuccess',
  GetDocumentControlAlternativesError = '[Document Control Alternatives] GetDocumentControlAlternativesError',
  GetDocumentControlAlternativesOrRemarksResult = '[Document Control Alternatives] GetDocumentControlAlternativesOrRemarksResult',
  SetDocumentControlAlternativesStatus = '[Document Control Alternatives] SetDocumentControlAlternativesStatus',
  Reset = '[Document Control Alternatives] Reset',
}

export class DocumentControlAlternatives implements Action {
  readonly type = DocumentControlAlternativestActionTypes.GetDocumentControlAlternatives;
  constructor(public payload: DocumentControlAlternativesModel) {}
}

export class DocumentMainBlockControlAlternatives implements Action {
  readonly type = DocumentControlAlternativestActionTypes.GetDocumentsMainBlockControlAlternatives;
  constructor(public payload: number[]) {}
}

export class DocumentControlAlternativesSuccess implements Action {
  readonly type = DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesSuccess;
  constructor(public payload: DocumentsControlAlternativesByPhase[]) {}
}

export class DocumentControlAlternativesError implements Action {
  readonly type = DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesError;
  constructor(public payload: any) {}
}

export class DocumentControlAlternativesSetStatus implements Action {
  readonly type = DocumentControlAlternativestActionTypes.SetDocumentControlAlternativesStatus;
  constructor(public payload: StoreLoadStatus) {}
}

export class DocumentControlAlternativesGetResult implements Action {
  readonly type = DocumentControlAlternativestActionTypes.GetDocumentControlAlternativesOrRemarksResult;
  constructor(public payload: boolean) {}
}

export class DocumentControlAlternativesReset implements Action {
  readonly type = DocumentControlAlternativestActionTypes.Reset;
}

export type DocumentControlAlternativesActions =
  | DocumentControlAlternatives
  | DocumentMainBlockControlAlternatives
  | DocumentControlAlternativesSuccess
  | DocumentControlAlternativesError
  | DocumentControlAlternativesSetStatus
  | DocumentControlAlternativesGetResult
  | DocumentControlAlternativesReset;

export interface DocumentControlAlternativesModel {
  activePoint: number[];
  documents: DocumentsItemModel[];
}
