import { agreementRoutePhasesReducer } from '@appShared/store/dictionaries/agreement-route-phases/agreement-route-phases.reducer';
import { alertPeriodTypesReducer } from '@appShared/store/dictionaries/alert-period-types/alert-period-types.reducer';
import { caseStatusesReducer } from '@appShared/store/dictionaries/case-statuses/case-statuses.reducer';
import { ConsiderationDocumentTypesReducer } from '@appShared/store/dictionaries/consideration-document-types/consideration-document-types.reducer';
import { consideratonPlacesReducer } from '@appShared/store/dictionaries/consideration-places/consideration-places.reducer';
import { consideratonPMMeetingQuestionTypesReducer } from '@appShared/store/dictionaries/consideration-pm-meeting-questions-types/consideration-pm-meeting-questions-types.reducer';
import { consideratonQuestionTypesReducer } from '@appShared/store/dictionaries/consideration-questions-types/consideration-questions-types.reducer';
import { consideratonTypesReducer } from '@appShared/store/dictionaries/consideration-types/consideration-types.reducer';
import { coveringTextTypesReducer } from '@appShared/store/dictionaries/covering-text-types/covering-text-types.reducer';
import { documentPackageRelationTypesReducer } from '@appShared/store/dictionaries/document-package-relation-types/document-package-relation-types.reducer';
import { documentStagesReducer } from '@appShared/store/dictionaries/document-stages/document-stages.reducer';
import { documentTemplatesReducer } from '@appShared/store/dictionaries/document-templates/document-templates.reducer';
import { folderEditRightTypesReducer } from '@appShared/store/dictionaries/folder/folder-edit-right-types/folder-edit-right-types.reducer';
import { folderViewAccessTypesReducer } from '@appShared/store/dictionaries/folder/folder-view-access-types/folder-view-access-types.reducer';
import { orderTypesReducer } from '@appShared/store/dictionaries/order-types/order-types.reducer';
import { organizationsLeaderReducer } from '@appShared/store/dictionaries/organizations-leader/organizations-leader.reducer';
import { participantTypesReducer } from '@appShared/store/dictionaries/participant-types/participant-types.reducer';
import { questionResolutionTypesReducer } from '@appShared/store/dictionaries/question-resolution-types/question-resolution-types.reducer';
import { reservationStatusesReducer } from '@appShared/store/dictionaries/reservation-statuses/reservation-statuses.reducer';
import { DictionaryEnumModel } from '@models/base/dictionary-enum.model';
import { DictionaryFullNameModel } from '@models/base/dictionary-full-name.model';
import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryWithCodeModel } from '@models/base/object-with-code.model';
import { ConsiderationParticipantTypeModel } from '@models/calendar-consideration/consideration-participant-type.model';
import { DictionaryStoreMapModel } from '@models/dictionary-store-map.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { GroupedProjectTypeModel } from '@models/document-package/project-type.model';
import { DocumentStatusItemModel } from '@models/document-statuses/document-status-item.model';
import { DocumentStatusValueModel } from '@models/document-statuses/document-status-value.model';
import { DocumentTypeModel } from '@models/document/document-type.model';
import { TemplateDocumentTypeModel } from '@models/document/template-document-type.model';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { approvalFormTypesReducer } from './approval-form-types/approval-form-types.reducer';
import { documentProhibitionTypesReducer } from './document-prohibition-types/document-prohibition-types.reducer';
import { documentStatusItemsReducer } from './document-status-items/document-status-items.reducer';
import { documentStatusValuesReducer } from './document-status-values/document-status-values.reducer';
import { documentStatusesReducer } from './document-statuses/document-statuses.reducer';
import { documentTypesAvailableReducer } from './document-types-available/document-types-available.reducer';
import { documentTypesReducer } from './document-types/document-types.reducer';
import { organizationsReducer } from './organizations/organizations.reducer';
import { projectTypesReducer } from './project-types/project-types.reducer';
import { questionCategoriesReducer } from './question-categories/question-categories.reducer';
import { signerCategoriesReducer } from './signer-categories/signer-categories.reducer';
import { nomenclaturesReducer } from '@appShared/store/dictionaries/nomenclatures/nomenclatures.reducer';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';
import {
  availableNomenclaturesReducer
} from "@appShared/store/dictionaries/availablenomenclatures/available-nomenclatures.reducer";

/** Интерфейс для хранилища */
export interface DictionariesState {
  projectTypes: DictionaryStoreModel<GroupedProjectTypeModel>;
  documentStatuses: DictionaryStoreModel<DictionaryModel>;
  approvalFormTypes: DictionaryStoreModel<DictionaryFullNameModel>;
  organizations: DictionaryStoreModel<DictionaryWithShortNameModel>;
  considerationTypes: DictionaryStoreModel<DictionaryModel>;
  participantTypes: DictionaryStoreModel<ConsiderationParticipantTypeModel>;
  questionResolutionTypes: DictionaryStoreModel<DictionaryModel>;
  signerCategories: DictionaryStoreModel<DictionaryEnumModel>;
  documentTypes: DictionaryStoreModel<DocumentTypeModel>;
  documentTypesAvailable: DictionaryStoreModel<DocumentTypeModel>;
  documentStatusValues: DictionaryStoreModel<DocumentStatusValueModel>;
  documentStatusItems: DictionaryStoreModel<DocumentStatusItemModel>;
  considerationQuestionsTypes: DictionaryStoreModel<DictionaryModel>;
  considerationPMMeetingQuestionsTypes: DictionaryStoreModel<DictionaryModel>;
  documentPackageRelationTypes: DictionaryStoreModel<DictionaryModel>;
  considerationDocumentTypes: DictionaryStoreModel<DocumentTypeModel>;
  documentTemplates: DictionaryStoreModel<TemplateDocumentTypeModel>;
  documentStages: DictionaryStoreModel<DictionaryWithDescriptionModel>;
  caseStatuses: DictionaryStoreModel<DictionaryModel>;
  organizationsLeader: DictionaryStoreModel<DictionaryModel>;
  alertPeriodTypes: DictionaryStoreModel<DictionaryWithDescriptionModel>;
  considerationPlaces: DictionaryStoreModel<DictionaryModel>;
  orderTypes: DictionaryStoreModel<DictionaryModel>;
  coveringTextTypes: DictionaryStoreModel<DictionaryModel>;
  questionCategories: DictionaryStoreModel<DictionaryWithCodeModel>;
  reservationStatuses: DictionaryStoreModel<DictionaryModel>;
  documentProhibitionTypes: DictionaryStoreModel<DictionaryWithCodeModel>;
  folderViewAccessTypes: DictionaryStoreModel<DictionaryModel>;
  folderEditRightTypes: DictionaryStoreModel<DictionaryModel>;
  agreementRoutePhases: DictionaryStoreMapModel<DictionaryModel>;
  nomenclatures: DictionaryStoreModel<NomenclatureModel>;
  availableNomenclatures: DictionaryStoreModel<NomenclatureModel>;
}

/** Список редюсеров */
export const dictionariesReducer: ActionReducerMap<DictionariesState> = {
  /** Типы проекта */
  projectTypes: projectTypesReducer,
  /** Типы статусов проекта */
  documentStatuses: documentStatusesReducer,
  /** Типы формы утверждения */
  approvalFormTypes: approvalFormTypesReducer,
  /** список организаций */
  organizations: organizationsReducer,
  /** Типы рассмотрения пакета повестки */
  considerationTypes: consideratonTypesReducer,
  /** Типы участников пакета повестки */
  participantTypes: participantTypesReducer,
  /** Типы решений по вопросам пакета повестки */
  questionResolutionTypes: questionResolutionTypesReducer,
  /** Категории подписанта */
  signerCategories: signerCategoriesReducer,
  /** Типы документов */
  documentTypes: documentTypesReducer,
  /** Типы документов доступные по ОИВ */
  documentTypesAvailable: documentTypesAvailableReducer,
  /** список возможных значений статусов ПД */
  documentStatusValues: documentStatusValuesReducer,
  /** список возможных статусов ПД */
  documentStatusItems: documentStatusItemsReducer,
  /** типы вопросов пакета повестки */
  considerationQuestionsTypes: consideratonQuestionTypesReducer,
  /** типы вопросов пакета повестки Заседание */
  considerationPMMeetingQuestionsTypes: consideratonPMMeetingQuestionTypesReducer,
  /** типы документов для сущности Пакет повестки */
  considerationDocumentTypes: ConsiderationDocumentTypesReducer,
  /** шаблоны документов */
  documentTemplates: documentTemplatesReducer,

  documentPackageRelationTypes: documentPackageRelationTypesReducer,
  /** этапы жизненного цикла документа */
  documentStages: documentStagesReducer,
  /** статус дел  */
  caseStatuses: caseStatusesReducer,
  /** подчиненность организации */
  organizationsLeader: organizationsLeaderReducer,
  alertPeriodTypes: alertPeriodTypesReducer,
  /** Места проведения пакета повестки */
  considerationPlaces: consideratonPlacesReducer,
  /** Типы поручения */
  orderTypes: orderTypesReducer,
  /** Типы поручения сопроводительного текста */
  coveringTextTypes: coveringTextTypesReducer,
  /** Типы категорий для вопросов повестки ПМ и ППМ */
  questionCategories: questionCategoriesReducer,
  /** Статусы резервации номеров документов  */
  reservationStatuses: reservationStatusesReducer,
  /** Типы ограничений для документа */
  documentProhibitionTypes: documentProhibitionTypesReducer,
  /** Типы прав просмотра папки */
  folderViewAccessTypes: folderViewAccessTypesReducer,
  /** Типы прав редактирования папки */
  folderEditRightTypes: folderEditRightTypesReducer,
  /** Этапы согласования */
  agreementRoutePhases: agreementRoutePhasesReducer,
  /** Номенклатурные индексы */
  nomenclatures: nomenclaturesReducer,
  /** Номенклатурные индексы доступные пользователю */
  availableNomenclatures: availableNomenclaturesReducer
};

export const selectDictionaries = createFeatureSelector<DictionariesState>('dictionaries');
