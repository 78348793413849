import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, Type } from '@angular/core';
import { ModalWindowRef } from '@appShared/modals-helper/modal-window-ref';

@Component({
  selector: 'app-overlay',
  templateUrl: './modal-window-base.component.html',
  styleUrls: ['./modal-window-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWindowBaseComponent implements OnInit {
  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context;

  constructor(private modalWindowRef: ModalWindowRef) {}

  close() {
    this.modalWindowRef.close(null);
  }

  ngOnInit() {
    this.content = this.modalWindowRef.content;

    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.modalWindowRef.close.bind(this.modalWindowRef),
      };
    } else {
      this.contentType = 'component';
    }
  }
}
