import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { Urls } from '@appCore/urls/urls';
import { EdoWithEmailSendingResponceModel } from '@appDocuments/document-sendings/models/edo-with-email-sending-responce.model';
import { EmailSendingRequestModel } from '@appDocuments/document-sendings/models/email-sending-request.model';
import { MassSendingRequestModel } from '@appDocuments/document-sendings/models/mass-sending-request.model';
import { PaperSendingRequestModel } from '@appDocuments/document-sendings/models/paper-sending-request.model';
import { PaperSendingModel } from '@appDocuments/document-sendings/models/paper-sending.model';
import { RepeatedSendingModel } from '@appDocuments/document-sendings/models/repeated-sending.model';
import { Sending } from '@appDocuments/document-sendings/models/sending.model';
import { UnsentSendingsModel } from '@appDocuments/document-sendings/models/unsent-sendings.model';
import { IsProAssignment } from '@appDocuments/shared/models/is-pro-assignment.model';
import { IsProRelationCreateResponce } from '@appDocuments/shared/models/is-pro-relation-create-responce.model';
import { LinkedIsProAssignment } from '@appDocuments/shared/models/linked-is-pro-assignment.model';
import { ReviewDateChangeRequestResponseModel } from '@appDocuments/shared/models/review-date-change-request-response.model';
import { calendarEventTypes } from '@appMain/calendar/const/calendar-event-types.const';
import { DocumentPackageConsiderationInfoModel } from '@appShared/api/document-package/models/document-package/document-package-consideration-info.model';
import { DocumentStatusItem } from '@appShared/enums/document-status-items.enum';
import { DocumentPackageBusinessVersionModel } from '@models/document-package/document-package-business-version.model';
import { DocumentPackageRequestModel } from '@models/document-package/document-package-request.model';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { DocumentStatusModel } from '@models/document-statuses/document-status.model';
import { PublicationsModel } from '@models/publications/publications.model';
import { Observable } from 'rxjs';
import { DocumentPackageByReservedStatusRequest } from './models/document-package/document-package-by-reserved-status-request.model';
import { DocumentPackageWithRedBlankLazyResponce } from './models/document-package/document-package-with-red-blank-lazy-responce.model';
import { DocumentPackageWithRedBlank } from './models/document-package/document-package-with-red-blank.model';
import { PaginationResponseModel } from '@models/pagination-response.model';

@Injectable({ providedIn: 'root' })
export class DocumentPackageApiService {
  constructor(private http: HttpService) {}

  /** Сохранение пакета документов */
  public save(request: DocumentPackageRequestModel): Observable<DocumentPackageResponseModel> {
    const url: string = Urls.packageFormSave();

    return this.http.postObservable(url, request);
  }

  /** Обновление пакета документов */
  public update(request: DocumentPackageRequestModel): Observable<DocumentPackageResponseModel> {
    const url: string = Urls.packageFormSave();

    return this.http.putObservable(url, request);
  }

  /** Сохранение пакета документов */
  public getDocumentPackage(id: number): Observable<DocumentPackageResponseModel> {
    const url: string = Urls.documentPackageGet(id);
    return this.http.getObservable(url);
  }

  /** Получение бизнес версии */
  public getBusinessVersion(packageId: number): Observable<DocumentPackageBusinessVersionModel> {
    const url = Urls.documentPackageGetBusinessVersion(packageId);
    return this.http.getObservable(url);
  }

  public addDocumentPackageSending(sending: Sending, documentPackageId: number): Observable<any> {
    const url = Urls.documentPackageSendingsAddOrUpdate();
    return this.http.postObservable(url, sending, { params: { documentPackageId } });
  }

  public updateDocumentPackageSending(sending: Sending): Observable<Sending> {
    const url = Urls.documentPackageSendingsAddOrUpdate();
    return this.http.putObservable(url, sending);
  }

  public getDocumentPackageSending(id: number): Observable<Sending> {
    const url = Urls.documentPackageSendingsGetOrRemove(id);
    return this.http.getObservable(url);
  }

  public removeDocumentPackageSending(id: number): Observable<void> {
    const url = Urls.documentPackageSendingsGetOrRemove(id);
    return this.http.deleteObservable(url);
  }

  public sendEmailDocumentPackageSending(
    sendingEmail: EmailSendingRequestModel
  ): Observable<EdoWithEmailSendingResponceModel> {
    const url = Urls.documentPackageSendingsSendEmail();
    return this.http.putObservable(url, sendingEmail);
  }

  public sendPaperDocumentPackageSending(sendingPaper: PaperSendingRequestModel): Observable<PaperSendingModel> {
    const url = Urls.documentPackageSendingsSendPaper();
    return this.http.putObservable(url, sendingPaper);
  }

  public massSendDocumentPackageSending(massSending: MassSendingRequestModel): Observable<void> {
    const url = Urls.documentPackageSendingsMassSend();
    return this.http.putObservable(url, massSending);
  }

  public sendRepeatedlyDocumentPackageSending(repeatedSending: RepeatedSendingModel): Observable<Sending> {
    const url = Urls.documentPackageSendingsRepeatedSendings();
    return this.http.putObservable(url, repeatedSending);
  }

  public getUnsentEmailDocumentPackageSendings(documentPackageId: number): Observable<boolean> {
    const url = Urls.documentPackageSendingsUnsentEmail();
    return this.http.getObservable(url, { params: { documentPackageId } });
  }

  public getUnsentCountDocumentPackageSendings(documentPackageId: number): Observable<UnsentSendingsModel> {
    const url = Urls.documentPackageSendingsUnsentCount();
    return this.http.getObservable(url, { params: { documentPackageId } });
  }

  public getUnsentPaperDocumentPackageSendings(documentPackageId: number): Observable<boolean> {
    const url = Urls.documentPackageSendingsUnsentPaper();
    return this.http.getObservable(url, { params: { documentPackageId } });
  }

  public getEmployeeDocumentPackageSendings(documentPackageId: number, employeeId: number): Observable<Sending[]> {
    const url = Urls.documentPackageSendingsGetEmployeeSendings();
    return this.http.getObservable<Sending[]>(url, { params: { documentPackageId, employeeId } });
  }

  public getOrganizationDocumentPackageSendings(
    documentPackageId: number,
    organizationId: number
  ): Observable<Sending[]> {
    const url = Urls.documentPackageSendingsGetOrganizationSendings();
    return this.http.getObservable(url, { params: { documentPackageId, organizationId } });
  }

  public getAllDocumentPackageSendings(
    documentPackageId: number,
    page: number,
    pageSize: number,
    text?: string
  ): Observable<PaginationResponseModel<Sending>> {
    const url = Urls.documentPackageSendingsGetAll(documentPackageId);
    const params = text ? { page, pageSize, text } : { page, pageSize };
    return this.http.getObservable(url, {
      params,
    });
  }

  public updatePublications(packageId: number, data: PublicationsModel): Observable<PublicationsModel> {
    const url = Urls.documentPackagePublicationsGetOrUpdate(packageId);
    return this.http.putObservable(url, data);
  }

  public getPublications(packageId: number): Observable<PublicationsModel> {
    const url = Urls.documentPackagePublicationsGetOrUpdate(packageId);
    return this.http.getObservable(url);
  }

  /** Проверяет, если можно провести заседание на выбранную дату */
  public checkIfScheduleExists(date: string, agendaPackageTypeId: number): Observable<boolean> {
    const url: string = Urls.getCheckIfScheduleExistsUrl();
    /** Айдишник типа события "итоговая повестка" пакета повестки "заседание" */
    const eventTypeId = calendarEventTypes.finalAgenda.id;

    return this.http.getObservable(url, {
      params: { eventTypeId, date, agendaPackageTypeId },
    });
  }

  /** Получение информации о рассмотрениях ПД */
  public getDocumentPаckageConsiderationInfo(packageId: number): Observable<DocumentPackageConsiderationInfoModel[]> {
    const url = Urls.getDocumentPаckageConsiderationInfo();

    return this.http.getObservable(url, {
      params: { documentPackageId: packageId, useQuestions: true },
    });
  }

  public getDocumentPаckageConsiderationRequestsChange(
    packageId: number
  ): Observable<ReviewDateChangeRequestResponseModel[]> {
    const url = Urls.getDocumentPаckageConsiderationRequestsChange(packageId);

    return this.http.getObservable(url);
  }

  /** Получение информации о типе редакции ПД */
  public getDocumentPаckageRevisionTypeInfo(packageId: number): Observable<DocumentStatusModel> {
    const revisionStatusId = DocumentStatusItem.revisionType;
    const url = Urls.getDocumentPаckageRevisionTypeInfo(revisionStatusId, packageId);

    return this.http.getObservable(url);
  }

  /** Поиск пакетов документов по имени или номеру */
  public searchDocumentPackagesByNameOrNumber(
    number: number,
    apiQueryParams?: object,
    otherOptions?: { isCapableForAffect: boolean; isReservedStatus: boolean; isCapableForOtherAffect: boolean }
  ): Observable<DocumentPackageWithRedBlank[]> {
    const { isCapableForAffect, isReservedStatus, isCapableForOtherAffect } = otherOptions || {};

    const url: string = Urls.searchDocumentPackagesByNameOrNumber(
      isCapableForAffect,
      isReservedStatus,
      isCapableForOtherAffect
    );
    const additionalQueryParams = apiQueryParams || {};

    const queryParamForSearch = isReservedStatus ? 'dpNameOrNumberText' : 'number';

    return this.http.getObservable(url, {
      params: { [queryParamForSearch]: number, ...additionalQueryParams },
    });
  }

  public searchDocumentPackagesByReservedStatus(
    queryParams: DocumentPackageByReservedStatusRequest
  ): Observable<DocumentPackageWithRedBlankLazyResponce> {
    const url = Urls.searchDocumentPackagesByReservedStatus();

    const params = { ...queryParams };

    return this.http.getObservable(url, { params });
  }

  /** Получение списка всех ОВИ для пакета документов */
  public getRelatedDocumentPackages(packageId: number): Observable<DocumentPackageWithRedBlank[]> {
    const url = Urls.getRelatedDocumentPackages(packageId);

    return this.http.getObservable(url);
  }

  /** Отметка признака ДП как прочитанный */
  public markDocumentPackagesAsRead(packageId: number): Observable<any> {
    const url = Urls.markDocumentPackageAsRead(packageId);

    return this.http.postObservable(url);
  }

  /**  Получение списка поручений из ИС ПРО */
  public getIsProAssignments(resolutionNumber: string): Observable<IsProAssignment[]> {
    const url = Urls.getIsProAssignments();
    const params = { resolutionNumber };

    return this.http.getObservable(url, { params });
  }

  /** Создание связи между поручением из ИС ПРО и ПД */
  public createIsProAssignmentRelation(
    data: IsProAssignment,
    documentPackageId: number,
    force: boolean
  ): Observable<IsProRelationCreateResponce> {
    const url = Urls.createIsProAssignmentRelation();
    const params = { documentPackageId, force };

    return this.http.postObservable(url, data, { params });
  }

  /** Получение списка созданных связей между поручением из ИС ПРО и ПД */
  public getIsProAssignmentRelations(documentPackageId: number): Observable<LinkedIsProAssignment[]> {
    const url = Urls.getIsProAssignmentRelations();
    const params = { documentPackageId };

    return this.http.getObservable(url, { params });
  }

  /** Удаление связи между поручением из ИС ПРО и ПД */
  public removeAssignmentRelation(
    documentPackageId: number,
    assignmentId: number,
    documentId: number
  ): Observable<void> {
    const url = Urls.removeAssignmentRelation();
    const params = { documentPackageId, assignmentId, documentId };

    return this.http.deleteObservable(url, { params });
  }

  public sendToRegistration(documentPackageId: number, organizationId: number): Observable<void> {
    const url = Urls.sendToRegistration(documentPackageId, organizationId);

    return this.http.postObservable(url, {});
  }

  public returnFromRegistration(documentPackageId: number): Observable<void> {
    const url = Urls.returnFromRegistration(documentPackageId);

    return this.http.postObservable(url, {});
  }

  public checkSendToRegistration(documentPackageId: number): Observable<boolean> {
    const url = Urls.checkSendToRegistration(documentPackageId);

    return this.http.getObservable(url);
  }

  public checkCancelRegistration(documentPackageId: number): Observable<boolean> {
    const url = Urls.checkCancelRegistration(documentPackageId);

    return this.http.getObservable(url);
  }
}
