import { RouterStateUrl } from '@appCore/store/router/router';
import { RouterReducerState } from '@ngrx/router-store';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

export const getRouterStore = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterState = createSelector(getRouterStore, store => store?.state);

export const getUrl = createSelector(getRouterState, state => state.url);

export const getQueryParams = createSelector(getRouterState, state => state?.queryParams);

export const getRouterParams = createSelector(getRouterState, state => state.params);

export function getRouterParam<T>(param: string): MemoizedSelector<any, T> {
  return createSelector(getRouterState, state => state.params[param]);
}
