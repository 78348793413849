import { NotificationCounterListModel } from '@appMain/notifications/models/notification-counter-list.model';
import { PaginationDefaultData } from '@models/enums/pagination-default-data.enum';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { NotificationModel } from '@models/notifications/notifications.response/notifications.response.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { NotificationsActions, NotificationsActionTypes } from './notifications.actions';

export interface NotificationsState extends EntityState<NotificationModel> {
  status: StoreLoadStatus;
  viewedStatus: StoreLoadStatus;
  countersDataLoadStatus: StoreLoadStatus;
  count: number;
  countersData: NotificationCounterListModel;
  totalResultsCount: number;
  currentPageNumber: number;
  lazyStatus: StoreLoadStatus;
  sortingField: string;
  sortingOrder: string;
}

const notificationsAdapter = createEntityAdapter<NotificationModel>({
  selectId: (e) => e.id,
});

export const initialState: NotificationsState = notificationsAdapter.getInitialState({
  status: null,
  viewedStatus: null,
  count: null,
  countersData: null,
  countersDataLoadStatus: null,
  totalResultsCount: null,
  currentPageNumber: PaginationDefaultData.pageNumber,
  lazyStatus: null,
  sortingField: null,
  sortingOrder: null,
});

export function notificationsReducer(
  state: NotificationsState = initialState,
  action: NotificationsActions
): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.Load:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
        currentPageNumber: PaginationDefaultData.pageNumber,
      };

    case NotificationsActionTypes.LoadSuccess:
      return notificationsAdapter.setAll(action.notifications.content, {
        ...state,
        status: StoreLoadStatus.loaded,
        totalResultsCount: action.notifications.totalResultsCount,
      });

    case NotificationsActionTypes.LoadError:
      return {
        ...state,
        status: StoreLoadStatus.error,
      };

    case NotificationsActionTypes.GetCountSuccess:
      return {
        ...state,
        count: action.payload,
      };

    case NotificationsActionTypes.Viewed:
      return {
        ...state,
        viewedStatus: StoreLoadStatus.inProgress,
      };

    case NotificationsActionTypes.ViewedError:
      return {
        ...state,
        viewedStatus: StoreLoadStatus.error,
      };

    case NotificationsActionTypes.GetCounterData:
      return {
        ...state,
        countersDataLoadStatus: StoreLoadStatus.inProgress,
      };

    case NotificationsActionTypes.GetCounterDataSuccess:
      return {
        ...state,
        countersData: action.payload,
        countersDataLoadStatus: StoreLoadStatus.loaded,
      };

    case NotificationsActionTypes.GetCounterDataError:
      return {
        ...state,
        countersDataLoadStatus: StoreLoadStatus.error,
      };

    case NotificationsActionTypes.MarkAsViewed:
      return notificationsAdapter.updateOne(
        {
          id: action.payload.id,
          changes: {
            viewed: action.payload.viewed,
          },
        },
        { ...state }
      );

    case NotificationsActionTypes.DeleteSuccess:
      return notificationsAdapter.removeOne(action.payload, {
        ...state,
      });

    case NotificationsActionTypes.LoadLazy:
      return {
        ...state,
        lazyStatus: StoreLoadStatus.inProgress,
      };

    case NotificationsActionTypes.LoadLazySuccess:
      return notificationsAdapter.addMany(action.notifications.content, {
        ...state,
        lazyStatus: StoreLoadStatus.loaded,
        totalResultsCount: action.notifications.totalResultsCount,
        currentPageNumber: action.currentPage,
      });

    case NotificationsActionTypes.LoadLazyError:
      return {
        ...state,
        lazyStatus: StoreLoadStatus.error,
      };

    case NotificationsActionTypes.ChangeSorting:
      return {
        ...state,
        sortingField: action.payload.fieldName,
        sortingOrder: action.payload.order,
      };

    default:
      return state;
  }
}

export const { selectAll: selectAllNotifications, selectTotal: selectNotificationsCount } =
  notificationsAdapter.getSelectors();
