import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  CdkOverlayOrigin,
  ConnectedPosition,
  ConnectionPositionPair,
  HorizontalConnectionPos,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { CommonLogicService } from '@appShared/services/common-logic.service';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, share, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tooltip-cdk',
  templateUrl: './tooltip-cdk.component.html',
  styleUrls: ['./tooltip-cdk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipCdkComponent extends Unsubscriber implements OnDestroy, OnInit {
  @Input() overlayOrigin: CdkOverlayOrigin;
  @Input() debounceTime = 300;
  @Input() debounceTimeOfHide = 0; // in milliseconds
  @Input() horizontalPos: HorizontalConnectionPos; // 'start' | 'center' | 'end'
  @Input() verticalPos: VerticalConnectionPos; // 'top' | 'center' | 'bottom'
  @Output() close = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();

  @ViewChild('dialog') dialog: ElementRef;
  isOpened = false;
  positions: ConnectedPosition[];

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private commonLogicService: CommonLogicService
  ) {
    super();
  }

  ngOnInit(): void {
    const overlayOriginEl = this.overlayOrigin.elementRef.nativeElement;

    const open$ = fromEvent(overlayOriginEl, 'mouseenter').pipe(
      filter(() => !this.isOpened),
      switchMap(() =>
        fromEvent(this.documentRef, 'mousemove').pipe(
          debounceTime(this.debounceTime),
          filter((event) => overlayOriginEl === event['target'])
        )
      ),
      share()
    );

    open$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.positions = [
        new ConnectionPositionPair(
          { originX: this.horizontalPos ?? 'center', originY: this.verticalPos ?? 'bottom' },
          { overlayX: this.horizontalPos ?? 'center', overlayY: this.verticalPos ?? 'top' }
        ),
      ];
      this.changeState(true);
    });

    const close$ = fromEvent(document, 'mousemove').pipe(
      debounceTime(this.debounceTimeOfHide),
      filter(() => this.isOpened),
      filter((event) => this.isMovedOutside(overlayOriginEl, this.dialog, event))
    );

    open$
      .pipe(
        switchMap(() => close$),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        this.changeState(false);
      });
    open$
      .pipe(debounceTime(0), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.commonLogicService.setDataCoordsEl(this.dialog.nativeElement.getBoundingClientRect()));
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  connectedOverlayDetach(): void {
    this.changeState(false);
  }

  private changeState(isOpened: boolean): void {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.cdr.markForCheck();
  }

  private isMovedOutside(overlayOriginEl, dialog, event): boolean {
    return !(overlayOriginEl.contains(event['target']) || dialog.nativeElement.contains(event['target']));
  }
}
