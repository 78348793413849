import { Injectable } from '@angular/core';
import { SettingApiResponceModel } from '@appShared/api/settings/models/setting-responce.model';
import { SettingsApiService } from '@appShared/api/settings/settings.api.service';
import { SettingCodeEnum } from '@appShared/enums/setting-code.enum';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsHelperService {
  private settings: SettingApiResponceModel[];

  constructor(private settingsApi: SettingsApiService) {}

  public getSettings(): void {
    this.settingsApi
      .getSettingAll()
      .pipe(first())
      .subscribe((settings) => (this.settings = settings));
  }

  /** Получение настроек кнопки Отправить */
  public getSettingApprovingSubmitButtonAllowed(): boolean {
    const sendButton = this.settings?.find((s) => s.code === SettingCodeEnum.sendButton);
    return sendButton ? sendButton.booleanValue : false;
  }

  public getSettingValueByCode(code: SettingCodeEnum): string {
    const setting = !!this.settings && this.settings.find((s) => s.code === code);
    return setting?.value;
  }

  /** Получение настроек видимости блока связей с поручениями ИС ПРО */
  public getIsEnabledIsProRelationsFormSetting(): boolean {
    const setting =
      !!this.settings && this.settings.find((s) => s.code === SettingCodeEnum.isproResolutionsGettingActive);
    return setting ? setting.booleanValue : false;
  }

  /** Получение настроек видимости уведомления о подтверждении действия при переносе ДР в раздел "Принятые документы" */
  public isShowNotifyMoveActingVersionAccDocs(): boolean {
    const setting = this.settings?.find(
      (s) => s.code === SettingCodeEnum.notificationConfirmationActionMovingActingVersionToAcceptedDocuments
    );
    return setting ? setting.booleanValue : false;
  }

  public isGroupedDocumentVersionMode(): boolean {
    const setting = this.settings?.find((s) => s.code === SettingCodeEnum.groupingVersions);
    return setting ? setting.booleanValue : false;
  }
}
