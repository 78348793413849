import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DictionaryApiService } from '../../../api/dictionary/dictionary.api.service';
import {
  OrganizationsActions,
  OrganizationsActionTypes,
  OrganizationsGetError,
  OrganizationsGetSuccess,
} from './organizations.actions';

@Injectable()
export class OrganizationsEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetOrganizations$: Observable<OrganizationsActions> = createEffect(() => this.actions$.pipe(
    ofType(OrganizationsActionTypes.Get),
    switchMap(() =>
      this.dictionaryApi.getOrganizationsAll().pipe(
        map((response) => new OrganizationsGetSuccess(response)),
        catchError(() => of(new OrganizationsGetError()))
      )
    )
  ));

  
  GetOrganizationsForCurrentUser$: Observable<OrganizationsActions> = createEffect(() => this.actions$.pipe(
    ofType(OrganizationsActionTypes.GetForCurrentUser),
    switchMap(() =>
      this.dictionaryApi.getOrganizationsForCurrentUser().pipe(
        map((response) => new OrganizationsGetSuccess(response)),
        catchError(() => of(new OrganizationsGetError()))
      )
    )
  ));
}
