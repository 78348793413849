import { Action } from '@ngrx/store';
import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';

export enum DocumentStagesActionTypes {
  Get = '[Document Stages] Get',
  GetSuccess = '[Document Stages] GetSuccess',
  Error = '[Document Stages] Error',
  Reset = '[Document Stages] Reset',
}

/** запросить список всех возможных cтатусов ПД  */
export class DocumentStagesGet implements Action {
  readonly type = DocumentStagesActionTypes.Get;
}

/** получить исключения */
export class DocumentStagesError implements Action {
  readonly type = DocumentStagesActionTypes.Error;
}

/** получить список всех возможных статусов ПД  */
export class DocumentStagesGetSuccess implements Action {
  readonly type = DocumentStagesActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithDescriptionModel[]) {}
}

/** сбросить список всех возможных статусов ПД */
export class DocumentStagesReset implements Action {
  readonly type = DocumentStagesActionTypes.Reset;
}

export type DocumentStagesActions =
  | DocumentStagesGet
  | DocumentStagesGetSuccess
  | DocumentStagesError
  | DocumentStagesReset;
