import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormsHelperService } from '@appCore/services/form-helper/form-helper.service';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getDelegating, getDelegatingDeleteStatus, getDelegatingSaveInProgress } from '@appCore/store/delegating';
import {
  DelegatingActionsCreate,
  DelegatingActionsDelete,
  DelegatingActionsEdit,
  DelegatingActionsSetActiveId,
} from '@appCore/store/delegating/delegating.actions';
import { getUserInfo } from '@appCore/store/user-info';
import { ControlStyle } from '@appShared/enums/control-style.enum';
import { DatesService } from '@appShared/services/dates.service';
import { DelegatingInfoModel } from '@models/delegating/delegating-info.model';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GENERAL_TEXT_ERRORS } from '@appShared/const/global-text-errors.const';
import { DelegatingFormModel } from '@appShared/components/delegating-popup/models/delegating-form.model';

@Component({
  selector: 'app-delegating-popup',
  templateUrl: './delegating-popup.component.html',
  styleUrls: ['./delegating-popup.component.scss'],
})
export class DelegatingPopupComponent implements OnInit, OnDestroy {
  @Input()
  delegating: DelegatingInfoModel;
  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  public delegatingForm: FormGroup<DelegatingFormModel>;
  public currentUser: EmployeeBaseModel;
  public myDelegatings: DelegatingInfoModel[] = [];
  public isShowForm = true;
  public toggleDeletePopup = false;
  public isSaveInProgress = false;
  public delegatingToDeleteId: number;
  public controlStyle = ControlStyle;
  public isFilledForm = false;

  private subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private formHelper: FormsHelperService,
    private redux: ReduxService,
    private datesService: DatesService
  ) {}

  ngOnInit() {
    this.delegatingForm = this.initDelegatingForm();
    this.setSubscriptions();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public resetForm(): void {
    if (!this.delegatingForm.controls.id.value) {
      return;
    }
    this.delegatingForm.controls.toEmployee.enable();
    this.delegatingForm.reset();
    this.delegatingForm.controls.toEmployee.patchValue({ organization: { ...this.currentUser.organization } });
    this.delegatingForm.controls.acting.patchValue(false);
    this.redux.dispatchAction(new DelegatingActionsSetActiveId(null));
    this.formHelper.markAsTouchedDeep(this.delegatingForm);
  }

  public getFormFirstError(): string {
    const errors = this.delegatingForm.errors;
    for (const errorName in errors) {
      if (errorName) {
        return errors[errorName];
      }
    }

    return null;
  }

  public deleteDelegating(delegating?: DelegatingInfoModel) {
    this.delegatingToDeleteId = delegating ? delegating.id : this.delegatingForm.controls.id.value;
    this.toggleDeletePopup = true;
  }

  public onRemoveDelegating() {
    this.redux.dispatchAction(new DelegatingActionsDelete(this.delegatingToDeleteId));
    this.closePopup();
  }

  public saveDelegating(): void {
    if (this.delegatingForm.invalid) {
      return;
    }

    const delegatingInfo = this.mapDelegatingInfoFormValueToRequest(this.delegatingForm.getRawValue());

    if (delegatingInfo.id) {
      this.redux.dispatchAction(new DelegatingActionsEdit(delegatingInfo));
    } else {
      this.redux.dispatchAction(new DelegatingActionsCreate(delegatingInfo));
    }
    this.closePopup();
  }

  public closePopup(): void {
    this.onClose.emit();
  }

  private initDelegatingForm(): UntypedFormGroup {
    if (this.delegating) {
      this.redux.dispatchAction(new DelegatingActionsSetActiveId(this.delegating.id));
    }

    const form = this.fb.group({
      id: [this.delegating?.id || null],
      toEmployee: [this.delegating?.toEmployee, [Validators.required]],
      dateBegin: [this.delegating ? moment(this.delegating.dateBegin) : null, [Validators.required]],
      timeBegin: [this.delegating ? moment(this.delegating.dateBegin).format('HH:mm') : null, [Validators.required]],
      dateEnd: [this.delegating ? moment(this.delegating.dateEnd) : null, [Validators.required]],
      timeEnd: [this.delegating ? moment(this.delegating.dateEnd).format('HH:mm') : null, [Validators.required]],
      comment: [this.delegating?.comment],
      acting: [this.delegating?.acting || false],
    });

    form.setValidators([this.formDatesValidator.bind(this), this.formEmployeeValidator.bind(this)]);
    this.formHelper.markAsTouchedDeep(form);
    return form;
  }

  private formDatesValidator(form: UntypedFormGroup): ValidationErrors {
    const errorName = 'datesError';
    const dateTimeBegin =
      form.value.dateBegin && form.value.timeBegin
        ? this.datesService.addTimeToDate(form.value.dateBegin, form.value.timeBegin)
        : null;
    const dateTimeEnd =
      form.value.dateEnd && form.value.timeEnd
        ? this.datesService.addTimeToDate(form.value.dateEnd, form.value.timeEnd)
        : null;
    const nowMoment = moment().startOf('minute');

    this.formHelper.deleteErrorByName(form.controls.dateBegin, errorName);
    this.formHelper.deleteErrorByName(form.controls.timeBegin, errorName);
    this.formHelper.deleteErrorByName(form.controls.dateEnd, errorName);
    this.formHelper.deleteErrorByName(form.controls.timeEnd, errorName);

    if (!form.value.id && dateTimeBegin && moment(dateTimeBegin).isBefore(nowMoment)) {
      const error = { [errorName]: GENERAL_TEXT_ERRORS.dateGreaterThanCurrent };
      form.controls.dateBegin.setErrors(error);
      form.controls.timeBegin.setErrors(error);
      return error;
    }

    const isEndSameOrBeforeBegin =
      dateTimeBegin && dateTimeEnd && moment(dateTimeEnd).isSameOrBefore(moment(dateTimeBegin));

    if (isEndSameOrBeforeBegin) {
      const error = { [errorName]: GENERAL_TEXT_ERRORS.endDateMustBiggerStartDate };

      form.controls.dateEnd.setErrors(error);
      form.controls.timeEnd.setErrors(error);

      return error;
    }

    return null;
  }

  private formEmployeeValidator(form: UntypedFormGroup): ValidationErrors {
    const errorName = 'employeeError';
    const formValue = form.value;
    const toEmployeeValue: EmployeeBaseModel = formValue.toEmployee;

    this.formHelper.deleteErrorByName(form.controls.toEmployee, errorName);

    if (
      toEmployeeValue &&
      this.myDelegatings.find((value) => value.id !== formValue.id && value.toEmployee.id === toEmployeeValue.id)
    ) {
      const error = { [errorName]: GENERAL_TEXT_ERRORS.delegationAlreadyAdded };

      form.controls.toEmployee.setErrors(error);

      return error;
    }

    return null;
  }

  private mapDelegatingInfoFormValueToRequest(formValue): DelegatingInfoModel {
    return {
      id: formValue.id,
      fromEmployee: this.currentUser,
      toEmployee: formValue.toEmployee,
      dateBegin: this.datesService.addTimeToDate(formValue.dateBegin, formValue.timeBegin),
      dateEnd: this.datesService.addTimeToDate(formValue.dateEnd, formValue.timeEnd),
      comment: formValue.comment,
      acting: formValue.acting,
    };
  }

  private setSubscriptions(): void {
    this.subscription.add(
      this.redux.selectStore(getUserInfo).subscribe((res) => {
        this.currentUser = res.currentUser.employee;
        const employeeControl = this.delegatingForm.controls.toEmployee;
        if (!employeeControl.value) {
          employeeControl.setValue({ organization: this.currentUser.organization });
        }
      })
    );

    this.subscription.add(this.redux.selectStore(getDelegating).subscribe((md) => (this.myDelegatings = md)));

    this.subscription.add(
      this.redux
        .selectStore(getDelegatingSaveInProgress)
        .subscribe((isSaveInProgress) => (this.isSaveInProgress = isSaveInProgress))
    );

    this.subscription.add(
      this.redux
        .selectStore(getDelegatingDeleteStatus)
        .pipe(filter((status) => status === StoreLoadStatus.loaded))
        .subscribe(() => {
          const formId = this.delegatingForm.controls.id.value;
          if (formId && formId === this.delegatingToDeleteId) {
            this.resetForm();
          }
        })
    );
  }
}
