import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessByPermission',
})
export class AccessByPermissionPipe implements PipeTransform {
  transform(allPermissions: string[], permission: string): boolean {
    if (!allPermissions) {
      return;
    }
    return allPermissions.includes(permission);
  }
}
