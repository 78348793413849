import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './header-button.component.scss'],
})
export class HeaderButtonComponent extends ButtonBaseComponent {
  /** флаг заголовчной кнопки */
  @Input()
  isNotifyButton: boolean;

  /** флаг для кнопки без рамки */
  @Input()
  withoutBorder: boolean;

  @Input()
  withHover = true;

  @Input()
  caption: string;

  constructor() {
    super();
  }
}
