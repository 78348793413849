import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsiderationTypes } from '@appMain/calendar-consideration/enums/consideration-types.enum';
import { CalendarHolidayModel } from '@appShared/components/controls/input-calendar/models/holiday.model';
import { CalendarExportQueryModel } from '@models/calendar/calendar-export-query.model';
import { CalendarPeriodParamsModel } from '@models/calendar/calendar.request/calendar-period-params.model';
import { CalendarDaysResponseModel } from '@models/calendar/calendar.response/calendar-events.response.model';
import { LdeEventModel } from '@models/lde/lde-event.model';
import { Observable, of } from 'rxjs';
import { HttpService } from '../../../core/services/http/http.service';
import { Urls } from '../../../core/urls/urls';

@Injectable({ providedIn: 'root' })
export class CalendarApiService {
  constructor(private httpService: HttpService) { }
  /**Получение календаря */
  public getCalendar(dateBegin: string, dateEnd: string): Observable<CalendarDaysResponseModel[]> {
    const url: string = Urls.getCalendarEvents();
    const params = {
      dateBegin: encodeURI(dateBegin),
      dateEnd: encodeURI(dateEnd),
    };
    return this.httpService.getObservable(url, {
      params: params,
    });
  }

  public getProductionCalendar(params: CalendarPeriodParamsModel): Observable<CalendarHolidayModel[]> {
    const url = Urls.productionDays;

    return this.httpService.getObservable(url, {
      params: this.httpService.getRequestParams(params),
    });
  }

  /** получение дат заседаний одного или нескольких типов заседаний
   * @param agendaPackageTypeIds массив идентификаторов типов заседаний (ConsiderationTypes)
   */
  public getMeetingDates(
    interval: CalendarPeriodParamsModel,
    agendaPackageTypeIds: ConsiderationTypes | ConsiderationTypes[]
  ): Observable<string[]> {
    if (!agendaPackageTypeIds) {
      return of([]);
    }
    const url = Urls.getMeetingDates();
    const params = new HttpParams()
      .append('agendaPackageTypeId', agendaPackageTypeIds.toString())
      .append('dateBegin', interval.dateBegin)
      .append('dateEnd', interval.dateEnd);

    return this.httpService.getObservable(url, { params });
  }

  /** запрос на формирование PDF/DOCX */
  public calendarRegistry(params: CalendarExportQueryModel): Observable<LdeEventModel> {
    const url = Urls.getCalendarRegistry();

    return this.httpService.getObservable(url, { params });
  }
}
