import { ESignResponseModel } from '@models/crypto-pro/crypto-pro.response/esign-response.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CryptoProAction, CryptoProActionTypes } from './crypto-pro.actions';

export interface CryptoProState extends EntityState<ESignResponseModel> {
  statusSign: StoreLoadStatus;
  statusSigners: StoreLoadStatus;
  error: any;
}

const cryptoProAdapter: EntityAdapter<ESignResponseModel> = createEntityAdapter<ESignResponseModel>({
  selectId: (e) => e.id,
});

export const initialState: CryptoProState = cryptoProAdapter.getInitialState({
  statusSign: null,
  statusSigners: null,
  error: null,
});

export function cryptoProReducer(state = initialState, action: CryptoProAction): CryptoProState {
  switch (action.type) {
    case CryptoProActionTypes.Save:
      return {
        ...state,
        statusSign: StoreLoadStatus.inProgress,
      };

    case CryptoProActionTypes.Reset:
      return {
        ...initialState,
      };

    case CryptoProActionTypes.SaveError:
      return {
        ...initialState,
        statusSign: StoreLoadStatus.error,
        error: action.payload,
      };

    case CryptoProActionTypes.SaveSuccess:
      return {
        ...state,
        statusSign: StoreLoadStatus.loaded,
        error: false,
      };

    case CryptoProActionTypes.GetSigners:
      return {
        ...state,
        statusSigners: StoreLoadStatus.inProgress,
      };

    case CryptoProActionTypes.GetSignersError:
      return {
        ...initialState,
        statusSigners: StoreLoadStatus.error,
        error: action.payload,
      };

    case CryptoProActionTypes.GetSignersSuccess:
      return cryptoProAdapter.setAll(action.payload, {
        ...state,
        statusSigners: StoreLoadStatus.loaded,
        error: false,
      });

    default:
      return state;
  }
}

export const { selectAll: selectAllSigners, selectTotal: selectSignersCount } = cryptoProAdapter.getSelectors();
