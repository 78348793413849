import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appErrorMark]',
})
export class ErrorMarkDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public markAsDanger(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'DANGER');
  }
  public unMarkAsDanger(): void {
    this.renderer.removeClass(this.elementRef.nativeElement, 'DANGER');
  }
}
