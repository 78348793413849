/** Типы подэтапов маршрута */
export enum PhaseTypeCode {
  /** инициация */
  initiation = 'INITIATION',
  /** Разработка */
  development = 'DEVELOPMENT',
  /** Согласование */
  approvement = 'APPROVEMENT',
  /** Доработка */
  rework = 'REWORK',
  /**  Экспертиза */
  examination = 'EXAMINATION'
}
