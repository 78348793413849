import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

/** модель выходного / рабочего дня */
export class CalendarHolidayModel {
  date: string;
  isWeekend: boolean;
}

export class CalendarHolidaysStoreModel {
  constructor(public holidays: CalendarHolidayModel[] = [], public loadStatus: StoreLoadStatus = null) {}
}
