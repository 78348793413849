import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

export enum FolderViewAccessTypes {
  Get = '[Folder View Access Types] Get',
  GetSuccess = '[Folder View Access Types] GetSuccess',
  GetError = '[Folder View Access Types] GetError',
  Reset = '[Folder View Access Types] Reset',
}

export class FolderViewAccessTypesGet implements Action {
  readonly type = FolderViewAccessTypes.Get;
}

export class FolderViewAccessTypesGetError implements Action {
  readonly type = FolderViewAccessTypes.GetError;
}

export class FolderViewAccessTypesGetSuccess implements Action {
  readonly type = FolderViewAccessTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class FolderViewAccessTypesReset implements Action {
  readonly type = FolderViewAccessTypes.Reset;
}

export type FolderViewAccessTypesAction =
  | FolderViewAccessTypesGet
  | FolderViewAccessTypesGetError
  | FolderViewAccessTypesGetSuccess
  | FolderViewAccessTypesReset;
