import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { RegistrationNumberStoreModel } from './registration-number-store.model';
import { RegistrationNumberAction, RegistrationNumberActionTypes } from './registration-number.actions';

export const initialState = new RegistrationNumberStoreModel(null, null);

/** Редюсер для учетного номера пакета */
export function registrationNumberReducer(
  state: RegistrationNumberStoreModel = initialState,
  action: RegistrationNumberAction
): RegistrationNumberStoreModel {
  switch (action.type) {
    case RegistrationNumberActionTypes.Set:
      return {
        registrationNumber: action.payload,
        saveStatus: StoreLoadStatus.loaded,
      };
    case RegistrationNumberActionTypes.Reset:
      return initialState;
    default:
      return state;
  }
}
