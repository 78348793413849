import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'toFormControl',
})
export class ToFormControlPipe implements PipeTransform {
  public transform(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }
}
