import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeBaseModel } from '@models/employee/employee-base.model';

@Pipe({
  name: 'employeePhotoUrl'
})
export class EmployeePhotoUrlPipe implements PipeTransform {
  transform = employeePhotoUrl;
}

export function employeePhotoUrl(employee: EmployeeBaseModel): string {
  if (!employee) {
    return '';
  }

  if (employee.photoUrl) {
    return employee.photoUrl;
  }

  const noPhotoName = employee.gender === 'FEMALE' ? 'female-no-photo' : 'male-no-photo';

  return `/assets/icons/${noPhotoName}.png`;
}
