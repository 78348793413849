import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '../../../../core/store/cache-time.const';
import { DictionaryApiService } from '../../../api/dictionary/dictionary.api.service';
import {
  DocumentStatusesAction,
  DocumentStatusesActionTypes,
  DocumentStatusesGetError,
  DocumentStatusesGetSuccess,
} from './document-statuses.actions';

@Injectable()
export class DocumentStatusesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentStatuses$: Observable<DocumentStatusesAction> = createEffect(() => this.actions$.pipe(
    ofType(DocumentStatusesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() => this.dictionaryApi.getStatuses()),
    map((response) => new DocumentStatusesGetSuccess(response)),
    catchError((err) => of(new DocumentStatusesGetError()))
  ));
}
