import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Observable, of } from 'rxjs';
import {
  ConsiderationPlacesAction,
  ConsiderationPlacesActionTypes,
  ConsiderationPlacesGetTopTenSuccess,
  ConsiderationPlacesGetTopTenError,
} from '@appShared/store/dictionaries/consideration-places/consideration-places.actions';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { throttleTime, switchMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class ConsiderationPlacesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetTopTenConsiderationPlaces$: Observable<ConsiderationPlacesAction> = createEffect(() => this.actions$.pipe(
    ofType(ConsiderationPlacesActionTypes.GetTopTen),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getTopTenAgendaPackagePlaces().pipe(
        map((response) => new ConsiderationPlacesGetTopTenSuccess(response)),
        catchError((err) => of(new ConsiderationPlacesGetTopTenError()))
      )
    )
  ));
}
