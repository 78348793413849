import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GitInfoService } from '@appCore/services/git-info/git-info.service';

@Component({
  selector: 'app-update-version-info',
  templateUrl: './update-version-info.component.html',
  styleUrls: ['./update-version-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateVersionInfoComponent {
  constructor(public gitInfo: GitInfoService) {}

  public reload(): void {
    window.location.reload();
  }

  public cancel(): void {
    this.gitInfo.removeVersionUpdated();
  }
}
