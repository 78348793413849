import {
  ConsiderationTypesAction,
  ConsiderationTypesActionTypes,
} from '@appShared/store/dictionaries/consideration-types/consideration-types.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

const DEFAULT_STATE = new DictionaryStoreModel<DictionaryModel>([], null);

export function consideratonTypesReducer(
  state: DictionaryStoreModel<DictionaryModel> = DEFAULT_STATE,
  action: ConsiderationTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case ConsiderationTypesActionTypes.Reset:
      return DEFAULT_STATE;

    case ConsiderationTypesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case ConsiderationTypesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case ConsiderationTypesActionTypes.GetError:
      return {
        ...DEFAULT_STATE,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getSaveStatus = (consideratonTypes) => consideratonTypes.saveStatus;
/** Получение словаря */
export const getConsiderationTypes = (consideratonTypes) => consideratonTypes.dictionary;
