import { DocumentTypeModel } from '@models/document/document-type.model';
import { Action } from '@ngrx/store';

export enum DocumentTypesActionTypes {
  Get = '[Document Types] Get',
  GetSuccess = '[Document Types] GetSuccess',
  GetError = '[Document Types] GetError',
  Reset = '[Document Types] Reset',
}

export class DocumentTypesGet implements Action {
  readonly type = DocumentTypesActionTypes.Get;
}

export class DocumentTypesGetError implements Action {
  readonly type = DocumentTypesActionTypes.GetError;
}

export class DocumentTypesGetSuccess implements Action {
  readonly type = DocumentTypesActionTypes.GetSuccess;
  constructor(public payload: DocumentTypeModel[]) {}
}

export class DocumentTypesReset implements Action {
  readonly type = DocumentTypesActionTypes.Reset;
}

export type DocumentTypesAction =
  | DocumentTypesGet
  | DocumentTypesGetError
  | DocumentTypesGetSuccess
  | DocumentTypesReset;
