import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryModel } from '@models/base/dictionary.model';

const CONSIDERATIONS_SORT_MAP = ['Заседание ПМ', 'Заседание ППМ', 'Совещание'];

@Pipe({
  name: 'sortConsiderationTypes',
})
export class SortConsiderationTypesPipe implements PipeTransform {
  transform(value: DictionaryModel[]): DictionaryModel[] {
    return value
      .reduce((acc, consideration) => {
        const { name } = consideration;
        const sortIndex = CONSIDERATIONS_SORT_MAP.findIndex((c) => c === name);
        const considerationIndex = sortIndex < 0 ? Math.max(CONSIDERATIONS_SORT_MAP.length, acc.length) : sortIndex;
        acc[considerationIndex] = consideration;
        return acc;
      }, [] as DictionaryModel[])
      .filter(Boolean);
  }
}
