/** стилевые классы кнопок*/
export enum ButtonStyles {
  addButton = 'add-button',
  closeButtonOnlyIcon = 'close-button-only-icon',
  closeButtonRedIcon = 'close-button-red-icon',
  checkButtonOnlyIcon = 'check-button-only-icon',
  editButtonOnlyIcon = 'edit-button-only-icon',
  shareButtonOnlyIcon = 'share-button-only-icon',
  addButtonBig = 'add-button-big',
  commentButtonBig = 'comment-button-big',
  addPeopleBig = 'add-people-big',
  showFiltresInHeader = 'show-filtres-in-header',
  fullWidthButton = 'full-width-button',
  routeAddPeopleButton = 'route-add-people-button',
  xls = 'xls',
  word = 'word',
  pdf = 'pdf',
  flexButton = 'flex-bt',
  approvedStyle = 'approved_green_style',
}
