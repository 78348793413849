import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModalWindowBaseComponent } from '@appShared/modals-helper/modal-window-base/modal-window-base.component';

@NgModule({
  declarations: [ModalWindowBaseComponent],
  imports: [CommonModule, OverlayModule],
})
export class ModalsHelperModule {}
