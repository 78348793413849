import { Component, Input } from '@angular/core';
import { ButtonBaseComponent } from '../button-base/button-base.component';

@Component({
  selector: 'app-big-button',
  templateUrl: './big-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './big-button.component.scss'],
})
export class BigButtonComponent extends ButtonBaseComponent {
  /** Статус выполнения действия кнопки */
  @Input()
  isLoading: boolean;

  constructor() {
    super();
  }
}
