import { DictionaryEnumModel } from '@models/base/dictionary-enum.model';
import { Action } from '@ngrx/store';

export enum SignerCategoriesActionTypes {
  Get = '[Signer Categories] Get',
  GetSuccess = '[Signer Categories] GetSuccess',
  GetError = '[Signer Categories] GetError',
  Reset = '[Signer Categories] Reset',
}

/** Обновление типа проекта */
export class SignerCategoriesGet implements Action {
  readonly type = SignerCategoriesActionTypes.Get;
  constructor(public payload: number) {}
}

/** Обновление типа проекта */
export class SignerCategoriesGetError implements Action {
  readonly type = SignerCategoriesActionTypes.GetError;
}

/** Обновление типа проекта */
export class SignerCategoriesGetSuccess implements Action {
  readonly type = SignerCategoriesActionTypes.GetSuccess;
  constructor(public payload: DictionaryEnumModel[]) {}
}

/** Обновление типа проекта */
export class SignerCategoriesReset implements Action {
  readonly type = SignerCategoriesActionTypes.Reset;
}

export type SignerCategoriesAction =
  | SignerCategoriesGet
  | SignerCategoriesGetError
  | SignerCategoriesGetSuccess
  | SignerCategoriesReset;
