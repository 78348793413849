export const DOCUMENTS_TEXT_ERRORS = {
  versionsNotExist:
    'Отсутствуют доступные версии документов раздела «Проект, приложения и пояснительная записка». Обратитесь к сотруднику ОИВ, ответственному за передачу прав доступа к документам в системе.',
  accessErrorMsg: 'Не удалось получить информацию необходимую для открытия редактора',
  documentTypeAlreadyExist: 'Документ данного типа уже существует в Пакете Документа',
  notUniqueEmployeeAttention: 'Вы повторно добавляете пользователя на данный этап',
  nextDateBeforeCurrent:
    'Указанная дата превышает дату, указанную для следующего согласующего на маршруте согласования',
  noDocumentsSelected: 'Не выбраны документы',
  actingVersion: 'Снятие признака эталонной версии невозможно так как у документа есть действующая редакция!',
  allVersionsEtalon: 'Переданы документы с не эталлонной версией!',
  canNotAddNewEmployee: 'Нельзя добавить нового сотрудника/организацию в заблокированную форму!',
  accessRestrictionForm: 'Форма ограничения доступа к документам не валидна',
  mustBeSelectedSameDocument: 'Для данной операции должно быть выбрано две версии одного документа',
  selectedVersionsIdentical: 'Выбранные версии идентичны',
  toViewAlternatives1: 'Для просмотра альтернатив необходимо выбрать не менее двух версий одного документа!',
  toViewAlternatives2: 'Для просмотра альтернатив необходимо выбрать версии одного документа!',
  toViewAlternativesNotAvailable: 'Просмотр альтернатив для данной версии недоступен',
  hasOrganization: 'Адресат уже есть в списке рассылки',
  fileExtension: 'Импорт в редактор возможен только из файлов',
  notAllowedPhase: 'Выбраны этапы, которые не могут быть добавлены при типе добавления "паралельно" -',
  userTemplateDuplicate: 'Выбраны этапы, которые должны быть на этапе в единственном виде',
  incorrectFinalPhasesQuantity: 'На маршрут можно добавить только один финальный этап',
  noMeetingDay: 'На выбранную дату нет заседания ПМ/ППМ.',
  isTooLittleDate:
    'Срок разработки Пакета Документа меньше регламентного. Для переноса даты рассмотрения необходимо оформить запрос\n      на перенос планируемой даты рассмотрения.',
  invalidDateError: 'Контрольный срок не должен быть меньше Планируемой даты рассмотрения',
  dateValidationErrorTextMeeting: 'На выбранную дату нет заседания',
  approvalTypeMustBeSpecified: 'Необходимо указать тип утверждения',
  projectDevelopmentEarlier: 'Указан срок разработки проекта раньше регламентного',
  importOnlyFiles: 'Импорт возможен только из файлов',
  needAddManager: 'Для согласования нужно добавить руководителя.',
  notAvailableNumbers: 'На выбранную дату отсутствуют свободные номера',
  sendRegistrationText: 'Информация о направлении на регистрацию была обновлена. Выполнение действия невозможно.',
  referenceVersionAlreadyCreated: 'Для выбранного документа уже создана эталонная версия',
  cannotCreateReferenceVersion: 'Нельзя создать эталонную версию из копии действующей редакции или ее версии',
  needSelectOneVersion: 'Необходимо выбрать одну версию для одного документа',
  cantCreateEditorialVersion: 'Нельзя создать редакторскую версию из редакторской версии',
  cantCreateEditorialVersionFromCopies:
    'Нельзя создать редакторскую версию из копии действующей редакции или ее версии',
  cantAddSameDocumentTwice: 'Нельзя добавить один документ два раза',
  editorialVersionExist:
    'Для данного документа существует Редакторская версия. Для создания новой Редакторской версии необходимо удалить существующую Редакторскую версию',
  cantCreateEditorialVersionWithoutText:
    'Невозможно создать редакторскую версию из версии документа, не содержащей текста',
  errorWhileCreating: 'Возникла ошибка при создании редакторской версии',
  forDraftMustSelectVersionMain: 'Для создания черновика необходимо выбрать версию документа основного раздела',
  forDraftMustSelectVersion: 'Для создания черновика необходимо выбрать одну версию документа',
  draftReplaced: 'Черновики документов будут заменены',
  errorWhilePreparing: 'Возникла ошибка при подготовке документов к печати',
  operationNotSupported: 'Данная операция не поддерживается для электронных таблиц',
  informationUpdatedActionCantExec: 'Информация о статусе регистрации была обновлена. Выполнение действия невозможно',
  informationUpdated: 'Информация о статусе регистрации была обновлена.',
  registrationNumberUsed: 'Выбранный номер уже использован. Выполнение действия невозможно',
  toMoveApprovalRoute: 'Для движения по маршруту согласования необходимо добавить согласования во внешних ОИВах.',
  forApprovalHaveDocument: 'Для отправки на согласование необходимо добавить основной документ в состав пакета.',
  mainDocCantEmpty: 'Основной документ в составе пакета не может быть пустым',
  forApprovalHaveDeadlines: 'Для отправки на согласование настройте сроки для пользователей.',
  documentBeingDownloaded: 'Невозможно отправить Пакет Документа на согласование, идет загрузка документа.',
  setUpDeadlines: 'Для отправки поручения настройте сроки для пользователей.',
  forCalculateNeedAdd: 'Для расчета необходимо добавить руководителя на этап «Проект создан»',
  forExtension: 'Для отправки запроса на продление срока добавьте контролера',
  needDeadlinesManually:
    'Необходимо проставить сроки вручную. Автоматический расчет сроков возможен только для типов проектов, подлежащих рассмотрению на Заседании Правительства Москвы или Заседании Президиума Правительства Москвы.',
  cannotSendToElectronicArchive:
    'В данный момент отправка запроса в Электронный архив недоступна. Повторите попытку позднее или обратитесь в службу технической поддержки',
  forRedirectionHaveDocument: 'Для перенаправления необходимо добавить основной документ в состав пакета.',
  etalonCreated: 'Документ уже имеет эталонную версию.',
};

export const DASHBOARD_TEXT_ERRORS = {
  notFoundIndexesErrorMsg: 'Отсутствуют доступные индексы, обратитесь в службу технической поддержки',
  indexByDateExistErrorMsg: 'Добавить номера на указанную дату невозможно. Существуют номера на более поздние даты',
  formNotValid: 'Форма не валидна',
  noDateSpecified: 'Не указана дата',
  selectReviewDate: 'Выберите дату рассмотрения',
  noMeetingSelectedDay: 'В выбранный день нет заседания.',
  justificationForRefusal: 'Укажите обоснование для отказа.',
  notExistPDs: 'Отсутствуют добавляемые ПД.',
};

export const ANALYTICS_TEXT_ERRORS = {
  anotherOpenedDate:
    'Добавить номера на указанную дату невозможно. В Журнале регистрации документов открыта другая дата',
  laterDates:
    'Добавить номера на указанную дату невозможно. В Журнале регистрации документов есть номера на более поздние даты',
  closedDate: 'Добавить номера на указанную дату невозможно. Дата закрыта',
};

export const CALENDAR_CONSIDERATION_TEXT_ERRORS = {
  incorrectEndDate: 'Дата окончания должна быть больше даты начала',
  maximumSymbolsReached: 'Дата окончания должна быть больше даты начала',
  noConsiderationOnDate: 'На выбранную дату нет запланированных заседаний. Выберите другую дату заседания.',
  endTimeMustBiggerStartTime: 'Время начала не может превышать время окончания',
  unknownDocumentType: 'Неизвестный тип документа',
};

export const PERSONAL_CABINET_TEXT_ERRORS = {
  reAddingUser: 'Повторное добавление пользователя с одинаковым типом поручения',
};

export const GENERAL_TEXT_ERRORS = {
  noResultsFromRequest: 'По вашему запросу ничего не найдено',
  emptyField: 'Поле не должно быть пустым.',
  emptyNote: 'Примечание не может быть пустым',
  userWithDataNotFound: 'Пользователь с указанными данными не найден',
  enterMoreThanThreeSymbols: 'Введите больше 3х символов',
  dateGreaterThanCurrent: 'Дата должна быть больше текущей',
  endDateMustBiggerStartDate: 'Дата окончания не может быть меньше даты начала',
  delegationAlreadyAdded: 'Делегирование для данного пользователя уже добавлено',
  fileSizeMore200mb: 'Размер загружаемого файла более 200 Мб. Выберите другой файл',
  problemsInternetConnection: 'Не удалось сохранить внесенные изменения из-за проблем с интернет-соединением',
  unexpectedError: 'Произошла непредвиденная ошибка',
  failedToSign: 'Не удалось подписать документ',
};
