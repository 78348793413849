// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-container {
  display: flex;
  height: 25px;
  padding: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
}
.arrow-container .arrow {
  display: flex;
  width: 7px;
  height: 7px;
  border-left: 1px solid #5d686f;
  border-bottom: 1px solid #5d686f;
  transform: rotate(135deg);
  margin-top: 11px;
}
.arrow-container .arrow.rotate {
  margin-top: 7px;
  transform: rotate(-45deg);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbGxhcHNlLWFycm93LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLFlBQUE7RUFDQSxlQUFBO0VBQ0EsZUFBQTtFQUNBLHNCQUFBO0FBQ0Y7QUFDRTtFQUNFLGFBQUE7RUFDQSxVQUFBO0VBQ0EsV0FBQTtFQUNBLDhCQUFBO0VBQ0EsZ0NBQUE7RUFDQSx5QkFBQTtFQUNBLGdCQUFBO0FBQ0o7QUFDSTtFQUNFLGVBQUE7RUFDQSx5QkFBQTtBQUNOIiwiZmlsZSI6ImNvbGxhcHNlLWFycm93LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFycm93LWNvbnRhaW5lciB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBoZWlnaHQ6IDI1cHg7XHJcbiAgcGFkZGluZzogMCAxMHB4O1xyXG4gIGN1cnNvcjogcG9pbnRlcjtcclxuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xyXG5cclxuICAuYXJyb3cge1xyXG4gICAgZGlzcGxheTogZmxleDtcclxuICAgIHdpZHRoOiA3cHg7XHJcbiAgICBoZWlnaHQ6IDdweDtcclxuICAgIGJvcmRlci1sZWZ0OiAxcHggc29saWQgIzVkNjg2ZjtcclxuICAgIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCAjNWQ2ODZmO1xyXG4gICAgdHJhbnNmb3JtOiByb3RhdGUoMTM1ZGVnKTtcclxuICAgIG1hcmdpbi10b3A6IDExcHg7XHJcblxyXG4gICAgJi5yb3RhdGUge1xyXG4gICAgICBtYXJnaW4tdG9wOiA3cHg7XHJcbiAgICAgIHRyYW5zZm9ybTogcm90YXRlKC00NWRlZyk7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/collapse-arrow/collapse-arrow.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,UAAA;EACA,WAAA;EACA,8BAAA;EACA,gCAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AACI;EACE,eAAA;EACA,yBAAA;AACN;AACA,gkCAAgkC","sourcesContent":[".arrow-container {\r\n  display: flex;\r\n  height: 25px;\r\n  padding: 0 10px;\r\n  cursor: pointer;\r\n  box-sizing: border-box;\r\n\r\n  .arrow {\r\n    display: flex;\r\n    width: 7px;\r\n    height: 7px;\r\n    border-left: 1px solid #5d686f;\r\n    border-bottom: 1px solid #5d686f;\r\n    transform: rotate(135deg);\r\n    margin-top: 11px;\r\n\r\n    &.rotate {\r\n      margin-top: 7px;\r\n      transform: rotate(-45deg);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
