import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputCheckboxComponent } from './components/controls/input-checkbox/input-checkbox.component';
import { SearchInputComponent } from './components/controls/search-input/search-input.component';
import { GroupItemSelectComponent } from './components/controls/select/group-item-select/group-item-select.component';
import { TextUnderscoresDirective } from './directives/text-underscores.directive';
import { NameExtractionPipe } from './pipes/name-extraction.pipe';

const directives = [TextUnderscoresDirective];
const components = [SearchInputComponent, InputCheckboxComponent, GroupItemSelectComponent];
const pipes = [NameExtractionPipe];

@NgModule({
  declarations: [...directives, ...components, ...pipes],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, InfiniteScrollModule],
  exports: [...directives, ...components, ...pipes],
})
export class ComponentLibModule {}
