import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[scrollToTreePhase]',
})
export class ScrollToTreePhase implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.elementRef?.nativeElement.classList.contains('scrollToPoint')) {
        this.elementRef.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    });
  }
}
