export enum OibRoles {
  npa = 'admin',
  /** Администратор */
  npaAdmin = 'NPA_ADMIN',
  /** Пользователь */
  npaUser = 'NPA_USER',
  /** Руководитель ОИВ */
  organisationHead = 'NPA_ORGANIZATION_HEAD',
  /** Сотрудник ОАУ */
  oauEmployee = 'NPA_OAU_EMPLOYEE',
  /** Сотрудник ПУ */
  puEmployee = 'NPA_PU_EMPLOYEE',
  /** Сотрудник ВА */
  npaReturnEmployee = 'NPA_RETURN_EMPLOYEE',
  /** Сотрудник ОАУ.ПЗ */
  pzOAUEmployee = 'NPA_OAU_PZ_EMPLOYEE',
  /** Сотрудник ОАУ.ОП */
  opOAUEmployee = 'NPA_OAU_OP_EMPLOYEE',
  /** Сотрудник ОАУ.ОР */
  orOAUEmployee = 'NPA_OAU_OR_EMPLOYEE',
  /** Сотрудник ОАУ.ОИР */
  oirOAUEmployee = 'NPA_OAU_OIR_EMPLOYEE',
  /** Сотрудник ОАУ.ПСАЗ */
  psazOAUEmployee = 'NPA_OAU_PSAZ_EMPLOYEE',
  /** Сотрудник ОАУ.ПДР */
  pdrOAUEmployee = 'NPA_OAU_CONSIDERATION_DATE_EMPLOYEE',
  /** Архивариус */
  archivist = 'NPA_ARCHIVIST',
  /** Внешний пользователь */
  externalUser = 'NPA_EXTERNAL_USER',
  /** Локальный пользователь */
  internalUser = 'NPA_INTERNAL_USER',
  /** Статс-секретарь */
  stateSecretary = 'NPA_STATE_SECRETARY',
  /** Ограничение предварительного согласования */
  prepareReviewLimit = 'NPA_PREPARE_REVIEW_LIMIT',
  /** Согласование по умолчанию при нарушении срока согласования */
  agreementDefault = 'NPA_AGREEMENT_DEFAULT',
  /** Пользователь Правовые акты Мэра Москвы и ПМ */
  governmentUser = 'NPA_GOVERNMENT_USER',
  /** Просмотр Состава Пакета после создания ДР */
  transitionalVersionViewer = 'NPA_TRANSITIONAL_VERSION_VIEWER',
  /** Пользователь ЛПА. Оформление и опубликование  */
  designAndPublicationInternal = 'NPA_INTERNAL_VDO_EMPLOYEE',
  /** Пользователь Прокуратура */
  prosecutorUser = 'NPA_PROCURACY',
  /** Пользователь Редактирование Заседаний ПМ/ППМ */
  npaAgendaEdit = 'NPA_AGENDA_EDIT',
  /** Просмотр Экспертной повестки */
  npaExpertViewer = 'NPA_EXPERT_VIEWER',
  /** Просмотр Итоговой повестки */
  npaResultViewer = 'NPA_RESULT_VIEWER',
  /** Доступ к аналитике и отчетности */
  npaReportViewer = 'NPA_REPORT_VIEWER',
  /** Просмотр Плановых вопросов  */
  npaPlannedViewer = 'NPA_PLANNED_VIEWER',
  /** Просмотр Предварительной повестки */
  npaPreliminaryViewer = 'NPA_PRELIMINARY_VIEWER',
  /** Просмотр Открытой повестки */
  npaOpenViewer = 'NPA_OPEN_VIEWER',
  /** Право передачи доступа */
  npaTransferAccess ='NPA_TRANSFER_ACCESS',
/** Право передачи доступа */
  npaResolutionOnDashboard ='NPA_RESOLUTION_ON_DASHBOARD',
  /** Пользователь ЭА */
  eaUser ='EA_USER'
}
