import { Component, ElementRef, Input } from '@angular/core';
import { SelectGroupItemModel } from '@models/select-group-item.model';
import { InputKeyCode } from '../../../../enums/input-key-code.enum';
import { SelectBaseComponent } from '../select-base-component/select.base';

@Component({
  selector: 'app-group-item-select',
  templateUrl: './group-item-select.component.html',
  styleUrls: [
    '../../control-base.directive.scss',
    './group-item-select.component.scss',
    '../select-base-component/select.base.scss',
  ],
})
export class GroupItemSelectComponent extends SelectBaseComponent {
  @Input()
  groupsList: SelectGroupItemModel[] = [];

  public activeGroupIndex: number | null = null;
  public activeOptionIndex: number | null = null;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  /**
   * Метод для отображения и скрытия списка опций
   */
  public toggleOptionsList(): void {
    if (!this.isShowOptions) {
      this.showOptionsList();
    } else {
      this.hideOptionsList();
    }

    this.resetSelectedOptions();
  }

  /**
   * Проверка на активность опции
   */
  public isOptionOnHover(groupIndex: number, optionIndex: number): boolean {
    return this.activeGroupIndex === groupIndex && this.activeOptionIndex === optionIndex;
  }

  /**
   * callback нажатия кнопки клавиатуры
   */
  public onKeyDown(event: KeyboardEvent): void {
    const keyCode = event.keyCode ? event.keyCode : event.which;

    switch (keyCode) {
      case InputKeyCode.tab:
        this.isShowOptions = false;
        break;

      case InputKeyCode.arrowDown:
        event.preventDefault();
        this.onArrowDownPress();
        break;

      case InputKeyCode.arrowUp:
        event.preventDefault();
        this.onArrowUpPress();
        break;

      case InputKeyCode.enter:
        event.preventDefault();
        this.onKeyEnterPress();
        break;

      default:
        event.preventDefault();
        break;
    }
  }

  /**
   * событие нажатия кнопки вниз
   */
  private onArrowDownPress(): void {
    if (typeof this.activeGroupIndex !== 'number') {
      this.activeGroupIndex = 0;
      this.activeOptionIndex = 0;
      return;
    }

    if (
      typeof this.activeOptionIndex === 'number' &&
      this.activeOptionIndex + 1 < this.groupsList[this.activeGroupIndex].optionsList.length
    ) {
      this.activeOptionIndex++;
      return;
    }

    if (this.activeGroupIndex + 1 < this.groupsList.length) {
      this.activeGroupIndex++;
      this.activeOptionIndex = 0;
    }
  }

  /**
   * событие нажатия кнопки вверх
   */
  private onArrowUpPress(): void {
    if (typeof this.activeOptionIndex !== 'number' || typeof this.activeGroupIndex !== 'number') {
      return;
    }

    if (this.activeOptionIndex > 0) {
      this.activeOptionIndex--;
      return;
    }

    if (this.activeGroupIndex > 0) {
      this.activeGroupIndex--;
      this.activeOptionIndex = this.groupsList[this.activeGroupIndex].optionsList.length - 1;
    }
  }

  /**
   * событие нажатия кнопки Enter
   */
  private onKeyEnterPress(): void {
    if (typeof this.activeOptionIndex !== 'number' || typeof this.activeGroupIndex !== 'number') {
      return;
    }

    if (this.activeOptionIndex < 0) {
      return;
    }

    this.selectOption(this.groupsList[this.activeGroupIndex].optionsList[this.activeOptionIndex]);
  }

  /**
   * сбросить выделение всех элементов списка
   */
  private resetSelectedOptions(): void {
    this.activeGroupIndex = null;
    this.activeOptionIndex = null;
  }
}
