import { AnalyticsPanelPermissions } from '@appPermissions/enums/analytics-panel-permissions';
import { CalendarPermissions } from '@appPermissions/enums/calendar-permissions.enum';
import { DocumentPackagePermissions } from '@appPermissions/enums/document-package-permissions.enum';
import { ElectronicArchivePermissions } from '@appPermissions/enums/electronic-archive.permissions';
import { PermissionType } from '@appPermissions/enums/permission-type.enum';
import { MenuItemsNames } from '@appShared/components/menu/enums/menu-items-names.enum';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { AnimationsTypes } from '@appShared/enums/animations-types.enum';
import { ComponentsInteractionTopics } from '@appShared/enums/components-interaction-topics.enum';
import { ComponentsInteractionService } from '@appShared/services/components-interaction.service';

export const MENU_ITEMS: MenuItemModel[] = [
  {
    name: MenuItemsNames.createPackage,
    iconClass: 'icon-add',
    alternativeIconClass: null,
    title: 'создать',
    id: 'create_list',
    routerLink: null,
    animation: AnimationsTypes.fadeIn,
    tooltip: null,
    items: [
      {
        name: MenuItemsNames.createPackageOfDocument,
        iconClass: 'create.png',
        alternativeIconClass: null,
        title: 'Пакет документа',
        id: `creating-pack-package-of-document`,
        routerLink: '/documents/create/document-package/1',
        animation: null,
        tooltip: null,
        items: null,
      },
    ],
    permissions: {
      permissions: DocumentPackagePermissions.documentPackageCreate,
      type: PermissionType.documentPackage,
    },
    mouseover: () =>
      ComponentsInteractionService.topic$.next(ComponentsInteractionTopics.WORK_STATUS_MENU_SHOULD_CLOSE),
  },
  {
    name: MenuItemsNames.dashboard,
    iconClass: 'icon-dashboard',
    alternativeIconClass: null,
    title: 'пакеты',
    id: 'dashboard',
    routerLink: '/main/dashboard',
    animation: null,
    tooltip: 'Пакеты документов',
    items: null,
    withRouterLink: true,
  },
  {
    name: MenuItemsNames.calendar,
    iconClass: 'icon-calendar',
    alternativeIconClass: null,
    title: 'календарь',
    id: 'calendar_button',
    routerLink: '/main/calendar',
    animation: null,
    tooltip: 'Календарь событий',
    items: null,
    permissions: {
      permissions: CalendarPermissions.CALENDAR_VIEW,
      type: PermissionType.calendar,
    },
  },
  {
    name: MenuItemsNames.analytics,
    iconClass: 'icon-analytics',
    alternativeIconClass: null,
    title: 'аналитика',
    id: null,
    routerLink: '/main/analytics',
    animation: null,
    tooltip: 'Аналитика и отчетность',
    items: null,
    permissions: {
      permissions: AnalyticsPanelPermissions.SUMMARY_ANALYTICS_PANEL_VIEW,
      type: PermissionType.summaryAnalytics,
    },
  },
  {
    name: MenuItemsNames.notifications,
    iconClass: 'icon-notifi',
    alternativeIconClass: 'icon-notifi-alternative',
    title: 'уведомления',
    id: null,
    routerLink: '/main/notifications',
    animation: null,
    tooltip: 'Уведомления',
    items: null,
  },
  {
    name: MenuItemsNames.electronicArchive,
    iconClass: 'icon-electronic-archive',
    alternativeIconClass: null,
    title: 'электронный архив',
    id: null,
    routerLink: null,
    isExternal: true,
    animation: null,
    tooltip: 'Электронный архив',
    items: null,
    permissions: {
      permissions: ElectronicArchivePermissions.ELECTRONIC_ARCHIVE_VIEW,
      type: PermissionType.electronicArchive,
    },
  },
  {
    name: MenuItemsNames.usefulResources,
    iconClass: 'icon-useful-sources',
    alternativeIconClass: 'icon-notifi-alternative',
    title: 'Полезные ресурсы',
    id: null,
    routerLink: '/main/useful-resources',
    animation: null,
    tooltip: 'Полезные ресурсы',
    items: null,
  },
];
