import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  DocumentCompositionItemLogicService
} from '@appDocuments/documents-composition/services/document-composition-item-logic.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements OnChanges {
  @Input() headerLabel: string;
  @Input() buttonStyle: string;
  @Input() buttonLabel: string;
  @Input() isButtonAvailable = true;
  @Input() canCheckAddDocumentButton = false;
  @Input() isOpened = true;
  @Output() headerButtonClick = new EventEmitter();
  @Output() isOpenedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isShowButtonAddMainDocument$: Observable<boolean>;

  constructor(private documentCompositionItemLogicService: DocumentCompositionItemLogicService) {
  }

  public headerAction(): void {
    this.headerButtonClick.emit();
  }

  public onTitleAction(): void {
    this.isOpened = !this.isOpened;
    this.isOpenedChange.emit(this.isOpened);
  }

  ngOnChanges(): void {
    if (this.canCheckAddDocumentButton) {
      this.isShowButtonAddMainDocument$ = this.documentCompositionItemLogicService.isShowButtonAddMainDocument();
    }
  }
}
