import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlbErrorPopupComponent } from '@appCore/components/glb-error-popup/glb-error-popup.component';
import { ToastsDocumentComponent } from '@appCore/components/toasts-document/toasts-document.component';
import { ToastsComponent } from '@appCore/components/toasts/toasts.component';
import { UpdateVersionInfoComponent } from '@appCore/components/update-version-info/update-version-info.component';
import { ToastPlaceholderPipe } from '@appCore/pipes/toast-placeholder.pipe';
import { SharedModule } from '@appShared/shared.module';
import { glbHttpErrorInterceptorProvider } from './glb/glb-api/glb-http-error.interceptor';
import { throwIfAlreadyLoaded } from './glb/glb-module-import-guard';

const exportComponents = [ToastsComponent, ToastsDocumentComponent, GlbErrorPopupComponent, UpdateVersionInfoComponent];
const exportPipes = [ToastPlaceholderPipe];

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [...exportComponents, ...exportPipes],
  declarations: [...exportComponents, ...exportPipes],
  providers: [glbHttpErrorInterceptorProvider],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
