import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup-tap',
  templateUrl: './popup-tap.component.html',
  styleUrls: ['./popup-tap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupTapComponent {
  @Input() isInvalid: boolean;
  @Input() isActive: boolean;
  @Input() text: string;
  @Input() isNeedDeleteBtn: boolean;

  @Output() onTapClick: EventEmitter<void> = new EventEmitter();
  @Output() onTapDelete: EventEmitter<void> = new EventEmitter();
}
