import { Injectable } from '@angular/core';
import { CACHE_TIME } from '@appCore/store/cache-time.const';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  DocumentTemplatesActionTypes,
  DocumentTemplatesGetError,
  DocumentTemplatesGetSuccess,
  DocumentTemplatesTypesAction,
} from './document-templates.actions';

@Injectable()
export class DocumentTemplatesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetDocumentTemplates$: Observable<DocumentTemplatesTypesAction> = createEffect(() => this.actions$.pipe(
    ofType(DocumentTemplatesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getTemplateDocumentTypes().pipe(
        map((response) => new DocumentTemplatesGetSuccess(response)),
        catchError((err) => of(new DocumentTemplatesGetError()))
      )
    )
  ));
}
