import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';
import { isDeepEqualObjectsByKeys } from '@sharedHelpers/compare-objects.const';
import { ActivatedRoute, Params } from '@angular/router';
import { first, map, share, skip, tap } from 'rxjs/operators';
import { SetDashboardRouteParams } from '@appMain/dashboard/store/dashboard-page/dashboard-page.actions';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { PHASES_FOR_DECISION_FILTER } from '@appMain/dashboard/const/phases-for-decision-filter.const';
import { MenuContextualPopupService } from '@appShared/services/menu-contextual-popup.service';
import { DecisionFilterTypeEnum } from '@appMain/dashboard/enums/decision-filter-type.enum';
import moment from 'moment/moment';
import { DashboardStatisticsNames } from '@appMain/dashboard/enums/dashboard-statistics-names.enum';
import { ComponentType } from '@angular/cdk/overlay';
import { getQueryParams } from '@appCore/store/router';
import { FILTER_DECISION_KEY } from '@appShared/const/filter-decision-key.const';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DecisionFiltersHelperService {
  private cacheOivs$ = new BehaviorSubject(null);

  private initialParams: Params = null;
  constructor(
    private dictionaryApiService: DictionaryApiService,
    private route: ActivatedRoute,
    private redux: ReduxService,
    private menuContextualPopupService: MenuContextualPopupService
  ) {}

  public getOrganizations(): Observable<DictionaryWithShortNameModel[]> {
    if (!isDeepEqualObjectsByKeys(this.initialParams, this.route.snapshot.queryParams)) {
      this.initialParams = this.route.snapshot.queryParams;

      return this.dictionaryApiService.getOrganizationsByDecisionFilter(this.initialParams).pipe(
        share(),
        tap((res) => this.cacheOivs$.next(res))
      );
    }

    return this.cacheOivs$;
  }

  public getStages(): Observable<DictionaryWithShortNameModel[]> {
    return of(PHASES_FOR_DECISION_FILTER);
  }

  public searchInitByIds(res: DictionaryWithShortNameModel[], filterType: DecisionFilterTypeEnum): void {
    const resMap = res.map((item) => item.id).toString();
    const queryParams =
      filterType === DecisionFilterTypeEnum.oivs
        ? { ...this.route.snapshot.queryParams, oivs: resMap }
        : { ...this.route.snapshot.queryParams, stage: resMap };

    if (!resMap) {
      filterType === DecisionFilterTypeEnum.oivs ? delete queryParams['oivs'] : delete queryParams['stage'];
    }

    this.redux.dispatchAction(new SetDashboardRouteParams(queryParams));
    this.setToLocalStorage();
  }

  public searchInitByDate(dateStart: string, dateEnd: string): void {
    const dateFrom = dateStart ? moment(dateStart).format('DD-MM-YYYY') : null;
    const dateTo = dateEnd ? moment(dateEnd).format('DD-MM-YYYY') : null;

    const queryParams = {
      ...this.route.snapshot.queryParams,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    this.redux.dispatchAction(new SetDashboardRouteParams(queryParams));
    this.setToLocalStorage();
  }

  public resetFilterByType(filterType: DecisionFilterTypeEnum): void {
    const queryParams = { ...this.route.snapshot.queryParams };
    switch (filterType) {
      case DecisionFilterTypeEnum.oivs:
        delete queryParams['oivs'];
        break;
      case DecisionFilterTypeEnum.deadline:
        delete queryParams['dateFrom'];
        delete queryParams['dateTo'];
        break;
      case DecisionFilterTypeEnum.stage:
        delete queryParams['stage'];
        break;
      default:
        break;
    }
    this.redux.dispatchAction(new SetDashboardRouteParams(queryParams));
    this.setToLocalStorage();
  }

  public async showFilterPopover<T, D>(originRef: HTMLElement, component: ComponentType<T>, data?: D): Promise<void> {
    const hasActive = originRef.classList.contains('active');

    if (hasActive) {
      return;
    }
    originRef.classList.add('active');
    await firstValueFrom(this.menuContextualPopupService.open(originRef, component, data));

    originRef.classList.remove('active');
  }

  public resetFilter(): void {
    const allowedProps = ['oivs', 'stage', 'dateFrom', 'dateTo'];

    const queryParams = Object.entries(this.route.snapshot.queryParams)
      .filter(([key]) => !allowedProps.includes(key))
      .reduce((acc, [k, v]) => ((acc[k] = v), acc), {});

    this.redux.dispatchAction(new SetDashboardRouteParams(queryParams));
    localStorage.removeItem(FILTER_DECISION_KEY);
  }

  public hasParam(filterType: DecisionFilterTypeEnum): Observable<boolean> {
    return this.route.queryParams.pipe(
      map((res) => {
        if (filterType === DecisionFilterTypeEnum.oivs) {
          return Boolean(res['oivs']);
        }
        if (filterType === DecisionFilterTypeEnum.deadline) {
          return Boolean(res['dateFrom'] || res['dateTo']);
        }
        if (filterType === DecisionFilterTypeEnum.stage) {
          return Boolean(res['stage']);
        }
      })
    );
  }

  public parseDate(dateParam: string): Date {
    const parseDate = dateParam.split('-');
    return new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`);
  }

  public getCanShowAllResetBtn(): Observable<boolean> {
    const params$ = this.route.queryParams;
    const hasFilter$ = this.hasFilter();
    return combineLatest([params$, hasFilter$]).pipe(
      map(([params, hasFilter]) => params.widget === DashboardStatisticsNames.makeDecision && hasFilter)
    );
  }

  public modifiedQueryParams(queryParams: Params): Params {
    const allowedProps = ['oivs', 'stage', 'dateFrom', 'dateTo'];
    return Object.entries(queryParams)
      .filter(([key]) => allowedProps.includes(key))
      .reduce((acc, [k, v]) => ((acc[k] = v), acc), {});
  }

  private hasFilter(): Observable<boolean> {
    const allowedProps = ['oivs', 'stage', 'dateFrom', 'dateTo'];
    return this.route.queryParams.pipe(
      map((res) => {
        return Object.keys(res).some((param) => allowedProps.includes(param));
      })
    );
  }

  private setToLocalStorage(): void {
    this.redux
      .selectStore(getQueryParams)
      .pipe(skip(1), first())
      .subscribe((queryParams) => {
        const resQueryParams = this.modifiedQueryParams(queryParams);

        if (isEmpty(resQueryParams)) {
          localStorage.removeItem(FILTER_DECISION_KEY);
          return;
        }

        localStorage.setItem(FILTER_DECISION_KEY, JSON.stringify(resQueryParams));
      });
  }
}
