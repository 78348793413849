import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import {
  getNotificationPaginationData,
  getNotificationsLoadData,
  getNotificationsSortingParams,
} from '@appCore/store/notifications';
import { NotificationsApiService } from '@appShared/api/notifications/notifications.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  NotificationsActions,
  NotificationsActionsLoad,
  NotificationsActionsLoadError,
  NotificationsActionsLoadLazy,
  NotificationsActionsLoadLazyError,
  NotificationsActionsLoadLazySuccess,
  NotificationsActionsLoadSuccess,
  NotificationsActionsMarkAsViewed,
  NotificationsActionsToggleArchiveState,
  NotificationsActionsToggleArchiveStateError,
  NotificationsActionsToggleArchiveStateSuccess,
  NotificationsActionsViewed,
  NotificationsActionsViewedError,
  NotificationsActionTypes,
  NotificationsGetCount,
  NotificationsGetCounterData,
  NotificationsGetCounterDataError,
  NotificationsGetCounterDataSuccess,
  NotificationsGetCountError,
  NotificationsGetCountSuccess,
} from './notifications.actions';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationsApiService,
    private redux: ReduxService
  ) {}

  getCount$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.GetCount, NotificationsActionTypes.ToggleArchiveStateSuccess),
      switchMap((action: NotificationsGetCount) =>
        this.notificationService.getCount().pipe(
          map((response) => new NotificationsGetCountSuccess(response)),
          catchError((err) => of(new NotificationsGetCountError(err)))
        )
      )
    )
  );

  getNotifications$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.Load, NotificationsActionTypes.LoadLazy),
      switchMap((action: NotificationsActionsLoad | NotificationsActionsLoadLazy) =>
        combineLatest([
          this.redux.selectStore(getNotificationsLoadData),
          this.redux.selectStore(getNotificationsSortingParams),
          this.redux.selectStore(getNotificationPaginationData),
          of(action),
        ]).pipe(first())
      ),
      switchMap(([loadData, sortingParams, paginationData, action]) => {
        const isLazy = action.type === NotificationsActionTypes.LoadLazy;
        return this.notificationService
          .getNotifications(loadData, sortingParams.fieldName, sortingParams.order, isLazy && paginationData)
          .pipe(
            map((response) =>
              isLazy
                ? new NotificationsActionsLoadLazySuccess(response, paginationData.page)
                : new NotificationsActionsLoadSuccess(response)
            ),
            catchError((err) =>
              of(isLazy ? new NotificationsActionsLoadLazyError(err) : new NotificationsActionsLoadError(err))
            )
          );
      })
    )
  );

  getCountersData$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.GetCounterData, NotificationsActionTypes.ToggleArchiveStateSuccess),
      switchMap((action: NotificationsGetCounterData) =>
        this.notificationService.getNotificationCounterData().pipe(
          map((response) => new NotificationsGetCounterDataSuccess(response)),
          catchError((err) => of(new NotificationsGetCounterDataError(err)))
        )
      )
    )
  );

  markAsViewed$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.Viewed),
      switchMap((action: NotificationsActionsViewed) =>
        this.notificationService.markNotificationViewed(action.payload.id, action.payload.viewed).pipe(
          mergeMap(() => {
            const result: NotificationsActions[] = [
              new NotificationsActionsMarkAsViewed({
                id: action.payload.id,
                viewed: action.payload.viewed,
              }),
              new NotificationsGetCount(),
              new NotificationsGetCounterData(),
            ];

            if (action.payload.needToDeleteNotification) {
              result.push(new NotificationsActionsLoad());
            }

            return result;
          }),
          catchError((err) => of(new NotificationsActionsViewedError(err)))
        )
      )
    )
  );

  sendToArchive$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.ToggleArchiveState),
      switchMap((action: NotificationsActionsToggleArchiveState) =>
        this.notificationService.toggleNotificationsArchiveState(action.payload).pipe(
          map(() => new NotificationsActionsToggleArchiveStateSuccess()),
          catchError((err) => of(new NotificationsActionsToggleArchiveStateError(err)))
        )
      )
    )
  );

  sendToArchiveSuccess$: Observable<NotificationsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActionTypes.ToggleArchiveStateSuccess),
      map(() => new NotificationsActionsLoad())
    )
  );
}
