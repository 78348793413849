import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss'],
})
export class AttachFileComponent {
  /**Значение лейбла */
  @Input()
  label: string;
  /**Текст кнопки */
  @Input()
  btnValue: string;
  /** Типы файлов */
  @Input()
  accept: string;
  /** Использовать или нет темную тему */
  @Input()
  isBlackTheme: boolean;
  /** Блокировка кнопки */
  @Input()
  disabled: boolean;
  /** Событие изменения файла */
  @Output()
  fileChange: EventEmitter<File> = new EventEmitter();

  /** Добавление файлов */
  onFileChange(e) {
    e.preventDefault();
    if (!e.target || !e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];
    e.srcElement.value = null;
    this.fileChange.emit(file);
  }
}
