import { Component, OnInit } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { Observable } from 'rxjs';
import { getIsHaveUserInfo } from '@appCore/store/user-info';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  public isHaveUserInfo$: Observable<boolean>;
  constructor(private redux: ReduxService) {}

  ngOnInit() {
    this.isHaveUserInfo$ = this.redux.selectStore(getIsHaveUserInfo);
  }

  public goBack(): void {
    window.history.back();
  }
}
