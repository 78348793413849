import { documentPackagePublicationsReducer } from '@appDocuments/core/store/document-package-publications/document-package-publications.reducer';
import { DocumentPackagePublicationsStoreModel } from '@appDocuments/core/store/document-package-publications/document-package-publications.store.model';
import {
  documentPackageSendingsReducer,
  DocumentPackageSendingsState,
} from '@appDocuments/core/store/document-package-sendings/document-package-sendings.reducer';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { attachmentsReducer, AttachmentsState } from './attachments/attachments.reducer';
import { documentPackageReducer } from './document-package/document-package.reducer';
import { DocumentPackageStoreModel } from './document-package/document-package.store.model';
import { RegistrationNumberStoreModel } from './registration-number/registration-number-store.model';
import { registrationNumberReducer } from './registration-number/registration-number.reducer';
import { taskResolutionsReducer } from './task-resolutions/task-resolutions.reducer';
import { documentPackageRegistrationReducer } from '@appDocuments/core/store/document-package-registration/document-package-registration.reducer';
import { DocumentPackageRegistrationStoreModel } from '@appDocuments/core/store/document-package-registration/document-package-registration.store.model';

export interface DocumentsModuleState {
  documentPackage: DocumentPackageStoreModel;
  registrationNumber: RegistrationNumberStoreModel;
  attachments: AttachmentsState;
  taskResolutions: DictionaryStoreModel<DictionaryModel>;
  documentPackagePublications: DocumentPackagePublicationsStoreModel;
  documentPackageSendings: DocumentPackageSendingsState;
  documentPackageRegistration: DocumentPackageRegistrationStoreModel;
}

export const documentsModuleReducers: ActionReducerMap<DocumentsModuleState> = {
  /** Пакет документов */
  documentPackage: documentPackageReducer,
  /** Учетный номер пакета */
  registrationNumber: registrationNumberReducer,
  /** Прикрепленные файлы */
  attachments: attachmentsReducer,
  /** список резолюций задач */
  taskResolutions: taskResolutionsReducer,
  /**публикации ПД */
  documentPackagePublications: documentPackagePublicationsReducer,
  /**рассылка ПД */
  documentPackageSendings: documentPackageSendingsReducer,
  /* раздел регистрации ЛПА*/
  documentPackageRegistration: documentPackageRegistrationReducer,
};

export const selectDocumentsModule = createFeatureSelector<DocumentsModuleState>('documentsModule');
