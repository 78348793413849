import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipContentComponent } from '@appShared/components/tooltip/tooltip-content.component';
import { TooltipClipboardComponent } from './clipboard/tooltip-clipboard.component';
import { TooltipClipboardDirective } from './clipboard/tooltip-clipboard.directive';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [TooltipDirective, TooltipContentComponent, TooltipClipboardComponent, TooltipClipboardDirective],
    exports: [TooltipDirective, TooltipContentComponent, TooltipClipboardComponent, TooltipClipboardDirective]
})
export class TooltipModule {}
