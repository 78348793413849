/** расшифровка идентификаторов статусов */
export enum DocumentStatusItem {
  /** Срочность */
  urgently = 1,
  /** Прием */
  reception,
  /** результат экспертизы */
  resultOfExamination,
  /** Проверка редактором */
  checkingForAnEditor,
  /** Рассмотрение экспертной группой */
  considerationByExpertGroup,
  /** Согласование начальником ОАУ ПМ */
  coordinationByHeadOfOAU,
  /** Печать на Красном бланке */
  printingOnRedBlank,
  /** Подписание */
  signing,
  /** Эталонная версия */
  referenceVersion,
  /** Опубликование */
  publishing,
  /** Рассылка */
  newsletter,
  /** Архив */
  archive,
  /** Тип редакции  */
  revisionType
}
