/**
 * При добавлении новых этапов необходимо указать связку этапов и подэтапов + названия в файле
 * documents\documents-route\const\phase-map.const.ts
 */
export enum RoutePhaseTypes {
  /**
   * Проект создан
   */
  projectCreated = 1,
  /**
   * Ответственный исполнитель
   */
  responsibleExecutor = 2,
  /**
   * Вопрос внесен
   */
  questionProvided = 3,
  /**
   * ПУ ПМ
   */
  puPm = 4,
  /**
   * Проект представлен
   */
  projectProvided = 5,
  /**
   * ОАУ ПМ
   */
  oauPm = 6,
  /**
   * Проект создан. Инициация
   */
  projectCreatedInitiation = 7,
  /**
   * Проект создан. Разработка
   */
  projectCreatedDevelopment = 8,
  /**
   * Проект создан. Согласование
   */
  projectCreatedApprovement = 9,
  /**
   * Проект создан. Доработка
   */
  projectCreatedRework = 10,
  /**
   * Ответственный исполнитель. Инициация
   */
  responsibleExecutorInitiation = 11,
  /**
   * разработка и согласование проекта ЛУЗ
   * Ответственный исполнитель. Согласование.
   */
  responsibleExecutorApprovement = 12,
  /**
   * Вопрос внесен. Инициация
   */
  questionProvidedInitiation = 13,
  /**
   * Вопрос внесен. Согласование
   */
  questionProvidedApprovement = 14,
  /**
   * инициация
   */
  puPmInitiation = 15,
  /**
   * разработка и согласование
   */
  puPmApprovement = 16,
  /**
   * согласование
   */
  projectProvidedApprovement = 17,
  /**
   * ОАУ ПМ. Инициация
   */
  oauPmInitiation = 18,
  /**
   * ОАУ ПМ. Предварительная экспертиза
   */
  oauPmExpertise = 19,
  /**
   * ОАУ ПМ. Разработка и согласование
   */
  oauPmApprovement = 20,
  /**
   * Проект согласован
   */
  projectApproved = 21,
  /**
   *инициация
   */
  projectApprovedInitiation = 22,
  /**
   * согласование
   */
  projectApprovedApprovement = 23,
  /**
   * Оформление в ОАУ
   */
  oauRegistration = 24,
  /**
   * Оформление в ОАУ. Инициация
   */
  oauRegistrationInitiation = 25,
  /**
   * Оформление в ОАУ. Согласование
   */
  oauRegistrationApprovement = 26,
  /**
   * Предварительное согласование
   */
  preliminaryApprovement = 27,
  /**
   * Предварительное согласование. Инициация
   */
  preliminaryApprovementInitiation = 28,
  /**
   * Предварительное согласование. Согласование
   */
  preliminaryApprovementApprovement = 29,
  /**
   * Согласительное совещание
   */
  concilation = 30,
  /**
   * Согласительное совещание. Поручение
   */
  concilationInitiation = 31,
  /**
   * Согласительное совещание. Согласование
   */
  concilationApprovement = 32,
  /**
   * Пользовательский этап
   */
  userPhase = 33,
  /**
   * Пользовательский этап. Поручение
   */
  userPhaseInitiation = 34,
  /**
   * Пользовательский этап. Согласование
   */
  userPhaseApprovement = 35,
  /**
   * Предварительное согласование. Доработка
   */
  preliminaryApprovementRevision = 36,
}
