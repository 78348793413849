import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ErrorPopupService } from '@appCore/services/error-popup/error-popup.service';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { CompositionAddDocumentRequestModel } from '@appShared/api/document-composition/models/document-composition.request/composition-add-document-request.model';
import { CompositionUpdateDocumntRequestModel } from '@appShared/api/document-composition/models/document-composition.request/composition-update-document-request.model';
import { DocumentStoreTypeEnum } from '@models/enums/document-store-type.enum';
import { DocumentGroupsEnum } from '@sharedEnums/document-groups.enum';
import { Observable, filter, switchMap } from 'rxjs';
import { CompositionAddDocumentFormValueModel } from '../models/composition-add-document-form-value.model';

@Injectable({
  providedIn: 'root',
})
export class CompositionAddDocumentService {
  constructor(private errorPopup: ErrorPopupService, private dictionaryApi: DictionaryApiService) {}

  /**
   * Маппинг из формы в данные для запроса на создание
   */
  public mapAddDocumentformToRequestModel(
    documentPackageId: number,
    documentGroup: DocumentGroupsEnum,
    formValue: CompositionAddDocumentFormValueModel
  ): CompositionAddDocumentRequestModel {
    return {
      documentPackageId,
      name: formValue.documentName,
      projectType: {
        id: formValue.projectType,
      },
      templateId: formValue.templateDocumentType ? formValue.templateDocumentType.id : null,
      description: formValue.documentDescription,
      documentType: formValue.documentType,
      secret: formValue.closedBase,
      closedBaseType: formValue.closedBaseType,
      strictStructure: formValue.strictStructure,
      documentGroup,
    };
  }

  /** Маппинг из формы в данные для запроса на обновление */
  public mapUpdateDocumentFormToRequestModel(
    documentToEditId: number,
    formValue: CompositionAddDocumentFormValueModel
  ): CompositionUpdateDocumntRequestModel {
    return {
      id: documentToEditId,
      name: formValue.documentName,
      description: formValue.documentDescription,
      secret: formValue.closedBase,
      closedBaseType: formValue.closedBaseType,
      strictStructure: formValue.strictStructure,
    };
  }

  public mapAddDocumentToFormData(
    documentPackageId: number,
    documentGroup: DocumentGroupsEnum,
    attachedFile: File,
    formValue: CompositionAddDocumentFormValueModel
  ): FormData {
    const storeType = formValue.saveToFileStorage ? DocumentStoreTypeEnum.FILE_STORAGE : DocumentStoreTypeEnum.LDE;
    const extension = this.getFileExtension(attachedFile.name);
    const fileWithDefinedContentType: Blob = attachedFile.slice(
      0,
      attachedFile.size,
      this.getMimeTypeByExtension(extension)
    );
    const formData = new FormData();
    formData.append('attachment', fileWithDefinedContentType, attachedFile.name);
    formData.append('format', extension);
    formData.append('documentPackageId', `${documentPackageId}`);
    formData.append('name', formValue.documentName);
    formData.append('description', formValue.documentDescription ? formValue.documentDescription : '');
    formData.append('projectTypeId', `${formValue.projectType}`);
    formData.append('secret', `${formValue.closedBase}`);
    formData.append('storeType', storeType.toString());
    if (formValue.closedBase) {
      formData.append('closedBaseTypeId', `${formValue.closedBaseType.id}`);
    }
    if (formValue.fixPageMargins) {
      formData.append('fixPageMargins', `${formValue.fixPageMargins}`);
    }
    if (formValue.documentType) {
      formData.append('docTypeId', `${formValue.documentType.id}`);
    }
    formData.append('strictStructure', `${formValue.strictStructure}`);
    formData.append('documentGroup', `${documentGroup}`);

    return formData;
  }

  public getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex < 0) {
      return '';
    }
    return fileName.substr(dotIndex + 1);
  }

  public mapImportRevisionToFormData(attachedFile: File): FormData {
    const formData = new FormData();

    formData.append('attachment', attachedFile, attachedFile.name);

    return formData;
  }

  public getMimeTypeByExtension(extension: string): string {
    extension = extension.toLowerCase();
    switch (extension) {
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'rtf':
        return 'application/rtf';
      case 'pdf':
        return 'application/pdf';
      case 'txt':
      case 'lx':
      case 'lex':
        return 'text/plain';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/octet-stream';
    }
  }

  public isAllowedExtension(fileName: string, allowedFileExtensions: string): boolean {
    const fileExt = this.getFileExtension(fileName).toLowerCase();
    const allowedExtArray = allowedFileExtensions.replace(/\./g, '').split(',');
    return allowedExtArray.includes(fileExt);
  }

  public allowedToAddFile(fileSize: number, maxFileSizeInMb = 200): boolean {
    const bitesInMb = 1048576;
    const maxFileSize = bitesInMb * maxFileSizeInMb;
    const message = `Размер загружаемого файла более ${maxFileSizeInMb} Мб. Выберите другой файл.`;
    if (fileSize > maxFileSize) {
      this.errorPopup.show({ message });
      return false;
    }
    return true;
  }

  public subscribeImportFormatsByDocumentType(control: AbstractControl): Observable<string> {
    return control.valueChanges.pipe(
      filter((type) => type),
      switchMap((type) => this.dictionaryApi.getImportFormatsByDocumentTypeId(type.id))
    );
  }
}
