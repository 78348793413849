export enum DocumentPackageRelationTypesIds {
  /** внесение изменений */
  alteration = 1,
  /** утрата силы */
  lossOfStrength,
  /** отмена */
  cancellation,
  /** отсутствует */
  missing
}
