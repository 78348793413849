/** Справочник событий календаря */
export const calendarEventTypes = {
  meeting: {
    id: 1,
    name: 'Совещание',
  },
  expertAgenda: {
    id: 3,
    name: 'Экспертная Повестка',
    calendarViewName: 'Экспертная группа',
  },
  preliminaryAgenda: {
    id: 4,
    name: 'Предварительная Повестка',
    calendarViewName: 'Рассмотрение предварительной повестки',
  },
  finalAgenda: {
    id: 5,
    name: 'Итоговая Повестка',
  },
  conciliationMeeting: {
    id: 6,
    name: 'Согласительное совещание',
  },
};
