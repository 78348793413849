import { DocumentRevisionInfoModel } from '@appDocuments/documents-composition/models/documents-revision-info.model';
import { RouteApproveActionModel } from '@appDocuments/documents-route/models/route-approve-action.model';
import { RouteExtendedReturnRequestModel } from '@appDocuments/documents-route/models/route-extended-return-request.model';
import { RouteStartApproveActionModel } from '@appDocuments/documents-route/models/route-start-approve-action.model';
import { SendToOuterApprovementActionModel } from '@appDocuments/documents-route/models/send-to-outer-approvement-action.model';
import { TreeAddBetweenStoreModel } from '@appDocuments/documents-route/models/tree-add-between-store.model';
import { TreeAddStoreModel } from '@appDocuments/documents-route/models/tree-add-store.model';
import { TreePhaseModel } from '@appDocuments/documents-route/models/tree/tree-phase.model';
import { EmployeeAddRequest } from '@appShared/api/documents-route/models/add-to-tree/employee-add.request.model';
import { AddCoauthorRequestModel } from '@appShared/api/documents-route/models/documents-route.request/add-coauthor-request.model';
import { AddControllerRequestModel } from '@appShared/api/documents-route/models/documents-route.request/add-controller-request.model';
import {
  AddPhaseParallelRequestModel,
  AddPhasesRequestModel,
} from '@appShared/api/documents-route/models/documents-route.request/add-phase-request.model';
import { ChangeEmployeeForPointModel } from '@appShared/api/documents-route/models/documents-route.request/change-employee-for-point.model';
import { ChangedUserAgreementRoutes } from '@appShared/api/documents-route/models/documents-route.request/changed-user-agreement-routes';
import { EmployeeYouCanChangeModel } from '@appShared/api/documents-route/models/employee-you-can-change.model';
import { RouteChangeSignerCategoriesModel } from '@appShared/api/documents-route/models/route-change-signer-categories.model';
import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { TaskResponseModel } from '@appShared/api/task/models/task.response';
import { Action } from '@ngrx/store';
import { DocumentRouteReworkActions } from './document-route-rework.actions';

export enum DocumentRouteActionTypes {
  Reset = '[Document Route] Reset',
  Get = '[Document Route] Get',
  GetSuccess = '[Document Route] GetSuccess',
  UpdateTree = '[Document Route] UpdateTree',
  Error = '[Document Route] Error',
  AddAfter = '[Document Route] AddAfter',
  AddAfterSuccess = '[Document Route] AddAfterSuccess',
  AddBefore = '[Document Route] AddBefore',
  AddBeforeSuccess = '[Document Route] AddBeforeSuccess',
  AddBetween = '[Document Route] AddBetween',
  AddBetweenSuccess = '[Document Route] AddBetweenSuccess',
  AddIncome = '[Document Route] AddIncome',
  AddIncomeSuccess = '[Document Route] AddIncomeSuccess',
  Start = '[Document Route] Start',
  StartSuccess = '[Document Route] StartSuccess',
  Delete = '[Document Route] Delete',
  DeleteSuccess = '[Document Route] DeleteSuccess',
  Approve = '[Document Route] Approve',
  ApproveWithRemark = '[Document Route] ApproveWithRemark',
  Introduce = '[Document Route] Introduce',
  SendComment = '[Document Route] SendComment',
  ApproveSuccess = '[Document Route] ApproveSuccess',
  InitDeadline = '[Document Route] InitDeadline',
  InitDeadlineSuccess = '[Document Route] InitDeadlineSuccess',
  InitiationAdd = '[Document Route] InitiationAdd',
  InitiationAddSuccess = '[Document Route] InitiationAddSuccess',
  StartDevelopment = '[Document Route] StartDevelopment',
  StartDevelopmentSuccess = '[Document Route] StartDevelopmentSuccess',
  TotalReturn = '[Document Route] TotalReturn',
  TotalReturnSuccess = '[Document Route] TotalReturnSuccess',
  StartApprovement = '[Document Route] StartApprovement',
  StartApprovementSuccess = '[Document Route] StartApprovementSuccess',
  SendToOuterApprovement = '[Document Route] SendToOuterApprovement',
  SendToOuterApprovementSuccess = '[Document Route] SendToOuterApprovementSuccess',
  InternalReturn = '[Document Route] InternalReturn',
  InternalReturnSuccess = '[Document Route] InternalReturnSuccess',
  ExternalReturn = '[Document Route] ExternalReturn',
  ExternalReturnSuccess = '[Document Route] ExternalReturnSuccess',
  AddPhases = '[Document Route] AddPhases',
  AddPhasesSuccess = '[Document Route] AddPhasesSuccess',
  AddPhasesParallel = '[Document Route] AddPhasesParallel',
  AddPhasesParallelSuccess = '[Document Route] AddPhasesParallelSuccess',
  RemoveInspector = '[Document Route] RemoveInspector',
  RemoveInspectorSuccess = '[Document Route] RemoveInspectorSuccess',
  GetEmployeesYouCanChange = '[Document Route] GetEmployeesYouCanChange',
  GetEmployeesYouCanChangeSuccess = '[Document Route] GettingEmployeesYouCanChangeSuccess',
  ChangeEmployeeForPoint = '[Document Route] ChangeEmployeeForPoint',
  ChangeEmployeeForPointSuccess = '[Document Route] ChangeEmployeeForPointSuccess',
  AddCoauthor = '[Document Route] AddCoauthor',
  AddCoauthorSuccess = '[Document Route] AddCoauthorSuccess',
  AddController = '[Document Route] AddController',
  AddControllerSuccess = '[Document Route] AddControllerSuccess',
  AddIntroducer = '[Document Route] AddIntroducer',
  AddIntroducerSuccess = '[Document Route] AddIntroducerSuccess',
  RemoveIntroducer = '[Document Route] RemoveIntroducer',
  RemoveIntroducerSuccess = '[Document Route] RemoveIntroducerSuccess',
  RemoveCoauthor = '[Document Route] RemoveCoauthor',
  RemoveCoauthorSuccess = '[Document Route] RemoveCoauthorSuccess',
  SendToOauLeader = '[Document Route] SendToOauLeader',
  SendToOauLeaderSuccess = '[Document Route] SendToOauLeaderSuccess',
  UpdateRoute = '[Document Route] UpdateRoute',
  ChangeSignerCategories = '[Document Route] ChangeSignerCategories',
  ChangeSignerCategoriesSuccess = '[Document Route] ChangeSignerCategoriesSuccess',
  ChangeAccessoryToInvestmentProject = '[Document Route] ChangeAccessoryToInvestmentProject',
  ChangeAccessoryToInvestmentProjectSuccess = '[Document Route] ChangeAccessoryToInvestmentProjectSuccess',
  Repair = '[Document Route] Repair',
  RepairSuccess = '[Document Route] RepairSuccess',
  AddParallelTasks = '[Document Route] AddParallelTasks',
  RemovePhase = '[Document Route] RemovePhase',
  RemovePhaseSuccess = '[Document Route] RemovePhaseSuccess',
  UpdateEsigningState = '[Document Route] UpdateEsignStatus',
  SetActiveCurrentPointId = '[Document Route] SetActiveCurrentPointId',
  ChangedUsers = '[Document Route] ChangedUsers',
  ChangedUsersSuccess = '[Document Route] ChangedUsersSuccess',
  ChangedUsersError = '[Document Route] ChangedUsersError',
  SkipPhase = '[Document Route] SkipPhase',
  SkipPhaseSuccess = '[Document Route] SkipPhaseSuccess',
  SkipPhaseError = '[Document Route] SkipPhaseError',
  MarkResponsibleExecutor = '[Document Route] MarkResponsibleExecutor',
  MarkResponsibleExecutorSuccess = '[Document Route] MarkResponsibleExecutorSuccess',
}

export class RouteReset implements Action {
  readonly type = DocumentRouteActionTypes.Reset;
}

export class DocumentRouteGet implements Action {
  readonly type = DocumentRouteActionTypes.Get;

  constructor(public payload: number) {}
}

export class DocumentRouteGetSuccess implements Action {
  readonly type = DocumentRouteActionTypes.GetSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class UpdateTree implements Action {
  readonly type = DocumentRouteActionTypes.UpdateTree;

  constructor(public payload: TreePhaseModel[]) {}
}

export class DocumentRouteConfigureDeadline implements Action {
  readonly type = DocumentRouteActionTypes.InitDeadline;

  constructor(
    public payload: {
      vetrexId: number;
      deadline: string;
    }
  ) {}
}

export class DocumentRouteInitDeadlineSuccess implements Action {
  readonly type = DocumentRouteActionTypes.InitDeadlineSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddAfter implements Action {
  readonly type = DocumentRouteActionTypes.AddAfter;

  constructor(public payload: TreeAddStoreModel) {}
}

export class AddAfterSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddAfterSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddBefore implements Action {
  readonly type = DocumentRouteActionTypes.AddBefore;

  constructor(public payload: TreeAddStoreModel) {}
}

export class AddBeforeSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddBeforeSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddBetween implements Action {
  readonly type = DocumentRouteActionTypes.AddBetween;

  constructor(public payload: TreeAddBetweenStoreModel) {}
}

export class AddBetweenSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddBetweenSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddIncome implements Action {
  readonly type = DocumentRouteActionTypes.AddIncome;

  constructor(public payload: TreeAddStoreModel) {}
}

export class AddIncomeSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddIncomeSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddIntroducer implements Action {
  readonly type = DocumentRouteActionTypes.AddIntroducer;

  constructor(
    public payload: {
      routeId: number;
      id: number;
      request: EmployeeAddRequest;
      versions?: DocumentRevisionInfoModel[];
    }
  ) {}
}

export class AddIntroducerSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddIntroducerSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RemoveIntroducer implements Action {
  readonly type = DocumentRouteActionTypes.RemoveIntroducer;

  constructor(public payload: number) {}
}

export class RemoveIntroducerSuccess implements Action {
  readonly type = DocumentRouteActionTypes.RemoveIntroducerSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteStart implements Action {
  readonly type = DocumentRouteActionTypes.Start;

  constructor(public payload: number) {}
}

export class RouteStartSuccess implements Action {
  readonly type = DocumentRouteActionTypes.StartSuccess;

  constructor(public payload: number) {}
}

export class RouteDelete implements Action {
  readonly type = DocumentRouteActionTypes.Delete;

  constructor(
    public payload: {
      id: number;
      routeId: number;
    }
  ) {}
}

export class RouteDeleteSuccess implements Action {
  readonly type = DocumentRouteActionTypes.DeleteSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteApprove implements Action {
  readonly type = DocumentRouteActionTypes.Approve;

  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteIntroduce implements Action {
  readonly type = DocumentRouteActionTypes.Introduce;

  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteSendComment implements Action {
  readonly type = DocumentRouteActionTypes.SendComment;

  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteApproveWithRemark implements Action {
  readonly type = DocumentRouteActionTypes.ApproveWithRemark;

  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteApproveSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ApproveSuccess;

  constructor(public payload: number) {}
}

export class DocumentRouteGetError implements Action {
  readonly type = DocumentRouteActionTypes.Error;

  constructor(public payload: any) {}
}

export class RouteInitiationAdd implements Action {
  readonly type = DocumentRouteActionTypes.InitiationAdd;

  constructor(
    public payload: {
      employeeId: number;
      deadlineDate: string;
      description: string;
    }
  ) {}
}
export class RouteInitiationAddSuccess implements Action {
  readonly type = DocumentRouteActionTypes.InitiationAddSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}
export class RouteStartDevelopment implements Action {
  readonly type = DocumentRouteActionTypes.StartDevelopment;

  constructor(public payload: DocumentRevisionInfoModel[]) {}
}

export class RouteStartDevelopmentSuccess implements Action {
  readonly type = DocumentRouteActionTypes.StartDevelopmentSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteTotalReturn implements Action {
  readonly type = DocumentRouteActionTypes.TotalReturn;

  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteTotalReturnSuccess implements Action {
  readonly type = DocumentRouteActionTypes.TotalReturnSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteStartApprovement implements Action {
  readonly type = DocumentRouteActionTypes.StartApprovement;
  constructor(public payload: RouteStartApproveActionModel) {}
}

export class RouteStartApprovementSuccess implements Action {
  readonly type = DocumentRouteActionTypes.StartApprovementSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteSendToOuterApprovement implements Action {
  readonly type = DocumentRouteActionTypes.SendToOuterApprovement;
  constructor(public payload: SendToOuterApprovementActionModel) {}
}

export class RouteSendToOuterApprovementSuccess implements Action {
  readonly type = DocumentRouteActionTypes.SendToOuterApprovementSuccess;
  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteInternalReturn implements Action {
  readonly type = DocumentRouteActionTypes.InternalReturn;

  /**
   * @param payload замечания к возврату
   */
  constructor(public payload: RouteExtendedReturnRequestModel) {}
}

export class RouteInternalReturnSuccess implements Action {
  readonly type = DocumentRouteActionTypes.InternalReturnSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteExternalReturn implements Action {
  readonly type = DocumentRouteActionTypes.ExternalReturn;

  /**
   * @param payload замечания к возврату
   */
  constructor(public payload: RouteApproveActionModel) {}
}

export class RouteExternalReturnSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ExternalReturnSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteAddPhases implements Action {
  readonly type = DocumentRouteActionTypes.AddPhases;

  /**
   * @param payload данные о новом этепа
   */
  constructor(public payload: AddPhasesRequestModel) {}
}

export class RouteAddPhasesSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddPhasesSuccess;

  /**
   * @param payload обновленный маршрут
   */
  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteAddPhasesParallel implements Action {
  readonly type = DocumentRouteActionTypes.AddPhasesParallel;

  /**
   * @param payload данные о новом этепа
   */
  constructor(public payload: AddPhaseParallelRequestModel) {}
}

export class RouteAddPhasesParallelSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddPhasesParallelSuccess;

  /**
   * @param payload обновленный маршрут
   */
  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteRemoveInspector implements Action {
  readonly type = DocumentRouteActionTypes.RemoveInspector;

  /**
   * @param payload id точки с которого удаляется контроллер
   */
  constructor(public payload: number) {}
}

export class GetEmployeesYouCanChange implements Action {
  readonly type = DocumentRouteActionTypes.GetEmployeesYouCanChange;

  /** @param payload - id проверяемого маршрута */
  constructor(public payload: number) {}
}

export class GetEmployeesYouCanChangeSuccess implements Action {
  readonly type = DocumentRouteActionTypes.GetEmployeesYouCanChangeSuccess;

  /** @param payload - ответ */
  constructor(public payload: EmployeeYouCanChangeModel[]) {}
}

export class RouteChangeEmployeeOfPoint implements Action {
  readonly type = DocumentRouteActionTypes.ChangeEmployeeForPoint;

  /**
   * @param payload данные для изменения сотрудника на точке
   */
  constructor(public payload: ChangeEmployeeForPointModel) {}
}

export class RouteChangeEmployeeOfPointSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ChangeEmployeeForPointSuccess;

  /**
   * @param payload ответ
   */
  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteRemoveInspectorSuccess implements Action {
  readonly type = DocumentRouteActionTypes.RemoveInspectorSuccess;

  /**
   * @param payload обновленный маршрут
   */
  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteAddCoauthor implements Action {
  readonly type = DocumentRouteActionTypes.AddCoauthor;

  constructor(
    public payload: {
      pointId: number;
      coauthors: AddCoauthorRequestModel[];
      phaseTypeId?: number;
    }
  ) {}
}

export class RouteAddCoauthorSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddCoauthorSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteAddController implements Action {
  readonly type = DocumentRouteActionTypes.AddController;

  constructor(
    public payload: {
      pointId: number;
      controller: AddControllerRequestModel;
      packageId: number;
    }
  ) {}
}

export class RouteAddControllerSuccess implements Action {
  readonly type = DocumentRouteActionTypes.AddControllerSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteRemoveCoauthor implements Action {
  readonly type = DocumentRouteActionTypes.RemoveCoauthor;

  constructor(public payload: number) {}
}

export class RouteRemoveCoauthorSuccess implements Action {
  readonly type = DocumentRouteActionTypes.RemoveCoauthorSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteSendToOauLeader implements Action {
  readonly type = DocumentRouteActionTypes.SendToOauLeader;

  constructor(
    public payload: {
      text: string;
      versions?: DocumentRevisionInfoModel[];
    }
  ) {}
}
export class RouteSendToOauLeaderSuccess implements Action {
  readonly type = DocumentRouteActionTypes.SendToOauLeaderSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteUpdateRoute implements Action {
  readonly type = DocumentRouteActionTypes.UpdateRoute;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteChangeSignerCategories implements Action {
  readonly type = DocumentRouteActionTypes.ChangeSignerCategories;
  constructor(public payload: { pointId: number; categories: RouteChangeSignerCategoriesModel }) {}
}

export class RouteChangeSignerCategoriesSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ChangeSignerCategoriesSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteChangeAccessoryToInvestmentProject implements Action {
  readonly type = DocumentRouteActionTypes.ChangeAccessoryToInvestmentProject;
  constructor(
    public payload: {
      pointId: number;
      isIncludeInvestmentProject: boolean;
      categories: RouteChangeSignerCategoriesModel;
    }
  ) {}
}

export class RouteChangeAccessoryToInvestmentProjectSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ChangeAccessoryToInvestmentProjectSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RoutePointRepair implements Action {
  readonly type = DocumentRouteActionTypes.Repair;
  constructor(public payload: { pointId: number; routeId: number; deadline?: string }) {}
}

export class RoutePointRepairSuccess implements Action {
  readonly type = DocumentRouteActionTypes.RepairSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class AddParallelTasks implements Action {
  readonly type = DocumentRouteActionTypes.AddParallelTasks;

  constructor(public payload: TaskResponseModel[]) {}
}

export class RouteRemovePhase implements Action {
  readonly type = DocumentRouteActionTypes.RemovePhase;

  /**
   * @param payload id родительского этапа
   */
  constructor(public payload: number) {}
}

export class RouteRemovePhaseSuccess implements Action {
  readonly type = DocumentRouteActionTypes.RemovePhaseSuccess;

  /**
   * @param payload обновленный маршрут
   */
  constructor(public payload: RouteTreeResponseModel) {}
}

export class UpdateEsigningState implements Action {
  readonly type = DocumentRouteActionTypes.UpdateEsigningState;

  /**
   * @param payload состояние подписания
   */
  constructor(public payload: boolean) {}
}

export class SetActiveCurrentPointId implements Action {
  readonly type = DocumentRouteActionTypes.SetActiveCurrentPointId;
  constructor(public payload: number) {}
}

export class ChangedUsers implements Action {
  readonly type = DocumentRouteActionTypes.ChangedUsers;
  constructor(public payload: number) {}
}

export class ChangedUsersSuccess implements Action {
  readonly type = DocumentRouteActionTypes.ChangedUsersSuccess;
  constructor(public payload: ChangedUserAgreementRoutes[]) {}
}

export class ChangedUsersError implements Action {
  readonly type = DocumentRouteActionTypes.ChangedUsersError;

  constructor(public payload: any) {}
}

export class SkipPhase implements Action {
  readonly type = DocumentRouteActionTypes.SkipPhase;
}

export class SkipPhaseSuccess implements Action {
  readonly type = DocumentRouteActionTypes.SkipPhaseSuccess;
  constructor(public payload: RouteTreeResponseModel) {}
}

export class SkipPhaseError implements Action {
  readonly type = DocumentRouteActionTypes.SkipPhaseError;
  constructor(public payload: any) {}
}
export class MarkResponsibleExecutor implements Action {
  readonly type = DocumentRouteActionTypes.MarkResponsibleExecutor;
  constructor(public payload: { pointId: number; isResponsibleExecutor: boolean }) {}
}

export class MarkResponsibleExecutorSuccess implements Action {
  readonly type = DocumentRouteActionTypes.MarkResponsibleExecutorSuccess;
  constructor(public payload: RouteTreeResponseModel) {}
}

export type DocumentRouteActions =
  | RouteReset
  | DocumentRouteGet
  | UpdateTree
  | AddAfter
  | AddAfterSuccess
  | AddBefore
  | AddBeforeSuccess
  | AddBetween
  | AddBetweenSuccess
  | AddIncome
  | AddIncomeSuccess
  | AddIntroducer
  | AddIntroducerSuccess
  | GetEmployeesYouCanChange
  | GetEmployeesYouCanChangeSuccess
  | RemoveIntroducer
  | RemoveIntroducerSuccess
  | RouteDelete
  | RouteDeleteSuccess
  | RouteStart
  | RouteStartSuccess
  | RouteApprove
  | RouteApproveWithRemark
  | RouteIntroduce
  | RouteSendComment
  | RouteApproveSuccess
  | DocumentRouteGetSuccess
  | DocumentRouteConfigureDeadline
  | DocumentRouteInitDeadlineSuccess
  | DocumentRouteGetError
  | RouteInitiationAdd
  | RouteInitiationAddSuccess
  | RouteStartDevelopment
  | RouteStartDevelopmentSuccess
  | RouteTotalReturn
  | RouteTotalReturnSuccess
  | RouteStartApprovement
  | RouteStartApprovementSuccess
  | RouteInternalReturn
  | RouteInternalReturnSuccess
  | RouteExternalReturn
  | RouteExternalReturnSuccess
  | RouteAddPhases
  | RouteAddPhasesSuccess
  | RouteAddPhasesParallel
  | RouteAddPhasesParallelSuccess
  | RouteRemoveInspector
  | RouteChangeEmployeeOfPoint
  | RouteChangeEmployeeOfPointSuccess
  | RouteRemoveInspectorSuccess
  | RouteAddCoauthor
  | RouteAddCoauthorSuccess
  | RouteAddController
  | RouteAddControllerSuccess
  | RouteRemoveCoauthor
  | RouteRemoveCoauthorSuccess
  | RouteUpdateRoute
  | RouteSendToOuterApprovement
  | RouteSendToOuterApprovementSuccess
  | RouteSendToOauLeader
  | RouteSendToOauLeaderSuccess
  | DocumentRouteReworkActions
  | RouteChangeSignerCategories
  | RouteChangeSignerCategoriesSuccess
  | RouteChangeAccessoryToInvestmentProject
  | RouteChangeAccessoryToInvestmentProjectSuccess
  | RoutePointRepair
  | RoutePointRepairSuccess
  | AddParallelTasks
  | RouteRemovePhase
  | RouteRemovePhaseSuccess
  | UpdateEsigningState
  | SetActiveCurrentPointId
  | ChangedUsers
  | ChangedUsersSuccess
  | ChangedUsersError
  | SkipPhase
  | SkipPhaseSuccess
  | SkipPhaseError
  | MarkResponsibleExecutor
  | MarkResponsibleExecutorSuccess;
