import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim',
})
export class TrimPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    return trim(value, limit);
  }
}

export function trim(value: string, limit: number): string {
  if (value && limit) {
    return value.slice(0, limit).trim();
  }

  return value;
}
