import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import { ControlBaseComponent } from '../control-base/control-base.component';

/**
 * Компонент текстового инпута
 */
@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['../control-base/control-base.component.scss', './input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent extends ControlBaseComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('inputElement')
  inputElement: ElementRef;

  @Input()
  label: string;

  /** Тип поля */
  @Input()
  controlType = 'text';

  /** Автозамена кавычек на ёлочки */
  @Input()
  useFrenchQuotes = true;

  /** Паказываем кнопку отчистить контрол */
  @Input()
  isShowClearButton = false;

  /** нажатие enter */
  @Output()
  onEnter: EventEmitter<void> = new EventEmitter();

  /** Значение поля */
  public model: string;

  /** Доступность поля */
  @Input()
  disabled = false;

  public control: UntypedFormControl = new UntypedFormControl();

  /** предварительное объявление функций NG_VALUE_ACCESSOR */
  public onChange = (value: any) => {};
  public onTouched = () => {};

  constructor(private injector: Injector) {
    super();
  }

  /** Получаем доступ к контролу в случае использования NG_VALUE_ACCESSOR */
  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl) {
      setTimeout(() => (this.control = ngControl.control as UntypedFormControl));
    }
  }

  /** Событие изменения значения поля */
  public onInputChange(): void {
    if (this.useFrenchQuotes && this.controlType === 'text') {
      this.model = this.frenchQuotes(this.model, this.inputElement.nativeElement);
    }

    this.onChange(this.model);
  }

  public setDefaultValue(): void {
    const control = this.control;

    if (this.controlType === 'time') {
      return control.setValue(moment().format('HH:mm'));
    }
  }
  /** Снятие фокуса */
  public onBlur(): void {
    this.onTouched();
    this.spaceTrim(this.model);
  }

  /** поймать передаваемое значение через NG_VALUE_ACCESSOR */
  public writeValue(value: any): void {
    this.model = value;
  }

  /** определить функцию onChange из NG_VALUE_ACCESSOR */
  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /** определить функцию onTouched из NG_VALUE_ACCESSOR */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** поймать отключение формы через NG_VALUE_ACCESSOR */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onClearControlValue(): void {
    super.onClearControlValue();
  }
}
