import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** Справочник "типы вопросов пакета повестки Заседание" */

export enum ConsiderationPMMeetingQuestionsTypesActionTypes {
  Get = '[Consideration PM Meeting Questions Types] Get',
  GetSuccess = '[Consideration PM Meeting Questions Types] GetSuccess',
  GetError = '[Consideration PM Meeting Questions Types] GetError',
  Reset = '[Consideration PM Meeting Questions Types] Reset',
}

export class ConsiderationPMMeetingQuestionsTypesGet implements Action {
  readonly type = ConsiderationPMMeetingQuestionsTypesActionTypes.Get;
}

export class ConsiderationPMMeetingQuestionsTypesGetError implements Action {
  readonly type = ConsiderationPMMeetingQuestionsTypesActionTypes.GetError;
}

export class ConsiderationPMMeetingQuestionsTypesGetSuccess implements Action {
  readonly type = ConsiderationPMMeetingQuestionsTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class ConsiderationPMMeetingQuestionsTypesReset implements Action {
  readonly type = ConsiderationPMMeetingQuestionsTypesActionTypes.Reset;
}

export type ConsiderationPMMeetingQuestionsTypesAction =
  | ConsiderationPMMeetingQuestionsTypesGet
  | ConsiderationPMMeetingQuestionsTypesGetSuccess
  | ConsiderationPMMeetingQuestionsTypesGetError
  | ConsiderationPMMeetingQuestionsTypesReset;
