import { Injectable } from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { CalendarApiService } from '@appShared/api/calendar/calendar-api.service';
import { CalendarPeriodParamsModel } from '@models/calendar/calendar.request/calendar-period-params.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { getHolidays } from '.';
import { CalendarHolidayModel } from '../../models/holiday.model';
import { DatePickerService } from '../../services/date-picker.service';
import {
  HolidaysActions,
  HolidaysActionTypes,
  HolidaysGet,
  HolidaysGetSuccess,
  HolidaysLoad,
} from './holidays.actions';

@Injectable()
export class HolidaysEffects {
  private holidays$ = this.redux.selectStore(getHolidays);

  constructor(
    private actions$: Actions,
    private calendarApi: CalendarApiService,
    private redux: ReduxService,
    private datePickerService: DatePickerService
  ) {}

  
  GetHolidays$: Observable<HolidaysActions> = createEffect(() => this.actions$.pipe(
    ofType(HolidaysActionTypes.Get),
    // throttleTime(100),
    withLatestFrom(this.holidays$),
    /** отсеять запрос с годом, который уже есть в хранилище */
    filter(
      ([action, holidays]: [HolidaysGet, CalendarHolidayModel[]]) =>
        !holidays.find((dateBlock) => dateBlock.date.substr(0, 4) === action.payload.toString())
    ),
    tap(() => new HolidaysLoad()),
    /** сформировать период запроса */
    map(([action, holidays]: [HolidaysGet, CalendarHolidayModel[]]) => ({
      dateBegin: `${action.payload}-01-01`,
      dateEnd: `${action.payload}-12-31`,
    })),
    mergeMap((period: CalendarPeriodParamsModel) =>
      this.calendarApi.getProductionCalendar(period).pipe(
        map((response) => new HolidaysGetSuccess(response)),
        catchError(() => {
          /** выдать дефолтный список праздников */
          const year = period.dateBegin.substr(0, 4);
          return of(new HolidaysGetSuccess(this.datePickerService.getDefaultHolidaysList(year)));
        })
      )
    )
  ));
}
