export enum DocumentSaveStatuses {
  createSuccess = 'Пакет документа успешно сохранен',
  createError = 'Ошибка при сохранении пакета документа',
  editSuccess = 'Пакет документа успешно отредактирован',
  editSuccessDateIsTransferred = 'Пакет документа успешно отредактирован.<br> Дата рассмотрения перенесена.<br> Необходимо пересчитать сроки согласования.',
  signedSuccess = 'Пакет Документа подписан ЭП успешно',
  editError = 'Ошибка при редактировании пакета документа',
  publicationSuccess = 'Публикации успешно сохранены',
  publicationError = 'Ошибка при сохранении публикаций',
  sendingSuccess = 'Рассылки успешно сохранены',
  sendingError = 'Ошибка при сохранении рассылок',
  cardSuccess = 'Карточка приема успешно сохранена',
  cardError = 'Ошибка при сохранении карточки приема',
  createCardReceptionSuccess = 'Карточка приема успешно создана<br> на странице Состав пакета',
  createCardReceptionError = 'Ошибка формирования карточки приема',
  deleteDocumentSuccess = 'Документ успешно удален',
  deleteDocumentError = 'Ошибка при удалении документа',
  success = 'Успешно ',
  templateCreateSuccess = 'Шаблон успешно сохранен',
  templateApplySuccess = 'Шаблон успешно применен',
  error = 'Произошла ошибка',
  createDraftSuccess = 'Черновик успешно сохранен',
  createDraftError = 'Ошибка при сохранении черновика',
  unselectEtalonStatusSuccess = 'Признак эталонной версии успешно снят',
  createOauPublicationSuccess = 'Версия для опубликования добавлена <br> в раздел Документы ОАУ ПМ',
  createLocalLegalActPublicationSuccess = 'Версия для опубликования добавлена <br> в раздел Документы для опубликования',
  deletePublicationVersionSuccess = 'Версия для опубликования успешно удалена',
  deletePublicationVersionError = 'Ошибка при удалении версии для опубликования',
  createCurrentVersion = 'Действующая редакция успешно создана',
  deleteCurrentVersion = 'Действующая редакция удалена',
  sharedActingVersionAddedSuccess = 'Документ отображен в разделе «Принятые документы»',
  allSharedActingVersionAddedSuccess = 'Все действующие редакции Пакета Документа отображены в разделе «Принятые документы»',
  registerSuccess = 'Пакет Документа успешно зарегистрирован',
  versionsToSendEA = 'Версии успешно отправлены в Электронный архив',
  dataNotFoundFromEA = 'Не найдены данные для удаления из Электронного архива',
}
