import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { MenuItemsNames } from '@appShared/components/menu/enums/menu-items-names.enum';

@Pipe({
  name: 'isShowNotificationsCounter',
})
export class IsShowNotificationsCounterPipe implements PipeTransform {
  transform(item: MenuItemModel, notificationsCount: number): boolean {
    return item.name === MenuItemsNames.notifications && !!notificationsCount;
  }
}
