import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlValueByProp'
})
export class UrlValueByPropPipe implements PipeTransform {
  transform(value: string, prop: string ): string {
    if (!value) {
      return;
    }

    if (value.includes('http')) {
      const url = new URL(value);
      return url[prop];
    }

    const modifiedUrl = `https://${value}`;

    return new URL(modifiedUrl)[prop];
  }
}
