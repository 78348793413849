import { Injectable } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AnnouncementModel } from '@models/announcement/announcement.model';
import { AnnouncementApiService } from '@appShared/api/announcement/announcement.api.service';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  private activeAnnouncement$: BehaviorSubject<AnnouncementModel> = new BehaviorSubject(null);
  private isAnnouncementExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isAnnouncementOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private announcementApi: AnnouncementApiService) {}

  public getActiveAnnouncement(): void {
    this.announcementApi
      .getActiveAnnouncement()
      .pipe(first())
      .subscribe((value) => this.activeAnnouncement$.next(value));
  }
  public getActiveAnnouncement$(): Observable<AnnouncementModel> {
    return this.announcementApi.getActiveAnnouncement().pipe(
      first(),
      tap((value) => {
        this.activeAnnouncement$.next(value);
      })
    );
  }

  public setAnnouncement(announcement: AnnouncementModel): void {
    this.activeAnnouncement$.next(announcement);
  }
  public getAnnouncement$(): Observable<AnnouncementModel> {
    return this.activeAnnouncement$;
  }
  public setIsAnnouncementExpanded(value: boolean): void {
    this.isAnnouncementExpanded$.next(value);
  }

  public setAnnouncementOpened(value: boolean): void {
    this.isAnnouncementOpened$.next(value);
  }

  public getIsAnnouncementOpened$(): Observable<boolean> {
    return this.isAnnouncementOpened$;
  }

  public getShowAnnouncementLayout(): Observable<boolean> {
    return combineLatest([this.isAnnouncementOpened$, this.isAnnouncementExpanded$]).pipe(
      map(([isOpened, isExpanded]) => isOpened && !isExpanded)
    );
  }
}
