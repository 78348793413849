import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { MenuItemsNames } from '@appShared/components/menu/enums/menu-items-names.enum';

@Pipe({
  name: 'iconClassName',
})
export class IconClassNamePipe implements PipeTransform {
  transform(item: MenuItemModel, notificationsCount: number): string {
    if (item.name === MenuItemsNames.notifications && !!notificationsCount) {
      return `item__icon ${item.alternativeIconClass}`;
    }

    if (item.isExternal) {
      return `item__icon item__external ${item.iconClass}`;
    }

    return `item__icon ${item.iconClass}`;
  }
}
