import { Pipe, PipeTransform } from '@angular/core';
import { DocumentCopyItemModel } from '@appDocuments/documents-composition/models/document-copy-item.model';
import { DocumentsItemModel } from '@models/document-composition/documents-item.model';
import { DocumentsVersionBaseModel } from '@models/document-composition/documents-version-base.model';

@Pipe({
  name: 'documentHeadVersion',
})
export class DocumentHeadVersionPipe implements PipeTransform {
  transform = documentHeadVersion;
}

export function documentHeadVersion(document: DocumentsItemModel | DocumentCopyItemModel): DocumentsVersionBaseModel {
  if (!document || !document.versions || !document.versions.length) {
    return null;
  }

  return document.versions[0];
}
