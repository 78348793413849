import { DictionaryModel } from '@models/base/dictionary.model';
import { Action } from '@ngrx/store';

/** Справочник "типы согласования" сопроводительного текста */
export enum CoveringTextTypesActionTypes {
  Get = '[Covering Text Types] Get',
  GetSuccess = '[Covering Text Types] GetSuccess',
  GetError = '[Covering Text Types] GetError',
  Reset = '[Covering Text Types] Reset',
}

export class CoveringTextTypesGet implements Action {
  readonly type = CoveringTextTypesActionTypes.Get;
}

export class CoveringTextTypesGetError implements Action {
  readonly type = CoveringTextTypesActionTypes.GetError;
}

export class CoveringTextTypesGetSuccess implements Action {
  readonly type = CoveringTextTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class CoveringTextTypesReset implements Action {
  readonly type = CoveringTextTypesActionTypes.Reset;
}

export type CoveringTextTypesAction =
  | CoveringTextTypesGet
  | CoveringTextTypesGetSuccess
  | CoveringTextTypesGetError
  | CoveringTextTypesReset;
