import { Pipe, PipeTransform } from '@angular/core';
import { DelegatingTypeEnum } from '@models/enums/delegating-type.enum';
import { employeeShortName } from '@appShared/pipes/employee-short-name.pipe';
import { CreatorInfoModel } from '@models/employee/creator-info-model';

@Pipe({
  name: 'employeeDelegatingTypeName',
})
export class EmployeeDelegatingTypeName implements PipeTransform {
  transform = delegatingTypeName;
}

export function delegatingTypeName(creator: CreatorInfoModel): string {
  let preparedDelegating = '';

  switch (creator.creatorDelegatingType) {
    case DelegatingTypeEnum.ACTING:
      preparedDelegating = `добавлено и.о. ${employeeShortName(creator.realUserCreate)}`;
      break;
    case DelegatingTypeEnum.ON_BEHALF_OF:
      preparedDelegating = `добавлено от имени ${employeeShortName(creator.realUserCreate)} `;
      break;
    default:
      break;
  }

  return preparedDelegating;
}
