import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentPackageResponseModel } from '@models/document-package/document-package-response.model';
import { PermissionsService } from '@appPermissions/permissions.service';
import { SettingCodeEnum } from '@appShared/enums/setting-code.enum';
import { SettingsHelperService } from '@appShared/services/settings-helper.service';
import { DocumentPackagePermissions } from '@appPermissions/enums/document-package-permissions.enum';
import { DashboardItemResponseModel } from '@appShared/api/dashboard/models/dashboard.responce/dashboard-item.responce.model';
import { PermissionType } from '@appPermissions/enums/permission-type.enum';

@Component({
  selector: 'app-ea-link',
  templateUrl: './ea-link.component.html',
  styleUrls: ['./ea-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EaLinkComponent {
  @Input() documentPackage: DocumentPackageResponseModel | DashboardItemResponseModel;
  @Input() title: string;
  public eArchiveDocumentUrl = this.settingsHelperService.getSettingValueByCode(SettingCodeEnum.eArchiveDocumentUrl);
  public hasLinkEAPermission$ = this.permissionService.getPermissions(
    DocumentPackagePermissions.linkToCardElectronicArchive,
    PermissionType.documentPackage
  );
  constructor(private settingsHelperService: SettingsHelperService, private permissionService: PermissionsService) {}
}
