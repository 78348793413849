import { ErrorMessageModel } from '@models/error-message.model';
import { Action } from '@ngrx/store';

export enum ErrorMessageActionTypes {
  AddErrorMessage = '[Error Message] AddErrorMessage',
  ResetErrorMessage = '[Error Message] ResetErrorMessage'
}

export class AddErrorMessage implements Action {
  readonly type = ErrorMessageActionTypes.AddErrorMessage;

  constructor(public payload: ErrorMessageModel) {}
}

export class ResetErrorMessage implements Action {
  readonly type = ErrorMessageActionTypes.ResetErrorMessage;
}

export type ErrorMessageActions = AddErrorMessage | ResetErrorMessage;
