import { DocumentCompositionFileResponseModel } from '@appShared/api/document-composition/models/document-composition.response';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { AttachmentsActions, AttachmentsActionTypes } from './attachments.actions';

export interface AttachmentsState extends EntityState<DocumentCompositionFileResponseModel> {
  status: StoreLoadStatus;
  addStatus: StoreLoadStatus;
  deleteStatus: StoreLoadStatus;
  error: boolean;
}

const attachmentsAdapter = createEntityAdapter<DocumentCompositionFileResponseModel>({
  selectId: (e) => e.id,
});

export const initialState: AttachmentsState = attachmentsAdapter.getInitialState({
  status: null,
  addStatus: null,
  deleteStatus: null,
  error: null,
});

export function attachmentsReducer(
  state: AttachmentsState = initialState,
  action: AttachmentsActions
): AttachmentsState {
  switch (action.type) {
    case AttachmentsActionTypes.Load:
      return {
        ...state,
        status: StoreLoadStatus.inProgress,
      };

    case AttachmentsActionTypes.LoadSuccess:
      return attachmentsAdapter.setAll(action.payload, {
        ...state,
        status: StoreLoadStatus.loaded,
        error: false,
      });

    case AttachmentsActionTypes.LoadError:
      return {
        ...state,
        status: StoreLoadStatus.error,
        error: true,
      };

    case AttachmentsActionTypes.Delete: {
      return {
        ...state,
        deleteStatus: StoreLoadStatus.inProgress,
        error: false,
      };
    }

    case AttachmentsActionTypes.DeleteSuccess: {
      return attachmentsAdapter.removeOne(action.payload, {
        ...state,
        deleteStatus: StoreLoadStatus.loaded,
        error: false,
      });
    }

    case AttachmentsActionTypes.DeleteError: {
      return {
        ...state,
        deleteStatus: StoreLoadStatus.error,
        error: true,
      };
    }

    case AttachmentsActionTypes.Reset:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export const {
  selectAll: selectAll,
  selectTotal: selectCount,
  selectEntities: selectAttachmentEntities,
} = attachmentsAdapter.getSelectors();
