import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalWindowRef } from '@appShared/modals-helper/modal-window-ref';
import { DictionaryModel } from '@models/base/dictionary.model';

import { AppealsLogicService } from '@appPersonalCabinet/appeals/logic/appeals-logic.service';
import { AppealModel } from '@appPersonalCabinet/appeals/models/appeal-search-response.model';
import { ModalWindowService } from '@appShared/modals-helper/modal-window.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { APPEAL_TYPE_INCIDENT } from '../../const/appeal-types.const';
import { RedirectToAppealsPopupComponent } from '../redirect-to-appeals-popup/redirect-to-appeals-popup.component';

@Component({
  selector: 'app-create-appeal-popup',
  templateUrl: './create-appeal-popup.component.html',
  styleUrls: ['./create-appeal-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAppealPopupComponent implements OnInit {
  public appealTypes$ = this.appealsLogicService.getAppealTypes() as Observable<DictionaryModel[]>;
  public createAppealForm: FormGroup;
  public errorStackTrace: string;
  public submitButtonLabel: string;

  constructor(
    private modalWindowRef: ModalWindowRef,
    private modalWindowService: ModalWindowService,
    private fb: FormBuilder,
    private appealsLogicService: AppealsLogicService
  ) {}

  ngOnInit(): void {
    this.errorStackTrace = this.modalWindowRef.data.stackTrace;
    this.createAppealForm = this.initForm();
    this.submitButtonLabel = this.errorStackTrace ? 'отправить' : 'сохранить';
  }

  public closePopup(): void {
    this.modalWindowRef.close();
  }

  public onSaveAppeal(): void {
    if (this.createAppealForm.invalid) {
      this.createAppealForm.markAllAsTouched();
      return;
    }

    const reqBody = {
      ...this.createAppealForm.value,
      createdFromPersonalCabinet: !this.errorStackTrace,
      textError: this.errorStackTrace || null,
    };

    this.appealsLogicService
      .createNewAppeal(reqBody)
      .pipe(first())
      .subscribe((res) => {
        this.appealsLogicService.setNewAppeal(new AppealModel(res));
        if (this.errorStackTrace) {
          this.modalWindowService.open(RedirectToAppealsPopupComponent, {});
        }
        this.modalWindowRef.close();
      });
  }

  private initForm(): FormGroup {
    const typeValue = this.errorStackTrace ? APPEAL_TYPE_INCIDENT : null;
    return this.fb.group({
      type: [typeValue, Validators.required],
      topic: [null, [Validators.required, Validators.minLength(2)]],
      description: [null, [Validators.required, Validators.minLength(2)]],
    });
  }
}
