import { AttachmentLoadModel } from '@appShared/api/document-composition/models/document-composition.response/attachment-load.model';
import { DocumentCompositionFileResponseModel } from '@appShared/api/document-composition/models/document-composition.response/index';
import { Action } from '@ngrx/store';

export enum AttachmentsActionTypes {
  Load = '[Attachments] Load',
  LoadSuccess = '[Attachments] LoadSuccess',
  LoadError = '[Attachments] LoadError',

  Delete = '[Attachments] Delete',
  DeleteSuccess = '[Attachments] DeleteSuccess',
  DeleteError = '[Attachments] DeleteError',

  Reset = '[Attachments] Reset',
}

export class AttachmentsActionsLoad implements Action {
  readonly type = AttachmentsActionTypes.Load;
  constructor(public payload: AttachmentLoadModel) {}
}
export class AttachmentsActionsLoadSuccess implements Action {
  readonly type = AttachmentsActionTypes.LoadSuccess;
  constructor(public payload: DocumentCompositionFileResponseModel[]) {}
}
export class AttachmentsActionsLoadError implements Action {
  readonly type = AttachmentsActionTypes.LoadError;
  constructor(public error: any) {}
}
export class AttachmentsActionsDelete implements Action {
  readonly type = AttachmentsActionTypes.Delete;
  constructor(public payload: number) {}
}
export class AttachmentsActionsDeleteSuccess implements Action {
  readonly type = AttachmentsActionTypes.DeleteSuccess;
  constructor(public payload: number) {}
}
export class AttachmentsActionsDeleteErorr implements Action {
  readonly type = AttachmentsActionTypes.DeleteError;
  constructor(public payload: any) {}
}
export class AttachmentsActionsReset implements Action {
  readonly type = AttachmentsActionTypes.Reset;
}

export type AttachmentsActions =
  | AttachmentsActionsLoad
  | AttachmentsActionsLoadSuccess
  | AttachmentsActionsLoadError
  | AttachmentsActionsDelete
  | AttachmentsActionsDeleteSuccess
  | AttachmentsActionsDeleteErorr
  | AttachmentsActionsReset;
