/** Тип задачи */
export enum TaskType {
  /** согласование */
  agreement = 1,
  /** разработка */
  development = 2,
  /** доработка */
  revision = 3,
  /** исправление замечаний */
  correctionRemarks = 4,
  /** Отправка на согласование */
  sendToApprovement = 5,
  /** На контроле */
  underControl = 6,
  /** Запрос на соавторство */
  requestCoauthor = 7,
  /** Запрос на продление сроков */
  requestDeadline = 8,
  /** На ознакомлении */
  underIntroduce = 9,
  /** Запрос о назначении в соавторство */
  requestToBeCoauthor = 10,
}
