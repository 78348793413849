import { AddToTreeTypes } from '@appDocuments/documents-route/enums/add-to-tree-types.enum';
import { CoordinatorsConnectionType } from '@appDocuments/documents-route/enums/coordinators-connection-type.enum';
import { RoutePhaseTypes } from '@appDocuments/documents-route/enums/route-phase-types.enum';
import { TreeStepModel } from '@appDocuments/documents-route/models/tree/tree-step.model';
import { VertexResponseModel } from '@appShared/api/documents-route/models/route-tree.response/vertex.response.model';
import { EmployeeRouteTypes } from '../../enums/employee-route-types.enum';
import { RoutePopupTypes } from '../../enums/route-popup-types.enum';
import { DocumentPackageRouteActions, DocumentRoutePageActionTypes } from './document-route-page.actions';

export interface RoutePageState {
  popupType: RoutePopupTypes;
  metadata: VertexResponseModel;
  betweenMetadata: {
    before: VertexResponseModel;
    after: VertexResponseModel;
  };
  substitutionPoint: TreeStepModel;
  addToTreeType: AddToTreeTypes;
  employeeAddActiveType: EmployeeRouteTypes;
  availableAddTypes: EmployeeRouteTypes[];
  // TODO: временный параметр чтоб скрывать возможность выбора последовательных вбок
  hideConnectionType: boolean;
  coordinatorsConnectionType: CoordinatorsConnectionType;
  lockOrganization: boolean;
  phaseTypeId: RoutePhaseTypes;
  phaseId: number;
  phaseName: string;
  editRoute: boolean;
  editPhaseIds: number[];
  controllerId: number;
  coauthorId: number;
  templateId: number;
  parentPhaseTypeId: number;
  isDeadlineNotRequired: boolean;
  outstandingTaskFlag: boolean;
  scrollToActivePoint: boolean;
  routeId: number;
  parentPhaseId: number;
}

export const initialState: RoutePageState = {
  popupType: null,
  metadata: null,
  betweenMetadata: null,
  substitutionPoint: null,
  addToTreeType: AddToTreeTypes.addAfter,
  employeeAddActiveType: EmployeeRouteTypes.executor,
  availableAddTypes: [EmployeeRouteTypes.executor, EmployeeRouteTypes.coordinator, EmployeeRouteTypes.inspector],
  // TODO: временный параметр чтоб скрывать возможность выбора последовательных вбок
  hideConnectionType: false,
  coordinatorsConnectionType: CoordinatorsConnectionType.serial,
  lockOrganization: false,
  phaseTypeId: null,
  phaseId: null,
  phaseName: null,
  editRoute: false,
  editPhaseIds: [],
  controllerId: null,
  coauthorId: null,
  templateId: null,
  parentPhaseTypeId: null,
  isDeadlineNotRequired: false,
  outstandingTaskFlag: false,
  scrollToActivePoint: false,
  routeId: null,
  parentPhaseId: null,
};

export function documentRoutePageReducer(state = initialState, action: DocumentPackageRouteActions): RoutePageState {
  switch (action.type) {
    case DocumentRoutePageActionTypes.PopupOpen: {
      return {
        ...state,
        popupType: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.AddToTreeParams: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case DocumentRoutePageActionTypes.SetChangeEmployeePopup: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case DocumentRoutePageActionTypes.ChangeEmployeeActiveType: {
      return {
        ...state,
        employeeAddActiveType: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.UpdateConnectionType: {
      return {
        ...state,
        coordinatorsConnectionType: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.ChangeAvailableAddTypes: {
      return {
        ...state,
        availableAddTypes: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.EditRoute: {
      return {
        ...state,
        editRoute: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.EditPhaseIds: {
      return {
        ...state,
        editPhaseIds: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.CloseDssPopup: {
      if (state.popupType === RoutePopupTypes.dssWait) {
        return {
          ...state,
          popupType: null,
        };
      } else {
        return state;
      }
    }

    case DocumentRoutePageActionTypes.SetOutstandingTaskFlag: {
      return {
        ...state,
        outstandingTaskFlag: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.ScrollToActivePoint: {
      return {
        ...state,
        scrollToActivePoint: action.payload,
      };
    }

    case DocumentRoutePageActionTypes.EditRouteReset: {
      return {
        ...state,
        editRoute: false,
        editPhaseIds: [],
      };
    }

    default: {
      return state;
    }
  }
}
