import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

/** Справочник "типы вопросов рассмотрения" */

export enum ConsiderationQuestionsTypesActionTypes {
  Get = '[Consideration Questions Types] Get',
  GetSuccess = '[Consideration Questions Types] GetSuccess',
  GetError = '[Consideration Questions Types] GetError',
  Reset = '[Consideration Questions Types] Reset',
}

export class ConsiderationQuestionsTypesGet implements Action {
  readonly type = ConsiderationQuestionsTypesActionTypes.Get;
}

export class ConsiderationQuestionsTypesGetError implements Action {
  readonly type = ConsiderationQuestionsTypesActionTypes.GetError;
}

export class ConsiderationQuestionsTypesGetSuccess implements Action {
  readonly type = ConsiderationQuestionsTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class ConsiderationQuestionsTypesReset implements Action {
  readonly type = ConsiderationQuestionsTypesActionTypes.Reset;
}

export type ConsiderationQuestionsTypesAction =
  | ConsiderationQuestionsTypesGet
  | ConsiderationQuestionsTypesGetSuccess
  | ConsiderationQuestionsTypesGetError
  | ConsiderationQuestionsTypesReset;
