import { Injectable } from '@angular/core';
import {
  DocumentPackageRelationTypesActions,
  DocumentPackageRelationTypesActionTypes,
  DocumentPackageRelationTypesGetError,
  DocumentPackageRelationTypesGetSuccess,
} from '@appShared/store/dictionaries/document-package-relation-types/document-package-relation-types.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { CACHE_TIME } from '../../../../core/store/cache-time.const';
import { DictionaryApiService } from '../../../api/dictionary/dictionary.api.service';

@Injectable()
export class DocumentPackageRelationTypesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  
  GetRelationTypes$: Observable<DocumentPackageRelationTypesActions> = createEffect(() => this.actions$.pipe(
    ofType(DocumentPackageRelationTypesActionTypes.Get),
    throttleTime(CACHE_TIME),
    switchMap(() =>
      this.dictionaryApi.getDocumentPackageRelationTypes().pipe(
        map((response) => new DocumentPackageRelationTypesGetSuccess(response)),
        catchError(() => of(new DocumentPackageRelationTypesGetError()))
      )
    )
  ));
}
