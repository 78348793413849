import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';

export interface OverlayCloseEvent<R> {
  type: 'close';
  data: R;
}

export class ModalWindowRef<R = any, T = any> {
  readonly afterClosed$ = new Subject<OverlayCloseEvent<R>>();

  constructor(public overlay: OverlayRef, public content: string | TemplateRef<any> | Type<any>, public data: T) {}

  public close(data?: R): void {
    this._close('close', data);
  }

  private _close(type: 'close', data: R): void {
    this.overlay.dispose();
    this.afterClosed$.next({
      type,
      data,
    });

    this.afterClosed$.complete();
  }
}
