import {
  ReservationStatusesActionTypes,
  ReservationStatusesTypesAction,
} from '@appShared/store/dictionaries/reservation-statuses/reservation-statuses.actions';
import { DictionaryModel } from '@models/base/dictionary.model';
import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState = new DictionaryStoreModel<DictionaryModel>([], null, null);

export function reservationStatusesReducer(
  state = initialState,
  action: ReservationStatusesTypesAction
): DictionaryStoreModel<DictionaryModel> {
  switch (action.type) {
    case ReservationStatusesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case ReservationStatusesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, null, StoreLoadStatus.loaded);
    case ReservationStatusesActionTypes.GetError:
      return {
        ...initialState,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса */
export const getReservationStatusesLoad = (state) => state.loadStatus;
/** Получение словаря */
export const getReservationStatuses = (state) => state.dictionary;
