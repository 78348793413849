import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@appCore/services/http/http.service';
import { baseUrl, Urls } from '@appCore/urls/urls';
import { CompositionAddDocumentService } from '@appDocuments/documents-composition/services/composition-add-document.service';
import { ConsiderationTypes } from '@appMain/calendar-consideration/enums/consideration-types.enum';
import { QuestionAccessType } from '@appMain/calendar-consideration/enums/question-access-type.enum';
import { AddConsiderationQuestionWithoutDocumentPackage } from '@appMain/calendar-consideration/models/add-consideration-question-without-document-package.model';
import { ConsiderationDocumentChooseRevisionModel } from '@appMain/calendar-consideration/models/consideration-document-choose-revision.model';
import { ConsiderationDocumentModel } from '@appMain/calendar-consideration/models/consideration-document.model';
import { ConsiderationFindEventParams } from '@appMain/calendar-consideration/models/consideration-find-event-params.model';
import { ConsiderationSearchDocumentPackagesResultModel } from '@appMain/calendar-consideration/models/consideration-search-document-packeges-result.model';
import { DocumentWithPermissionsModel } from '@appMain/calendar-consideration/models/DocumentWithPermissonsModel';
import { EditConsiderationQuestionRequest } from '@appMain/calendar-consideration/models/edit-consideration-question.request.model';
import {
  CalendarDocumentPackageQuestionDocument,
  QuestionDocumentsListModel,
} from '@appMain/calendar-consideration/models/question-documents-list.model';
import { DocumentVersionListResponseModel } from '@appShared/api/document-composition/models/document-composition.response/document-version-list-response.model';
import { DictionaryModel } from '@models/base/dictionary.model';
import { AddConsiderationMaterialRequestModel } from '@models/calendar-consideration/calendar-consideration.request/add-consideration-material.request.model';
import { AddConsiderationParticipantRequestModel } from '@models/calendar-consideration/calendar-consideration.request/add-consideration-participant.request.model';
import { AddConsiderationQuestionRequestModel } from '@models/calendar-consideration/calendar-consideration.request/add-consideration-question.request.model';
import { AddConsiderationRequestModel } from '@models/calendar-consideration/calendar-consideration.request/add-consideration.request.model';
import { ConsiderationQuestionChangeIndexRequestModel } from '@models/calendar-consideration/calendar-consideration.request/consideration-question-change-index-request.model';
import { ConsiderationQuestionResolutionRequestModel } from '@models/calendar-consideration/calendar-consideration.request/consideration-question-resolution.request.model';
import { GetConsiderationRequestModel } from '@models/calendar-consideration/calendar-consideration.request/get-consideration.request.model';
import { SearchDocumentPackagesRequestModel } from '@models/calendar-consideration/calendar-consideration.request/search-document-packages.request.model';
import { ConsiderationEventResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-event.response.model';
import { ConsiderationMaterialResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-material.response.model';
import { ConsiderationParticipantResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-participant.response.model';
import { ConsiderationQuestionResolutionResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-question-resolution.response.model';
import { ConsiderationQuestionResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration-question.response.model';
import { ConsiderationResponseModel } from '@models/calendar-consideration/calendar-consideration.response/consideration.response.model';
import { LdeEventModel } from '@models/lde/lde-event.model';
import { Observable } from 'rxjs';
import { DocumentsPrintRequestModel } from '../document-composition/models/document-composition.request/documents-print-request.model';

@Injectable({ providedIn: 'root' })
export class CalendarConsiderationApiService {
  private readonly documentsUrl = `${baseUrl}/calendar/documents/`;

  constructor(private httpService: HttpService, private addDocumentService: CompositionAddDocumentService) {}

  /** Создание рассмотрения */
  public postConsideration(data: AddConsiderationRequestModel): Observable<ConsiderationResponseModel> {
    const url: string = Urls.getOrPostOrPutConsideration();

    return this.httpService.postObservable(url, data);
  }

  /** Редактирование рассмотрения */
  public editConsideration(data: ConsiderationResponseModel): Observable<ConsiderationResponseModel> {
    const url: string = Urls.getOrPostOrPutConsideration();

    return this.httpService.putObservable(url, data);
  }

  /** Получение рассмотрения */
  public getConsiderationGeneralInfo(params: GetConsiderationRequestModel): Observable<ConsiderationResponseModel> {
    const url: string = Urls.getOrPostOrPutConsideration();

    return this.httpService.getObservable(url, {
      params,
    });
  }

  /** Добавление материала к рассмотрению */
  public postConsiderationMaterial(
    data: AddConsiderationMaterialRequestModel
  ): Observable<ConsiderationMaterialResponseModel> {
    const url: string = Urls.getOrPostOrPutConsiderationMateraials();

    const formData: FormData = new FormData();
    formData.append('attachment', data.attachment, data.attachment.name);
    formData.append('agendaPackageID', `${data.agendaPackageID}`);

    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
    };

    return this.httpService.postObservable(url, formData, options);
  }

  /** Получение материалов к рассмотрению */
  public getConsiderationMaterials(considerationId: number): Observable<ConsiderationMaterialResponseModel[]> {
    const url: string = Urls.getOrPostOrPutConsiderationMateraials();

    return this.httpService.getObservable(url, {
      params: { agendaPackageId: considerationId },
    });
  }

  /** Удаление материалов */
  public deleteMaterial(id: number): Observable<null> {
    const url: string = Urls.deleteConsiderationMaterial(id);

    return this.httpService.deleteObservable(url);
  }

  /** Получение участников пакета повестки */
  public getConsiderationParticipants(considerationId: number): Observable<ConsiderationParticipantResponseModel[]> {
    const url: string = Urls.getConsiderationParticipants();

    return this.httpService.getObservable(url, {
      params: { agendaPackageId: considerationId },
    });
  }

  /** Добавление участника пакета повестки */
  public postConsiderationParticipant(
    data: AddConsiderationParticipantRequestModel
  ): Observable<ConsiderationParticipantResponseModel> {
    const url: string = Urls.postOrPutConsiderationParticipant();

    return this.httpService.postObservable(url, data);
  }

  /** Удаление участника */
  public deleteParticipant(id: number): Observable<null> {
    const url: string = Urls.deleteConsiderationParticipant(id);

    return this.httpService.deleteObservable(url);
  }

  /** Редактирование участника пакета повестки */
  public putConsiderationParticipant(
    data: ConsiderationParticipantResponseModel
  ): Observable<ConsiderationParticipantResponseModel> {
    const url: string = Urls.postOrPutConsiderationParticipant();

    return this.httpService.putObservable(url, data);
  }

  /** Поиск пакетов документа */
  public searchDocumentPackeges(
    searchParams: SearchDocumentPackagesRequestModel
  ): Observable<ConsiderationSearchDocumentPackagesResultModel> {
    const url: string = Urls.searchDocumentPackegesforConsiderationQuestions();
    return this.httpService.getObservable(url, {
      params: { ...searchParams, page: 0, pageSize: 100000 },
    });
  }

  /** Добавление вопроса пакета повестки */
  public postConsiderationQuestions(
    data: AddConsiderationQuestionRequestModel
  ): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.postOrPutConsiderationQuestion();

    return this.httpService.postObservable(url, data);
  }

  /** Добавление решения к вопросу пакета повестки */
  public postConsiderationQuestionsResolution(
    data: ConsiderationQuestionResolutionRequestModel
  ): Observable<ConsiderationQuestionResolutionResponseModel> {
    const url: string = Urls.considerationQuestionResolution();

    return this.httpService.postObservable(url, data);
  }

  /** Обновление решения к вопросу пакета повестки для не согласительных совещаний*/
  public patchConsiderationQuestionsResolution(
    data: ConsiderationQuestionResolutionRequestModel
  ): Observable<ConsiderationQuestionResolutionResponseModel> {
    const url: string = Urls.editConsiderationQuestionResolution(data?.id);

    return this.httpService.patchObservable(url, { type: { ...data.type } });
  }

  /** Обновление решения к вопросу пакета повестки для согласительных совещаний*/
  public putConsiderationQuestionsResolution(
    data: ConsiderationQuestionResolutionRequestModel
  ): Observable<ConsiderationQuestionResolutionResponseModel> {
    const url: string = Urls.considerationQuestionResolution();

    return this.httpService.putObservable(url, data);
  }

  /** Получение вопросов к рассмотрению */
  public getConsiderationQuestions(considerationId: number): Observable<ConsiderationQuestionResponseModel[]> {
    const url: string = Urls.getConsiderationQuestionsList();

    return this.httpService.getObservable(url, {
      params: { agendaPackageId: considerationId },
    });
  }

  /** Получение вопроса */
  public getQuestion(id: number): Observable<null> {
    const url: string = Urls.getConsiderationQuestion(id);

    return this.httpService.getObservable(url);
  }

  /** Удаление вопроса */
  public deleteQuestion(id: number): Observable<null> {
    const url: string = Urls.deleteConsiderationQuestion(id);

    return this.httpService.deleteObservable(url);
  }

  /** Редактирование вопроса пакета повестки */
  public putConsiderationQuestion(
    data: ConsiderationQuestionResponseModel
  ): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.postOrPutConsiderationQuestion();

    return this.httpService.putObservable(url, data);
  }

  /** Множественное редактирование вопроса пакета повестки */
  public putConsiderationAndResolutionQuestion(
    data: EditConsiderationQuestionRequest
  ): Observable<ConsiderationQuestionResponseModel[]> {
    const url: string = Urls.considerationQuestionAndResolution();

    return this.httpService.putObservable(url, data);
  }

  /** Изменение порядкового номера вопроса */
  public changeConsiderationQuestionsIndexOrType(
    questionInfo: ConsiderationQuestionChangeIndexRequestModel
  ): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.changeConsiderationQuestionIndexOrType(questionInfo.questionId);

    return this.httpService.getObservable(url, {
      params: { newIndex: questionInfo.newIndex, newTypeId: questionInfo.newTypeId },
    });
  }

  /** Поиск пакетов документа */
  public getDocumentPackegesInfo(
    ids: number[],
    agendaPackageId?: number
  ): Observable<ConsiderationSearchDocumentPackagesResultModel> {
    const url: string = Urls.searchDocumentPackegesforConsiderationQuestions();

    const params = { idArray: ids, page: 0, pageSize: 100000 };

    const paramsMutable = agendaPackageId ? { ...params, forAgendaPackageId: agendaPackageId } : params;

    return this.httpService.getObservable(url, {
      params: paramsMutable,
    });
  }

  /** Получение документов в Пакете повестки */
  public findCalendarConsiderationDocuments(agendaPackageId: number): Observable<DocumentWithPermissionsModel[]> {
    const url = `${this.documentsUrl}find?agendaPackageId=${agendaPackageId}`;
    return this.httpService.getObservable(url);
  }

  public chooseGeneralInfoRevision$(
    data: ConsiderationDocumentChooseRevisionModel
  ): Observable<ConsiderationDocumentChooseRevisionModel> {
    const url: string = Urls.chooseGeneralInfoRevision();

    return this.httpService.postObservable(url, data);
  }

  public deleteGeneralInfoRevision$(
    documentId: number,
    agendaPackageId: number
  ): Observable<ConsiderationDocumentChooseRevisionModel> {
    const url: string = Urls.deleteGeneralInfoRevision(documentId, agendaPackageId);

    return this.httpService.deleteObservable(url);
  }

  /** Получение актуальной версии документа в Пакете повестки */
  public getCalendarConsiderationDocumentVersions(
    documentId: number,
    agendaPackageId: number
  ): Observable<DocumentVersionListResponseModel> {
    const url = Urls.calendarDocumentVersions(documentId);

    return this.httpService.getObservable(url, {
      params: {
        agendaPackageId,
      },
    });
  }

  /** Создать документ в Пакете повестки */
  public postCalendarConsiderationDocument(data: ConsiderationDocumentModel): Observable<ConsiderationDocumentModel> {
    return this.httpService.postObservable(this.documentsUrl, data);
  }

  /** Удалить документ в Пакете повестки */
  public deleteCalendarConsiderationDocument(documentId: number): Observable<never> {
    const url: string = this.documentsUrl + documentId;
    return this.httpService.deleteObservable(url);
  }

  public printConsiderationDocument(data: DocumentsPrintRequestModel): Observable<LdeEventModel> {
    const url: string = this.documentsUrl + 'print';
    return this.httpService.postObservable(url, data);
  }

  public postCalendarDocumentsImport(params: string, attachedFile: File): Observable<ConsiderationDocumentModel> {
    const url: string = Urls.postCalendarDocumentsImport(params);

    const extension = this.addDocumentService.getFileExtension(attachedFile.name);
    const formData: FormData = new FormData();
    const attachedFileWithContentType: Blob = attachedFile.slice(
      0,
      attachedFile.size,
      this.addDocumentService.getMimeTypeByExtension(extension)
    );
    formData.append('attachment', attachedFileWithContentType, attachedFile.name);

    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
    };

    return this.httpService.postObservable(url, formData, options);
  }

  public sortConsiderationQuestionsByPublishing(
    agendaPackageId: number,
    massMediaFirst: boolean
  ): Observable<ConsiderationQuestionResponseModel[]> {
    const url: string = Urls.sortConsiderationQuestions();

    return this.httpService.putObservable(url, null, {
      params: { agendaPackageId, massMediaFirst },
    });
  }

  /** Поиск событий по месту и времени проведения */
  public findEventByPlaceAndTime(params: ConsiderationFindEventParams): Observable<ConsiderationEventResponseModel[]> {
    const url: string = Urls.findEventByPlaceAndTime();
    return this.httpService.getObservable(url, { params });
  }

  public findMeetengBySelectedDay(date: string): Observable<any> {
    const url = Urls.findMeetengBySelectedDay();
    return this.httpService.getObservable(url, { params: { date } });
  }

  /** Получить список мест проведения заседания */
  public getConsiderationPlacesName(): Observable<DictionaryModel[]> {
    const url = Urls.considerationPlaces();

    return this.httpService.getObservable(url);
  }

  public editConsiderationPlacesName(place: DictionaryModel): Observable<DictionaryModel> {
    const url = Urls.considerationPlaces();

    return this.httpService.putObservable(url, place);
  }

  /** Формирование вопроса */
  public formQuestion(id: number): Observable<null> {
    const url: string = Urls.formConsiderationQuestion(id);

    return this.httpService.postObservable(url);
  }

  /** Пересоздать вопросы для пакета повестки */
  public reCreateQuestion(id: number): Observable<null> {
    const url: string = Urls.reCreateConsiderationQuestion(id);

    return this.httpService.postObservable(url);
  }

  public sendInvites(invites: number[]): Observable<null> {
    const url = `${baseUrl}/calendar/agenda-package/participants/send-invites`;

    return this.httpService.postObservable(url, invites);
  }

  public refreshDocument(documentId: number, agendaPackageId: number): Observable<ConsiderationDocumentModel> {
    const url = `${baseUrl}/calendar/documents/${documentId}/refresh?agendaPackageId=${agendaPackageId}`;

    return this.httpService.postObservable(url);
  }

  /** Включение вопроса в итоговую повестку */
  public includeQuestionFinalAgenda(questionId: number): Observable<null> {
    const url: string = Urls.includeQuestionFinalAgenda(questionId);

    return this.httpService.postObservable(url);
  }

  public getCalendarQuestionDocuments(params: {
    agendaPackageId: number;
    questionId: number;
  }): Observable<CalendarDocumentPackageQuestionDocument> {
    const url: string = Urls.getCalendarQuestionDocuments();

    return this.httpService.getObservable(url, {
      params,
    });
  }

  public changeConsiderationQuestionMeetingDate(
    questionId: number,
    newDate: string
  ): Observable<ConsiderationQuestionResponseModel> {
    const url = Urls.postConsiderationQuestionMeetingDate(questionId);
    const params = new HttpParams().append('newEventDate', newDate);

    return this.httpService.postObservable<ConsiderationQuestionResponseModel>(url, questionId, { params });
  }

  public changeQuestionAccessType(
    questionId: number,
    status: QuestionAccessType
  ): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.changeQuestionAccessType(questionId);

    return this.httpService.patchObservable(url, {
      accessType: status,
    });
  }

  public changeDocumentAccessType(questionId: number, documentId: number, status: QuestionAccessType): Observable<any> {
    const url: string = Urls.changeDocumentAccessType(questionId, documentId);

    return this.httpService.patchObservable(url, {
      accessType: status,
    });
  }

  public changeTypeIndexQuestion(questionId: number, param: string): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.changeTypeIndexQuestion(questionId, param);

    return this.httpService.getObservable(url);
  }

  /** Добавление вопроса ПП без связи с ПД */
  public addConsiderationQuestionWithoutDocumentPackage(
    question: AddConsiderationQuestionWithoutDocumentPackage
  ): Observable<ConsiderationQuestionResponseModel> {
    const url: string = Urls.addQuestionWithoutDocumentPackage();

    return this.httpService.postObservable(url, question);
  }

  public getMeetingsDates(selectedConsiderationTypeId: ConsiderationTypes): ConsiderationTypes | ConsiderationTypes[] {
    const meetings = [ConsiderationTypes.meetingPM, ConsiderationTypes.meetingPPM];
    return meetings.includes(selectedConsiderationTypeId) ? meetings : selectedConsiderationTypeId;
  }

  public addAccompanyingMaterial(payload: {
    question: ConsiderationQuestionResponseModel;
    file: File;
    name: string;
  }): Observable<QuestionDocumentsListModel> {
    const { question, file, name } = payload;

    const url: string = Urls.addAccompanyingMaterial(question.id);

    const extension = this.addDocumentService.getFileExtension(file.name);

    const formData: FormData = new FormData();

    formData.append('name', name);
    formData.append('format', extension);

    const attachedFileWithContentType: Blob = file.slice(
      0,
      file.size,
      this.addDocumentService.getMimeTypeByExtension(extension)
    );
    formData.append('attachment', attachedFileWithContentType, file.name);
    formData.append('accessType', question.accessType);

    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data'),
    };

    return this.httpService.postObservable(url, formData, options);
  }

  public findAccompanyingMaterials({ questionId }: { questionId: number }): Observable<QuestionDocumentsListModel[]> {
    const url: string = Urls.findAccompanyingMaterials();

    return this.httpService.getObservable(url, {
      params: {
        questionId,
      },
    });
  }

  public deleteAccompanyingMaterial(questionId: number, documentId: number) {
    const url: string = Urls.deleteAccompanyingMaterial();

    return this.httpService.deleteObservable(url, {
      params: {
        questionId,
        documentId,
      },
    });
  }
}
