import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionsModule } from '@appPermissions/permissions.module';
import { TooltipModule } from '@appShared/components/tooltip/tooltip.module';
import { MenuComponent } from '@appShared/components/menu/menu.component';
import { GetExternalUrlPipe } from '@appShared/components/menu/pipes/get-external-url.pipe';
import { IconClassNamePipe } from '@appShared/components/menu/pipes/icon-class-name.pipe';
import { IsShowNotificationsCounterPipe } from '@appShared/components/menu/pipes/is-show-notifications-counter.pipe';
import { IsCreateDocumentRoutePipe } from '@appShared/components/menu/pipes/is-create-document-route.pipe';
import { GetRouterLinkPipe } from './pipes/get-router-link.pipe';

const components = [MenuComponent];

const pipes = [
  GetExternalUrlPipe,
  IconClassNamePipe,
  IsShowNotificationsCounterPipe,
  IsCreateDocumentRoutePipe,
  GetRouterLinkPipe,
];

@NgModule({
  imports: [CommonModule, RouterModule, PermissionsModule, TooltipModule],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
})
export class MenuModule {}
