import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsGetCount } from '@appCore/store/notifications/notifications.actions';
import { NotificationType } from '@appMain/notifications/enums/notification-type.enum';
import { transformDelegating } from '@appShared/helpers/transform-delegating';
import { employeeShortName } from '@appShared/pipes/employee-short-name.pipe';
import { NotificationCreatedEventModel } from '@models/notifications/notifications.response/notification-created-event.model';
import { ToastModel } from '../../models/toast.model';
import { ReduxService } from '../redux/redux.service';
import { ToastsService } from '../toasts/toasts.service';
import { SocketChannels } from '../websocket/constants/channel-names';
import { WebSocketService } from '../websocket/websocket.service';

/**
 * Сервис для инициализации нотификаций и обработки событий приходящих по websocket
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private websocket: WebSocketService,
    private toasts: ToastsService,
    private redux: ReduxService,
    private router: Router
  ) {}

  public getNotifications(): void {
    this.redux.dispatchAction(new NotificationsGetCount());
    this.websocket.selectChanel(SocketChannels.notificationsChanel).subscribe((notification) => {
      const allowedNotificationTypesForReload = [
        NotificationType.removeAccessToSubselection,
        NotificationType.removeAccessToFolder,
      ];
      if (allowedNotificationTypesForReload.includes(notification.notificationType.id)) {
        this.router.navigate([], {
          skipLocationChange: true,
        });
      }

      const notificationData = this.mapNotificationCreatedEventToToast(notification);
      this.toasts.showToast(notificationData);
      this.redux.dispatchAction(new NotificationsGetCount());
    });
  }

  /** Мапинг данных в тост */
  private mapNotificationCreatedEventToToast(notification: NotificationCreatedEventModel): ToastModel {
    return {
      text: notification.text,
      header: notification.header,
      author: this.getAuthorData(notification),
      projectTypeShortName: notification.projectType ? notification.projectType.shortName : '',
      date: notification.date,
      notificationId: notification.notificationId,
      isError: false,
      notificationType: notification.notificationType,
      projectGroupType: notification?.projectType?.projectGroupType,
      projectTypeDesignation: notification?.projectType?.designation,
      theme: notification.theme,
      accountNumber: notification.documentPackageNumber,
      documentPackageId: notification.documentPackageId,
      folderId: notification.folderId,
      deadlineExtensionRequestId: notification.deadlineExtensionRequestId,
      agendaPackageId: notification.agendaPackageId,
      placeholdersOrder: this.placeholdersOrderMapper(notification.placeholdersOrder),
      delegatingId: notification.delegatingId,
    };
  }

  /** Формирование данных об авторе уведомления */
  private getAuthorData(notification: NotificationCreatedEventModel): string {
    const author = employeeShortName(notification.author);
    const realAuthor = employeeShortName(notification.realAuthor);
    const isDelegate =
      notification?.author?.id !== notification?.realAuthor?.id || notification?.substituteEmployee?.id;
    const delegateType = transformDelegating(notification.isActingAuthor);
    const substituteEmployee = employeeShortName(notification?.substituteEmployee);
    const delegatedAuthor = !!substituteEmployee ? substituteEmployee : author;

    return isDelegate ? `${realAuthor} ${delegateType} ${delegatedAuthor}` : author;
  }

  private placeholdersOrderMapper(source: { [key: string]: number }): Map<number, number> {
    const placeholders = new Map();
    for (const key in source) {
      if (Object.hasOwn(source, key)) {
        placeholders.set(Number(key), source[key]);
      }
    }

    return placeholders;
  }
}
