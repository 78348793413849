import { Action } from '@ngrx/store';
import { DictionaryWithShortNameModel } from '@models/base/dictionary-with-short-name.model';

export enum OrganizationsActionTypes {
  Get = '[Organizations] Get',
  GetForCurrentUser = '[Organizations] GetForCurrentUser',
  GetSuccess = '[Organizations] GetSuccess',
  GetError = '[Organizations] GetError',
  Reset = '[Organizations] Reset',
}

/** запросить список организаций */
export class OrganizationsGet implements Action {
  readonly type = OrganizationsActionTypes.Get;
}

export class OrganizationsGetForCurrentUser implements Action {
  readonly type = OrganizationsActionTypes.GetForCurrentUser;
}
/** получить исключения */
export class OrganizationsGetError implements Action {
  readonly type = OrganizationsActionTypes.GetError;
}

/** получить список организаций  */
export class OrganizationsGetSuccess implements Action {
  readonly type = OrganizationsActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithShortNameModel[]) {}
}

/** сбросить список организаций */
export class OrganizationsReset implements Action {
  readonly type = OrganizationsActionTypes.Reset;
}

export type OrganizationsActions =
  | OrganizationsGet
  | OrganizationsGetForCurrentUser
  | OrganizationsGetSuccess
  | OrganizationsGetError
  | OrganizationsReset;
