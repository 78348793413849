import { DocumentsAsideAction, DocumentsAsideActionTypes } from './document-aside.actions';

/** модель состояния левого сайдбара документов */
export interface DocumentsAsideState {
  isNarrowBar: boolean;
}

const fromLocalStorage: DocumentsAsideState = JSON.parse(localStorage.getItem('documentsAside'));

/** базовое состояние левого сайдбара документов */
export const initialState: DocumentsAsideState = {
  isNarrowBar: fromLocalStorage ? fromLocalStorage.isNarrowBar : false
};

/** редьюсер для генерации новых состояний левого сайдбара документов */
export function documentsAsideReducer(
  state: DocumentsAsideState = initialState,
  action: DocumentsAsideAction
): DocumentsAsideState {
  switch (action.type) {
    /** состояние широкого сайдбара */
    case DocumentsAsideActionTypes.ExpandAsideBar:
      return {
        ...state,
        isNarrowBar: false
      };

    /** состояние узкого сайдбара */
    case DocumentsAsideActionTypes.NarrowAsideBar:
      return {
        ...state,
        isNarrowBar: true
      };

    default:
      return state;
  }
}
