import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { NomenclatureModel } from '@models/nomenclatures/nomenclature.model';
import { NomenclaturesAction, NomenclaturesActionTypes } from './nomenclatures.actions';

export const initialState = new DictionaryStoreModel<NomenclatureModel>([], null);

export const nomenclaturesReducer = (
  state: DictionaryStoreModel<NomenclatureModel> = initialState,
  action: NomenclaturesAction
): DictionaryStoreModel<NomenclatureModel> => {
  switch (action.type) {
    case NomenclaturesActionTypes.Get:
      return {
        ...state,
        saveStatus: StoreLoadStatus.inProgress,
      };
    case NomenclaturesActionTypes.GetSuccess:
      return new DictionaryStoreModel(action.payload, StoreLoadStatus.loaded);
    case NomenclaturesActionTypes.GetError:
      return {
        ...initialState,
        saveStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
};

export const getSaveStatus = (state) => state.saveStatus;
export const getNomenclatures = (state): NomenclatureModel[] =>
  state.dictionary.filter((item) => !item.deleted && !item.markedDeleted);
