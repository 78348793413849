import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getIsLocalLegalAct } from '@appDocuments/core/store/document-package';
import { takeUntil } from 'rxjs/operators';

/** Отобразит элемент, если текущий ПД в сторе не является Локальным Правовым Актом (ЛПА) */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfNotLocalLegalAct]',
})
export class ShowIfNotLocalLegalActDirective extends Unsubscriber implements OnInit, OnDestroy {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private redux: ReduxService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.redux
      .selectStore(getIsLocalLegalAct)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isLocalAct) => {
        if (isLocalAct) {
          this.viewContainer.clear();
          this.hasView = false;
          return;
        }
        if (!this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }
}
