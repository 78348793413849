import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

export enum DocumentPackageRelationTypesActionTypes {
  Get = '[Document Package Relation Types] Get',
  GetSuccess = '[Document Package Relation Types] GetSuccess',
  GetError = '[Document Package Relation Types] GetError',
  Reset = '[Document Package Relation Types] Reset',
}

export class DocumentPackageRelationTypesGet implements Action {
  readonly type = DocumentPackageRelationTypesActionTypes.Get;
}

export class DocumentPackageRelationTypesGetError implements Action {
  readonly type = DocumentPackageRelationTypesActionTypes.GetError;
}

export class DocumentPackageRelationTypesGetSuccess implements Action {
  readonly type = DocumentPackageRelationTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class DocumentPackageRelationTypesReset implements Action {
  readonly type = DocumentPackageRelationTypesActionTypes.Reset;
}

export type DocumentPackageRelationTypesActions =
  | DocumentPackageRelationTypesGet
  | DocumentPackageRelationTypesGetSuccess
  | DocumentPackageRelationTypesGetError
  | DocumentPackageRelationTypesReset;
