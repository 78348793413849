import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { AgreementRouteTemplateRequestModel } from '@appShared/api/route-template/models/route-template.request.model';
import { AgreementRouteTemplateResponseModel } from '@appShared/api/route-template/models/route-template.response.model';
import { Action } from '@ngrx/store';

export enum RouteTemplateActionTypes {
  RouteTemplateReset = '[Route Template] RouteTemplateReset',
  RouteTemplateGet = '[Route Template] RouteTemplateGet',
  RouteTemplateGetSuccess = '[Route Template] RouteTemplateGetSuccess',
  RouteTemplateCreate = '[Route Template] RouteTemplateCreate',
  RouteTemplateCreateSuccess = '[Route Template] RouteTemplateCreateSuccess',
  RouteTemplateApply = '[Route Template] RouteTemplateApply',
  RouteTemplateApplySuccess = '[Route Template] RouteTemplateApplySuccess',
  RouteTemplateCreateInitiation = '[Route Template] RouteTemplateCreateInitiation',
  RouteTemplateApplyInitiation = '[Route Template] RouteTemplateApplyInitiation',
  RouteTemplateActivePoints = '[Route Template] RouteTemplateActivePoints',
  RouteTemplateActivePointsSuccess = '[Route Template] RouteTemplateActivePointsSuccess',
  RouteTemplateError = '[Route Template] RouteTemplateError',
}

export class RouteTemplateReset implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateReset;
}

export class RouteTemplateGet implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateGet;
}

export class RouteTemplateGetSuccess implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateGetSuccess;

  constructor(public payload: AgreementRouteTemplateResponseModel[]) {}
}

export class RouteTemplateCreate implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateCreate;

  constructor(public payload: AgreementRouteTemplateRequestModel) {}
}

export class RouteTemplateCreateSuccess implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateCreateSuccess;

  constructor(public payload: AgreementRouteTemplateResponseModel) {}
}

export class RouteTemplateApply implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateApply;

  constructor(public payload: number) {}
}

export class RouteTemplateApplySuccess implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateApplySuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteTemplateCreateInitiation implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateCreateInitiation;

  constructor(public payload: AgreementRouteTemplateRequestModel) {}
}

export class RouteTemplateApplyInitiation implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateApplyInitiation;

  constructor(public payload: number) {}
}

export class RouteTemplateActivePoints implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateActivePoints;
}

export class RouteTemplateActivePointsSuccess implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateActivePointsSuccess;

  constructor(public payload: RouteTreeResponseModel) {}
}

export class RouteTemplateError implements Action {
  readonly type = RouteTemplateActionTypes.RouteTemplateError;

  constructor(public payload: any) {}
}

export type RouteTemplateActions =
  | RouteTemplateReset
  | RouteTemplateGet
  | RouteTemplateGetSuccess
  | RouteTemplateCreate
  | RouteTemplateCreateSuccess
  | RouteTemplateCreateInitiation
  | RouteTemplateError;
