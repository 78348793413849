import { DocumentPackagePublicationsAction, DocumentPackagePublicationsActionTypes } from '@appDocuments/core/store/document-package-publications/document-package-publications.actions';
import { DocumentPackagePublicationsStoreModel } from '@appDocuments/core/store/document-package-publications/document-package-publications.store.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';

export const initialState = new DocumentPackagePublicationsStoreModel(null, null, null);

export function documentPackagePublicationsReducer(
  state: DocumentPackagePublicationsStoreModel = initialState,
  action: DocumentPackagePublicationsAction
): DocumentPackagePublicationsStoreModel {
  switch (action.type) {
    case DocumentPackagePublicationsActionTypes.Get:
      return {
        ...initialState,
        status: StoreLoadStatus.inProgress
      };

    case DocumentPackagePublicationsActionTypes.GetSuccess:
      return {
        ...state,
        publications: action.payload,
        status: StoreLoadStatus.loaded
      };

    case DocumentPackagePublicationsActionTypes.GetError:
      return {
        ...state,
        status: StoreLoadStatus.error
      };
    case DocumentPackagePublicationsActionTypes.Update:
      return {
        ...state,
        status: StoreLoadStatus.inProgress
      };

    case DocumentPackagePublicationsActionTypes.UpdateSuccess:
      return {
        ...state,
        publications: action.payload,
        status: StoreLoadStatus.loaded
      };

    case DocumentPackagePublicationsActionTypes.UpdateError:
      return {
        ...state,
        error: action.error,
        status: StoreLoadStatus.error
      };
    default:
      return state;
  }
}
