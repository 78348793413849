import { Component, OnInit } from '@angular/core';
import { ButtonBgColors } from '../../../enums/button-bg-colors.enum';
import { ButtonBaseComponent } from '../button-base/button-base.component';

/** компонент многоугольной кнопки */
@Component({
  selector: 'app-polyhedral-button',
  templateUrl: './polyhedral-button.component.html',
  styleUrls: ['../button-base/button-base.component.scss', './polyhedral-button.component.scss']
})
export class PolyhedralButtonComponent extends ButtonBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.bgColorClassName) {
      this.bgColorClassName = ButtonBgColors.gray;
    }
  }
}
