import { Action } from '@ngrx/store';
import { DictionaryModel } from '@models/base/dictionary.model';

export enum AgreementRoutePhasesActions {
  Get = '[Agreement Route Phases] Get',
  GetSuccess = '[Agreement Route Phases] GetSuccess',
  GetError = '[Agreement Route Phases] GetError',
  Reset = '[Agreement Route Phases] Reset',
}

export class AgreementRoutePhasesGet implements Action {
  readonly type = AgreementRoutePhasesActions.Get;
}

export class AgreementRoutePhasesGetError implements Action {
  readonly type = AgreementRoutePhasesActions.GetError;
}

export class AgreementRoutePhasesGetSuccess implements Action {
  readonly type = AgreementRoutePhasesActions.GetSuccess;
  constructor(public payload: DictionaryModel[]) {}
}

export class AgreementRoutePhasesReset implements Action {
  readonly type = AgreementRoutePhasesActions.Reset;
}

export type AgreementRoutePhasesAction =
  | AgreementRoutePhasesGet
  | AgreementRoutePhasesGetSuccess
  | AgreementRoutePhasesGetError
  | AgreementRoutePhasesReset;
