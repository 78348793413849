import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br'
})
/** конвертация \n в <br> с html-безопасным выхлопом */
export class Nl2BrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    value = String(value);
    value = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
