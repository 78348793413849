import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputCalendarAnimations } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar-window.animations';
import { InputCalendarBaseComponent } from '@appShared/components/controls/input-calendar/components/input-calendar-base-component/input-calendar.base';
import { CalendarIconTypes } from '@appShared/components/controls/input-calendar/enums/calendar-icon-types.enum';
import { UserInputStreamModel } from '@appShared/components/controls/input-calendar/models/date-picker.model';
import { DatePickerService } from '@appShared/components/controls/input-calendar/services/date-picker.service';
import { DomEventService } from '@appShared/services/dom-event.service';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CalendarWindowStates } from '../../enums/calendar-window-states.enum';

@Component({
  selector: 'app-input-calendar-only-icon',
  templateUrl: './input-calendar-only-icon.component.html',
  styleUrls: ['./input-calendar-only-icon.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCalendarOnlyIconComponent),
      multi: true,
    },
  ],
  animations: InputCalendarAnimations,
})
export class InputCalendarOnlyIconComponent extends InputCalendarBaseComponent implements OnInit, OnDestroy {
  protected userInputStream: Subject<UserInputStreamModel> = new Subject();

  @Input() iconType: CalendarIconTypes = CalendarIconTypes.bigIcon;

  @Output() onDateConfirmed: EventEmitter<string> = new EventEmitter();
  @Output() calendarWindowState = new EventEmitter<CalendarWindowStates>();

  public selectedDate: moment.Moment;
  public calendarPosition$ = new BehaviorSubject<{ left: string; top: string }>({ left: '0', top: '0' });

  /** Сделано через стрелочную функцию, т.к. иначе прослушка не отменяется */
  private scrollEventListener = (ev) => this.calendarState$.value === CalendarWindowStates.open && ev.preventDefault();

  constructor(private calendarService: DatePickerService, domEventService: DomEventService, elementRef: ElementRef) {
    super(domEventService, elementRef);
  }

  ngOnInit() {
    this.subscribeToUserInputStream();
    /** Выставление позиции fixed, чтоб календарь всегда всплывал поверх окна. */
    this.calendarState$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.calendarPosition$.next(this.getPosition());
      this.calendarWindowState.emit(res);
    });

    /** Блокируем скрол когда календарь открыт. HostListener не умеет работать с passive:false */
    window.addEventListener('wheel', this.scrollEventListener, { passive: false });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    window.removeEventListener('wheel', this.scrollEventListener);
  }

  public confirmDate() {
    this.hideCalendar();
    const formattedDate = this.selectedDate.toISOString(this.isShowLocalTimeZone);

    this.onChange(formattedDate);
    this.onDateConfirmed.emit(formattedDate);
  }

  public getPosition() {
    if (!this.elementRef || !this.elementRef.nativeElement) {
      return {
        left: '0',
        top: '0',
      };
    }

    const calendarEl: HTMLElement = this.elementRef.nativeElement;
    const position = calendarEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const calendarHeight = 250;

    if (windowHeight - position.bottom > calendarHeight) {
      return {
        left: `${position.left + position.width}px`,
        top: `${position.top + position.height}px`,
      };
    }

    return {
      left: `${position.left + position.width}px`,
      top: `${position.top - calendarHeight}px`,
    };
  }

  /** подписка на поток пользовательского ввода */
  private subscribeToUserInputStream(): void {
    this.userInputStream
      .asObservable()
      .pipe(
        map((inputValue: UserInputStreamModel) => this.mapMinMaxDates(inputValue)),
        map((inputValue: UserInputStreamModel) => this.calendarService.movePositionToPermittedPeriod(inputValue)),
        map((inputValue: UserInputStreamModel) => this.calendarService.mapCurrentDate(inputValue)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((inputValue: UserInputStreamModel) => {
        this.selectedDate = inputValue.currentDate;
        this.calendarDisplayPosition = {
          currentDate: inputValue.currentDate,
          datePosition: inputValue.datePosition,
          minDate: inputValue.minDate,
          maxDate: inputValue.maxDate,
        };
      });
  }
}
