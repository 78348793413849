import { PublicationsModel } from '@models/publications/publications.model';
import { Action } from '@ngrx/store';

export enum DocumentPackagePublicationsActionTypes {
  Get = '[Document Package Publications]   Get',
  GetSuccess = '[Document Package Publications]  GetSuccess',
  GetError = '[Document Package Publications]  GetError',
  Update = '[Document Package Publications]  Update',
  UpdateSuccess = '[Document Package Publications]  UpdateSuccess',
  UpdateError = '[Document Package Publications]  UpdateError',
}

export class DocumentPackagePublicationsGet implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.Get;
}

export class DocumentPackagePublicationsGetSuccess implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.GetSuccess;
  constructor(public payload: PublicationsModel) {}
}

export class DocumentPackagePublicationsGetError implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.GetError;
  constructor(public error: any) {}
}

export class DocumentPackagePublicationsUpdate implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.Update;
  constructor(public payload: PublicationsModel) {}
}

export class DocumentPackagePublicationsUpdateSuccess implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.UpdateSuccess;
  constructor(public payload: PublicationsModel) {}
}

export class DocumentPackagePublicationsUpdateError implements Action {
  readonly type = DocumentPackagePublicationsActionTypes.UpdateError;
  constructor(public error: any) {}
}

export type DocumentPackagePublicationsAction =
  | DocumentPackagePublicationsGet
  | DocumentPackagePublicationsGetSuccess
  | DocumentPackagePublicationsGetError
  | DocumentPackagePublicationsUpdate
  | DocumentPackagePublicationsUpdateSuccess
  | DocumentPackagePublicationsUpdateError;
