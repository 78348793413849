import { Pipe, PipeTransform } from '@angular/core';

/**
 * TODO: При необходимости пайп можно будет расширить
 * добавив в него параметр, который будет возвращаться
 * в случае пустого значения (сейчас это символ '-')
 */
@Pipe({
  name: 'isEmptyValue',
})
export class IsEmptyValuePipe implements PipeTransform {
  transform(value: string, emptyMarker = '-'): string {
    if (value !== undefined && value !== null && value !== '') {
      return value;
    }

    return emptyMarker;
  }
}
