import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { SwitchEditorService } from '@appCore/services/third-party/switch-editor.service';
import { LDE_SCRIPT_SRC, LTE_SCRIPT_SRC } from '@appShared/const/editor-scripts.const';
import { environment } from '@environments/environment';

/** Сервис для подключения сторонних модулей к проекту */
@Injectable({
  providedIn: 'root',
})
export class ThirdPartyService {
  private isMounted = false;
  private isLoaded = false;
  private timeoutRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
    private switchEditorService: SwitchEditorService
  ) {}

  /**
   * Подгрузка редактора при инициализации приложения
   * Таймаут нужен, чтоб картинки и шрифты приложения успели подгрузиться до редактора, и приложение не лагало
   */
  public connectLDE(userRoles?: string[]): void {
    this.zone.runOutsideAngular(() => {
      if (!environment.loadLde || this.isLoaded) {
        return;
      }

      if (!this.isMounted) {
        const location = window.location.href;
        const isContainsEditor = location.includes('/editor/');
        const loadEditorTimeout = isContainsEditor ? 0 : 10000;
        this.isMounted = true;
        this.addLteScript(userRoles);
        this.getThirdPartyScript(userRoles).onload = () => {
          this.timeoutRef = setTimeout(() => {
            this.loadLDE();
          }, loadEditorTimeout);
        };
      } else {
        clearTimeout(this.timeoutRef);
        this.addLteScript(userRoles);
        this.getThirdPartyScript(userRoles).onload = () => {
          this.loadLDE();
        };
      }
    });
  }

  private addLteScript(userRoles: string[]): void {
    const ldeScriptSrc = this.switchEditorService.getEditorScriptSrc(userRoles, LTE_SCRIPT_SRC);
    const thirdPartyScript = this.document.createElement('script');
    thirdPartyScript.src = ldeScriptSrc;
    this.document.body.appendChild(thirdPartyScript);
  }

  private getThirdPartyScript(userRoles: string[]): HTMLScriptElement {
    const ldeScriptSrc = this.switchEditorService.getEditorScriptSrc(userRoles, LDE_SCRIPT_SRC);

    const thirdPartyScript = this.document.createElement('script');
    thirdPartyScript.src = ldeScriptSrc;
    this.document.body.appendChild(thirdPartyScript);
    return thirdPartyScript;
  }

  private loadLDE(): void {
    const pathToModule = this.switchEditorService.getPathToModule();

    ldeLoad({
      onProgress: () => {},
      onError: (err) => {
        console.error(`Ошибка загрузки файлов редактора ${err}`);
      },
      pathToModule,
    });

    this.isLoaded = true;
  }
}
