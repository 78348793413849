import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { PermissionModel } from './models/permission.model';
import { PermissionsService } from './permissions.service';

@Directive({
  selector: '[enableByPermission]'
})
export class EnableByPermissionDirective implements AfterViewInit, OnDestroy {
  @Input()
  set enableByPermission(condition: PermissionModel) {
    this.condition$.next(condition);
  }
  condition$ = new ReplaySubject<PermissionModel>();
  destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef, private permissionsService: PermissionsService) {}

  ngAfterViewInit() {
    this.setDisableState();
    this.condition$
      .pipe(
        switchMap(condition => this.getPermissions$(condition)),
        catchError(() => of(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(enabled => {
        if (enabled) {
          this.setEnabledState();
        } else {
          this.setDisableState();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setDisableState(): void {
    this.elementRef.nativeElement.style.pointerEvents = 'none';
    this.elementRef.nativeElement.style.opacity = '0.4';
  }

  setEnabledState(): void {
    this.elementRef.nativeElement.style.pointerEvents = 'auto';
    this.elementRef.nativeElement.style.opacity = '1';
  }

  getPermissions$(condition: PermissionModel): Observable<boolean> {
    if (!condition) {
      return of(false);
    }
    if (!condition.permissions || !condition.type) {
      return of(false);
    }
    return this.permissionsService.getPermissions(condition.permissions, condition.type, condition.id);
  }
}
