import { Injectable } from '@angular/core';
import { DictionaryApiService } from '@appShared/api/dictionary/dictionary.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  SignerCategoriesAction,
  SignerCategoriesActionTypes,
  SignerCategoriesGet,
  SignerCategoriesGetError,
  SignerCategoriesGetSuccess,
} from './signer-categories.actions';

@Injectable()
export class SignerCategoriesEffects {
  constructor(private actions$: Actions, private dictionaryApi: DictionaryApiService) {}

  getSignerCategories$: Observable<SignerCategoriesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SignerCategoriesActionTypes.Get),
      switchMap((action: SignerCategoriesGet) =>
        this.dictionaryApi.getSignerCategories(action.payload).pipe(
          map((response) => new SignerCategoriesGetSuccess(response)),
          catchError(() => of(new SignerCategoriesGetError()))
        )
      )
    )
  );
}
