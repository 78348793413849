/**
 * Имена пунктов основного меню
 */
export enum MenuItemsNames {
  createPackage = 'createPackage',
  createPackageOfDocument = 'createPackageOfDocument',
  dashboard = 'dashboard',
  calendar = 'calendar',
  analytics = 'analytics',
  notifications = 'notifications',
  electronicArchive = 'electronicArchive',
  usefulResources = 'usefulResources'
}
