export enum DocumentTypesEnum {
  /** Документ */
  DOCUMENT = 1,
  /** Приложение */
  ADDITIONAL = 3,
  /** Лист учета замечаний */
  REMARKS_SHEET = 4,
  /** Лист согласования */
  AGREEMENT_SHEET = 5,
  /** Протокол согласительного совещания */
  MEETING_PROTOCOL = 6,
  /** Пояснительная записка */
  EXPLANATORY_NOTE = 7,
  /** Пресс-релиз */
  PRESS_RELEASE = 8,
  /** Копия поручения */
  ORDER_COPY = 9,
  /** Заключение контрольно-счетной палаты */
  ACCOUNTS_CHAMBER_CONCLUSION = 11,
  /** Справка */
  REFERENCE = 13,
  /** Финансово-экономическое обоснование */
  FINANCIAL_RATIONALE = 15,
  /** Копия правового акта */
  LEGAL_ACT_COPY = 16,
  /** Лист согласования с ЭП */
  AGREEMENT_SHEET_ES = 19,
  /** Карточка приема */
  ACCEPT_CARD = 20,
  /** Лист выдачи */
  ISSUE_SHEET = 21,
  /** Лист разногласий */
  DISAGREEMENT_SHEET = 22,
  /** Протокол совещания */
  CALENDAR_MEETING_PROTOCOL = 23,
  /** Протокол заседания */
  SESSION_PROTOCOL = 24,
  /** Повестка */
  AGENDA = 25,
  /** Аналитическая записка */
  ANALYTICAL_NOTE = 28,
  /** Протокол заседания Президиума ПМ */
  PRESIDIUM_MEETING_PROTOCOL = 29,
  /** О внесении изменений */
  AMENDMENT = 30,
  /** Выписка из протокола */
  PROTOCOL_EXTRACT = 31,
  /** Внутренняя опись */
  INTERNAL_INVENTORY = 32,
  /** Сводная аналитическая записка */
  SUMMARY_ANALYTICAL_NOTE = 33,
  /** Протокол согласительного совещания */
  CONCILIATION_MEETING_PROTOCOL = 34,
  /** Список участников */
  PARTICIPANT_LIST = 35,
  /** Повестка согласительного совещания */
  CONCILIATION_MEETING_AGENDA = 36,
  /** Повестка заседания ПМ */
  MEETING_AGENDA = 37,
  /** Повестка заседания Президиума */
  PRESIDIUM_MEETING_AGENDA = 38,
  /** Лист-заверитель */
  WITNESS_SHEET = 39,
  /** Опись */
  INVENTORY = 40,
  /** Акт */
  DELIVERY_ACCEPTANCE_ACT = 41,
  /** Лист-разделитель */
  SPLIT_LIST = 42,
  /** Документ отчета в формате DOCX */
  REPORT_DOCX = 43,
  /** Документ отчета в формате PDF */
  REPORT_PDF = 44,
  /** Выписка из протокола */
  PROTOCOL_EXTRACT_WEB = 45,
  /** Сопроводительное письмо */
  COVERING_LETTER = 46,
  /** Заключение */
  CONCLUSION = 47,
  /** Проект дополнительного соглашения к инвестиционному контракту */
  ADDITIONAL_AGREEMENT_INVESTMENT_CONTRACT = 48,
  /** Копия сопроводительного документа */
  COPY_ACCOMPANYING_DOCUMENT = 49,
  /** Лист согласования проекта дополнительного соглашение к инвестиционному контракту документа */
  AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT = 50,
  /** Лист согласования проекта дополнительного соглашение к инвестиционному контракту документа с ЭП */
  AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT_ES = 51,
  /** Экспертная повестка заседания ПМ */
  EXPERT_AGENDA_SITTING_PM = 52,
  /** Экспертная повестка заседания Президиума ПМ */
  EXPERT_AGENDA_SITTING_PPM = 53,
  /** Итоговая повестка заседания ПМ */
  FINAL_AGENDA_SITTING_PM = 54,
  /** Итоговая повестка заседания Президиума ПМ */
  FINAL_AGENDA_SITTING_PPM = 55,
  /** Документ версии для опубликования */
  DOCUMENT_PUBLICATION = 56,
  /** Главный документ (ЛПА) */
  INTERNAL_DOCUMENT = 57,
  /** Пояснительная записка (ЛПА) */
  INTERNAL_EXPLANATORY_NOTE = 58,
  /** Экспертная повестка заседания ПМ с экспертизой */
  EXPERT_AGENDA_SITTING_PM_EXPERTISE = 59,
  /** Экспертная повестка заседания Президиума ПМ с экспертизой */
  EXPERT_AGENDA_SITTING_PPM_EXPERTISE = 60,
  /** Итоговая повестка заседания ПМ с экспертизой */
  FINAL_AGENDA_SITTING_PM_EXPERTISE = 61,
  /** Итоговая повестка заседания Президиума ПМ с экспертизой */
  FINAL_AGENDA_SITTING_PPM_EXPERTISE = 62,
  /** Документ без шаблона */
  OTHER_MEETING_DOCUMENT = 63,
  /** Приложение (ЛПА) */
  INTERNAL_ADDITIONAL = 66,
  /** Документ версии для опубликования (для ЛПА) */
  DOCUMENT_PUBLICATION_LPA = 67,
  /** Сопроводительный материал */
  COVERING_MATERIAL = 90,
  /** Протокол рассмотрения на совещании */
  CONSIDIRATION_MEETING_PROTOCOL = 94,
  /** Электронная таблица */
  SPREADSHEET = 1001,
}
