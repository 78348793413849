import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemModel } from '@appShared/components/menu/models/menu-item.model';
import { MenuItemsNames } from '@appShared/components/menu/enums/menu-items-names.enum';

@Pipe({
  name: 'isCreateDocumentRoute',
})
export class IsCreateDocumentRoutePipe implements PipeTransform {
  transform(item: MenuItemModel, routeUrl: string): boolean {
    if (item.name !== MenuItemsNames.createPackage) {
      return false;
    }

    const patternDocument: RegExp = /\/documents\//;

    return patternDocument.test(routeUrl);
  }
}
