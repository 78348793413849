import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input()
  isBlackTheme: boolean;

  @Input()
  hasParentSize: boolean;

  @Input()
  color: string;

  @Input()
  topColor: string = 'transparent';

  @Input()
  borderWidth: string;

  /** (1/4 часть закрашенная, 3/4 часть полупрозрачная) */
  @Input()
  inversionParts: boolean;
}
