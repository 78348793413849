import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxInputLength]',
})
export class MaxInputLengthDirective {
  @Input('appMaxInputLength') lengthNumber: number;

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent): boolean {
    const target = event.target as HTMLInputElement;
    if (target.value.length > this.lengthNumber) {
      return false;
    }
  }
}
