import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CalendarConsiderationApiService } from '@appShared/api/calendar-consideration/calendar-consideration-api.service';
import { ControlBaseComponent } from '@appShared/components/controls/control-base/control-base.component';
import { DictionaryModel } from '@models/base/dictionary.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize, first,
  map
} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-calendar-places',
  templateUrl: './autocomplete-calendar-places.component.html',
  styleUrls: ['./autocomplete-calendar-places.component.scss'],
})
export class AutocompleteCalendarPlacesComponent extends ControlBaseComponent implements OnInit, OnDestroy {
  /** Показывать крестик сброса */
  @Input()
  isShowClearBtn = false;
  /** Текст ошибки поиска */
  @Input()
  searchErrorMsg: string;
  /** Подсказка при поиске */
  @Input()
  searchTooltip: string;

  @Input()
  canEdit: boolean;

  public editControl: UntypedFormControl = new UntypedFormControl();
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public optionsList$: Observable<DictionaryModel[]>;
  public isShowEditPopup = false;
  public optionLabel: string;
  public isLoaderActive: boolean;

  private considerationPlaces: DictionaryModel[];

  private editPlacesNameBSubject$ = new BehaviorSubject<DictionaryModel>({} as DictionaryModel);

  constructor(private apiService: CalendarConsiderationApiService) {
    super();
  }

  ngOnInit() {
    this.optionLabel = 'name';
    this.isLoaderActive = true;

    this.subscribeToInputFilter();
    this.getConsiderationPlaces();
  }

  onNameUpdate() {
    const oldValue = this.control.value;
    const newName = this.editControl.value;

    this.apiService.editConsiderationPlacesName({ id: oldValue.id, name: newName }).pipe(
      first(),
    ).subscribe(
      (res) => {
        this.editPlacesNameBSubject$.next(res);
        this.isShowEditPopup = false;
        this.filterControl.setValue(res);
        this.control.setValue(res);
      },
      (err) => (this.isShowEditPopup = false)
    );
  }

  onEditStart() {
    this.isShowEditPopup = true;
    this.editControl.setValue(this.control.value.name);
  }

  /** Редактирование возможно только для уже созданого места */
  isEditMode() {
    return this.canEdit && this.control.value && this.control.value.id;
  }

  /** подписка на изменение поля фильтрации */
  private subscribeToInputFilter(): void {
    this.optionsList$ = combineLatest([this.filterControl.valueChanges, this.editPlacesNameBSubject$]).pipe(
      filter(() => !!this.considerationPlaces?.length),
      distinctUntilChanged(),
      debounceTime(300),
      map(([filterString, placesName]) => {

        if (placesName?.id) {
          this.considerationPlaces = this.considerationPlaces.filter((el) => el.id !== placesName.id).concat([placesName]);
          this.editPlacesNameBSubject$.next({} as DictionaryModel);
          return this.considerationPlaces;
        }

        if (filterString && filterString.length > 2) {
          this.setInputValue(filterString);
          return this.considerationPlaces.filter((place) => place.name.includes(filterString));
        }

        return this.considerationPlaces;
      })
    );
  }

  /** Оставляем введенное значение в контроле, для случая когда нужное место не найдено/не выбрано */
  private setInputValue(inputValue: string): void {
    const controlValue = { id: null, name: inputValue };

    this.control.patchValue(controlValue);
  }

  private getConsiderationPlaces(): void {
    this.apiService.getConsiderationPlacesName().pipe(
      first(),
      finalize(() => {
        this.isLoaderActive = false;
      })
    ).subscribe((res) => {
      this.considerationPlaces = res;
    });
  }

  ngOnDestroy(): void {
    this.editPlacesNameBSubject$.next({} as DictionaryModel);
    this.editPlacesNameBSubject$.complete();
  }
}
