import { DictionaryWithDescriptionModel } from '@models/base/dictionary-with-description.model';
import { Action } from '@ngrx/store';

export enum AlertPeriodTypesActionTypes {
  Get = '[Alert Period Types] Get',
  GetSuccess = '[Alert Period Types] GetSuccess',
  GetError = '[Alert Period Types] GetError',
}

export class AlertPeriodTypesGet implements Action {
  readonly type = AlertPeriodTypesActionTypes.Get;
}

export class AlertPeriodTypesGetError implements Action {
  readonly type = AlertPeriodTypesActionTypes.GetError;
}

export class AlertPeriodTypesGetSuccess implements Action {
  readonly type = AlertPeriodTypesActionTypes.GetSuccess;
  constructor(public payload: DictionaryWithDescriptionModel[]) {}
}

export type AlertPeriodTypesAction = AlertPeriodTypesGet | AlertPeriodTypesGetError | AlertPeriodTypesGetSuccess;
