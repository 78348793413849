import { PopupAction, PopupActionTypes } from './popup.actions';

export interface PopupState {
  open: boolean;
}

const DEFAULT_STATE: PopupState = { open: false };

export function popupReducer(state: PopupState = DEFAULT_STATE, action: PopupAction) {
  switch (action.type) {
    case PopupActionTypes.Open:
      return {
        open: true
      };

    case PopupActionTypes.Close:
      return {
        open: false
      };

    default:
      return { ...state };
  }
}
