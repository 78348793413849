import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AnnouncementModel } from '@models/announcement/announcement.model';
import { DOCUMENT } from '@angular/common';
import { Unsubscriber } from '@appCore/glb/unsubscriber';
import { UserInfoResponseModel } from '@models/user-info.model';
import { AnnouncementService } from '@appShared/services/announcement.service';
import { ANNOUNCEMENT_KEY } from '@appShared/const/announcement-key.const';

@Component({
  selector: 'app-announcement-content',
  templateUrl: './announcement-content.component.html',
  styleUrls: ['./announcement-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementContentComponent extends Unsubscriber implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  public announcement: AnnouncementModel;
  @Input()
  public userInfo: UserInfoResponseModel;
  @Output()
  public closeAnnouncement: EventEmitter<void> = new EventEmitter();
  public showExpand: boolean;
  public isExpanded = false;
  private maxHeight = 56;

  @ViewChild('wrapper')
  text: ElementRef;

  constructor(
    private announcementService: AnnouncementService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateStyles();
  }

  ngAfterViewInit(): void {
    this.initializeExpand();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public onClose(): void {
    this.isExpanded = false;
    const items = JSON.parse(localStorage.getItem(ANNOUNCEMENT_KEY)) || [];
    localStorage.setItem(
      ANNOUNCEMENT_KEY,
      JSON.stringify([...items, { [this.userInfo?.currentUser.employee.id]: this.announcement.id }])
    );
    this.closeAnnouncement.emit();
    this.announcementService.setIsAnnouncementExpanded(this.isExpanded);
  }

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.announcementService.setIsAnnouncementExpanded(this.isExpanded);
  }

  private initializeExpand(): void {
    const element = this.text?.nativeElement?.querySelector('.text-mirror');
    this.showExpand = (element?.offsetHeight || 0) > this.maxHeight;
  }

  private updateStyles(): void {
    const stylesElement = this.document.querySelector('#announcement-styles');
    if (stylesElement) {
      stylesElement.remove();
    }

    const css = this.announcement?.text?.match(/<style>(.*)<\/style>/gms)?.[0].replace(/<style>|<\/style>/gms, '');
    if (!css) {
      return;
    }
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('style');
    style.setAttribute('id', 'announcement-styles');
    style.appendChild(this.document.createTextNode(css));
    head.appendChild(style);
  }
}
