import { SignedDataModel } from '@models/crypto-pro/crypto-pro.request/crypto-pro-sign-request.model';
import { ESignResponseModel } from '@models/crypto-pro/crypto-pro.response/esign-response.model';
import { Action } from '@ngrx/store';

export enum CryptoProActionTypes {
  Reset = '[CryptoPro ] Reset',
  SaveSuccess = '[CryptoPro ] SaveSuccess',
  SaveError = '[CryptoPro ] SaveError',
  Save = '[CryptoPro ] Save',
  GetSigners = '[CryptoPro ] GetSigners',
  GetSignersSuccess = '[CryptoPro ] GetSignersSuccess',
  GetSignersError = '[CryptoPro ] GetSignersError'
}

export class CryptoProReset implements Action {
  readonly type = CryptoProActionTypes.Reset;
}

export class CryptoProSave implements Action {
  readonly type = CryptoProActionTypes.Save;

  constructor(public payload: SignedDataModel) {}
}

export class CryptoProSaveSuccess implements Action {
  readonly type = CryptoProActionTypes.SaveSuccess;
}

export class CryptoProSaveError implements Action {
  readonly type = CryptoProActionTypes.SaveError;

  constructor(public payload: any) {}
}

export class CryptoProGetSigners implements Action {
  readonly type = CryptoProActionTypes.GetSigners;
  /**
   * @param payload  id пакета документов
   */
  constructor(public payload: number) {}
}

export class CryptoProGetSignersSuccess implements Action {
  readonly type = CryptoProActionTypes.GetSignersSuccess;

  constructor(public payload: ESignResponseModel[]) {}
}

export class CryptoProGetSignersError implements Action {
  readonly type = CryptoProActionTypes.GetSignersError;

  constructor(public payload: any) {}
}

export type CryptoProAction =
  | CryptoProReset
  | CryptoProSaveSuccess
  | CryptoProSaveError
  | CryptoProSave
  | CryptoProGetSigners
  | CryptoProGetSignersSuccess
  | CryptoProGetSignersError;
