import { NavigationExtras, Params } from '@angular/router';
import { Action } from '@ngrx/store';

export enum RouterActionTypes {
  Go = '[Router] Go',
  ChangeQuery = '[Router] Change Query',
  ToggleQuery = '[Router] Toggle Query',
  Back = '[Router] Back',
  Forward = '[Router] Forward',
  Clear = '[Router] Clear'
}

export class Go implements Action {
  readonly type = RouterActionTypes.Go;
  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

/**
 * Изменение query параметров с сохранением текущего адреса.
 * При изменении используется стратегия merge - старые параметры будут сохранены, если не переопределены в новом наборе параметров.
 * Что бы удалить старый параметр его необходимо переопределить null,
 * Если необходимо полностью заменить старую строку запроса лучше использовать действие Go пустым url.
 */
export class ChangeQuery implements Action {
  readonly type = RouterActionTypes.ChangeQuery;
  /**
   * @param payload объект содержащий набор параметров
   */
  constructor(public payload: Params) {}
}

/**
 * Изменение указанных параметров с сохранением текущего адреса и других параметров.
 * Указанный в payload параметр может сделать два действия:
 * 1. Добавиться в строку адреса, если его там нет
 * 2. Принять новое значение
 */
export class ToggleParams implements Action {
  readonly type = RouterActionTypes.ToggleQuery;
  /**
   * @param payload объект содержащий набор параметров
   */
  constructor(public payload: Params) {}
}

export class Back implements Action {
  readonly type = RouterActionTypes.Back;
}

export class Forward implements Action {
  readonly type = RouterActionTypes.Forward;
}

export class ClearQueryParams implements Action {
  readonly type = RouterActionTypes.Clear;
}

export type Actions = Go | Back | Forward | ClearQueryParams;
