import { DictionaryStoreModel } from '@models/dictionary-store.model';
import { DocumentStatusValueModel } from '@models/document-statuses/document-status-value.model';
import { StoreLoadStatus } from '@models/enums/store-load-status.enum';
import { DocumentStatusValuesActions, DocumentStatusValuesActionTypes } from './document-status-values.actions';

const DEFAULT_STATE = new DictionaryStoreModel<DocumentStatusValueModel>();

export function documentStatusValuesReducer(
  state: DictionaryStoreModel<DocumentStatusValueModel> = DEFAULT_STATE,
  action: DocumentStatusValuesActions
): DictionaryStoreModel<DocumentStatusValueModel> {
  switch (action.type) {
    case DocumentStatusValuesActionTypes.Reset:
      return DEFAULT_STATE;

    case DocumentStatusValuesActionTypes.Get:
      return {
        ...state,
        loadStatus: StoreLoadStatus.inProgress,
      };
    case DocumentStatusValuesActionTypes.GetSuccess:
      return {
        ...state,
        dictionary: action.payload,
        loadStatus: StoreLoadStatus.loaded,
      };
    case DocumentStatusValuesActionTypes.Error:
      return {
        ...DEFAULT_STATE,
        loadStatus: StoreLoadStatus.error,
      };

    default:
      return state;
  }
}

/** Получение статуса загрузк */
export const getLoadStatus = (store) => store.loadStatus;
/** Получение словаря */
export const getDocumentStatusValues = (store) => store.dictionary;
