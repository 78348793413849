import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EaLinkComponent } from '@appDocuments/shared/components/ea-link/ea-link.component';
import { PermissionsModule } from '@appPermissions/permissions.module';
import { AlertPopupComponent } from '@appShared/components/alert-popup/alert-popup.component';
import { ButtonsModule } from '@appShared/components/buttons/buttons.module';
import { ConfirmationPopupComponent } from '@appShared/components/confirmation-popup/confirmation-popup.component';
import { AttachFileComponent } from '@appShared/components/controls/attach-file/attach-file.component';
import { AutocompleteDocumentPackagesComponent } from '@appShared/components/controls/autocomplete-document-packages/autocomplete-document-packages.component';
import { AutocompleteEmployeeComponent } from '@appShared/components/controls/autocomplete-employee/autocomplete-employee.component';
import { AutocompleteOrganizationComponent } from '@appShared/components/controls/autocomplete-organization/autocomplete-organization.component';
import { AutocompleteComponent } from '@appShared/components/controls/autocomplete/autocomplete.component';
import { FilteredMultiSelectComponent } from '@appShared/components/controls/filtered-multi-select/filtered-multi-select.component';
import { InputCalendarModule } from '@appShared/components/controls/input-calendar/input-calendar.module';
import { InputTextComponent } from '@appShared/components/controls/input-text/input-text.component';
import { MultiSelectTreeComponent } from '@appShared/components/controls/multi-select-tree/multi-select-tree.component';
import { MultiSelectComponent } from '@appShared/components/controls/multi-select/multi-select.component';
import { NewEmployeeSubFormComponent } from '@appShared/components/controls/new-employee-sub-form/new-employee-sub-form.component';
import { SelectBaseComponent } from '@appShared/components/controls/select/select-base-component/select.base';
import { SelectComponent } from '@appShared/components/controls/select/select/select.component';
import { TextareaComponent } from '@appShared/components/controls/textarea/textarea.component';
import { CreateAppealPopupComponent } from '@appShared/components/create-appeal-popup/create-appeal-popup.component';
import { DelegatingPopupComponent } from '@appShared/components/delegating-popup/delegating-popup.component';
import { FormValidationErrorPopupComponent } from '@appShared/components/form-validation-error-popup/form-validation-error-popup.component';
import { ContentHeaderComponent } from '@appShared/components/headers/content-header/content-header.component';
import { LogoutComponent } from '@appShared/components/logout/logout.component';
import { MassPrintSelectTypePopupComponent } from '@appShared/components/mass-print-select-type-popup/mass-print-select-type-popup.component';
import { MenuModule } from '@appShared/components/menu/menu.module';
import { PopupButtonsBlockComponent } from '@appShared/components/popup-buttons-block/popup-buttons-block.component';
import { PopupTapComponent } from '@appShared/components/popup-tap/popup-tap.component';
import { PopupTitleComponent } from '@appShared/components/popup-title/popup-title.component';
import { PopupComponent } from '@appShared/components/popup/popup.component';
import { TooltipModule } from '@appShared/components/tooltip/tooltip.module';
import { ClickStopPropagationDirective } from '@appShared/directives/click-stop-propagation.directive';
import { InputRefTrimValueDirective } from '@appShared/directives/input-ref-trim-value.directive';
import { ScrollOffsetTopDirective } from '@appShared/directives/scroll-offset-top.directive';
import { SharedPipesModule } from '@appShared/pipes/shared-pipes.module';
import { ComponentLibModule } from '@libs/projects/component-lib/src/public-api';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AlertConfirmationPopupComponent } from './alert-confirmation-popup/alert-confirmation-popup.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { AnnouncementContentComponent } from './announcement/components/announcement-content/announcement-content.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { LayoutHeaderComponent } from './base-layout/layout-header/layout-header.component';
import { LayoutMenuComponent } from './base-layout/layout-menu/layout-menu.component';
import { CollapseArrowComponent } from './collapse-arrow/collapse-arrow.component';
import { AutocompleteCalendarPlacesComponent } from './controls/autocomplete-calendar-places/autocomplete-calendar-places.component';
import { CheckboxSwitchComponent } from './controls/checkbox-switch/checkbox-switch.component';
import { CheckboxComponent } from './controls/checkbox/checkbox.component';
import { DelegatingPositionComponent } from './controls/delegating-position/delegating-position.component';
import { ErrorAlertComponent } from './controls/form-field/error-alert/error-alert.component';
import { ErrorMarkDirective } from './controls/form-field/error-mark.directive';
import { FormFieldComponent } from './controls/form-field/form-field.component';
import { HeaderSearchInputComponent } from './controls/header-search-input/header-search-input.component';
import { InputRadioComponent } from './controls/input-radio/input-radio.component';
import { GroupItemSearchSelectComponent } from './controls/select/group-item-search-select/group-item-search-select.component';
import { SelectRadioComponent } from './controls/select/select-radio/select-radio.component';
import { SectionComponent } from './headers/section/section.component';
import { LoadersModule } from './loaders/loaders.component';
import { RedirectToAppealsPopupComponent } from './redirect-to-appeals-popup/redirect-to-appeals-popup.component';
import { TimeSliderComponent } from './time-slider/time-slider.component';
import { TooltipCdkComponent } from './tooltip-cdk/tooltip-cdk.component';

const components = [
  LogoutComponent,
  TextareaComponent,
  InputTextComponent,
  SelectComponent,
  AttachFileComponent,
  PopupComponent,
  AutocompleteComponent,
  AutocompleteEmployeeComponent,
  AutocompleteDocumentPackagesComponent,
  ConfirmationPopupComponent,
  ContentHeaderComponent,
  FormValidationErrorPopupComponent,
  NewEmployeeSubFormComponent,
  MultiSelectComponent,
  MultiSelectTreeComponent,
  FilteredMultiSelectComponent,
  AutocompleteOrganizationComponent,
  GroupItemSearchSelectComponent,
  SelectBaseComponent,
  PopupTitleComponent,
  PopupButtonsBlockComponent,
  PopupTapComponent,
  TimeSliderComponent,
  AutocompleteCalendarPlacesComponent,
  DelegatingPopupComponent,
  BaseLayoutComponent,
  LayoutHeaderComponent,
  LayoutMenuComponent,
  SectionComponent,
  InputRadioComponent,
  HeaderSearchInputComponent,
  CheckboxComponent,
  FormFieldComponent,
  ErrorAlertComponent,
  CheckboxSwitchComponent,
  MassPrintSelectTypePopupComponent,
  SelectRadioComponent,
  CreateAppealPopupComponent,
  RedirectToAppealsPopupComponent,
  AlertPopupComponent,
  TooltipCdkComponent,
  DelegatingPositionComponent,
  CollapseArrowComponent,
  AnnouncementComponent,
  AnnouncementContentComponent,
  EaLinkComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LoadersModule,
    ButtonsModule,
    TooltipModule,
    InputCalendarModule,
    SharedPipesModule,
    NgxSliderModule,
    PermissionsModule,
    InfiniteScrollModule,
    MenuModule,
    OverlayModule,
    ComponentLibModule,
  ],
  declarations: [
    ...components,
    ErrorMarkDirective,
    ScrollOffsetTopDirective,
    InputRefTrimValueDirective,
    ClickStopPropagationDirective,
    AlertConfirmationPopupComponent,
  ],
  exports: [
    LoadersModule,
    ButtonsModule,
    TooltipModule,
    InputCalendarModule,
    ...components,
    ErrorMarkDirective,
    ScrollOffsetTopDirective,
    MenuModule,
    InputRefTrimValueDirective,
    ClickStopPropagationDirective,
    ComponentLibModule,
  ],
})
export class SharedComponentsModule {}
