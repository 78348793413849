import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryModel } from '@models/base/dictionary.model';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';

@Pipe({
  name: 'projectGroupTypeClassname',
})
export class ProjectGroupTypeClassnamePipe implements PipeTransform {
  transform(groupType: DictionaryModel): string {
    if (!groupType) {
      return '';
    }

    return isLocalLegalAct(groupType.id) ? 'local' : '';
  }
}

export function isLocalLegalAct(groupTypeId: number): boolean {
  if (!groupTypeId) {
    return false;
  }

  return groupTypeId === ProjectGroupType.LOCAL_LEGAL_ACTS;
}
