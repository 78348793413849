import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sortArray'
})
export class SortArrayPipe implements PipeTransform {
  /**
   * Сортировка массива по возрастанию
   * @param array Исходный массив
   * @param key поле, по которому будет происходить сортировка
   * @param isReverse флаг обратной сортировки (по убыванию)
   */

  transform(array: any[], key: string, isReverse?: boolean): any[] {
    if (!array) {
      return [];
    }

    if (isReverse) {
      return array.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    }
    return array.sort((a, b) => (a[key] > b[key] ? 1 : -1));
  }
}
