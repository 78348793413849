import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { RRWebInitData } from '@models/rrweb/rrweb-init.model';
import { UserInfoResponseModel } from '@models/user-info.model';
import { RRWebOrigins } from '@sharedEnums/rrweb-origins.enum';
import { RrwebTypesCodes } from '@sharedEnums/rrweb-types-codes.enum';
import * as rrweb from 'rrweb';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RRWebService {
  private events: any[] = [];
  private eventsToSend: any[] = [];
  private stopFn: any;
  private initData$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private zone: NgZone) {}

  public initRrweb(userInfo: UserInfoResponseModel, section: RrwebTypesCodes) {
    if (userInfo.realUser.employee.underRecordingActions) {
      this.startRecording(userInfo, section);
    }
  }

  public stopRecording() {
    if (this.stopFn) {
      this.stopFn();
      this.events = [];
    }
  }

  private startRecording(userInfo: UserInfoResponseModel, section: RrwebTypesCodes) {
    this.zone.runOutsideAngular(() => {
      this.stopFn = rrweb.record({
        emit: (event) => {
          this.events.push(event);
        },
        inlineStylesheet: false,
      });

      this.initSession(userInfo, section).subscribe((initData: RRWebInitData) => {
        this.initData$.next(initData);
      });

      this.timeoutLoop();
    });
  }

  private initSession(userInfo: UserInfoResponseModel, section: RrwebTypesCodes): any {
    return this.http.post<RRWebInitData>('/rrweb/session/init', {
      origin: RRWebOrigins.aissd,
      section: section,
      username: userInfo.currentUser.employee.login,
    });
  }

  private saveEventsToServer(sessionId: string, events: any[]): any {
    if (!sessionId || !events.length) {
      return of(null);
    }
    return this.http.post<void>(`/rrweb/events/${sessionId}`, events);
  }

  private timeoutLoop() {
    setTimeout(() => {
      this.eventsToSend = [...this.events];
      this.events = [];

      const initData = this.initData$.getValue();
      this.saveEventsToServer(initData?.id, this.eventsToSend).subscribe(() => {
        this.timeoutLoop();
      });
    }, 5 * 1000);
  }
}
