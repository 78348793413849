import { DocumentTypeModel } from '@models/document/document-type.model';
import { ProjectGroupType } from '@models/enums/project-group-type.enum';
import { Action } from '@ngrx/store';

export enum DocumentTypesAvailableActionTypes {
  Get = '[Document Types Available] Get',
  GetSuccess = '[Document Types Available] Get Success',
  GetError = '[Document Types Available] Get Error',
  Reset = '[Document Types Available] Reset',
}

export class DocumentTypesAvailableGet implements Action {
  readonly type = DocumentTypesAvailableActionTypes.Get;
  constructor(public payload?: { projectGroupType: ProjectGroupType; documentPackageId: number }) {}
}

export class DocumentTypesAvailableGetError implements Action {
  readonly type = DocumentTypesAvailableActionTypes.GetError;
}

export class DocumentTypesAvailableGetSuccess implements Action {
  readonly type = DocumentTypesAvailableActionTypes.GetSuccess;
  constructor(public payload: DocumentTypeModel[]) {}
}

export class DocumentTypesAvailableReset implements Action {
  readonly type = DocumentTypesAvailableActionTypes.Reset;
}

export type DocumentTypesAvailableAction =
  | DocumentTypesAvailableGet
  | DocumentTypesAvailableGetError
  | DocumentTypesAvailableGetSuccess
  | DocumentTypesAvailableReset;
