/** Типы пользователей на маршруте */
export enum EmployeeRouteTypes {
  /** Согласующий */
  coordinator = 1,
  /** Исполнитель */
  executor = 2,
  /** Автор */
  author = 3,
  /** Соавтор */
  inspector = 4,
  /** Руководитель */
  leader = 5,
  /** Контролер */
  controller = 6,
  /** Инициатор */
  initiator = 8,
  /** Соисполнитель */
  coexecutor = 9,
  /** Ознакомитель */
  introducer = 10,
  /** Делегат */
  delegate = 11,
}
